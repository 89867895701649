<template>
  <div class="row relative-position flex-1 q-py-xl q-px-xl pb-0">
    <!-- Right Column -->
    <div class="flex-y flex-1">
      <div class="flex-1 scroll-y">
        <form v-on:submit.prevent class="bg-blue-grey-1 q-px-xl q-pt-lg">
          <span class="label-span">Name on Card*</span>
          <q-input
            v-model="nameoncard"
            @input="$v.nameoncard.$touch()"
            @blur="$v.nameoncard.$touch()"
            outlined
            placeholder="Name on Card"
            type="text"
            class="q-mb-sm "
            bottom-slots
            :error="fieldErrors('nameoncard').length > 0"
            no-error-icon
            input-class="caps-input"
            bg-color="white"
          >
            <template v-slot:error>
              <div class="text-pink">
                {{
                  fieldErrors("nameoncard").length > 0
                    ? fieldErrors("nameoncard")[0]
                    : ""
                }}
              </div>
            </template>
          </q-input>
          <stripe-element @error="stripeErrorHandler" ref="card" />
          <p v-show="invalidCodeMessage" class="mt-4 pink--text">
            {{ invalidCodeMessage }}
          </p>
          <p v-show="cardError" class="mt-4 pink--text">{{ cardError }}</p>
          <span class="label-span mt-4">Billing Info:</span>
          <q-input
            v-model="addressForm.street_one"
            @input="$v.addressForm.street_one.$touch()"
            @blur="$v.addressForm.street_one.$touch()"
            outlined
            placeholder="Address line 1"
            type="text"
            class="q-mb-sm"
            bottom-slots
            :error="fieldErrors('addressForm.street_one').length > 0"
            no-error-icon
            input-class="caps-input"
            bg-color="white"
          >
            <template v-slot:error>
              <div class="text-pink">
                {{
                  fieldErrors("addressForm.street_one").length > 0
                    ? fieldErrors("addressForm.street_one")[0]
                    : ""
                }}
              </div>
            </template>
          </q-input>
          <q-input
            v-model="addressForm.street_two"
            outlined
            placeholder="Address line 2"
            class="q-mb-lg"
            type="text"
            bg-color="white"
          />
          <div class="row">
            <q-input
              v-model="addressForm.city"
              @input="$v.addressForm.city.$touch()"
              @blur="$v.addressForm.city.$touch()"
              outlined
              placeholder="City"
              type="text"
              class="q-mb-lg"
              bottom-slots
              :error="fieldErrors('addressForm.city').length > 0"
              no-error-icon
              style="margin-right: 18px;max-width: 47%;"
              bg-color="white"
            >
              <template v-slot:error>
                <div class="text-pink">
                  {{
                    fieldErrors("addressForm.city").length > 0
                      ? fieldErrors("addressForm.city")[0]
                      : ""
                  }}
                </div>
              </template>
            </q-input>
            <q-input
              v-model="addressForm.zipcode"
              @input="$v.addressForm.zipcode.$touch()"
              @blur="$v.addressForm.zipcode.$touch()"
              outlined
              placeholder="Zip/Postal"
              type="text"
              class="q-mb-lg q-ml-sm flex-1"
              bottom-slots
              :error="fieldErrors('addressForm.zipcode').length > 0"
              no-error-icon
              input-class="caps-input"
              bg-color="white"
            >
              <template v-slot:error>
                <div class="text-pink">
                  {{
                    fieldErrors("addressForm.zipcode").length > 0
                      ? fieldErrors("addressForm.zipcode")[0]
                      : ""
                  }}
                </div>
              </template>
            </q-input>
          </div>
          <div class="row">
            <q-input
              v-model="addressForm.state"
              @input="$v.addressForm.state.$touch()"
              @blur="$v.addressForm.state.$touch()"
              outlined
              placeholder="State/Province"
              type="text"
              class="q-mb-lg"
              bottom-slots
              :error="fieldErrors('addressForm.state').length > 0"
              no-error-icon
              style="margin-right: 18px;max-width: 47%;"
              bg-color="white"
            >
              <template v-slot:error>
                <div class="text-pink">
                  {{
                    fieldErrors("addressForm.state").length > 0
                      ? fieldErrors("addressForm.state")[0]
                      : ""
                  }}
                </div>
              </template>
            </q-input>
            <q-input
              v-model="addressForm.country"
              @input="$v.addressForm.country.$touch()"
              @blur="$v.addressForm.country.$touch()"
              outlined
              placeholder="Country"
              type="text"
              class="q-mb-lg q-ml-sm flex-1"
              bottom-slots
              :error="fieldErrors('addressForm.country').length > 0"
              no-error-icon
              input-class="caps-input"
              bg-color="white"
            >
              <template v-slot:error>
                <div class="text-pink">
                  {{
                    fieldErrors("addressForm.country").length > 0
                      ? fieldErrors("addressForm.country")[0]
                      : ""
                  }}
                </div>
              </template>
            </q-input>
          </div>
        </form>
      </div>

      <div class="text-xs-right">
        <q-btn
          color="teal-14"
          label="Save"
          class="q-my-sm q-py-sm"
          style="min-width:230px"
          :loading="loader"
          :disabled="isDisabled"
          @click.native="createNewPayCard"
        />
      </div>
    </div>
    <q-btn
      flat
      :icon="$icons.matArrowBack"
      class="absolute-top-left q-ml-sm"
      round
      color="dark"
      size="sm"
      @click="$emit('back')"
    />
  </div>
</template>

<script>
import validationMixin from "@/mixins/validationMixin";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import StripeElement from "@/components/StripeElement";

export default {
  name: "RegisterPayCard",
  mixins: [validationMixin],
  props: ["loader", "invalidCodeMessage"],
  components: {
    StripeElement,
  },
  validations: {
    nameoncard: {
      required,
      maxLength: maxLength(40),
      minLength: minLength(2),
    },
    addressForm: {
      street_one: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(2),
      },
      city: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(2),
      },
      zipcode: {
        required,
        minLength: minLength(5),
      },
      state: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(2),
      },
      country: {
        required,
        maxLength: maxLength(40),
        minLength: minLength(2),
      },
    },
  },
  validationMessages: {
    nameoncard: {
      required: "message.validation.cardinfo.nameoncard.required",
      maxLength: "message.validation.cardinfo.nameoncard.maxLength",
      minLength: "message.validation.cardinfo.nameoncard.minLength",
    },
    addressForm: {
      street_one: {
        required: "message.validation.billingaddress.street_one.required",
        maxLength: "message.validation.billingaddress.street_one.maxLength",
        minLength: "message.validation.billingaddress.street_one.minLength",
      },
      city: {
        required: "message.validation.billingaddress.city.required",
        maxLength: "message.validation.billingaddress.city.maxLength",
        minLength: "message.validation.billingaddress.city.minLength",
      },
      zipcode: {
        required: "message.validation.billingaddress.zipcode.required",
        minLength: "message.validation.billingaddress.zipcode.minLength",
      },
      state: {
        required: "message.validation.billingaddress.state.required",
        maxLength: "message.validation.billingaddress.state.maxLength",
        minLength: "message.validation.billingaddress.state.minLength",
      },
      country: {
        required: "message.validation.billingaddress.country.required",
        maxLength: "message.validation.billingaddress.country.maxLength",
        minLength: "message.validation.billingaddress.country.minLength",
      },
    },
  },
  mounted() {
    // this.fetchUser()
  },
  data() {
    return {
      nameoncard: null,
      zipcode: null,
      street_one: null,
      street_two: null,
      city: null,
      country: null,
      state: null,
      addressForm: {
        street_one: "",
        street_two: "",
        city: "",
        zipcode: "",
        state: "",
        country: "",
      },
      cardError: null,
    };
  },
  methods: {
    async createNewPayCard() {
      // It must have NameOnCard
      const result = await this.$refs.card.createToken();
      if (result) {
        const variables = {
          token: {
            id: result.token.id,
            card_id: result.token.card.id,
            nameoncard: this.nameoncard,
            last4: result.token.card.last4,
          },
          address: {
            street1: this.addressForm.street_one,
            street2: this.addressForm.street_two,
            city: this.addressForm.city,
            zipcode: this.addressForm.zipcode,
            state: this.addressForm.state,
            country: this.addressForm.country,
          },
        };
        this.$emit("registerCardEvent", variables);
      }
    },
    stripeErrorHandler(message) {
      this.cardError = message;
    },
  },
  computed: {
    isDisabled() {
      if (this.$v.$invalid) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
