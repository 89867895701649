<template>
  <div
    class="relative-position flex-1 editor-prototype row items-center"
    ref="check"
  >
    <markup-editor
      ref="editor"
      v-model="content"
      :minHeight="minHeight"
      :maxHeight="maxHeight"
      :placeholder="placeholder"
      :isAbPosition="isAbPosition"
      :emojisQuery="emojisQuery"
      @mentionsQuery="mentionsQuery = $event"
      @emojisEvent="emojisQuery = $event"
      @submit="handleSubmit"
      @focus="handleFocus"
      @blur="handleBlur"
      @paste="(data) => $emit('paste', data)"
      @keyPressed="(e) => $emit('keyPressed', e)"
      :autofocus="autofocus"
      @currentPositioning="currentPositioning"
      :isMessagingEditor="isMessagingEditor"
      :updateScrollFlag="updateScroll"
      :setMargin="setMargin"
      :overflow="overflow"
      @escapePressed="$emit('escapePressed')"
      @openAddLink="$emit('openAddLink')"
    />
    <div
      class="editor-options absolute"
      v-if="isMenu && !hideEditorMenu && showEditorMenu"
    >
      <q-btn
        dense
        flat
        round
        size="md"
        padding="0"
        :color="isMessagingEditor ? 'black' : 'grey-5'"
        :icon="$icons.matMoreHoriz"
        :style="
          isMessagingEditor ? { 'margin-top': '4px' } : { 'margin-top': '7px' }
        "
        v-if="showMenu"
      >
        <q-menu content-class="overflow-auto" :offset="[75, 0]">
          <q-list>
            <div class="bg-white q-pa-xs">
              <q-btn
                dense
                flat
                round
                size="sm"
                color="grey-5"
                :icon="$icons.matFormatBold"
                @click="toggleBold"
              />
              <q-btn
                dense
                flat
                round
                size="sm"
                color="grey-5"
                :icon="$icons.matFormatItalic"
                @click="toggleItalic"
              />
              <q-btn
                dense
                flat
                round
                size="sm"
                color="grey-5"
                :icon="$icons.mdiAt"
                @click="insertText('@')"
              />
              <q-btn
                dense
                flat
                round
                size="sm"
                color="grey-5"
                :icon="$icons.matTagFaces"
                @click="insertText(':')"
              />
            </div>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <mentions-picker
      :isAbPosition="isAbPosition"
      :topPositions="topPositions"
      :leftPositions="leftPositions"
      :bottomPositions="bottomPosition"
      :query="mentionsQuery"
      :users="mentions"
      @insertMention="insertMention"
    />
    <emoji-picker
      :isAbPosition="isAbPosition"
      :topPositions="topPositions"
      :leftPositions="leftPositions"
      :query="emojisQuery"
      :emojisList="emojiDetail"
      @insertEmoji="insertEmoji"
      :subtaskEditor="subtaskEditor"
    />
  </div>
</template>

<script>
import MarkupEditor from "@/components/Editor/MarkupEditor";
import MentionsPicker from "@/components/Editor/MentionsPicker";
import EmojiPicker from "@/components/Editor/EmojiPicker";
import emojiData from "@/components/Editor/emoji.json";

export default {
  name: "Editor",
  props: {
    value: String,
    autofocus: Boolean,
    minHeight: {
      type: Number,
      default: 23,
    },
    maxHeight: {
      type: Number,
      default: 105,
    },
    mentions: Array,
    isMenu: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
    subtaskEditor: Boolean,
    isAbPosition: {
      type: Boolean,
      default: false,
    },
    bottomPositions: {
      type: Boolean,
      default: false,
    },
    hideEditorMenu: {
      type: Boolean,
      default: false,
    },
    isMessagingEditor: {
      type: Boolean,
      default: false,
    },
    showEditorMenu: {
      type: Boolean,
      default: true,
    },
    updateScroll: {
      type: Boolean,
      default: true,
    },
    setMargin: Boolean,
    showMenu: {
      type: Boolean,
      default: true,
    },
    overflow: {
      type: String,
      default: "hidden",
    },
    fromReplyEditor: {
      default: false,
    },
  },
  components: {
    MarkupEditor,
    MentionsPicker,
    EmojiPicker,
  },
  data() {
    return {
      emojisQuery: null,
      mentionsQuery: null,
      topPositions: 0,
      leftPositions: 0,
      bottomPosition: 0,
    };
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    emojiDetail() {
      let result = [];
      for (var i in emojiData)
        result.push({
          name: i,
          char: emojiData[i],
        });
      return result;
    },
  },
  methods: {
    currentPositioning(position) {
      if(this.bottomPositions) {
        this.topPositions = 'inherit';
        this.bottomPosition = 28;
      }else{
        this.topPositions = position.top;
      }
      if (this.fromReplyEditor) {
        this.leftPositions = -57;
      } else {
        this.leftPositions = position.left;
      }
    },
    toggleBold() {
      this.$refs.editor.toggleMark("*");
    },
    toggleItalic() {
      this.$refs.editor.toggleMark("_");
    },
    insertText(text) {
      this.$refs.editor.insertText(text);
    },
    insertEmoji(char) {
      this.$refs.editor.insertEmoji(char);
    },
    insertMention(username) {
      this.$refs.editor.insertMention(username);
      this.mentionsQuery = null;
    },
    handleSubmit() {
      this.$emit("submit");
    },
    handleFocus() {
      this.$emit("focus");
    },
    handleBlur(event) {
      this.$emit("blur", event);
    },
  },
};
</script>

<style>
.editor {
  outline: none;
  padding-right: 30px;
}
.editor.editor.is-scrolling {
  padding-right: 20px;
}
.emojis span {
  cursor: pointer;
  float: left;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
}
.emojis span:nth-child(8n + 1) {
  clear: left;
}
.emojis span.selected {
  background: #ddd;
}
.editor + .editor-options {
  top: 4px;
  right: 4px;
}
.editor.is-scrolling + .editor-options {
  top: 6px;
  right: 21px;
}
</style>