<template>
  <div
    class=" column flex-no-wrap comment-section"
    style="height: 100%; padding-bottom: 5px"
  >
    <div class="q-pb-md" style="height: 100%">
      <q-toolbar class="q-pa-sm header">
        <div class="header-tabs">
          <q-btn
            :class="selectedTab === 'active' ? 'selected-tab' : ''"
            style="border-radius: 19px 0 0 19px;"
            :label="'Active ' + getNumberOfPoints('active')"
            no-caps
            @click="changeTab('active')"
          />
          <q-btn
            :class="selectedTab === 'done' ? 'selected-tab' : ''"
            style="border-radius: 0 19px 19px 0;"
            :label="'Done ' + getNumberOfPoints('done')"
            no-caps
            @click="changeTab('done')"
          />
        </div>
        <div class="row">
          <q-btn
            :icon="$icons.mdiHelp"
            round
            size="sm"
            class="help-button-small"
            style="margin-left: 10px"
            @click="
              toggleVisualCommentsWalkthrough(
                !user.onboarding.is_visual_comments
              )
            "
          />
        </div>
      </q-toolbar>
      <div
        class="walkthrough walkthrough-small"
        v-if="user.onboarding.is_visual_comments"
      >
        <div class="icon">
          <q-icon
            :name="$icons.fasComment"
            size="24px"
            style="color: #007aff"
          />
        </div>
        <div class="content">
          <p class="bold">Post visual comments</p>
          <p>
            Post comments, change statuses, mark work done as complete and more.
          </p>
          <div class="video" @click="videoPlayer">
            <q-icon
              class="play"
              color="primary"
              :name="$icons.mdiPlay"
              size="55px"
            />
          </div>
        </div>
        <div class="close" @click="toggleVisualCommentsWalkthrough(false)">
          <q-icon :name="$icons.matClose" size="xs" style="color: #677484" />
        </div>
      </div>
      <div style="height: calc(100% - 50px);">
        <div class="overflow-auto" style="height: calc(100% - 50px);">
          <div
            v-if="commentPoints.length"
            class="comment-content q-mt-sm"
            ref="commentContent"
          >
            <template
              v-for="(commentPoint, commentIndex) in getCommentsListing"
            >
              <visual-comment-point
                :key="commentIndex"
                :commentPoint="commentPoint"
                :visualBubbleComments="visualBubbleComments[commentPoint.id]"
                :commentIndex="commentIndex"
                :checkInputChanged="checkInputChanged"
                :labels="labels"
                :selectedTab="selectedTab"
                :clickonthemeoption="clickonthemeoption"
                :selectedPointData="selectedPointData"
                :deleteCommentPoint="deleteCommentPoint"
                :deleteMediaComment="deleteMediaComment"
                :editCommentHandler="editCommentHandler"
                :enabledTextbox="enabledTextbox"
                :editCommentContent="editCommentContent"
                :resetTextArea="resetTextArea"
                :sendComment="sendComment"
                :handleKeyPressed="handleKeyPressed"
                :handleEditorBlur="handleEditorBlur"
                :closeCommentEditor="closeCommentEditior"
                :user="user"
                @selectPoint="(data) => $emit('selectPoint', data)"
                @scrollToPoint="(point) => $emit('scrollToPoint', point)"
                @addCommentToPoint="
                  (index) => $emit('addCommentToPoint', index)
                "
              />
            </template>
          </div>
          <div v-else class="empty-comment-content">
            <img src="../../../public/static/images/logo/click-logo.svg" />
            <div>
              <span>
                Double Click anywhere on the image to leave a comment
              </span>
            </div>
          </div>
        </div>
        <div class="drawer-menu-options row justify-end items-center">
          <q-btn
            class="carousal-arrows drawer-carousal-arrow-left  row justify-center items-center q-mr-md"
            :icon="$icons.fasAngleLeft"
            size="14px"
            dense
            @click="previousImage"
            :disabled="!hasPreviousImage"
          />
          <q-btn
            class="carousal-arrows drawer-carousal-arrow-right  row justify-center items-center q-mr-md"
            :icon="$icons.fasAngleRight"
            size="14px"
            dense
            @click="nextImage"
            :disabled="!hasNextImage"
          />
          <q-btn
            class="close-comment-section row justify-center icon-center"
            :icon="$icons.mdiCheckBold"
            size="20px"
            dense
            text-color="white"
            @click="changeView(false)"
          />
        </div>
      </div>
    </div>
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";

import VideoDialog from "../VuePlayer/VideoDialog";
import { UpdateUserOnboardingMutation } from "@/gql";
import VisualCommentPoint from "@/views/MediaView/VisualCommentPoint";
const soundFile = require("@/assets/completeTaskSound.wav");
export default {
  name: "CommentViewDrawer",
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: true,
      },
      titleOfTheme: "Not Started",
      themeColor: "notstarted",
      filterTask: { label: false },
      commentEditorFlag: false,
      editCommentContent: null,
      commentPointIndex: null,
      selectedTab: "active",
      commentPointsData: this.commentPoints,
      sound: null,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
    };
  },
  components: {
    VideoDialog,
    VisualCommentPoint,
  },
  props: [
    "commentPoints",
    "commentListing",
    "labels",
    "selectedPointData",
    "enabledTextbox",
    "newCommentPoint",
    "currentWorkspaceId",
    "changeView",
    "hasPreviousImage",
    "hasNextImage",
    "previousImage",
    "nextImage",
  ],
  mounted() {
    this.sound = new Audio(soundFile);
  },
  methods: {
    sendComment(data) {
      if (data.text.trim()) {
        let newData;
        if (this.enabledTextbox != null && this.enabledTextbox >= 0) {
          newData = {
            comment: data.text,
            id: this.getCommentsListing[this.enabledTextbox].id,
          };
        } else {
          newData = {
            comment: data.text,
            id: this.getCommentsListing[this.newCommentPoint.index].id,
          };
        }
        if (!this.editCommentContent) {
          this.$emit("sendComment", newData);
        } else {
          const changedata = {
            id: this.editCommentContent.id,
            comment: data.text,
          };
          this.$emit("updateComment", changedata);
        }
        this.$nextTick(() => {
          this.$refs.commentContent.scrollTop = this.$refs.commentContent.scrollHeight;
        });
        this.resetTextArea();
      }
    },
    clickonthemeoption(theme, color, point) {
      this.titleOfTheme = theme;
      this.themeColor = color;
      const themedata = {
        id: point,
        last_label: color,
      };
      this.$emit("updateMediaCommentPointLabel", themedata);
    },
    deleteCommentPoint(index, point) {
      const deletedata = {
        index: index,
        id: point,
      };
      this.menu = false;
      this.$emit("deleteCommentPoint", deletedata);
    },
    handleEditorBlur(data, point, index) {
      if (!data.target.value.trim().length) {
        this.closeCommentEditior(point, index);
      }
    },
    closeCommentEditior(point, index) {
      if (!point.comments.length) {
        this.deleteCommentPoint(index, point.id);
      }
      this.$emit("addCommentToPoint", -1);
      this.editCommentContent = null;
    },
    deleteMediaComment(data) {
      this.$emit("deleteMediaComment", data);
    },
    editCommentHandler(data) {
      this.selectedPointData = data.selectedPointIndex;
      // let pointData = {
      //   point: data.selectedPoint,
      //   index: data.selectedPointIndex,
      // };
      this.$emit("addCommentToPoint", data.selectedPointIndex);
      this.editCommentContent = data.comment;
    },
    scrollToPoint(point, index) {
      let data = {
        point: point,
        index: index,
      };
      this.$emit("selectPoint", data);
      this.$emit("scrollToPoint", point);
    },
    resetTextArea() {
      this.editCommentContent = null;
      this.$emit("addCommentToPoint", null);
      this.$eventBus.$emit("resetTaskCommentEditor");
    },
    checkInputChanged(point, data) {
      this.sound.currentTime = 0;
      this.sound.play();
      point.last_label = data ? "#15d89a" : "nolabel";
      point.completed = data;
      let labelName = data ? "Complete" : "No Label";
      this.clickonthemeoption(labelName, point.last_label, point.id);
    },
    getNumberOfPoints(tab) {
      let numberOfPoints = 0;
      if (tab === "active") {
        numberOfPoints = this.commentPointsData.reduce(
          (count, point) =>
            point.last_label !== "#15d89a" ? count + 1 : count,
          0
        );
      } else {
        numberOfPoints = this.commentPointsData.reduce(
          (count, point) =>
            point.last_label === "#15d89a" ? count + 1 : count,
          0
        );
      }
      return numberOfPoints;
    },
    changeTab(tab) {
      this.selectedTab = tab;
      this.$emit("tabChanged", this.selectedTab);
    },
    async closeWalkthroughBanner() {
      this.user.onboarding.is_visual_comments = false;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_visual_comments",
          flag_value: false,
        },
      });
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/569676712",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    async toggleVisualCommentsWalkthrough(flag) {
      this.user.onboarding.is_visual_comments = flag;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_visual_comments",
          flag_value: flag,
        },
      });
    },
    handleKeyPressed() {
      this.$refs.commentContent.scrollTop = this.$refs.commentContent.scrollHeight;
    },
  },
  computed: {
    getCommentsListing() {
      this.commentPointsData.forEach((commentPoint) => {
        commentPoint["comments"] = [];
        commentPoint["menuFlag"] = false;
        if (commentPoint.last_label === "#15d89a") {
          commentPoint["completed"] = true;
        } else {
          commentPoint["completed"] = false;
        }
        if (this.commentListing) {
          this.commentListing.forEach((comment) => {
            if (
              comment &&
              commentPoint.id === comment.media_comments_point_id
            ) {
              commentPoint.comments.push(comment);
            }
          });
        }
      });
      return this.commentPointsData;
    },
    visualBubbleComments() {
      return groupBy(this.commentListing, "media_comments_point_id");
    },
  },
  watch: {
    selectedPointData(newValue) {
      if (newValue) {
        let element = document.getElementById("point" + newValue.index);
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    commentPoints(newValue) {
      this.commentPointsData = newValue;
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: center;
  height: 50px;
}
.comment-content {
  /* height: calc(100% - 50px); */
  overflow: auto;
  padding-bottom: 15px;
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
}
.drawer-title {
  color: #1d2b3b;
  font-family: "Avenir Next";
  font-size: 17px;
  font-weight: bold;
}
.comment-list {
  &:hover {
    .add-comment-button {
      opacity: 1;
    }
  }
}
.comment-list-options {
  display: none;
}
.comment-list:hover .comment-list-options {
  display: block;
}
.comment-editor {
  //margin-bottom: 10px;
}
.comment-checkbox {
  &::before {
    content: "";
    position: absolute;
    left: 25px;
    top: 40px;
    height: 75%;
    width: 1px;
    border-left: 3px solid #dbecff;
  }
}
.comment-list-section {
  width: 100%;
  .point-menu-options {
    position: absolute;
    top: 1px;
    right: 8px;
  }
}
.add-comment-button {
  opacity: 0;
  display: flex;
  align-items: center;
  text-align: center;
  color: #007aff;
  max-width: 240px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  &::after,
  &::before {
    content: "";
    flex: 1;
    border-bottom: 2px dashed #007aff;
  }
  &:not(:empty)::before {
    margin-right: 0.45em;
  }
  &:not(:empty)::after {
    margin-left: 0.45em;
  }
}
.comment-options {
  display: flex;
  justify-content: space-between;
}
/* .thread-icons {
  opacity: 0;
  border: 2px solid #007aff;
} */
// .group-hover-item {
//   height: min-content;
// }
// .thread-icon {
//   height: min-content;
// }
.empty-comment-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 135px;
}
.empty-comment-content img {
  margin-bottom: 28px;
}
.empty-comment-content div {
  width: 200px;
  height: 40px;
  text-align: center;
  color: #677484;
  font-family: "Avenir Next";
}
.comment-list.point-selected {
  border-left: solid 4px #007aff;
  border-radius: 5px;
  background-color: #e1ecf9;
  .comment-checkbox {
    &::before {
      border-color: #007aff;
      left: 22px;
    }
  }
}
.comment-list.point-not-selected {
  padding-left: 4px;
}

.comment-title {
  justify-content: space-between;
}
.comment-title .comment-text {
  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
  color: #007aff;
}
.header-tabs {
  display: flex;
  width: 257px;
  height: 35px;
  border-radius: 19px;
}
.header-tabs button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Avenir Next";
  font-size: 15px;
  font-weight: 500;
  color: #007aff;
  cursor: pointer;
  border: solid 1.5px #007aff;
}
.header-tabs button.selected-tab {
  background-color: #007aff;
  color: #ffffff;
}
</style>
