<template>
  <q-icon
    @click="showTooltip = false"
    :name="$icons.mdiHelp"
    class="doc-help-button q-mr-sm cursor-pointer"
  >
    <q-tooltip v-if="showTooltip" anchor="bottom start">
      Learn about the files section
    </q-tooltip>
    <q-menu
      @before-hide="showTooltip = true"
      auto-close
      :offset="[100, 10]"
      content-class="document-help-menu"
    >
      <q-list>
        <q-item @click="viewDocShortcuts" clickable :class="['items-center']"
          ><q-icon :name="$icons.mdiKeyboard" size="20px" class="q-mr-md" />
          Shortcuts</q-item
        >
        <q-item @click="playVideo" clickable :class="['items-center']">
          <div class="help-icon row items-center justify-center q-mr-md">
            <q-icon :name="$icons.mdiHelp" />
          </div>
          Walkthrough</q-item
        >
      </q-list>
    </q-menu>
  </q-icon>
</template>

<script>
export default {
  name: "DocumentHelpMenu",
  data() {
    return {
      showTooltip: true,
    };
  },
  props: {
    viewDocShortcuts: Function,
    playVideo: Function,
  },
};
</script>
<style lang="scss" scoped>
.doc-help-button {
  font-size: 16px !important;
  color: #cce4ff;
  align-items: center;
  justify-content: center;
}
.document-help-menu {
  .q-item {
    color: #42526e;
    .help-icon {
      background-color: #42526e;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      color: #fff;
    }
    &:hover {
      color: #fff;
      .help-icon {
        background-color: #fff;
        color: #42526e;
      }
    }
  }
}
</style>
