var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-dialog',{attrs:{"transition-hide":"none","transition-show":"none","persistent":"","content-class":"revamp-commenting-dialog q-ma-none"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.resetDialog.apply(null, arguments)},"show":_vm.handleEventListner},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('q-card',{staticClass:"file-viewer relative-position"},[(_vm.view)?_c('q-bar',{staticClass:"file-viewer-header"},[_c('div',{staticClass:"file-viewer-overlay top"}),_c('div',{staticClass:"row justify-between items-center flex-1 q-mt-md"},[_c('div',{staticClass:"row items-center q-pt-md"},[_c('div',[(_vm.userDetail.pic)?_c('q-avatar',{attrs:{"rounded":"","size":"38px"}},[_c('img',{attrs:{"src":_vm.userDetail.pic,"alt":_vm.userDetail.first}})]):_c('avatar',{attrs:{"size":32,"customStyle":{ 'font-size': '14px', 'font-weight': '600' },"username":_vm.userDetail.first + ' ' + _vm.userDetail.last}})],1),_c('div',{staticClass:"text-white q-ml-md"},[_c('div',{staticClass:"row items-center q-mb-xs"},[_c('div',{staticClass:"username"},[_vm._v(" "+_vm._s(_vm.userDetail.fullname)+" ")]),(
                  _vm.dialogMedia.commentPoint &&
                  _vm.dialogMedia.commentPoint.length > 0
                )?_c('div',{staticClass:"comment-point q-ml-sm",style:(_vm.dialogMedia.commentPoint.length ==
                  _vm.completedMediaPoints(_vm.dialogMedia).length
                    ? `background: #15d89a`
                    : '')},[_vm._v(" "+_vm._s(_vm.completedMediaPoints(_vm.dialogMedia).length + "/" + _vm.dialogMedia.commentPoint.length)+" ")]):_vm._e()]),_c('div',{staticClass:"title"},[_c('span',[_vm._v(" "+_vm._s(_vm.calculatedTime)+" - ")]),_c('span',[_vm._v(" "+_vm._s(_vm.dialogMedia.filename)+" "),_c('q-tooltip',{staticStyle:{"z-index":"1"}},[_vm._v(_vm._s(_vm.dialogMedia.filename))])],1)])])]),_c('q-btn',{staticClass:"close-icon row justify-center items-center cursor-pointer",attrs:{"icon":_vm.$icons.mdiCloseThick,"size":"12px","dense":"","flat":"","text-color":"black"},on:{"click":_vm.resetDialog}})],1)]):_vm._e(),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll"}],ref:"reVampScroll",class:[
        _vm.view ? 'file-viewer-content' : 'comment-viewer-content',
        'overflow-auto',
      ],staticStyle:{"padding":"0"},attrs:{"id":"imageScrollContainer"}},[(!_vm.view)?_c('div',[_c('q-page-container',{staticStyle:{"padding":"0"}},[_c('div',{staticStyle:{"position":"relative"}},[_vm._l((_vm.returncommentpoints),function(point,index){return [(
                  _vm.selectedTab === 'active'
                    ? point.last_label !== '#15d89a'
                    : point.last_label === '#15d89a'
                )?_c('comment-box',{key:point.id,ref:"commentBox",refInFor:true,class:{ ishide: _vm.ishide },attrs:{"index":index,"currentUser":_vm.currentUser,"isWorkspaceOwner":_vm.isWorkspaceOwner,"commentListing":_vm.commentListing,"point":point,"selectedPointData":_vm.selectedPoint,"labels":_vm.labels,"initialiseComment":_vm.initialiseComment},on:{"deleteCommentPoint":_vm.deleteCommentPoint,"sendComment":_vm.sendComment,"updatepositions":_vm.updatepositions,"updateMediaCommentPointLabel":_vm.updateMediaCommentPointLabel,"deleteMediaComment":_vm.deleteMediaComment,"updateComment":_vm.updateComment,"selectPoint":_vm.selectPoint}}):_vm._e()]}),_c('vue-load-image',[(_vm.mediaLink)?_c('img',{ref:"myImgId",style:({ cursor: _vm.returncursorpoint }),attrs:{"slot":"image","src":_vm.mediaLink},on:{"mouseleave":_vm.mouseCursorLeave,"mousemove":_vm.mouseCursor,"dblclick":_vm.point_it},slot:"image"}):_vm._e(),_c('q-spinner',{attrs:{"slot":"preloader","color":"primary","size":"5em"},slot:"preloader"})],1),_c('span',{ref:"myCustomCursor",staticClass:"comment-custom-tooltip tooltipcolor text-white an-14 medium-text",staticStyle:{"position":"absolute","display":"none"}},[_vm._v(" Double Click to leave a comment ")])],2)])],1):[[_c('q-btn',{staticClass:"carousal-arrows carousal-viewer-arrow left row justify-center items-center",attrs:{"icon":_vm.$icons.fasAngleLeft,"size":"14px","dense":"","disabled":!_vm.hasPreviousImage},on:{"click":_vm.previousImage}}),_c('q-btn',{staticClass:"carousal-arrows carousal-viewer-arrow right row justify-center items-center",attrs:{"icon":_vm.$icons.fasAngleRight,"size":"14px","dense":"","disabled":!_vm.hasNextImage},on:{"click":_vm.nextImage}})],_c('vue-load-image',{class:[
            _vm.isZoomedIn !== 'none'
              ? 'zoomed-in-wrapper'
              : 'zoomed-out-wrapper',
            'image-viewer',
          ],attrs:{"tabindex":"0","id":"image-wrapper"}},[_c('img',{class:_vm.getZoomClass(),staticStyle:{"max-height":"100%"},attrs:{"slot":"image","id":"image-viewer","src":_vm.imgSrc},on:{"mouseover":_vm.handleZoom,"mouseout":_vm.removeZoom,"click":_vm.customeZoomIn,"mousedown":_vm.imageMouseDownHandler,"mouseup":_vm.imageMouseUpHandler,"mouseleave":_vm.imageMouseLeaveHandler},slot:"image"}),_c('q-spinner',{attrs:{"slot":"preloader","color":"primary","size":"5em"},slot:"preloader"})],1)]],2),(_vm.view)?_c('div',{staticClass:"file-viewer-footer"},[_c('div',{staticClass:"file-viewer-overlay bottom"}),_c('div',{staticClass:"row items-center justify-center"},[_c('div',{staticClass:"carousel"},[_c('carousel',{attrs:{"paginationEnabled":false,"mouseDrag":true,"navigationEnabled":false,"navigateTo":_vm.activeCarouselPage,"perPageCustom":[[768, 10]]}},_vm._l((_vm.getImageList),function(media,index){return _c('slide',{key:index},[_c('div',{class:[
                  _vm.getDialogMedia && _vm.getDialogMedia.id === media.id
                    ? 'carasoule-media-selected'
                    : 'carasoule-media-not-selected',
                  'carasoule-media cursor-pointer row justify-center',
                ],on:{"click":() => _vm.setActiveMedia(media, index)}},[(media.commentPoint.length > 0)?_c('div',{staticClass:"comment-point",style:(media.commentPoint.length ==
                    _vm.completedMediaPoints(media).length
                      ? `background: #15d89a`
                      : '')},[(media.commentPoint.length <= 9)?_c('span',[_vm._v(" "+_vm._s(_vm.completedMediaPoints(media).length + "/" + media.commentPoint.length)+" ")]):_c('span',[_vm._v("9+")])]):_vm._e(),_c('img',{attrs:{"src":media.file_resource === 's3'
                      ? _vm.$resizeMedia(media.file, 200, 200)
                      : media.thumb,"alt":"#"}}),(_vm.getDialogMedia && _vm.getDialogMedia.id === media.id)?_c('div',{staticClass:"overlay"}):_vm._e(),(!(_vm.getDialogMedia && _vm.getDialogMedia.id === media.id))?_c('div',{staticClass:"overlay-on-hover"}):_vm._e(),_c('q-tooltip',{staticStyle:{"z-index":"1"}},[_vm._v(_vm._s(media.filename))])],1)])}),1)],1),_c('div',{staticClass:"header-buttons"},[_c('q-btn',{staticClass:"zoom-btn",attrs:{"flat":"","size":"12px","round":"","icon":_vm.$icons.mdiMagnifyMinusOutline},on:{"click":_vm.zoomOutFn}},[_c('q-tooltip',[_vm._v(" Zoom out ")])],1),_c('q-btn',{class:[_vm.isZoomedIn !== 'none' ? 'zoom' : 'zoom-btn'],attrs:{"flat":"","size":"12px","round":"","icon":_vm.$icons.mdiMagnifyPlusOutline},on:{"click":_vm.zoomInFn}},[_c('q-tooltip',[_vm._v(" Zoom in ")])],1),_c('q-btn',{staticClass:"zoom-btn",attrs:{"flat":"","size":"12px","round":"","icon":_vm.$icons.matOpenInNew},on:{"click":_vm.openInNewWindow}},[_c('q-tooltip',[_vm._v(" Open in a new "+_vm._s(_vm.isElectron ? "window" : "tab")+" ")])],1),_c('q-btn',{staticClass:"zoom-btn",attrs:{"flat":"","size":"12px","round":"","icon":_vm.$icons.mdiDownload},on:{"click":function($event){return _vm.downloadFile(_vm.dialogMedia.id)}}},[_c('q-tooltip',[_vm._v(" Download file ")])],1),(_vm.isGoogle)?_c('q-btn',{staticStyle:{"color":"#727e8b"},attrs:{"flat":"","size":"12px","round":"","icon":_vm.$icons.fabGoogleDrive},on:{"click":_vm.openNextTabLink}},[_c('q-tooltip',[_vm._v(" Google Drive ")])],1):_vm._e(),(_vm.isDropbox)?_c('q-btn',{staticStyle:{"color":"#727e8b"},attrs:{"flat":"","size":"12px","round":"","icon":_vm.$icons.fabDropbox},on:{"click":_vm.openNextTabLink}},[_c('q-tooltip',[_vm._v(" Drop Box ")])],1):_vm._e(),(_vm.isVisible([1, 2, 3]))?_c('q-btn',{class:[
              'comment-view-btn',
              _vm.commentMode ? 'comment-mode-on' : 'comment-mode-off',
            ],attrs:{"flat":"","size":"10px","icon":_vm.commentMode ? _vm.$icons.matDone : _vm.$icons.matQuestionAnswer,"label":_vm.commentMode ? 'Done' : 'Comment'},on:{"click":_vm.checkVisualCommentAccess}},[_c('q-tooltip',[_vm._v(" Create and Read Comments ")])],1):_vm._e()],1)])]):_vm._e(),(!_vm.view)?_c('div',{staticClass:"comment-drawer"},[_c('comment-view-drawer',{ref:"commentViewDrawer",attrs:{"commentPoints":_vm.returncommentpoints,"commentListing":_vm.commentListing,"labels":_vm.labels,"selectedPointData":_vm.selectedPoint,"enabledTextbox":_vm.enabledTextbox,"newCommentPoint":_vm.returnNewCommentPoint,"currentWorkspaceId":_vm.currentWorkspace,"changeView":_vm.toggleCommentMode,"hasPreviousImage":_vm.hasPreviousImage,"hasNextImage":_vm.hasNextImage,"previousImage":_vm.previousImage,"nextImage":_vm.nextImage},on:{"sendComment":_vm.sendComment,"updateMediaCommentPointLabel":_vm.updateMediaCommentPointLabel,"deleteCommentPoint":_vm.deleteCommentPoint,"addCommentToPoint":_vm.addCommentToPoint,"deleteMediaComment":_vm.deleteMediaComment,"scrollToPoint":_vm.scrollToPoint,"updateComment":_vm.updateComment,"selectPoint":_vm.selectPoint,"tabChanged":_vm.changeTab}})],1):_vm._e()],1),(_vm.user.onboarding.is_image_viewer)?_c('image-viewer-walkthrough-dialog',{on:{"closeBanner":_vm.closeImageViewerWalkthrough}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }