<template>
  <div class="q-ml-md ">
    <template v-if="isFolderOpenView">
      <folder-detail-grid-view
        :isFolderOpenView="isFolderOpenView"
        :folderSection="folderSection"
        :items="items"
        :fullViewHandler="fullViewHandler"
        class="folder-open-view"
        :addMediaBookmark="addMediaBookmark"
        :removeMediaBookmark="removeMediaBookmark"
        :fromComputer="fromComputer"
        :videoPlayer="videoPlayer"
        :audioPlayer="audioPlayer"
        :pdfView="pdfView"
        :canRemove="canRemove"
        :currentUser="currentUser"
        :clickonmoreoption="clickonmoreoption"
        :removeMediaFile="removeMediaFile"
        :companyId="companyId"
        :currentWorkspace="workspace"
        :activeCompany="activeCompany"
        :openFolderId="openFolderId"
        :closeFolderHandler="closeFolderHandler"
        :folderList="folderList"
        :isWorkspaceOwner="isWorkspaceOwner"
        :authToken="authToken"
        :editDoc="editDoc"
        @setProgress="(progress) => $emit('setProgress', progress)"
      />
    </template>
    <template v-else>
      <div
        v-if="selected.length"
        class="row items-center q-pt-sm q-pb-xs stick-at-top"
        style="z-index:111"
      >
        <q-checkbox v-model="selectAll" size="sm" @input="toggleAll" />
        <q-btn
          flat
          dense
          no-caps
          :icon="$icons.fasShare"
          label="Share"
          class="multiple-select-options primary-btn"
          @click="checkShareFolderAccess(selected)"
        />
        <q-btn
          flat
          dense
          no-caps
          :icon="$icons.mdiContentCopy"
          @click="
            copyMoveFileFolderHandler(
              selected,
              false,
              true,
              false,
              isFolderOpenView ? false : true
            )
          "
          label="Copy"
          class="multiple-select-options primary-btn"
        />
        <q-btn
          flat
          dense
          no-caps
          :icon="$icons.mdiCursorMove"
          @click="
            copyMoveFileFolderHandler(
              selected,
              true,
              true,
              false,
              isFolderOpenView ? false : true
            )
          "
          label="Move"
          class="multiple-select-options primary-btn"
        />
        <q-btn
          flat
          dense
          no-caps
          :icon="$icons.fasTrash"
          label="Delete"
          class="multiple-select-options red-btn"
          @click="bulkDeleteFileConfirm.flag = true"
        />
      </div>
      <div ref="scrollstatesettergrid" class="flex-x flex-wrap align-start">
        <template v-if="!isFolderOpenView">
          <template v-for="(folder, index) in folderList">
            <folder
              :folder="folder"
              :key="index + '-grid-folder'"
              :deleteFolder="deleteFolderHandler"
              :selectedIds="selectedIds"
              @renameFolder="openRenameFolderDialog"
              :toggleSelect="toggleSelect"
              :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
              :attachFolderToPost="attachFolderToPost"
              :addMediaFolderBookmark="addMediaFolderBookmark"
              :removeMediaFolderBookmark="removeMediaFolderBookmark"
              @openfolder="openFolder"
              :uploadFileToFolder="uploadFileToFolder"
            />
          </template>
        </template>
        <template>
          <template v-for="(media, index) in items">
            <div
              :key="index + '-grid'"
              class="border-radius10 q-ma-xs relative-position"
              style="width: 170px;"
            >
              <card-media
                :media="media"
                :height="170"
                :fullview="fullview"
                :videoPlayer="videoPlayer"
                :audioPlayer="audioPlayer"
                :pdfView="pdfView"
                :addMediaBookmark="addMediaBookmark"
                :removeMediaBookmark="removeMediaBookmark"
                :canRemoveMedia="
                  canRemove || (currentUser && currentUser.id === media.user_id)
                "
                :removeMediaFile="removeMediaFile"
                @copyLink="clickonmoreoption"
                :companyId="companyId"
                :workspace="workspace"
                :activeCompany="activeCompany"
                :fromComputer="fromComputer"
                :toggleSelect="toggleSelect"
                :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                :selectedIds="selectedIds"
                :isFolderOpenView="isFolderOpenView"
                @renameFile="openRenameFileDialog"
                :editDoc="editDoc"
                :currentUser="currentUser"
              ></card-media>
            </div>
          </template>
        </template>
        <div
          class="load-more-media cursor-pointer border-radius10 q-ma-xs"
          @click="$emit('paginate')"
          v-if="!removeloadButton"
        >
          <q-icon
            :name="$icons.mdiDotsHorizontal"
            size="45px"
            color="blue-grey-5"
            style="margin:auto"
          >
            <q-tooltip>
              Load More
            </q-tooltip>
          </q-icon>
        </div>
        <div class="full-width pr-2 text-center" v-if="isloading">
          <q-circular-progress
            indeterminate
            size="30px"
            :thickness="0.2"
            color="primary"
          />
        </div>
      </div>
    </template>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="isFolderOpenView ? sectionGalleryMedia : items"
      :ishide="ishide"
      :labels="labels"
      :moreOption="moreOption"
      :currentUser="currentUser"
      :dialogMedia="returndialogMedia"
      :isWorkspaceOwner="isWorkspaceOwner"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :resetDialog="() => resetDialog()"
      @sendComment="(data) => $emit('sendComment', data)"
      @updateComment="(data) => $emit('updateComment', data)"
      @updatepositions="(data) => $emit('updatepositions', data)"
      @clickonhidebutton="ishide = !ishide"
      @clickonmoreoption="clickonmoreoption"
      @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
      @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
      @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      @updateMediaCommentPointLabel="
        (data) => $emit('updateMediaCommentPointLabel', data)
      "
      @setActiveMedia="setActiveMedia"
    ></image-viewer-dialog>
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :medias="items"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <audio-dialog
      v-model="showAudioModel"
      v-if="showAudioModel"
      :medias="items"
      :resetAudioDialog="resetAudioDialog"
      :audioMediaData="audioMediaData"
      :displayAudioPlayer="displayAudioPlayer"
    >
    </audio-dialog>
    <confirm-dialog
      v-if="delete_media.flag"
      v-model="delete_media.flag"
      title="Delete File?"
      question="Are you sure you want to delete this file?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => removeMediaDialogHandler(false)"
      :successMethod="() => removeMediaDialogHandler(true)"
      :loading="deletingMedia"
    />

    <pdf-view-dialog
      v-if="showpdfModel"
      :fileExt="fileExt"
      v-model="showpdfModel"
      @resetPdfDialog="resetPdfDialog"
      :pdfMediaData="pdfMediaData"
    />
    <create-media-folder-dialog
      v-if="renameMediaFolderDialog.flag"
      v-model="renameMediaFolderDialog.flag"
      :renameFolder="renameFolderDetails"
      :loading="renameMediaFolderDialog.loading"
      :createFolderHandler="renameFolderHandler"
      :cancelMethod="cancel"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Delete Folder?"
      question="Are you sure you want to delete this folder?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false)"
      :successMethod="() => deleteFolderDialogHandler(true)"
      :loading="deleteFolder.loader"
    />
    <copy-move-file-folder-dialog
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isMoveAction="copyMoveDialog.isMoveAction"
      :fileFolderDetails="copyMoveDialog.data"
      :isRoot="copyMoveDialog.isRoot"
      :isMultiple="copyMoveDialog.isMultiple"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyFileFolderHandler="moveCopyFileFolderHandler"
      :multipleMoveCopyHandler="multipleMoveCopyHandler"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="currentWorkspace"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="labels"
      :folderList="folderList"
      :authToken="authToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
    <div v-if="bulkDeleteFileConfirm.flag">
      <confirm-dialog
        v-model="bulkDeleteFileConfirm.flag"
        title="Delete Assets?"
        question="Are you sure you want to delete these assets?"
        cancleText="Cancel"
        successText="Yes"
        :cancleMethod="() => bulkDeleteAssetsConfirmHandler(false)"
        :successMethod="() => bulkDeleteAssetsConfirmHandler(true)"
        :loading="bulkDeleteFileConfirm.loader"
      />
    </div>
    <vue-dropzone-file-upload
      hidden
      ref="folderFileUploader"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="fileUploadCloseHandler"
    ></vue-dropzone-file-upload>
    <update-file-dialog
      v-if="renameFileDialog.flag"
      v-model="renameFileDialog.flag"
      :renameFile="renameFileDetails"
      :cancelMethod="cancelRenameFile"
    />
  </div>
</template>

<script>
import RandomPosition from "random-position";

import CardMedia from "@/components/Miscellaneous/CardMedia";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import PdfViewDialog from "@/components/Dialogs/PdfViewDialog";
import AudioDialog from "@/components/Dialogs/AudioPlayerDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import CreateMediaFolderDialog from "@/components/Dialogs/CreateMediaFolderDialog";
import orderBy from "lodash/orderBy";

import {
  DeleteMediaMutation,
  RenameMediaFolderMutation,
  DeleteMediaFolderMutation,
  UpdateMediaMutation,
} from "@/gql";
import Folder from "@/views/MediaView/GridView/Folder";
import CopyMoveFileFolderDialog from "@/components/Dialogs/CopyMoveFileFolderDialog";
import MediaViewMixin from "@/mixins/MediaViewMixin";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import FolderDetailGridView from "@/views/MediaView/FolderDetailGridView";
import UpdateFileDialog from "@/components/Dialogs/UpdateFileDialog";

export default {
  name: "GridView",
  mixins: [MediaViewMixin, FileExtensionMixin],
  props: [
    "workspace",
    "canRemoveMedia",
    "isWorkspaceOwner",
    "user",
    "currentCompanyId",
    "currentView",
    "isloading",
    "imageList",
    "labels",
    "activeCompany",
    "removeloadButton",
    "addMediaBookmark",
    "removeMediaBookmark",
    "folderList",
    "fromComputer",
    "isFolderOpenView",
    "openFolderId",
    "authToken",
    "folderSection",
    "closeFolderHandler",
    "editDoc",
    "openDocEditor",
  ],
  components: {
    CardMedia,
    VideoDialog,
    PdfViewDialog,
    ConfirmDialog,
    AudioDialog,
    ImageViewerDialog,
    Folder,
    CreateMediaFolderDialog,
    CopyMoveFileFolderDialog,
    FolderDetailsDialog,
    VueDropzoneFileUpload,
    FolderDetailGridView,
    UpdateFileDialog,
  },
  data() {
    return {
      showModal: false,
      showVideoModel: false,
      showAudioModel: false,
      displayPlayer: false,
      displayAudioPlayer: false,
      dialogMedia: null,
      videoMediaData: null,
      audioMediaData: null,
      media: [],
      delete_media: {
        id: null,
        flag: false,
      },
      gridMediaId: "",
      ishide: false,
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
        {
          title: "Hide Comments",
        },
      ],
      pagination: {
        loading: false,
        limit: 10,
        offset: 0,
      },
      pdfMediaData: null,
      showpdfModel: false,
      deletingMedia: false,
      renameMediaFolderDialog: {
        flag: false,
        loading: false,
      },
      renameFolderDetails: null,
      deleteFolder: {
        id: null,
        flag: false,
        loader: false,
      },
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
      folderId: null,
      sectionMedia: [],
      renameFileDialog: {
        flag: false,
      },
      renameFileDetails: null,
    };
  },
  computed: {
    fileExt() {
      if (this.pdfMediaData) {
        return this.pdfMediaData.filename.split(".")[1];
      }
      return null;
    },
    returndialogMedia() {
      return this.dialogMedia;
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = this.items.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    currentWorkspace() {
      return this.workspace;
    },
    canRemove() {
      return this.canRemoveMedia;
    },
    items() {
      if (this.isFolderOpenView) {
        if (this.imageList) {
          // Used Mixin : FileExtensionMixin
          return orderBy(this.transformMediaCollection(this.imageList), [
            "folder_sequence",
          ]);
        } else {
          return [];
        }
      } else {
        if (this.imageList) {
          this.scollbar();
          // Used Mixin : FileExtensionMixin
          return this.transformMediaCollection(this.imageList);
        } else {
          return [];
        }
      }
    },
    sectionGalleryMedia() {
      if (this.sectionMedia) {
        // Used Mixin : FileExtensionMixin
        return orderBy(this.transformMediaCollection(this.sectionMedia), [
          "folder_sequence",
        ]);
      } else {
        return [];
      }
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = this.items.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    currentUser() {
      return this.user;
    },
    companyId() {
      return this.currentCompanyId;
    },
  },
  methods: {
    openRenameFileDialog(media) {
      this.renameFileDetails = media;
      this.renameFileDialog.flag = true;
    },
    cancelRenameFile() {
      this.renameFileDetails = null;
      this.renameFileDialog.flag = false;
      this.renameFileDialog.loading = false;
    },
    checkShareFolderAccess(selected) {
      let eligible = this.$checkEligiblity("share_folders");
      if (eligible) {
        this.attacheFileFolderToMessage(selected);
      }
    },
    //Media reordering inside open folder
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = [...this.items];
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);
      media.folder_sequence = RandomPosition.between(
        tmp[newIndex - 1]
          ? tmp[newIndex - 1].folder_sequence
          : RandomPosition.first(),
        tmp[newIndex + 1]
          ? tmp[newIndex + 1].folder_sequence
          : RandomPosition.last()
      );
      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          folder_sequence: media.folder_sequence,
        },
      });
    },
    openRenameFolderDialog(folder) {
      this.renameFolderDetails = folder;
      this.renameMediaFolderDialog.flag = true;
    },
    cancel() {
      this.renameFolderDetails = null;
      this.renameMediaFolderDialog.flag = false;
      this.renameMediaFolderDialog.loading = false;
    },
    async renameFolderHandler(title) {
      this.renameMediaFolderDialog.loading = true;
      if (this.renameFolderDetails) {
        try {
          const variables = {
            id: this.renameFolderDetails.id,
            title,
          };
          await this.$api.mutate({
            mutation: RenameMediaFolderMutation,
            variables,
          });
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspace}`
          );
          query.data.mediaFolders = query.data.mediaFolders.map((f) => {
            if (f.id !== this.renameFolderDetails.id) {
              f.title = title;
            }
            return f;
          });
          this.renameMediaFolderDialog.loading = false;
          this.renameMediaFolderDialog.flag = false;
          this.renameFolderDetails = null;
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
          this.renameMediaFolderDialog.loading = false;
          this.renameMediaFolderDialog.flag = false;
          this.renameFolderDetails = null;
        }
      }
    },
    async deleteFolderHandler(folderId) {
      this.deleteFolder.id = folderId;
      this.deleteFolder.flag = true;
    },
    async deleteFolderDialogHandler(flag = false) {
      if (flag) {
        this.deleteFolder.loader = true;
        try {
          const variables = {
            id: this.deleteFolder.id,
          };
          await this.$api.mutate({
            mutation: DeleteMediaFolderMutation,
            variables,
          });
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspace}`
          );
          query.data.mediaFolders = query.data.mediaFolders.filter(
            (f) => f.id !== this.deleteFolder.id
          );
          // this.$mixpanelEvent("delete-folder", {});
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteFolder.id = null;
      this.deleteFolder.flag = false;
      this.deleteFolder.loader = null;
    },
    resetPagination() {
      this.pagination.offset = 0;
      this.pagination.limit = 10;
    },
    scollbar() {
      if (this.imageList.length <= 50) {
        this.$nextTick(() => {
          const ps = this.$refs.scrollstatesettergrid;
          if (ps) ps.scrollTop = 1;
        });
      }
    },
    fullViewHandler(data, sectionMedia) {
      this.sectionMedia = sectionMedia;
      this.fullview(data);
    },
    fullview(data) {
      if (data.newpoint === "yes") {
        const value = {
          title: "Add Comment",
          gridMediaId: this.gridMediaId,
          media: data.media,
          forscroll: "no",
          x: 0,
          y: 0,
        };
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
        this.clickonmoreoption(value);
      } else {
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
      }
    },
    videoPlayer(media) {
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    audioPlayer(media) {
      this.audioMediaData = media;
      this.displayAudioPlayer = true;
      this.showAudioModel = true;
    },
    resetAudioDialog() {
      this.displayAudioPlayer = false;
      setTimeout(() => {
        this.showAudioModel = false;
      }, 10);
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
      this.$emit("closeMediaModel", {
        id: this.gridMediaId,
      });
    },
    removeMediaFile(postId, mediaId) {
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
    },
    async removeMediaDialogHandler(flag = false) {
      if (flag) {
        this.deletingMedia = true;
        await this.$api.mutate({
          mutation: DeleteMediaMutation,
          variables: { id: this.delete_media.id },
        });
        if (this.openFolderId) {
          const query = this.$api.getQuery(
            `MediaFolderQuery:${this.openFolderId}`
          );
          query.data.mediaFolder.media = query.data.mediaFolder.media.filter(
            (f) => f.id !== this.delete_media.id
          );
        } else {
          const query = this.$api.getQuery(
            `MediaQuery:${this.currentWorkspace}`
          );
          query.data.newMedia = query.data.newMedia.filter(
            (f) => f.id !== this.delete_media.id
          );
        }
      }
      this.resetListMediaSelection();
      this.deletingMedia = false;
      this.delete_media.flag = false;
      this.delete_media.post = null;
      this.delete_media.id = null;
    },
    clickonmoreoption(item) {
      const data = {
        title: item.title,
        gridMediaId: this.gridMediaId,
        media: item.media,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
      };
      this.$emit("clickonmoreoption", data);
    },
    pdfView(media) {
      this.pdfMediaData = media;
      this.showpdfModel = true;
      // if (this.fileExt.match(/pdf.*/)) {
      //   window.open(media.file, "_self");
      // } else {
      // }
    },
    resetPdfDialog() {
      this.pdfMediaData = null;
      this.showpdfModel = false;
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        find(
          this.$refs.mediaCommentingView.$refs.commentBox,
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
  },
  watch: {
    "$route.params.workspace": function() {
      this.resetPagination();
    },
  },
};
</script>
