export default {
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      CJS_PUBLIC: "CJS.public.workspace",
      CREATE_PRIVATE_WORKSPACE: "C.private.workspace",
      CHANGE_WORKSPACE_SETTING: "change.workspace.setting",
      ADD_MEMBER_TO_PUBLIC_WORKSPACE: "add.members_public_workspace",
      ADD_MEMBER_TO_PRIVATE_WORKSPACE: "add.members_private_workspace",
      INVITE_PEOPLE_TO_PUBLIC_WORKSPACE:
        "invite_people.public.company_workspace",
      INVITE_PEOPLE_TO_PRIVATE_WORKSPACE:
        "invite_people.private.company_workspace",
      POST_MESSAGE_TO_WORKSPACE: "messages.post_to_workspace",
      REMOVE_MESSAGE_FROM_WORKSPACE: "messages.remove_from_workspace",
      DELETE_WORKSPACE: "delete.workspace",
      REMOVE_USER_FORM_WORKSPACE: "remove.user_from.workspace",
      CHANGE_USER_ROLE: "change.user_roles",
      DEACTIVATE_USER_ACCOUNT: "deactivate.user.account",
      DELETE_USER_ACCOUNT: "delete.user.account",
    };
  },
  computed: {
    currentRole() {
      if (this.user) {
        return this.user.roles[0].id;
      }
      return 4;
    },
    userPermissions() {
      const query = this.$api.getQuery("UserPermissionsQuery");
      if (query.data) return query.data.userPermissions;
      return null;
    },
    canCJSPublic() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.CJS_PUBLIC;
        });
      } else {
        return false;
      }
    },
    canCreatePrivateWorkspace() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.CREATE_PRIVATE_WORKSPACE;
        });
      } else {
        return false;
      }
    },
    canChangeWorkspaceSetting() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.CHANGE_WORKSPACE_SETTING;
        });
      } else {
        return false;
      }
    },
    canAddMemberToPublicWorkspace() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.ADD_MEMBER_TO_PUBLIC_WORKSPACE;
        });
      } else {
        return false;
      }
    },
    canAddMemberToPrivateWorkspace() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.ADD_MEMBER_TO_PRIVATE_WORKSPACE;
        });
      } else {
        return false;
      }
    },
    canInviteMemberToPublicWorkspace() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.INVITE_PEOPLE_TO_PUBLIC_WORKSPACE;
        });
      } else {
        return false;
      }
    },
    canInviteMemberToPrivateWorkspace() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.INVITE_PEOPLE_TO_PRIVATE_WORKSPACE;
        });
      } else {
        return false;
      }
    },
    canPostMessageFromWorkspace() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.POST_MESSAGE_TO_WORKSPACE;
        });
      } else {
        return false;
      }
    },
    canRemoveMessageFromWorkspace() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.REMOVE_MESSAGE_FROM_WORKSPACE;
        });
      } else {
        return false;
      }
    },
    canDeleteWorkspace() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.DELETE_WORKSPACE;
        });
      } else {
        return false;
      }
    },
    canRemoveUserFromWorkspace() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.REMOVE_USER_FORM_WORKSPACE;
        });
      } else {
        return false;
      }
    },
    canChangeUserRole() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.CHANGE_USER_ROLE;
        });
      } else {
        return false;
      }
    },
    canDeactivateUserAccount() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.DEACTIVATE_USER_ACCOUNT;
        });
      } else {
        return false;
      }
    },
    canDeleteUserAccount() {
      if (this.userPermissions) {
        return this.userPermissions.some((permission) => {
          return permission.name === this.DELETE_USER_ACCOUNT;
        });
      } else {
        return false;
      }
    },
  },
  methods: {
    isVisible(rolesArray) {
      if (rolesArray.indexOf(this.currentRole) > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
