<template>
  <div
    class="flex-x flex-wrap"
    :style="[
      (attachments.length > 0 ||
        (comment && comment.mediafolder && comment.mediafolder.length > 0)) && {
        'margin-top': '12px',
        'margin-bottom': '-10px',
      },
    ]"
  >
    <template
      v-if="comment && comment.mediafolder && comment.mediafolder.length"
    >
      <template v-for="folder in comment.mediafolder">
        <task-folder
          :key="folder.id"
          :folder="folder"
          @openfolder="openFolder"
          :detachFolder="deleteFolderHandler"
          :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
          :data="comment"
          :height="height"
          @renameFolder="openRenameFolderDialog"
        />
      </template>
    </template>

    <div
      v-for="(media, index) in attachments"
      :key="index"
      :style="
        height
          ? {
              'margin-right': '5px',
              'margin-bottom': '5px',
            }
          : { 'margin-right': '10px', 'margin-bottom': '10px' }
      "
    >
      <task-dialog-card-media
        :type="type"
        :media="media"
        :canRemoveMedia="canRemove"
        :removeMedia="removeMedia"
        :postId="postId"
        :fullview="fullview"
        @copyLink="clickonmoreoption"
        :videoPlayer="videoPlayer"
        :audioPlayer="audioPlayer"
        :pdfView="pdfView"
        :height="height"
        :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
        :fromComputer="() => fromComputer(media.id)"
        @renameFile="openRenameFileDialog"
        :editDoc="editDoc"
        :currentUser="currentUser"
      ></task-dialog-card-media>
    </div>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="attachments"
      :dialogMedia="dialogMedia"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :currentUser="currentUser"
      :moreOption="moreOption"
      :isWorkspaceOwner="isWorkspaceOwner"
      :ishide="ishide"
      :labels="labels"
      :hideButtonBack="hideButtonBack"
      :resetDialog="() => resetDialog()"
      @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      @clickonmoreoption="clickonmoreoption"
      @clickonhidebutton="ishide = !ishide"
      @deleteCommentPoint="deleteCommentPoint"
      @sendComment="sendComment"
      @updatepositions="updatepositions"
      @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
      @deleteMediaComment="deleteMediaComment"
      @updateComment="updateComment"
      @setActiveMedia="setActiveMedia"
    ></image-viewer-dialog>
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :medias="attachments"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <audio-dialog
      v-model="showAudioModel"
      v-if="showAudioModel"
      :medias="attachments"
      :resetAudioDialog="resetAudioDialog"
      :audioMediaData="audioMediaData"
      :displayAudioPlayer="displayAudioPlayer"
    >
    </audio-dialog>
    <pdf-view-dialog
      v-if="showpdfModel"
      :fileExt="fileExt"
      v-model="showpdfModel"
      @resetPdfDialog="showpdfModel = false"
      :pdfMediaData="pdfMediaData"
    />
    <vue-dropzone-file-upload
      hidden
      ref="vueFileUploader"
      :workspace="workspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="fileUploadCloseHandler"
      @setProgress="setProgress"
      :media_id="mediaId"
    ></vue-dropzone-file-upload>
    <update-file-dialog
      v-if="renameFileDialog.flag"
      v-model="renameFileDialog.flag"
      :renameFile="renameFileDetails"
      :cancelMethod="cancelRenameFile"
    />
    <create-media-folder-dialog
      v-if="renameMediaFolderDialog.flag"
      v-model="renameMediaFolderDialog.flag"
      :renameFolder="renameFolderDetails"
      :loading="renameMediaFolderDialog.loading"
      :createFolderHandler="renameFolderHandler"
      :cancelMethod="cancelFolder"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import map from "lodash/map";

import TaskDialogCardMedia from "@/components/Miscellaneous/TaskDialogCardMedia";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import PdfViewDialog from "@/components/Dialogs/PdfViewDialog";
import AudioDialog from "@/components/Dialogs/AudioPlayerDialog";
import { GetMediaDetailMutation, GetNewMediaListMutation } from "@/gql";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import TaskFolder from "@/components/Miscellaneous/TaskFolder";
import UpdateFileDialog from "@/components/Dialogs/UpdateFileDialog";
import CreateMediaFolderDialog from "@/components/Dialogs/CreateMediaFolderDialog";

import { RenameMediaFolderMutation } from "@/gql";

export default {
  name: "SubTaskMedia",
  props: [
    "attachments",
    "removeMedia",
    "postId",
    "canRemoveMedia",
    "forceDisableRemove",
    "labels",
    "user",
    "isWorkspaceOwner",
    "type",
    "height",
    "workspace",
    "comment",
    "openFolder",
    "deleteFolderHandler",
    "copyMoveFileFolderHandler",
    "editDoc",
  ],
  data() {
    return {
      showModal: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      showAudioModel: false,
      displayAudioPlayer: false,
      audioMediaData: null,
      dialogMedia: null,
      gridMediaId: "",
      hideButtonBack: "#231d3b !important",
      ishide: false,
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
      ],
      pdfMediaData: null,
      showpdfModel: false,
      mediaId: null,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      renameFileDialog: {
        flag: false,
      },
      renameFileDetails: null,
      renameMediaFolderDialog: {
        flag: false,
        loading: false,
      },
      renameFolderDetails: null,
    };
  },
  components: {
    TaskDialogCardMedia,
    VideoDialog,
    PdfViewDialog,
    AudioDialog,
    ImageViewerDialog,
    VueDropzoneFileUpload,
    TaskFolder,
    UpdateFileDialog,
    CreateMediaFolderDialog,
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : null;
    },
    currentWorkspace() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    fileExt() {
      if (this.pdfMediaData) {
        return this.pdfMediaData.filename.split(".")[1];
      }
      return null;
    },
    canRemove() {
      return this.canRemoveMedia;
    },
    returnpoints() {
      var vm = this;
      let array = [];
      let filteredCommentPoints = this.attachments.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        array = filteredCommentPoints[0].commentPoint;
      }
      return array;
    },
    returnCommentListing() {
      var vm = this;
      let array = [];
      let filteredComment = this.attachments.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        array = filteredComment[0].comments;
      }
      return array;
    },
    currentUser() {
      return this.user;
    },
  },
  methods: {
    openRenameFolderDialog(folder) {
      this.renameFolderDetails = folder;
      this.renameMediaFolderDialog.flag = true;
    },
    cancelFolder() {
      this.renameFolderDetails = null;
      this.renameMediaFolderDialog.flag = false;
      this.renameMediaFolderDialog.loading = false;
    },
    async renameFolderHandler(title) {
      this.renameMediaFolderDialog.loading = true;
      if (this.renameFolderDetails) {
        try {
          const variables = {
            id: this.renameFolderDetails.id,
            title,
          };
          await this.$api.mutate({
            mutation: RenameMediaFolderMutation,
            variables,
          });
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${this.currentWorkspace}`
          );
          query.data.mediaFolders = query.data.mediaFolders.map((f) => {
            if (f.id !== this.renameFolderDetails.id) {
              f.title = title;
            }
            return f;
          });
          this.renameMediaFolderDialog.loading = false;
          this.renameMediaFolderDialog.flag = false;
          this.renameFolderDetails = null;
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
          this.renameMediaFolderDialog.loading = false;
          this.renameMediaFolderDialog.flag = false;
          this.renameFolderDetails = null;
        }
      }
    },
    openRenameFileDialog(media) {
      this.renameFileDetails = media;
      this.renameFileDialog.flag = true;
    },
    cancelRenameFile() {
      this.renameFileDetails = null;
      this.renameFileDialog.flag = false;
      this.renameFileDialog.loading = false;
    },
    async fileUploadCloseHandler(fileList) {
      const mediaIds = fileList ? map(fileList, (f) => f.id) : [];
      this.fileUpload.flag = false;
      this.fileUpload.dialog = false;

      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: mediaIds,
        },
      });

      if (!this.mediaId) {
        const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
        query.data.newMedia.push(...response.data.getNewMediaList);
      }
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    fromComputer(media_id) {
      this.mediaId = media_id;
      this.$refs.vueFileUploader.openFileDialog();
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        this.$refs.mediaCommentingView.$refs.commentBox.find(
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    fullview(data) {
      if (data.newpoint === "yes") {
        const value = {
          title: "Add Comment",
          gridMediaId: this.gridMediaId,
          media: data.media,
          forscroll: "no",
          x: 0,
          y: 0,
        };
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
        this.clickonmoreoption(value);
      } else {
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
      }
    },
    videoPlayer(media) {
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    pdfView(media) {
      // this.pdfMediaData = media;
      // this.showpdfModel = true;
      // if (this.fileExt.match(/pdf.*/)) {
      //   window.open(media.file, "_self");
      // } else {
      // }
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
        if (isElectron) window.electron.openExternal(media.file);
        else window.open(media.file, "_blank");
    },
    audioPlayer(media) {
      this.audioMediaData = media;
      this.displayAudioPlayer = true;
      this.showAudioModel = true;
    },
    resetAudioDialog() {
      this.displayAudioPlayer = false;
      setTimeout(() => {
        this.showAudioModel = false;
      }, 10);
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    resetPdfDialog() {
      setTimeout(() => {
        this.showpdfModel = false;
      }, 10);
    },
    resetDialog() {
      this.showModal = false;
      this.dialogMedia = null;
      this.closeMediaModel({
        id: this.gridMediaId,
      });
    },
    async closeMediaModel(variable) {
      await this.$api.mutate({
        mutation: GetMediaDetailMutation,
        variables: variable,
      });
    },
    clickonmoreoption(item) {
      const data = {
        title: item.title,
        gridMediaId: item.media.id,
        media: item.media,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
      };
      this.$emit("clickonmoreoption", data);
    },
    deleteCommentPoint(deletedata) {
      const makedata = {
        id: deletedata.id,
        index: deletedata.index,
        media_id: this.gridMediaId,
      };
      this.$emit("deleteCommentPoint", makedata);
    },
    sendComment(data) {
      const commentArg = {
        media_comments_point_id: data.id,
        comment: data.comment,
        media_id: this.gridMediaId,
      };
      this.$emit("sendComment", commentArg);
    },
    updatepositions(updateposition) {
      this.$emit("updatepositions", updateposition);
    },
    updateMediaCommentPointLabel(themedata) {
      this.$emit("updateMediaCommentPointLabel", themedata);
    },
    deleteMediaComment(commentData) {
      const commentdata = {
        commentid: commentData.commentid,
        index: commentData.index,
        media_id: this.gridMediaId,
        commentPointId: commentData.commentpointid,
      };
      this.$emit("deleteMediaComment", commentdata);
    },
    updateComment(changedata) {
      this.$emit("updateComment", changedata);
    },
  },
  watch: {
    ishide: {
      handler: function(newValue) {
        if (newValue) {
          this.hideButtonBack = "#8E9DAD !important";
        } else {
          this.hideButtonBack = "#231d3b !important";
        }
      },
      deep: true,
    },
  },
};
</script>
