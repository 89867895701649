<template>
  <q-dialog :value="true">
    <q-card class="image-viewer-walkthrough">
      <div class="body">
        <div class="video" @click="videoPlayer">
          <q-icon
            class="play"
            color="primary"
            :name="$icons.mdiPlay"
            size="55px"
          />
        </div>
        <div class="content">
          <p class="bold">View files and share comments</p>
          <p>
            Click on the image to zoom in and click and drag to pan around.
            Leave visual comments and more.
          </p>
        </div>
      </div>
      <div class="close" @click="closeWalkthroughBanner">
        <q-icon :name="$icons.matClose" size="xs" style="color: #677484" />
      </div>
    </q-card>
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
  </q-dialog>
</template>

<script>
import VideoDialog from "../VuePlayer/VideoDialog.vue";
export default {
  components: { VideoDialog },
  name: "ImageViewerWalkthroughDialog",
  data() {
    return {
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
    };
  },
  methods: {
    closeWalkthroughBanner() {
      this.$emit("closeBanner", null);
    },
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/569676712",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-viewer-walkthrough {
  width: 524px;
  height: 162px;
  padding: 15px 10px 15px 28px;
  display: flex;
  justify-content: space-around;
  font-family: "Avenir Next";
  font-size: 15.7px;

  .body {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .video {
    width: 171px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4.6px;
    background-color: #eff8ff;
    cursor: pointer;

    .play {
      color: white;
    }
  }
  .content {
    width: 250px;
    margin-left: 25px;
    .bold {
      font-weight: bold;
    }
    p {
      margin: 0;
    }
  }

  .close {
    margin-left: auto;
    cursor: pointer;
    height: max-content;
    margin-top: -10px;
  }
}
</style>
