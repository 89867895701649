<template>
  <node-view-wrapper as="span" style="display: inline-block">
    <img
      v-bind="node.attrs"
      :draggable="isDraggable"
      :data-drag-handle="isDraggable"
      ref="resizableImg"
      :class="[isResizing ? 'resize-handle' : isDraggable && 'drag-handle']"
    />
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2";

export default {
  components: {
    NodeViewWrapper,
  },

  props: nodeViewProps,

  data() {
    return {
      isResizing: false,
      lastMovement: 0,
      aspectRatio: 0,
    };
  },

  computed: {
    isDraggable() {
      return this.node?.attrs?.isDraggable;
    },
  },

  mounted() {
    this.$refs.resizableImg.onload = () => {
      this.aspectRatio =
        this.$refs.resizableImg.naturalWidth /
        this.$refs.resizableImg.naturalHeight;
    };

    this.$refs.resizableImg.addEventListener("mousedown", this.startResizeMode);

    this.$refs.resizableImg.addEventListener(
      "mousemove",
      this.resizeImageHandler
    );

    this.$refs.resizableImg.addEventListener("mouseup", this.stopResizeMode);
  },

  methods: {
    resizeImageHandler(e) {
      if (!this.isResizing) {
        return;
      }
      let movement = Math.sqrt(Math.pow(e.offsetY, 2) + Math.pow(e.offsetX, 2));
      if (this.lastMovement > 0) {
        if (movement > this.lastMovement) {
          this.resizeAspectRatio(true);
        } else if (movement < this.lastMovement) {
          this.resizeAspectRatio(false);
        }
      }

      this.lastMovement = movement;
    },
    startResizeMode() {
      if (this.isDraggable) {
        return;
      }
      this.isResizing = true;
      this.updateAttributes({ isResizing: this.isResizing });
    },
    stopResizeMode() {
      this.isResizing = false;
      this.lastMovement = 0;
      this.updateAttributes({ isResizing: this.isResizing });
    },
    resizeAspectRatio(grow) {
      let calcW;
      let calcH;

      if (grow) {
        calcH = this.$refs.resizableImg.height + 2;
      } else {
        calcH = this.$refs.resizableImg.height - 2;
      }

      calcW = calcH * this.aspectRatio;

      this.updateAttributes({
        width: calcW,
        height: calcH,
        isResizing: this.isResizing,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.resize-handle {
  cursor: nwse-resize;
}
.drag-handle {
  cursor: grab;
}
</style>
