<template>
  <tr
    :class="[
      selectedIds.includes(folder.id) && 'selected-item q-mb-xs',
      'media-list-folder vabotu-tbl-row group-hover',
    ]"
    @click="$emit('openfolder', folder)"
  >
    <q-menu context-menu content-style="min-width:150px" auto-close>
      <q-list>
        <q-item
          class="q-mb-sm"
          @click="checkMediaFolderAccess(folder)"
          clickable
        >
          Attach to message
        </q-item>
        <q-separator />
        <q-item
          v-close-popup
          @click="uploadFileToFolder(folder.id)"
          clickable
        >
          <q-item-section>
            Upload files
          </q-item-section>
        </q-item>
        <q-item
          clickable
          @click="$emit('renameFolder', folder)"
          class="q-mb-sm"
          v-close-popup
        >
          <q-item-section>
            Rename
          </q-item-section>
        </q-item>
        <q-separator />
        <!-- <q-item clickable v-close-popup>
          <q-item-section>
            Download
          </q-item-section>
        </q-item> -->
        <q-item
          @click="
            copyMoveFileFolderHandler([folder], false, false, true)
          "
          avatar
          clickable
          v-close-popup
        >
          <q-item-section> Copy </q-item-section>
        </q-item>
        <q-item
          @click="
            copyMoveFileFolderHandler([folder], true, false, true)
          "
          clickable
          v-close-popup
        >
          <q-item-section>
            Move
          </q-item-section>
        </q-item>
        <q-item
          @click="deleteFolder(folder.id)"
          clickable
          class="text-red"
          v-close-popup
        >
          <q-item-section> Delete </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <td width="20%">
      <q-list class="list list--two-line vb-tbody-list">
        <q-item class="q-pl-none">
          <q-item-section
            @click.stop="toggleSelect(folder)"
            class="items-center"
            avatar
          >
            <span class="custom-selection-checkbox">
              <div
                class="selected-custom-selection-checkbox"
                v-if="selectedIds.includes(folder.id)"
              ></div>
            </span>
            <!-- <q-checkbox
              v-model="folder.selected"
              @input="toggleSelect(folder)"
              size="sm"
              color="primary"
            /> -->
          </q-item-section>
          <q-item-section class="relative-position" avatar>
            <q-avatar rounded>
              <q-icon
                class="media-grid-folder-icon"
                size="40px"
                :name="$icons.matFolder"
              />
              <div class="media-grid-folder-count">
                {{ folder.media_count > 9 ? "9+" : folder.media_count }}
              </div>
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label class="break-word pl-3 an-15 medium-text">
              {{ folder.title }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </td>
    <td></td>
    <td></td>
    <td>
      <q-list>
        <q-item>
          <q-item-section>{{
            folder.created_at | tableDateFormate
          }}</q-item-section>
          <q-item-section side>
            <div
              class="task-menu-option thread-icons q-px-xs q-mt-sm q-mr-xs group-hover-item row item-center"
              style="z-index: 100"
            >
              <div
                v-if="folder.isbookmarked"
                @click.stop="removeMediaFolderBookmark(folder)"
              >
                <q-icon
                  size="20px"
                  class="cursor-pointer"
                  color="pink"
                  :name="$icons.matBookmark"
                >
                  <q-tooltip>
                    Remove from Saved items
                  </q-tooltip>
                </q-icon>
              </div>
              <div
                v-if="!folder.isbookmarked"
                @click.stop="addMediaFolderBookmark(folder)"
              >
                <q-icon
                  size="20px"
                  class=" cursor-pointer"
                  color="black"
                  :name="$icons.matBookmarkBorder"
                >
                  <q-tooltip>
                    Add to Saved items
                  </q-tooltip>
                </q-icon>
              </div>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </td>
  </tr>
</template>
<script>
export default {
  name: "ListFolder",
  props: [
    "folder",
    "deleteFolder",
    "toggleSelect",
    "selectedIds",
    "copyMoveFileFolderHandler",
    "attachFolderToPost",
    "uploadFileToFolder",
    "addMediaFolderBookmark",
    "removeMediaFolderBookmark",
  ],
  methods: {
    checkMediaFolderAccess(folder) {
      let eligible = this.$checkEligiblity("file_folders");
      if (eligible) {
        this.attachFolderToPost([folder]);
      }
    },
  },
};
</script>
