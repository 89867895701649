<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <q-card
      style="flex:0 1 1240px;max-width:100%;height:100%;max-height:800px;"
    >
      <div class="text-right q-mt-xs q-mr-xs">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="grey"
          round
          size="sm"
          @click="closeUpgradeModal"
        />
      </div>
      <div class="row q-px-xl q-pt-md q-pb-none" v-if="view === 'storage'">
        <!-- Left Column -->
        <div class="flex-y flex-1 justify-start">
          <strong class="an-35 bold-text">Storage Options</strong>
          <div class="divider section-spacer"></div>
          <img
            src="/static/images/settings/setting-moster.png"
            alt="Monster"
            class="absolute-bottom-left q-ml-md q-mb-md"
          />
        </div>
        <div class="flex-1 q-pb-md overflow-auto" style="max-height:570px">
          <div class="flex-1 scroll-y">
            <span class="label-span">Your Storage</span>
            <div class="row justify-center" v-if="companyLoading">
              <q-circular-progress
                indeterminate
                size="30px"
                :thickness="0.2"
                color="primary"
              />
            </div>
            <div class="row justify-center items-center q-mb-md" v-else>
              <div class="input-card q-pa-md full-width text-center">
                <span>
                  <strong>{{ usedStorage }}</strong> /
                  <strong>{{ allowedStorage }}</strong> GB
                </span>
                <q-linear-progress
                  size="30px"
                  color="primary"
                  track-color="indigo-3"
                  class="q-my-none"
                  :value="usedStorageInPercentage"
                />
                <span>
                  <strong>{{ freeStorageSpace }}</strong> GB FREE
                </span>
              </div>
            </div>
            <span class="label-span q-mb-md">
              <span class="q-mt-sm">Upgrade/Downgrade Your Storage</span>
            </span>
            <div class="row" v-if="companyLoading">
              <q-circular-progress
                indeterminate
                size="30px"
                :thickness="0.2"
                color="primary"
              />
            </div>
            <div class="row flex-wrap" v-else>
              <div
                class="flex-1 flex-y card text-center q-mx-xs q-mb-sm relative-position	"
                style="background: #F7F9FC; min-width: 115px"
                v-for="(plan, index) in myPlans"
                :class="{
                  'elevation-5': activePlan.storage_plan === plan.storage_plan,
                }"
                :key="`plan-${index}`"
              >
                <q-badge
                  v-if="activePlan.storage_plan === plan.storage_plan"
                  class="absolute"
                  color="primary"
                  style="left:-4px;top:-8px;padding:5px;border-radius:50%"
                >
                  <q-icon size="12px" :name="$icons.matCheck" color="white" />
                </q-badge>
                <div class="v-card__text text-center">
                  <div>
                    <div class="v-title q-mt-sm q-mb-xs an-15 bold-text">
                      {{ plan.plan }}
                    </div>
                    <span class="nolabel--text an-12 medium-text">
                      Your new total
                      <br />space will be
                    </span>
                    <div class="nolabel--text an-13 demi-bold-text">
                      {{ plan.storage }} GB
                    </div>
                  </div>
                </div>
                <div class="v-card__text active text-white q-pa-none">
                  <q-btn
                    size="md"
                    class="full-width"
                    color="teal-14"
                    @click="handleConfirmBox(plan, true)"
                  >
                    <span v-if="plan.storage_charge === 0">
                      <span class="an-15 bold-text">Free</span>
                    </span>
                    <span v-else>
                      <span class="an-15 bold-text"
                        >${{ plan.storage_charge }}</span
                      >
                      / MO
                    </span>
                  </q-btn>
                </div>
              </div>
            </div>
            <div class="editor-prototype q-mt-md">
              <ul class="nolabel--text an-13 regular-text">
                <li>
                  The prices shown above are in addition to your subscription
                  charges.
                </li>
                <li>
                  If you upgrade/downgrade your storage, the new space will be
                  added/ subtracted to/from your existing space
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <register-pay-card
        v-if="view === 'RegisterPayCard'"
        @back="view = 'storage'"
        :loader="loader"
        @registerCardEvent="registerCardEventHandler"
        :invalidCodeMessage="invalidCodeMessage"
      />

      <q-dialog
        transition-hide="none"
        transition-show="none"
        v-model="confirm.flag"
      >
        <q-card style="max-width:290px">
          <div>
            <div class=" q-pt-md text-h5 text-center">
              <span class="q-mx-auto">{{ confirm.title }}</span>
            </div>
            <div
              class="text-grey-7 text-center q-py-md q-px-md"
              v-html="confirm.desc"
            ></div>
            <div
              class="row"
              style="border-top: 1px solid #d0d7dd;"
              v-if="confirm.infoMod"
            >
              <div class="col-grow">
                <q-btn
                  color="deep-orange-6"
                  class="q-ma-none full-width"
                  size="14px"
                  flat
                  @click="resetConfirm"
                  label="OK"
                />
              </div>
            </div>
            <div class="row " style="border-top: 1px solid #d0d7dd;" v-else>
              <div class="col-grow">
                <q-btn
                  :disabled="loadingConfirm"
                  class="q-ma-none full-width"
                  style="border-right: 1px solid #d0d7dd;"
                  @click="resetConfirm"
                  size="14px"
                  color="grey-10"
                  flat
                  label="Cancel"
                />
              </div>
              <div class="col-grow">
                <q-btn
                  class="q-ma-none full-width"
                  size="14px"
                  color="deep-orange-6"
                  flat
                  label="OK"
                  @click="switchPlan"
                  :loading="loadingConfirm"
                />
              </div>
            </div>
          </div>
        </q-card>
      </q-dialog>
    </q-card>
  </q-dialog>
</template>
<script>
import errorHandler from "@/utils/ErrorHandler";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import RegisterPayCard from "@/components/AdministrationWizard/RegisterPayCard";
import {
  PayCardQuery,
  CompanyQuery,
  CheckCompanyStatusMutation,
  UpdatePlanMutation,
  AddNewPayCardMutation,
} from "@/gql";

export default {
  name: "StorageOptionDialog",
  mixins: [ProxyModelMixin],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    payCard: {
      query: PayCardQuery,
      cacheKey: "PayCardQuery",
      defaultValue: null,
    },
    company: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
  },
  components: {
    RegisterPayCard,
  },
  mounted() {
    this.checkCompanyStatus();
  },
  data() {
    return {
      confirm: {
        title: null,
        desc: null,
        flag: false,
        infoMod: false,
      },
      allowedStorage: null,
      usedstorage: null,
      plans: [
        {
          id: 1,
          plan: "FREE",
          storage: 100,
          base_plan: "v1_base",
          storage_plan: "v1_storage_free",
          charge: 9.0,
          storage_charge: 0,
        },
        {
          id: 2,
          plan: "100 GB",
          storage: 200,
          base_plan: "v1_base",
          storage_plan: "v1_storage_100gb",
          charge: 9.0,
          storage_charge: 5,
        },
        {
          id: 3,
          plan: "500 GB",
          storage: 600,
          base_plan: "v1_base",
          storage_plan: "v1_storage_500gb",
          charge: 9.0,
          storage_charge: 10,
        },
        {
          id: 4,
          plan: "1000 GB",
          storage: 1100,
          base_plan: "v1_base",
          storage_plan: "v1_storage_1000gb",
          charge: 9.0,
          storage_charge: 15,
        },
      ],
      newPlans: [
        {
          id: 1,
          plan: "FREE",
          storage: 100,
          base_plan: "v2_base",
          storage_plan: "v2_storage_free",
          charge: 12.99,
          storage_charge: 0,
        },
        {
          id: 2,
          plan: "100 GB",
          storage: 200,
          base_plan: "v2_base",
          storage_plan: "v2_storage_100gb",
          charge: 12.99,
          storage_charge: 5,
        },
        {
          id: 3,
          plan: "500 GB",
          storage: 600,
          base_plan: "v2_base",
          storage_plan: "v2_storage_500gb",
          charge: 12.99,
          storage_charge: 10,
        },
        {
          id: 4,
          plan: "1000 GB",
          storage: 1100,
          base_plan: "v2_base",
          storage_plan: "v2_storage_1000gb",
          charge: 12.99,
          storage_charge: 15,
        },
      ],
      v3_plans: [
        {
          id: 1,
          plan: "FREE",
          storage: 5,
          total_storage: 5,
          storage_plan: "v3_storage_5gb",
          storage_charge: 0,
        },
        {
          id: 2,
          plan: "100 GB",
          storage: 100,
          total_storage: 105,
          storage_plan: "v3_storage_100gb",
          storage_charge: 5,
        },
        {
          id: 3,
          plan: "500 GB",
          storage: 500,
          total_storage: 505,
          storage_plan: "v3_storage_500gb",
          storage_charge: 10,
        },
        {
          id: 4,
          plan: "1000 GB",
          storage: 1000,
          total_storage: 1005,
          storage_plan: "v3_storage_1000gb",
          storage_charge: 15,
        },
      ],
      view: "storage",
      loader: false,
      loadingConfirm: false,
      invalidCodeMessage: null,
      selectedPlan: null,
    };
  },
  methods: {
    closeUpgradeModal() {
      this.proxyModel = false;
      this.$emit("close");
    },
    async checkCompanyStatus() {
      const res = await this.$api.mutate({
        mutation: CheckCompanyStatusMutation,
      });
      this.allowedStorage = res.data.checkCompanyStatus.allowed_storage;
      this.usedstorage = res.data.checkCompanyStatus.used_storage;
    },
    switchPlan() {
      if (this.activePlan.id !== this.selectedPlan.id) {
        if (this.payCard === null) {
          this.resetConfirm();
          this.view = "RegisterPayCard";
        } else {
          this.updatePlan(this.selectedPlan);
        }
      }
    },
    handleConfirmBox(plan, flag) {
      this.selectedPlan = plan;
      if (plan.id > this.activePlan.id) {
        this.confirm = {
          title: "Upgrade Storage?",
          desc: `Are you sure you want to upgrade your storage to ${plan.storage} GB extra? Your new monthly payment for Heycollab - will be $${plan.storage_charge} per month.`,
          flag,
          infoMod: false,
        };
      } else if (plan.id < this.activePlan.id) {
        this.confirm = {
          title: "Downgrade Storage?",
          desc: `Are you sure you want to downgrade your storage to ${plan.storage} GB extra? Your new monthly payment for Heycollab - will be $${plan.storage_charge} per month.`,
          flag,
          infoMod: false,
        };
      }
    },
    resetConfirm() {
      this.confirm = {
        title: null,
        desc: null,
        flag: false,
        infoMod: false,
      };
    },
    async updatePlan(plan) {
      this.loadingConfirm = true;
      const variables = {
        base_plan: plan.base_plan,
        storage_plan: plan.storage_plan,
      };

      try {
        const response = await this.$api.mutate({
          mutation: UpdatePlanMutation,
          variables,
        });
        if (response) {
          this.checkCompanyStatus();
          const result = await this.$api.query({
            query: CompanyQuery,
          });
          if (result) {
            const query = this.$api.getQuery("CompanyQuery");
            query.data.company = result.data.company;
          }
          this.selectedPlan = null;
          this.resetConfirm();
          this.loadingConfirm = false;
        }
      } catch (error) {
        // console.log(error);
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message:
            "You can not downgrade. Your storage utlization is more than requested plan",
          timeout: 6000,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.confirm.flag = false;
        this.invalidCodeMessage = errorHandler.getErrorMessage(
          error,
          this,
          "message.validation.StorgeExcessiveUsed"
        );
        this.loadingConfirm = false;
      }
    },
    async registerCardEventHandler(args) {
      this.loader = true;
      const variables = {
        ...args,
        first: this.user.first,
        email: this.user.email,
      };
      try {
        const response = await this.$api.mutate({
          mutation: AddNewPayCardMutation,
          variables,
        });

        this.$api.query({
          query: PayCardQuery,
          cacheKey: "PayCardQuery",
        });

        if (response.data.addNewPayCard) {
          this.loader = false;
          this.view = "storage";
          this.successHandler(this.$t("message.newPaycardAdded"));
          this.updatePlan(this.selectedPlan);
        } else {
          this.invalidCodeMessage = this.$t("message.CardDeclined");
          this.loader = false;
        }
      } catch (error) {
        this.invalidCodeMessage = errorHandler.getErrorMessage(
          error,
          this,
          "message.validation.CardDeclined"
        );
        this.loader = false;
      }
    },
    successHandler(message) {
      this.$q.notify({
        classes: 'success-notification',
        position: "top-right",
        message,
        timeout: 6000,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
  },
  computed: {
    checkCompanyPriceVersion() {
      if (this.company) {
        return this.company.pricing_version === 1
          ? "v1"
          : this.company.pricing_version === 2
          ? "v2"
          : "v3";
      }
      return "v3";
    },
    activePlan() {
      return this.company
        ? this.myPlans.find(
            (plan) =>
              plan.base_plan === this.company.subscription.base_plan &&
              plan.storage_plan === this.company.subscription.storage_plan
          )
        : null;
    },
    myPrice() {
      if (this.checkCompanyPriceVersion === "v1") {
        return 9;
      }
      if (this.checkCompanyPriceVersion === "v2") {
        return 12.99;
      }
      return this.company.subscription.base_plan === "v3_free"
        ? 0
        : this.company.subscription.base_plan === "v3_studio"
        ? 29.99
        : 59.99;
    },
    myPlans() {
      if (this.checkCompanyPriceVersion === "v1") {
        return this.plans;
      }
      if (this.checkCompanyPriceVersion === "v2") {
        return this.newPlans;
      }
      let v3_plans = [];
      this.v3_plans.forEach((plan) => {
        v3_plans.push({
          ...plan,
          base_plan: this.company.subscription.base_plan,
          charge:
            this.company.subscription.base_plan === "v3_free"
              ? 0
              : this.company.subscription.base_plan === "v3_studio"
              ? 29.99
              : 59.99,
        });
      });
      return v3_plans;
    },
    usedStorageInPercentage() {
      return this.usedStorage ? this.usedStorage / this.allowedStorage : 0;
    },
    usedStorage() {
      return this.usedstorage ? Math.round(this.usedstorage) : 0;
    },
    freeStorageSpace() {
      const available = this.usedStorage
        ? this.allowedStorage - this.usedStorage
        : this.allowedStorage;
      return Math.round(available);
    },
  },
};
</script>
