var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.draggableValue),expression:"draggableValue"}],ref:_vm.handleId,class:(_vm.selectedPointData && _vm.selectedPointData.index === _vm.index) ||
    (_vm.initialiseComment && _vm.returnCommentListing.length === 0)
      ? 'absolute point'
      : 'absolute',style:({ top: _vm.returnPointY + 'px', left: _vm.returnPointX + 'px' })},[_c('q-btn',{staticClass:"q-ma-none",style:({
      'background-color':
        _vm.point.last_label === 'nolabel' ? '#007aff' : _vm.point.last_label,
      border: '2px solid white',
      'z-index':
        (_vm.selectedPointData && _vm.selectedPointData.index === _vm.index) ||
        (_vm.initialiseComment && _vm.returnCommentListing.length === 0)
          ? 9
          : 0,
    }),attrs:{"round":"","size":(_vm.selectedPointData && _vm.selectedPointData.index === _vm.index) ||
      (_vm.initialiseComment && _vm.returnCommentListing.length === 0)
        ? '15px'
        : '12px',"text-color":"white","label":_vm.index + 1},on:{"click":_vm.selectPoint}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }