<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    :value="value"
    maximized
  >
    <div>
      <div class="row justify-center items-center full-height">
        <div>
          <div>
            <div>
              <q-btn
                round
                flat
                size="md"
                color="primary"
                text-color="white"
                :icon="$icons.matClose"
                class="absolute-top-right q-mt-sm q-mr-sm"
                @click="resetAudioDialog"
              >
                <q-tooltip>
                  Close
                </q-tooltip>
              </q-btn>
            </div>
            <div v-if="displayAudioPlayer">
              <audio controls="controls" id="audio_player" autoplay="true">
                <source :src="audioMediaData.file" type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-dialog>
</template>
<script>
export default {
  name: "AudioPlayerDialog",
  props: [
    "value",
    "resetAudioDialog",
    "medias",
    "audioMediaData",
    "displayAudioPlayer",
  ],
};
</script>
