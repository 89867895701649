import mime from "mime-types";
import getUnixTime from "date-fns/getUnixTime";

export default {
  data() {
    return {
      isLoadingImage: false,
      dropBoxObj: window.Dropbox,
    };
  },
  methods: {
    dropBox(type = null, userId) {
      const vm = this;
      const options = {
        success: (files) => {
          const getFiles = {};
          vm.isLoadingImage = true;
          files.forEach(async (element) => {
            const obj = {
              id: element.id,
              file: element.link,
              filename: element.name,
              type: mime.lookup(element.name),
              file_resource: "dropbox",
              size: element.bytes,
              commentPoint: [],
              comments: [],
              thumb: element.link.replace("dl=0", "raw=1"),
              user_id: userId,
              workspace_id: null,
              __typename: "media",
              created_at: getUnixTime(new Date()),
            };
            getFiles[element.id] = obj;
            if (Object.keys(getFiles).length === files.length) {
              vm.isLoadingImage = false;
              vm.attachMediaToPost(getFiles, "google", type);
            }
          });
        },
        multiselect: true,
        extensions: [".pdf", ".doc", ".docx", "images", "video"],
        folderselect: false,
        sizeLimit: 52428800,
      };
      vm.dropBoxObj.choose(options);
    },
  },
};
