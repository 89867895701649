<template>
  <textarea
    class="editor mark-down-editor"
    :class="[isScroll ? 'is-scrolling' : '']"
    ref="textarea"
    :value="value"
    @paste="onPaste"
    @input="handleInput"
    @keydown="handleKeyDown"
    @blur="handleBlur"
    @focus="handleFocus"
    :style="{
      overflow: overflowValue,
      'max-height': maxHeight + 'px',
      padding: isMessagingEditor && '8px 0 8px',
      'min-height': isMessagingEditor && '40px',
      marginLeft: setMargin && '15px',
    }"
    :autofocus="autofocus"
    @keydown.esc.stop="handleEscapeKey"
  />
</template>

<script>
let getCaretCoordinates = require("textarea-caret");

export default {
  name: "MarkupEditor",
  props: [
    "value",
    "emojisQuery",
    "minHeight",
    "maxHeight",
    "autofocus",
    "isAbPosition",
    "isMessagingEditor",
    "updateScrollFlag",
    "setMargin",
    "height",
    "overflow",
  ],
  data() {
    return {
      isScroll: false,
      caret: null,
      removeEnter: false,
      overflowValue: this.overflow,
      editorData: null,
    };
  },
  computed: {
    getMinHeight() {
      return this.minHeight;
    },
    getMaxHeight() {
      return this.maxHeight;
    },
  },
  mounted() {
    if (this.autofocus) {
      this.fireFocus();
    }
    this.$nextTick(() => {
      this.updateScroll();
    });
  },
  methods: {
    onPaste(evt) {
      const vm = this;
      var items = (evt.clipboardData || evt.originalEvent.clipboardData).items;
      for (let index in items) {
        var item = items[index];
        if (item.kind === "file") {
          var blob = item.getAsFile();
          var reader = new FileReader();
          reader.onload = (event) => {
            const obj = {
              file: blob,
              preview: event.target.result,
            };
            vm.$emit("paste", obj);
          };
          reader.readAsDataURL(blob);
        }
      }
      vm.$emit("paste");
    },
    doEdit() {
      this.fireFocus();
      this.$nextTick(() => {
        this.updateScroll();
      });
      document.execCommand("insertText", false, "");
    },
    fireFocus() {
      this.$refs.textarea.focus();
      this.$refs.textarea.scrollTo(0, 0);
    },
    resetTextArea() {
      this.$refs.textarea.style.height = `${this.getMinHeight}px`;
      this.isScroll = false;
      this.overflowValue = "hidden";
      this.fireFocus();
    },
    fireBlur() {
      this.$refs.textarea.blur();
    },
    handleFocus(event) {
      this.$emit("focus", event);
    },
    handleBlur(event) {
      this.$emit("blur", event);
    },
    updateScroll() {
      this.$refs.textarea.style.height = `${this.getMinHeight}px`;
      this.$refs.textarea.style.height =
        0 + this.$refs.textarea.scrollHeight + "px";
      if (
        this.$refs.textarea.scrollHeight - 5 > this.getMaxHeight &&
        this.updateScrollFlag
      ) {
        this.overflowValue = "auto";
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },
    toggleMark(mark) {
      const { textarea } = this.$refs;
      const { value } = textarea;
      const { start, end } = this.getSelection();

      textarea.focus();
      textarea.setSelectionRange(start, end);

      // [mark] [start] [text] [end] [mark] => remove mark
      if (
        value.slice(start - mark.length, start) === mark &&
        value.slice(end, end + mark.length) === mark
      ) {
        textarea.setSelectionRange(start - mark.length, end + mark.length);
        document.execCommand("insertText", false, value.slice(start, end));
        textarea.setSelectionRange(start - mark.length, end - mark.length);
      }
      // [start] [mark] [text] [mark] [end] => remove mark
      else if (
        value.slice(start, start + mark.length) === mark &&
        value.slice(end - mark.length, end) === mark
      ) {
        document.execCommand(
          "insertText",
          false,
          value.slice(start + mark.length, end - mark.length)
        );
        textarea.setSelectionRange(start, end - mark.length * 2);
      }
      // [start] [text] [end] => add mark
      else {
        document.execCommand(
          "insertText",
          false,
          `${mark}${value.slice(start, end)}${mark}`
        );
        textarea.setSelectionRange(start, end + mark.length * 2);
      }
    },
    handleInput(event) {
      const { textarea } = this.$refs;
      // if (
      //   event.target.value.includes(process.env.VUE_APP_APPBASE_URL) &&
      //   this.getQueryParameterByName("task", event.target.value)
      // ) {
      //   event.target.value = this.trimAny(event.target.value, ["@"]);
      // }
      // if (event.target.value.includes(process.env.VUE_APP_APPBASE_URL) && this.$getQueryParameterByName("task", event.target.value)) {
      //   //let workspaceId = this.$getQueryParameterByName("workspace", event.target.value);
      //   let workspaceName = this.$getQueryParameterByName("workspaceName", event.target.value).substring(0, 2).toUpperCase();
      //   let subtaskId = this.$getQueryParameterByName("sub_task", event.target.value);
      //   let taskId = this.$getQueryParameterByName("task", event.target.value);

      //   let taskStr = subtaskId ? 'S'+workspaceName+'-'+taskId+'-'+subtaskId : 'T'+workspaceName+'-'+taskId;

      //   let stringArr = event.target.value.split(" ");
      //   stringArr.forEach(function(item, i) { if (item.indexOf(process.env.VUE_APP_APPBASE_URL) !== -1) stringArr[i] = taskStr; });

      //   event.target.value = stringArr.join(" ");
      // }
      this.$emit("input", event.target.value);
      this.emitMentionsQuery();
      this.updateScroll();
      if (this.isAbPosition) {
        this.caret = getCaretCoordinates(textarea, textarea.selectionEnd);
        this.$emit("currentPositioning", {
          top: this.caret.top > 105 ? 105 : this.caret.top,
          left: this.caret.left,
        });
      }
    },
    getQueryParameterByName(name, url) {
      var match = RegExp("[?&]" + name + "=([^&]*)").exec(url);
      return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    },
    trimAny(str, chars) {
      var start = 0,
        end = str.length;

      while (start < end && chars.indexOf(str[start]) >= 0) ++start;

      while (end > start && chars.indexOf(str[end - 1]) >= 0) --end;

      return start > 0 || end < str.length ? str.substring(start, end) : str;
    },
    handleKeyDown(e) {
      this.$emit("keyPressed", e);
      if (
        e.keyCode === 13 &&
        !e.shiftKey &&
        this.getCurrentToken().value[0] !== "@" &&
        this.emojisQuery === null &&
        !this.removeEnter
      ) {
        e.preventDefault();
        this.$emit("submit");
        this.resetTextArea();
      }
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 66) {
        this.toggleMark("*");
      }
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 73) {
        this.toggleMark("_");
      }
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 75) {
        this.$emit("openAddLink");
      }
    },
    emitMentionsQuery() {
      const token = this.getCurrentToken().value;
      this.$emit("mentionsQuery", token[0] === "@" ? token : null);
      this.$emit("emojisEvent", token[0] === ":" ? token : null);
      if (document.getElementById("media-comment-scroll")) {
        if (token[0] === ":") {
          document.getElementById("media-comment-scroll").style.height =
            "325px";
        } else {
          document.getElementById("media-comment-scroll").style.height = "auto";
        }
      }
    },
    getSelection() {
      const { textarea } = this.$refs;
      const { selectionStart, selectionEnd } = textarea;

      if (selectionStart !== selectionEnd) {
        return {
          start: selectionStart,
          end: selectionEnd,
        };
      }

      const token = this.getCurrentToken();
      return {
        start: token.start,
        end: token.end,
      };
    },
    getCurrentToken() {
      const { textarea } = this.$refs;
      let start = textarea.selectionStart;
      let end = start;

      while (
        textarea.value[start - 1] !== undefined &&
        !textarea.value[start - 1].match(/\s/) &&
        start > 0
      ) {
        start--;
      }

      while (
        textarea.value[end] !== undefined &&
        !textarea.value[end].match(/\s/) &&
        end < textarea.value.length
      ) {
        end++;
      }

      const value = textarea.value.slice(start, end);

      return { start, end, value };
    },
    insertText(input) {
      this.fireFocus();
      document.execCommand("insertText", false, input);
    },
    insertEmoji(emoji) {
      const { textarea } = this.$refs;
      this.removeEnter = true;
      this.fireFocus();
      const { start, end } = this.getSelection();
      textarea.setSelectionRange(start, end);
      document.execCommand("insertText", false, `${emoji}`);
      document.execCommand("insertText", false, ` `);
      setTimeout(() => {
        this.removeEnter = false;
      }, 10);
    },
    insertMention(username) {
      const { textarea } = this.$refs;
      this.removeEnter = true;
      this.fireFocus();
      const { start, end } = this.getSelection();
      textarea.setSelectionRange(start, end);
      document.execCommand("insertText", false, `@${username}`);
      document.execCommand("insertText", false, ` `);
      setTimeout(() => {
        this.removeEnter = false;
      }, 10);
    },
    handleEscapeKey() {
      this.resetTextArea();
      this.$emit("escapePressed");
    },
  },
};
</script>
