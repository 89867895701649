<template>
  <div
    :class="[
      isFromTask ? '' : isFromAbout ? 'q-pa-xs' : 'q-pa-md',
      'document-editor-toolbar-wrapper row flex-no-wrap justify-between',
    ]"
    :style="isFromAbout || isFromTask && { borderBottom: 'none' }"
  >
    <div class="publish-mobile-view">
      <document-toolbar-options
        v-if="!isMobileView"
        :editor="editor"
        :isDisabled="isDisabled"
        :isFromNote="isFromNote"
        :isFromTask="isFromTask"
        :isFromAbout="isFromAbout"
        @textformat="(data) => $emit('textformat', data)"
        :showButton="showButton"
        :toggleResize="toggleResize"
        :isDraggable="isDraggable"
      />
    </div>
    <div>
      <document-toolbar-options
        v-if="isMobileView"
        :editor="editor"
        :isDisabled="isDisabled"
        :isFromNote="isFromNote"
        :isFromAbout="isFromAbout"
        @textformat="(data) => $emit('textformat', data)"
        :showButton="showButton"
        :toggleResize="toggleResize"
        :isDraggable="isDraggable"
      />
    </div>
  </div>
</template>
<script>
import DocumentToolbarOptions from "@/components/DocumentEditor/DocumentToolbarOptions";
export default {
  components: { DocumentToolbarOptions },
  props: {
    editor: {
      type: Object,
      default: () => null,
    },
    isDisabled: Boolean,
    isMobileView: {
      type: Boolean,
      default: false,
    },
    isFromNote: {
      type: Boolean,
      default: false,
    },
    isFromAbout: {
      type: Boolean,
      default: false,
    },
    isFromTask: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    toggleResize: {
      type: Function,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
