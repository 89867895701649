<template>
  <div
    class="col-grow border-radius5 editor-box-border"
    id="mediafilesubtaskUploader"
  >
    <div
      class="row flex-1 relative-position q-py-sm"
      style="
        background: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      "
    >
      <add-formate-option
        @toggleBold="toggleBold"
        @toggleItalic="toggleItalic"
        @insertText="insertText"
        :closeHyperlinkModal="closeHyperlinkModal"
        :showAddLink="showAddLink"
        @openAddLink="openAddLink"
        :addLinkTitle="addLinkTitle"
      />
    </div>
    <div class="flex-x flex-1 relative-position">
      <add-media-plus
        @clicked="fileUpload.dialog = true"
        @openSharedFile="sharedFileDialog = true"
        @dropBox="() => dropBox(null, currentUser.id)"
        @googleDrive="() => googleDrive(null, currentUser.id)"
        @fromComputer="fromComputer"
      />
      <editor
        v-model="newSubtask"
        ref="subtaskVisible"
        class="text-subtitle1"
        :editor-style="{
          'max-height': '200px',
          border: newSubtaskLen > 3000 ? '1px solid #ff3279' : 'none',
        }"
        @submit="addSubTaskHandler"
        :maxHeight="200"
        :is-menu="false"
        :subtaskEditor="true"
        placeholder="Create Subtask ..."
        :autofocus="false"
        :fileUploadFlag="fileUpload.dialog"
        :overflow="'auto'"
        :mentions="mentionsList"
        :isAbPosition="true"
        :bottomPositions="true"
        @openAddLink="openAddLink"
      />
      <!-- Send Btn -->
      <div class="action-buttons">
        <q-btn
          :icon="$icons.matClose"
          class="cancel-button"
          @click="resetSubtaskEditor"
          size="13px"
          round
          dense
          flat
        />
        <div
          class="add-subtask-button ma-0 elevation-0"
          :class="
            (!newSubtask || showProgress || !isFileUploading) && 'disabled'
          "
          @click="addSubTaskHandler"
          :disabled="!newSubtask || showProgress || !isFileUploading"
        >
          <q-icon :name="$icons.mdiCheckBold" />
        </div>
      </div>
      <shared-files-dialog
        v-if="sharedFileDialog"
        v-model="sharedFileDialog"
        @closeDialog="sharedFileDialog = false"
        :imageList="imageList"
        :attachMediaToPost="addSharedFileToUploader"
        :isloadingMedia="isloadingMedia"
        :currentWorkspaceId="currentWorkspace"
      />
      <vue-dropzone-file-upload
        hidden
        ref="vueFileUploader"
        :workspace="currentWorkspace"
        :isWorkspaceOwner="isWorkspaceOwner"
        :authToken="authToken"
        :totalFile="totalFile"
        @close="fileUploadCloseHandler"
        @setProgress="setProgress"
        @totalFileCount="(total) => (totalFile = total)"
        @updateUploadCount="(uploaded) => (uploadCount = uploaded)"
      ></vue-dropzone-file-upload>
    </div>
    <div style="display: flex; justify-content: center">
      <q-linear-progress
        color="green"
        :indeterminate="query"
        size="20px"
        class="ma-0 progress"
        :value="progress"
        v-if="progress > 0"
        dark
        rounded
      >
        <div class="absolute-full flex label" style="margin-left: 10px">
          <div style="margin-right: 5px">
            <q-btn
              round
              :icon="$icons.matClose"
              size="5px"
              style="color: #000000; opacity: 0.5; background: #ffffff"
              @click="cancelUpload"
            >
              <q-tooltip> Cancel Upload </q-tooltip>
            </q-btn>
          </div>
          <div>
            <p>
              {{progress &lt; 1 && uploadCount && totalFile? `${uploadCount} of ${totalFile} files uploaded` : 'Uploaded'}}
              <span>{{ Math.round(progress * 100) + "%" }}</span>
            </p>
          </div>
        </div>
      </q-linear-progress>
    </div>
    <div
      v-if="totalFiles.length > 0 || folderListArr.length"
      class="overflow-auto q-mt-sm full-width media-attachments-wrapper scroll-wrapper"
    >
      <template v-for="folder in folderListArr">
        <div v-if="folder" :data-id="folder.id" :key="folder.id">
          <div
            class="relative-position media-list-folder edtor-media-upload-attachment q-mb-sm q-mx-md row flex-no-wrap items-center group-hover-media cursor-pointer noselect"
            @click="openFolder(folder)"
          >
            <div class="relative-position" style="height: 40px">
              <q-avatar size="40px" rounded>
                <q-icon
                  class="media-grid-folder-icon"
                  size="40px"
                  :name="$icons.matFolder"
                />
                <div class="media-grid-folder-count">
                  {{ folder.media_count > 9 ? "9+" : folder.media_count }}
                </div>
              </q-avatar>
            </div>
            <div class="file-name" v-tooltip>
              {{ folder.title }}
            </div>
            <div
              @click.stop="removeFolder(folder)"
              class="media-remove cursor-pointer group-hover-media-item"
            >
              <q-icon :name="$icons.matClose" class="close-icon" />
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="totalFiles.length > 0"
        v-sortable="{
          onAdd: onDragAddOrUpdate,
          onUpdate: onDragAddOrUpdate,
          emptyInsertThreshold: 100,
        }"
      >
        <template v-for="file in totalFiles">
          <div
            class="q-px-xs q-pt-xs"
            v-if="file"
            :data-id="file.id"
            :key="file.id"
          >
            <media
              :showProgress="showProgress"
              :media="file"
              :removeMedia="removeMedia"
              :height="40"
              :fullview="fullview"
              :editDoc="editDoc"
              class="q-mx-md"
            ></media>
          </div>
        </template>
      </div>
    </div>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="totalFiles"
      :dialogMedia="dialogMedia"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :currentUser="currentUser"
      :moreOption="moreOption"
      :isWorkspaceOwner="isWorkspaceOwner"
      :ishide="ishide"
      :labels="taskLabels"
      :resetDialog="() => resetDialog()"
      @clickonhidebutton="ishide = !ishide"
      @setActiveMedia="setActiveMedia"
      @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
      @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
      @sendComment="(data) => $emit('sendComment', data)"
      @updatepositions="(data) => $emit('updatepositions', data)"
      @updateMediaCommentPointLabel="
        (data) => $emit('updateMediaCommentPointLabel', data)
      "
      @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
      @updateComment="(data) => $emit('updateComment', data)"
    ></image-viewer-dialog>
    <upgrade-storage-dialog
      v-if="company && company.pricing_version === 3 && upgradeStorageDialog"
      v-model="upgradeStorageDialog"
      :reachedLimitPlan="company && company"
    />
  </div>
</template>
<script>
import get from "lodash/get";
import keys from "lodash/keys";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import RandomPosition from "random-position";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";

import { mapGetters } from "vuex";
import Editor from "@/components/Editor/Editor";
import AddMediaPlus from "@/components/Editor/AddMediaPlus";
import Media from "@/components/Miscellaneous/Media";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";
import UpgradeStorageDialog from "@/components/Dialogs/UpgradeStorageDialog";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import VueDropzoneFileUpload from "../Miscellaneous/VueDropzoneFileUpload.vue";
import {
  UpdateMediaMutation,
  DetachSubtaskFolderMutation,
  DetachSubtaskMediaMutation,
} from "@/gql";
import AddFormateOption from "@/components/Editor/AddFormateOption";

export default {
  name: "SubtaskEditor",
  props: {
    taggUsers: Array,
    workspace: Object,
    currentUser: Object,
    taskLabels: Array,
    isWorkspaceOwner: Boolean,
    subtask: {},
    resetSubtaskEditor: Function,
    imageList: Array,
    newSubtaskEditorVisibility: Boolean,
    isloadingMedia: Boolean,
    openSubtask: Boolean,
    editDoc: Function,
    openFolder: Function,
  },
  mixins: [GoogleDriveMethodMixin, DropBoxMixin, CopiedImageOnEditor],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    company: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
  },
  mounted() {
    this.openSubtask && this.$refs.subtaskVisible.$refs.editor.fireFocus();
  },
  data() {
    return {
      panel: [true],
      newSubtask: null,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      previousTagged: [],
      commentEditorMedia: false,
      upgradeStorageDialog: false,
      fileList: {},
      folderList: {},
      sharedFileDialog: false,
      showModal: false,
      dialogMedia: null,
      gridMediaId: "",
      ishide: false,
      hideButtonBack: "#231d3b !important",
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
      ],
      progress: 0,
      totalFile: 0,
      uploadCount: 0,
      showAddLink: false,
      addLinkTitle:null,
      addLinkTextPosition:{
        start:null,
        end:null,
      },
    };
  },
  components: {
    Editor,
    VueDropzoneFileUpload,
    Media,
    AddMediaPlus,
    SharedFilesDialog,
    UpgradeStorageDialog,
    ImageViewerDialog,
    AddFormateOption,
  },
  beforeCreate() {
    this.$eventBus.$off("updateFileList");
  },
  created() {
    this.$eventBus.$on("updateFileList", this.updateFileList);
  },
  methods: {
    openAddLink() {
      this.addLinkTextPosition.start = null;
      this.addLinkTextPosition.end = null;
      if(this.$refs.subtaskVisible.$refs.editor.value) {
        const { start, end } = this.$refs.subtaskVisible.$refs.editor.getSelection();
        this.addLinkTitle = this.$refs.subtaskVisible.$refs.editor.value.substring(start, end);
        this.addLinkTextPosition.start = start;
        this.addLinkTextPosition.end = end;  
      }
      this.showAddLink = true;
    },
    closeHyperlinkModal() {
      this.addLinkTitle = null;
      this.showAddLink = false;
    },
    toggleBold() {
      this.$refs.subtaskVisible.$refs.editor.toggleMark("*");
    },
    toggleItalic() {
      this.$refs.subtaskVisible.$refs.editor.toggleMark("_");
    },
    insertText(text) {
      if(this.addLinkTextPosition.start && this.addLinkTextPosition.end) {
        console.log("text, false, this.$refs.subtaskVisible.$refs.editor.value.slice(this.addLinkTextPosition.start, this.addLinkTextPosition.end)", text, false, this.$refs.subtaskVisible.$refs.editor.value.slice(this.addLinkTextPosition.start, this.addLinkTextPosition.end));
        this.$refs.subtaskVisible.$refs.editor.insertText(text, false, this.$refs.subtaskVisible.$refs.editor.value.slice(this.addLinkTextPosition.start, this.addLinkTextPosition.end));
      } else {
        this.$refs.subtaskVisible.$refs.editor.insertText(text);
      }
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = [...this.sortedFiles];
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);
      media.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          sequence: media.sequence,
        },
      });
    },
    async removeMedia(media) {
      this.uploadCount = 0;
      this.deleteCommonMediaHandler(media);
      if (this.subtask) {
        const variables = {
          subtask_id: this.subtask.id,
          media: [media.id],
        };
        await this.$api.mutate({
          mutation: DetachSubtaskMediaMutation,
          variables,
        });
      } else if (
        !this.subtask &&
        (media.is_document_section == 1 ||
          (media.file_resource === "s3" && media["verifyKey"] !== "fromShared"))
      ) {
        this.deleteMediaMutation(media);
      }

      if (!this.uploadedFileIds.length) {
        this.progress = 0;
      }
      const subtaskMediaIds = this.subtaskMedia.map((m) => m.id);
      if (this.subtaskMedia.length && subtaskMediaIds.includes(media.id)) {
        this.subtask.media = this.subtaskMedia.filter((m) => m.id != media.id);
      }
    },
    createSubTask(outsourceMediaIds = []) {
      const optmedia =
        this.sortedFiles && this.sortedFiles.length > 0 ? this.sortedFiles : [];
      let folderIds;
      if (this.subtaskFolders.length) {
        const subtaskFolderIds = this.subtaskFolders.map((obj) => obj.id);
        folderIds = map(this.folderList, (f) => f.id).filter(
          (f) => !subtaskFolderIds.includes(f)
        );
      } else {
        folderIds = map(this.folderList, (f) => f.id);
      }
      // For Any drive Files
      const driveData = this.makeDriveData();

      const obj = {
        optmedia: optmedia,
        newSubtask: this.newSubtask,
        uploadedFileIds: [...this.s3MediaIds, ...outsourceMediaIds],
        workspace_id: this.currentWorkspace,
        media_urls: driveData,
        mediafolder: this.folderListArr,
        folder: folderIds, // Folder ids
      };
      this.newSubtask = null;
      this.fileList = {};
      this.folderList = {};
      this.uploadCount = 0;
      this.totalFile = 0;
      this.$emit("addSubtask", obj);
    },
    removeFolder(folder) {
      if (this.subtaskFolderIds.includes(folder.id)) {
        this.detachFolder({
          subtask_id: this.subtask.id,
          folder: [folder.id],
        });
      } else {
        this.deleteFolderHandler(folder);
      }
    },
    async detachFolder(args) {
      const variables = {
        ...args,
      };
      await this.$api.mutate({
        mutation: DetachSubtaskFolderMutation,
        variables,
      });
      //Remove from Entity cache
      const subtakEntity = this.$api.getEntity("subTask", args.subtask_id);
      subtakEntity.mediafolder = subtakEntity.mediafolder.filter(
        (f) => f.id !== args.folder[0]
      );
      this.subtask.mediafolder = this.subtask.mediafolder.filter(
        (f) => f.id !== args.folder[0]
      );
    },
    async addSubTaskHandler() {
      if (!this.isFileUploading) {
        return;
      }
      this.progress = 0;
      if (this.$refs.subtaskVisible) {
        this.$refs.subtaskVisible.$refs.editor.resetTextArea();
      }
      if (this.newSubtask && this.newSubtask !== "") {
        this.commonOutSourceFileHandler((outsourceMediaIds, code) => {
          if (code === 403) {
            this.upgradeStorageDialog = true;
          } else {
            this.createSubTask(outsourceMediaIds);
          }
        });
      }
    },
    fullview(data) {
      if (data.newpoint === "yes") {
        const value = {
          title: "Add Comment",
          gridMediaId: this.gridMediaId,
          media: data.media,
          forscroll: "no",
          x: 0,
          y: 0,
        };
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
        this.clickonmoreoption(value);
      } else {
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
      }
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        this.$refs.mediaCommentingView.$refs.commentBox.find(
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
    clickonmoreoption(item) {
      const data = {
        title: item.title,
        gridMediaId: item.media.id,
        media: item.media,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
      };
      this.$emit("clickonmoreoption", data);
    },
    deleteCommentPoint(deletedata) {
      const makedata = {
        id: deletedata.id,
        index: deletedata.index,
        media_id: this.gridMediaId,
      };

      this.fileList[this.gridMediaId].commentPoint = this.fileList[
        this.gridMediaId
      ].commentPoint.filter((c) => c.id !== deletedata.id);
      this.$emit("deleteCommentPoint", makedata);
    },
    sendComment(data) {
      const commentArg = {
        media_comments_point_id: data.id,
        comment: data.comment,
        media_id: this.gridMediaId,
      };
      this.$emit("sendComment", commentArg);
    },
    deleteMediaComment(commentData) {
      const commentdata = {
        commentid: commentData.commentid,
        index: commentData.index,
        media_id: this.gridMediaId,
        commentPointId: commentData.commentpointid,
      };
      this.$emit("deleteMediaComment", commentdata);
    },
    updateFileList(data) {
      if (!this.fileList[data.media_id].verifyKey) {
        this.fileList[data.media_id].commentPoint.push(data);
      }
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    fromComputer() {
      this.$refs.vueFileUploader.openFileDialog();
    },
    cancelUpload() {
      this.$refs.vueFileUploader.cancelUpload();
      this.fileList = {};
      this.uploadCount = 0;
      this.totalFile = 0;
      setTimeout(() => {
        this.progress = 0;
      }, 10);
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    newSubtaskLen() {
      return this.newSubtask !== null && this.newSubtask !== undefined
        ? this.newSubtask.length
        : 0;
    },
    currentWorkspace() {
      return this.workspace ? this.workspace.id : -1;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
    mentionsList() {
      return this.taggUsers;
    },
    s3MediaIds() {
      const s3File = filter(
        this.fileList,
        (a) =>
          a.file_resource !== "outsource" &&
          (a.file_resource === "s3" || a.hasOwnProperty("verifyKey"))
      );
      const data = {};
      for (var media in s3File) {
        data[s3File[media].id] = s3File[media];
      }
      if (data) {
        return keys(data);
      } else {
        return [];
      }
    },
    uploadedFileIds() {
      if (this.fileList) {
        return map(
          filter(this.fileList, (media) => {
            if (
              media.file_resource !== "google" &&
              media.file_resource !== "dropbox"
            ) {
              return media;
            }
          }),
          (media) => media.id
        );
      } else {
        return [];
      }
    },
    uploadedMedia() {
      return this.uploadedFileIds.map((id) => this.$api.getEntity("media", id));
    },
    subtaskMedia() {
      return this.subtask ? this.subtask.media : [];
    },
    sortedFiles() {
      return orderBy(
        [...this.uploadedMedia, ...this.subtaskMedia],
        ["sequence"]
      );
    },
    outSourcedFiles() {
      return filter(this.fileList, (media) => {
        if (
          media.file_resource === "google" ||
          media.file_resource === "dropbox"
        ) {
          return media;
        }
      });
    },
    totalFiles() {
      return [...this.sortedFiles, ...this.outSourcedFiles];
    },
    subtaskFolders() {
      if (this.subtask && this.subtask.mediafolder) {
        return this.subtask.mediafolder;
      } else {
        return [];
      }
    },
    subtaskFolderIds() {
      return this.subtaskFolders.map((obj) => obj.id);
    },
    folderListArr() {
      const newSharedFolders = this.folderList
        ? uniqBy(
            Object.keys(this.folderList).map((key) => this.folderList[key]),
            "id"
          )
        : [];
      const mergedFolderList = uniqBy(
        [...newSharedFolders, ...this.subtaskFolders],
        "id"
      );
      return mergedFolderList;
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = filter(this.imageList, (o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = filter(this.imageList, (o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    isFileUploading() {
      if (this.progress === 0 || this.progress === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    subtask(to) {
      this.newSubtask = to && to.title;
      if (to && to.title !== null) {
        this.$nextTick(() => {
          this.$refs.subtaskVisible.$refs.editor.doEdit();
        });
      }
    },
    ishide: {
      handler: function (newValue) {
        if (newValue) {
          this.hideButtonBack = "#8E9DAD !important";
        } else {
          this.hideButtonBack = "#231d3b !important";
        }
      },
      deep: true,
    },
    newSubtaskEditorVisibility(val) {
      if (val) {
        this.$refs.subtaskVisible.$refs.editor.fireFocus();
      }
    },
    newSubtask() {
      if (this.newSubtask) {
        this.$emit("visibleSubtaskAdder");
      }
    },
    fileList(val) {
      this.panel = [true];
      if (Object.keys(val).length) {
        setTimeout(() => {
          this.$emit("visibleSubtaskAdder");
        }, 300);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  .cancel-button {
    right: 5px;
    color: #62a0e3;
    font-weight: bold;
    background: #dbecff;
  }
}
.add-subtask-button {
  background: #007aff;
  height: 32px;
  width: 32px;
  margin: auto !important;
  margin-right: 5px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  cursor: pointer;
}
// .add-subtask-button.disabled {
//   color: #62A0E3;
//   background: #DBECFF;
// }
</style>
