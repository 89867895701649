<template>
  <div
    :ref="handleId"
    :class="
      (selectedPointData && selectedPointData.index === index) ||
      (initialiseComment && returnCommentListing.length === 0)
        ? 'absolute point'
        : 'absolute'
    "
    :style="{ top: returnPointY + 'px', left: returnPointX + 'px' }"
    v-draggable="draggableValue"
  >
    <q-btn
      round
      :size="
        (selectedPointData && selectedPointData.index === index) ||
        (initialiseComment && returnCommentListing.length === 0)
          ? '15px'
          : '12px'
      "
      text-color="white"
      :style="{
        'background-color':
          point.last_label === 'nolabel' ? '#007aff' : point.last_label,
        border: '2px solid white',
        'z-index':
          (selectedPointData && selectedPointData.index === index) ||
          (initialiseComment && returnCommentListing.length === 0)
            ? 9
            : 0,
      }"
      class="q-ma-none"
      :label="index + 1"
      @click="selectPoint"
    >
    </q-btn>
  </div>
</template>
<script>
import orderBy from "lodash/orderBy";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import Draggable from "@/directives/draggable";
import striptags from "striptags";

export default {
  name: "CommentBox",
  props: [
    "point",
    "index",
    "commentListing",
    "isWorkspaceOwner",
    "currentUser",
    "labels",
    "selectedPointData",
    "initialiseComment",
  ],
  data() {
    return {
      dragPosx: 0,
      dragPosy: 0,
      lastposx: 0,
      lastposy: 0,
      editmode: false,
      positionBoolean: true,
      menu: false,
      newComment: "",
      handleId: "handle-id",
      titleOfTheme: "Not Started",
      themeColor: "notstarted",
      editeddata: null,
      textBoxMessage: "",
      draggableValue: {
        handle: undefined,
        initialPosition: { x: 0, y: 0 },
        ApiResponse: false,
      },
      filterTask: { label: false },
    };
  },
  beforeDestroy() {
    this.$eventBus.$off("scrolldown");
  },
  mounted() {
    this.$eventBus.$off("scrolldown", this.scrolldown);
    this.$eventBus.$on("scrolldown", this.scrolldown);
    this.draggableValue.handle = this.$refs[this.handleId];
    this.draggableValue.onPositionChange = this.onPosChanged;
    this.draggableValue.onPositionChangedmouseup = this.onPositionChangedmouseup;
    this.draggableValue.onPositionChangedmousedown = this.onPositionChangedmousedown;
  },
  directives: {
    Draggable,
  },
  computed: {
    returnCommentListing() {
      let point = this.point.id;
      let filteredCommentListing = this.commentListing.filter((o) => {
        return o && o.media_comments_point_id === point;
      });
      return orderBy(filteredCommentListing, ["id"], ["asc"]);
    },
    returnPointX() {
      return this.point.x;
    },
    returnPointY() {
      return this.point.y;
    },
    mediaCommentLabels() {
      this.labels.forEach((label) => {
        if (label.color === "nolabel") {
          label.color = "#677484";
        }
        if (label.color === "complete") {
          label.color = "#15d89a";
        }
        if (label.color === "inprogress") {
          label.color = "#FEBE36";
        }
        if (label.color === "pendingreview") {
          label.color = "#4DA1FF";
        }
        if (label.color === "notstarted") {
          label.color = "#8A75AE";
        }
        if (label.color === "overdue") {
          label.color = "#D75656";
        }
        if (label.color === "hightpriority") {
          label.color = "#FF3279";
        }
        if (label.color === "no") {
          label.color = "#transparent";
        }
      });

      return this.labels;
    },
    returnStatusName() {
      if (this.point.last_label === "nolabel") {
        return "Not Started";
      } else if (this.point.last_label === "#FEBE36") {
        return "In Prgress";
      } else if (this.point.last_label === "#15d89a") {
        return "Complete";
      } else if (this.point.last_label === "#4DA1FF") {
        return "Pending Review";
      } else if (this.point.last_label === "#8A75AE") {
        return "Not Started";
      } else if (this.point.last_label === "#D75656") {
        return "Overdue";
      } else if (this.point.last_label === "#FF3279") {
        return "High Priority";
      } else {
        return "";
      }
    },
    chCount() {
      return striptags(this.textBoxMessage).length;
    },
  },
  methods: {
    selectPoint() {
      let data = {
        point: this.point,
        index: this.index,
      };
      this.$emit("selectPoint", data);
    },
    characterCounter(data) {
      this.chCount = data;
    },
    deleteCommentPoint(index) {
      const deletedata = {
        index: index,
        id: this.point.id,
      };
      this.menu = false;
      this.$emit("deleteCommentPoint", deletedata);
    },
    clickonthemeoption(theme, color) {
      this.titleOfTheme = theme;
      this.themeColor = color;
      const themedata = {
        id: this.point.id,
        last_label: color,
      };
      this.$emit("updateMediaCommentPointLabel", themedata);
    },
    onPosChanged(pos) {
      this.dragPosx = pos.x;
      this.dragPosy = pos.y;
    },
    onPositionChangedmousedown(pos) {
      setTimeout(() => {
        this.scrolldown();
      }, 200);
      if (pos.x === 0 && pos.y === 0) {
        this.positionBoolean = true;
      } else {
        this.positionBoolean = false;
        this.lastposx = pos.x;
        this.lastposy = pos.y;
      }
    },
    onPositionChangedmouseup(pos) {
      if (this.positionBoolean) {
        if (
          this.point.x + pos.x === this.point.x &&
          this.point.y + pos.y === this.point.y
        ) {
          return;
        }
        const updateposition = {
          id: this.point.id,
          x: this.point.x + pos.x,
          y: this.point.y + pos.y,
        };
        if (updateposition.x >= -6 && updateposition.y >= -6) {
          this.$emit("updatepositions", updateposition);
        }
        this.$eventBus.$emit("resetmessagebox");
      } else {
        // if (pos.x === this.dragPosx && pos.y === this.dragPosy) {
        //   return;
        // }
        const updateposition = {
          id: this.point.id,
          x: this.point.x + pos.x,
          y: this.point.y + pos.y,
        };
        if (updateposition.x >= -6 && updateposition.y >= -6) {
          this.$emit("updatepositions", updateposition);
        }
        this.$eventBus.$emit("resetmessagebox");
      }
    },
    scrolldown() {
      if (this.$refs.scrollableComment) {
        this.$refs.scrollableComment.scrollTop =
          this.$refs.scrollableComment.scrollHeight -
          this.$refs.scrollableComment.clientHeight;
      }
    },
    sendComment() {
      if (this.$refs.mediaEditor) {
        this.$refs.mediaEditor.$refs.editor.resetTextArea();
      }
      if (this.textBoxMessage !== "" && !this.editmode) {
        var commentData = {
          comment: this.textBoxMessage.substring(0, 700),
          id: this.point.id,
        };
        this.textBoxMessage = "";
        this.$emit("sendComment", commentData);
        this.$nextTick(() => {
          this.scrolldown();
        });
      } else if (this.textBoxMessage !== "") {
        const changedata = {
          id: this.editeddata.id,
          comment: this.textBoxMessage,
        };
        this.$emit("updateComment", changedata);
        this.editmode = false;
      }
      this.textBoxMessage = "";
    },
    editComment(data) {
      this.editmode = true;
      this.editeddata = data;
      this.newComment = data.comment;
      this.textBoxMessage = data.comment;
    },
    deleteMediaComment(commentData) {
      const data = {
        commentid: commentData.commentid,
        index: this.index,
        commentpointid: this.point.id,
      };
      this.$emit("deleteMediaComment", data);
    },
  },
  watch: {
    menu(val) {
      if (val) {
        this.$refs.visibleComment &&
          this.$refs.visibleComment.$refs.commentEditor.$refs.editor.fireFocus();
      }
    },
    textBoxMessage: {
      handler: function(newValue) {
        if (newValue === "") {
          this.editmode = false;
        }
      },
      deep: true,
    },
    editeddata(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.mediaEditor.$refs.editor.doEdit();
        });
      }
    },
  },
};
</script>
<style scoped>
.point {
  border-radius: 50%;
  border: solid 3px #007aff;
  animation: pulse-blue 1.2s infinite;
}

@keyframes pulse-blue {
  0% {
    /* transform: scale(0.8); */
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    /* transform: scale(1.2); */
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    /* transform: scale(1); */
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}
</style>
