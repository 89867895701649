<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <q-card style="width: 450px;" class="text-center q-px-md q-py-sm">
      <div class="text-right">
        <q-btn
          flat
          dense
          :icon="$icons.matClose"
          color="grey"
          round
          size="md"
          @click="proxyModel = false"
        />
      </div>
      <div class="q-py-md">
        <div>
          <span class="text-weight-bold" style="font-size:20px"
            >Maximum Storage reached</span
          >
        </div>
        <div>
          You've reached the maximumm storage of
          {{
            reachedLimitPlan &&
            reachedLimitPlan.subscription.storage_plan === "v3_storage_5gb"
              ? "5GB"
              : reachedLimitPlan &&
                reachedLimitPlan.subscription.storage_plan ===
                  "v3_storage_100gb"
              ? "105GB"
              : reachedLimitPlan &&
                reachedLimitPlan.subscription.storage_plan ===
                  "v3_storage_500gb"
              ? "505GB"
              : "1005GB"
          }}
          for your company.To continue using Heycollab upgrade your storage or
          upgrade your plan.
        </div>
      </div>
      <div class="q-mb-md">
        <q-btn
          no-caps
          color="teal-14"
          label="Upgrade Storage"
          class="q-px-sm q-mr-sm"
          @click="handleButtonOption('upgradeStorage')"
        />
        <q-btn
          no-caps
          color="teal-14"
          label="Upgrade Plan"
          class="q-px-sm"
          @click="handleButtonOption('upgradePlan')"
        />
      </div>
    </q-card>
    <storage-option-dialog
      v-model="isStorageFull"
      v-if="isStorageFull"
      @close="handleClose"
    />
  </q-dialog>
</template>
<script>
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import StorageOptionDialog from "@/components/Dialogs/StorageOptionDialog";

export default {
  mixins: [ProxyModelMixin],
  props: ["reachedLimitPlan"],
  components: {
    StorageOptionDialog,
  },
  data() {
    return {
      isStorageFull: false,
      isUserCountReached: false,
    };
  },
  methods: {
    handleButtonOption(option) {
      if (option === "upgradeStorage") {
        this.isStorageFull = true;
      }
      if (option === "upgradePlan") {
        this.isUserCountReached = true;
      }
    },
    handleClose() {
      this.$emit("closeUploadDialog");
      this.proxyModel = false;
    },
  },
};
</script>
