<template>
  <div
    v-if="isOpen"
    class="new-emoji-menu bg-white"
    ref="emojiMenu"
    :style="[
      isAbPosition
        ? { top: topPositions + 'px', left: leftPositions + 'px' }
        : {},
      subtaskEditor && { top: '-250px' },
    ]"
  >
    <span
      v-for="(emoji, i) in getEmoji"
      :ref="`emoji${i}`"
      :key="i"
      @click="handleClick(emoji)"
      :class="{ selected: i === index }"
      @mouseover="index = i"
      >{{ emoji.char }}</span
    >
  </div>
</template>

<script>
export default {
  name: "EmojiPicker",
  props: [
    "query",
    "emojisList",
    "topPositions",
    "leftPositions",
    "isAbPosition",
    "subtaskEditor",
  ],
  data() {
    return {
      open: false,
      index: 0,
    };
  },
  computed: {
    isOpen() {
      return this.open;
    },
    getEmoji() {
      const query = this.query.slice(1);
      return query !== ""
        ? this.emojisList.filter((emoji) =>
            emoji.name.toLowerCase().includes(query.toLowerCase())
          )
        : this.emojisList;
    },
  },
  methods: {
    handleScroll(val) {
      const ref = `emoji${val}`;
      const scroll = this.$refs[ref][0].offsetTop - 245;
      if (scroll >= 0) {
        this.$refs.emojiMenu.scrollTop = scroll;
      }
    },
    handleKeyDown(event) {
      if (event.key === "Escape") {
        event.preventDefault();
        this.open = false;
      }
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        this.index = Math.max(this.index - 1, 0);
        this.handleScroll(this.index);
      }
      if (event.key === "ArrowRight") {
        event.preventDefault();
        this.index = Math.min(this.index + 1, this.getEmoji.length - 1);
        this.handleScroll(this.index);
      }
      if (event.key === "ArrowUp") {
        event.preventDefault();
        // Arrow Up logic
        this.index = Math.max(this.index - 8, 0);
        this.handleScroll(this.index);
      }
      if (event.key === "ArrowDown") {
        event.preventDefault();
        this.index = Math.min(this.index + 8, this.getEmoji.length - 1);
        this.handleScroll(this.index);
        // Arrow Down logic
      }
      if (event.key === "Enter") {
        // Logic for Enter Click
        event.preventDefault();
        if (this.getEmoji[this.index]) {
          this.$emit("insertEmoji", this.getEmoji[this.index].char);
        }
      }
    },
    handleClick(emoji) {
      this.$emit("insertEmoji", emoji.char);
      this.open = false;
    },
  },
  watch: {
    query(query) {
      if (query !== null) {
        this.open = true;
      } else {
        this.open = false;
      }
    },
    open(open) {
      if (open) {
        // start listening for keys
        // document.addEventListener("click", this.handleClick, true);
        document.addEventListener("keydown", this.handleKeyDown, true);
      } else {
        // document.removeEventListener("click", this.handleClick, true);
        document.removeEventListener("keydown", this.handleKeyDown, true);
        this.index = 0;
      }
    },
  },
};
</script>
