<template>
  <div>
    <div
      v-if="selected.length"
      class="row items-center stick-at-top q-pt-sm"
      style="z-index: 111"
    >
      <q-btn
        flat
        dense
        no-caps
        :icon="$icons.fasShare"
        label="Share"
        class="multiple-select-options primary-btn list-view"
        @click="attacheFileFolderToMessage(selected)"
      />
      <q-btn
        flat
        dense
        no-caps
        :icon="$icons.mdiContentCopy"
        @click="copyMoveFileFolderHandler(selected, false, true, false, false)"
        label="Copy"
        class="multiple-select-options primary-btn list-view"
      />
      <q-btn
        flat
        dense
        no-caps
        :icon="$icons.mdiCursorMove"
        @click="copyMoveFileFolderHandler(selected, true, true, false, false)"
        label="Move"
        class="multiple-select-options primary-btn list-view"
      />
      <q-btn
        flat
        dense
        no-caps
        :icon="$icons.fasTrash"
        label="Delete"
        class="multiple-select-options red-btn list-view"
        @click="bulkDeleteFileConfirm.flag = true"
      />
    </div>
    <!-- Table Header -->
    <div v-if="items.length || folderSection.length" class="row">
      <div class="col-6">
        <q-item class="q-pa-none">
          <q-item-section avatar>
            <q-checkbox v-model="selectAll" size="sm" @input="toggleAll" />
          </q-item-section>
          <q-item-section avatar>
            <div class="greybtn--text text-midi">Name</div>
          </q-item-section>
        </q-item>
      </div>
      <div class="col-2">
        <q-item>
          <q-item-section avatar>
            <q-item-label class="greybtn--text text-midi">
              Comments
            </q-item-label>
          </q-item-section>
        </q-item>
      </div>
      <div class="col-2">
        <q-item>
          <q-item-section avatar>
            <q-item-label class="greybtn--text text-midi"> Size </q-item-label>
          </q-item-section>
        </q-item>
      </div>
      <div class="col-2">
        <q-item>
          <q-item-section avatar>
            <q-item-label class="greybtn--text text-midi">
              Date Uploaded
            </q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </div>
    <!-- Section  -->
    <template>
      <div
        v-sortable="{
          emptyInsertThreshold: 100,
          onEnd: onDragEnd,
        }"
      >
        <div
          :key="section.id"
          :data-id="section.id"
          v-for="section in folderSection"
          class="group-hover"
        >
          <q-expansion-item
            switch-toggle-side
            expand-icon-toggle
            :value="!openSectionData.includes(section.id)"
            @input="
              collapsibleChangeHandler(
                openSectionData.includes(section.id),
                section
              )
            "
            header-class="folder-section items-center cursor-pointer "
            :header-style="[
              openSectionData.includes(section.id) && {
                'background-color': '#eff2f6',
              },
              editSection.id === section.id && {
                'background-color': '#dbecff',
              },
            ]"
            class="q-mb-xs"
          >
            <template v-slot:header>
              <div class="row flex-1 items-center">
                <q-input
                  v-if="editSection.id === section.id"
                  dense
                  :value="section.title"
                  padding="0"
                  outlined
                  input-class="text-bold"
                  input-style="font-size:26px"
                  class="flex-1"
                  autofocus
                  @blur="editSection.id = null"
                  @change="updateSectionTitle"
                  @keydown.enter.exact="updateSectionTitle"
                />
                <div
                  v-else
                  @click="editSection.id = section.id"
                  style="font-size: 26px"
                  class="row items-center text-bold flex-1"
                >
                  <div>
                    {{ section.title }}
                  </div>
                  <div
                    class="media-count row items-center justify-center q-ml-sm"
                  >
                    {{
                      sectionMedia[section.id]
                        ? sectionMedia[section.id].length
                        : 0
                    }}
                  </div>
                </div>

                <q-btn
                  v-if="sectionMedia[section.id] && selected.length"
                  flat
                  dense
                  no-caps
                  :icon="$icons.mdiCursorMove"
                  label="Move to section"
                  class="section-btn primary-btn"
                  @click="openMoveFileSectionFolderDialog(section, selected)"
                />
                <div class="row section-header-item items-center">
                  <q-btn
                    size="md"
                    flat
                    round
                    dense
                    color="black"
                    class="q-mr-xs toolbar-buttons"
                    :icon="$icons.matAdd"
                  >
                    <q-tooltip> Add Files </q-tooltip>
                    <q-menu auto-close>
                      <q-list>
                        <q-item
                          @click="uploadFileToSection(section.id)"
                          clickable
                        >
                          <q-item-section
                            style="min-width: 35px"
                            class="q-pr-none"
                            avatar
                          >
                            <q-icon :name="$icons.matComputer" size="24px" />
                          </q-item-section>
                          <q-item-section> Your Computer </q-item-section>
                        </q-item>
                        <q-item
                          @click="openSharedFileDialog(section.id)"
                          clickable
                        >
                          <q-item-section
                            style="min-width: 35px"
                            class="q-pr-none"
                            avatar
                          >
                            <q-icon :name="$icons.fasCopy" size="24px" />
                          </q-item-section>
                          <q-item-section> Shared Files </q-item-section>
                        </q-item>
                        <q-separator />
                        <q-item
                          @click="
                            () => {
                              dropBox('fromFolder', currentUser.id);
                              sectionId = section.id;
                            }
                          "
                          clickable
                        >
                          <q-item-section
                            style="min-width: 35px"
                            class="q-pr-none"
                            avatar
                          >
                            <q-icon :name="$icons.fabDropbox" size="24px" />
                          </q-item-section>
                          <q-item-section> Dropbox </q-item-section>
                        </q-item>
                        <q-item
                          @click="
                            () => {
                              googleDrive('fromFolder', currentUser.id);
                              sectionId = section.id;
                            }
                          "
                          clickable
                        >
                          <q-item-section
                            style="min-width: 35px"
                            class="q-pr-none"
                            avatar
                          >
                            <q-icon :name="$icons.fabGoogleDrive" size="24px" />
                          </q-item-section>
                          <q-item-section> Google Drive </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                  <q-btn
                    size="16px"
                    flat
                    round
                    dense
                    :icon="$icons.matArrowDropDown"
                    color="black"
                    padding="0"
                    class="option-btn toolbar-buttons"
                    :disabled="isLastSection(section)"
                    @click="moveSectionDown(section)"
                  >
                    <q-tooltip> Move section down </q-tooltip>
                  </q-btn>
                  <q-btn
                    size="16px"
                    flat
                    round
                    dense
                    :icon="$icons.matArrowDropUp"
                    color="black"
                    padding="0"
                    class="option-btn toolbar-buttons"
                    :disabled="isFirstSection(section)"
                    @click="moveSectionUp(section)"
                  >
                    <q-tooltip> Move section up </q-tooltip>
                  </q-btn>
                  <q-btn
                    size="12px"
                    flat
                    round
                    dense
                    :icon="$icons.matDelete"
                    color="black"
                    padding="0"
                    class="option-btn toolbar-buttons"
                  >
                    <q-menu auto-close>
                      <q-list>
                        <q-item
                          class="text-red"
                          @click="
                            openDeleteConfirmationDialog(section.id, true)
                          "
                          clickable
                        >
                          <q-item-section>
                            Delete section & media
                          </q-item-section>
                        </q-item>
                        <q-item
                          class="text-red"
                          @click="
                            openDeleteConfirmationDialog(section.id, false)
                          "
                          clickable
                        >
                          <q-item-section> Delete section </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </div>
            </template>
            <!-- Section Media -->
            <template>
              <div
                v-sortable="{
                  emptyInsertThreshold: 100,
                  onAdd: (event) => onDragAddOrUpdateInSection(event, section),
                  onUpdate: (event) =>
                    onDragAddOrUpdateInSection(event, section),
                  group: 'folderMedia',
                }"
                class="q-mt-sm"
              >
                <template v-for="item in sectionMedia[section.id]">
                  <div
                    :class="[
                      selectedIds.includes(item.id) && 'selected-item',
                      'vabotu-tbl-row  row items-center section-media-hover',
                    ]"
                    :key="item.id"
                    :data-id="item.id"
                    @click="
                      fullViewHandler(
                        item,
                        'noComment',
                        sectionMedia[section.id]
                      )
                    "
                  >
                    <q-menu context-menu content-class="overflow-auto">
                      <q-list>
                        <q-item clickable v-if="canRemove">
                          <q-item-section> Share </q-item-section>
                          <q-item-section side>
                            <q-icon :name="$icons.matKeyboardArrowRight" />
                          </q-item-section>
                          <q-menu auto-close anchor="top end" self="top start">
                            <q-list style="min-width: 150px">
                              <q-item @click="attachToPost(item)" clickable>
                                Attach to message
                              </q-item>
                              <q-item
                                v-if="!media.is_document_section"
                                @click.stop="$copyTextToClipboard(item.file)"
                                clickable
                              >
                                <q-item-section> Copy Link </q-item-section>
                              </q-item>
                            </q-list>
                          </q-menu>
                        </q-item>
                        <q-item
                          v-if="canRemove"
                          clickable
                          @click="
                            copyMoveFileFolderHandler(
                              [item],
                              true,
                              false,
                              false,
                              false
                            )
                          "
                          v-close-popup
                        >
                          <q-item-section> Move </q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          class="q-mb-sm"
                          @click="
                            copyMoveFileFolderHandler(
                              [item],
                              false,
                              false,
                              false,
                              false
                            )
                          "
                          v-close-popup
                        >
                          <q-item-section> Copy </q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          class="q-mb-sm"
                          @click="openRenameFileDialog(item)"
                          v-close-popup
                        >
                          <q-item-section> Rename </q-item-section>
                        </q-item>
                        <q-item
                          v-if="
                            item.is_document_section &&
                            !item.is_read_only &&
                            currentUser &&
                            currentUser.id === item.user_id
                          "
                          @click="updateReadOnlyHandler(item.id, true)"
                          clickable
                          class="q-mb-sm"
                          v-close-popup
                        >
                          <q-item-section> Set to read only </q-item-section>
                        </q-item>
                        <q-item
                          v-else-if="
                            item.is_document_section &&
                            item.is_read_only &&
                            currentUser &&
                            currentUser.id === item.user_id
                          "
                          @click="updateReadOnlyHandler(item.id, false)"
                          clickable
                          class="q-mb-sm"
                          v-close-popup
                        >
                          <q-item-section> Remove read only </q-item-section>
                        </q-item>
                        <q-separator />
                        <q-item
                          clickable
                          @click="fromComputer(item.id)"
                          v-if="canRemove && item.type.match(/image.*/)"
                          v-close-popup
                        >
                          <q-item-section> Replace image </q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          @click="downloadFile(item.id)"
                          v-if="item.file_resource === 's3'"
                          v-close-popup
                        >
                          <q-item-section> Download </q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          @click="
                            fullViewHandler(
                              item,
                              'comment',
                              sectionMedia[section.id]
                            )
                          "
                          v-if="item.type.match(/image.*/)"
                          v-close-popup
                        >
                          <q-item-section> Add Comment </q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          @click="openNextTabLink(item)"
                          v-if="item.file_resource === 'dropbox'"
                          v-close-popup
                        >
                          <q-item-section> Open Dropbox Link </q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          @click="openNextTabLink(item)"
                          v-if="item.file_resource === 'google'"
                          v-close-popup
                        >
                          <q-item-section>
                            Open GoogleDrive Link
                          </q-item-section>
                        </q-item>
                        <q-item
                          clickable
                          @click="removeMedia(null, item.id)"
                          class="text-red"
                          v-if="
                            canRemove ||
                            (currentUser && currentUser.id === item.user_id)
                          "
                          v-close-popup
                        >
                          <q-item-section> Delete File </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                    <div class="col-6">
                      <q-list class="list list--two-line vb-tbody-list">
                        <template>
                          <q-item>
                            <q-item-section class="items-center" avatar>
                              <span
                                @click.stop="toggleSelect(item)"
                                class="custom-selection-checkbox"
                              >
                                <div
                                  class="selected-custom-selection-checkbox"
                                  v-if="selectedIds.includes(item.id)"
                                ></div>
                              </span>
                            </q-item-section>
                            <q-item-section avatar>
                              <q-avatar size="40px" style="border-radius: 6px">
                                <img
                                  :src="
                                    (item.file_resource === 'dropbox' ||
                                      item.file_resource === 'google') &&
                                    isImg(item)
                                      ? item.thumb
                                      : item.fileSrc
                                  "
                                  class="cursor-pointer"
                                  style="border-radius: 7px"
                                />
                                <div
                                  v-if="
                                    item.type.match(/video.*/) &&
                                    item.file_resource !== 'dropbox'
                                  "
                                  class="row justify-center items-center absolute video-icon"
                                >
                                  <div class="text-center flex-1">
                                    <q-btn
                                      :icon="$icons.mdiFileVideoOutline"
                                      style="color: #FF001F;"
                                      size="16px"
                                      round
                                      dense
                                      padding="0"
                                      flat
                                    />
                                  </div>
                                </div>
                                <div
                                  class="comment-point"
                                  v-if="item.commentPoint.length > 0"
                                  :style="
                                    item.commentPoint.length ==
                                    completedMediaPoints(item).length
                                      ? `background: #15d89a`
                                      : ''
                                  "
                                >
                                  <span v-if="item.commentPoint.length <= 9">
                                    {{
                                      completedMediaPoints(item).length +
                                      "/" +
                                      item.commentPoint.length
                                    }}
                                  </span>
                                  <span v-else>9+</span>
                                </div>
                                <div
                                  style="top: -8px; left: -9px"
                                  v-if="item.file_resource === 'google'"
                                  class="showing-file-type-table"
                                >
                                  <q-btn dense round color="pink" size="md">
                                    <q-icon
                                      style="top: 3px; left: 3px"
                                      class="absolute"
                                      size="13px"
                                      color="white"
                                      :name="$icons.fabGoogleDrive"
                                    />
                                  </q-btn>
                                </div>
                                <div
                                  style="top: -8px; left: -9px"
                                  v-if="item.file_resource === 'dropbox'"
                                  class="showing-file-type-table"
                                >
                                  <q-btn dense round color="pink" size="md">
                                    <q-icon
                                      style="top: 4px; left: 4px"
                                      class="absolute"
                                      size="13px"
                                      color="white"
                                      :name="$icons.fabDropbox"
                                    />
                                  </q-btn>
                                </div>
                              </q-avatar>
                            </q-item-section>
                            <q-item-section>
                              <q-item-label
                                class="username break-word pl-3 an-15 medium-text"
                              >
                                {{ item.filename }}
                              </q-item-label>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-list>
                    </div>
                    <div class="col-2">
                      <q-list>
                        <q-item>
                          <q-item-section avatar>
                            <q-item-label>
                              <!-- <q-icon
                                    class="comment-icon-media"
                                    name="fas fa-comments"
                                /> -->
                              {{
                                item.commentPoint.length
                                  ? completedMediaPoints(item).length +
                                    "/" +
                                    item.commentPoint.length
                                  : 0
                              }}
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </div>
                    <div class="col-2">
                      <q-list>
                        <q-item>
                          <q-item-section avatar>
                            <q-item-label>
                              <!-- <q-icon
                                    class="comment-icon-media"
                                    name="fas fa-comments"
                                /> -->
                              <template v-if="item.is_document_section">
                                -
                              </template>
                              <template v-else>
                                {{ item.size | filesizeFormat }} MB
                              </template>
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </div>
                    <div class="col-2">
                      <q-list>
                        <template>
                          <q-item>
                            <q-item-section>
                              <q-item-label>
                                {{ item.created_at | tableDateFormate }}
                              </q-item-label>
                            </q-item-section>
                            <q-item-section side>
                              <div
                                class="task-menu-option thread-icons row item-center q-px-xs q-mt-sm q-mr-xs section-media-hover-item"
                                style="z-index: 100"
                              >
                                <div
                                  v-if="item.isbookmarked"
                                  @click.stop="removeMediaBookmark(item)"
                                  class="cursor-pointer"
                                >
                                  <q-icon
                                    size="20px"
                                    color="pink"
                                    :name="$icons.matBookmark"
                                  >
                                    <q-tooltip>
                                      Remove from Saved items
                                    </q-tooltip>
                                  </q-icon>
                                </div>
                                <div
                                  v-if="!item.isbookmarked"
                                  @click.stop="addMediaBookmark(item)"
                                  class="cursor-pointer"
                                >
                                  <q-icon
                                    size="20px"
                                    color="black"
                                    :name="$icons.matBookmarkBorder"
                                  >
                                    <q-tooltip> Add to Saved items </q-tooltip>
                                  </q-icon>
                                </div>
                              </div>
                              <div>
                                <q-icon
                                  v-if="item.isbookmarked"
                                  @click.stop="removeMediaBookmark(item)"
                                  size="20px"
                                  class="cursor-pointer"
                                  color="pink"
                                  :name="$icons.matBookmark"
                                >
                                  <q-tooltip>
                                    Remove from Saved items
                                  </q-tooltip>
                                </q-icon>
                              </div>
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-list>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </q-expansion-item>
          <div class="row">
            <div
              class="add-folder-section-area group-hover-item q-mb-xs flex-1 text-center row justify-center items-center cursor-pointer"
              @click="addSectionHandler(section)"
            >
              <div>Add Section</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Root Media -->
    <div
      v-if="folderRootMedia.length"
      style="margin-left: -48px; margin-right: 0px"
      class="root-section-divider"
    ></div>
    <template>
      <div
        v-sortable="{
          emptyInsertThreshold: 100,
          group: 'folderMedia',
          onAdd: onDragAddOrUpdateInRoot,
          onUpdate: onDragAddOrUpdateInRoot,
        }"
        class="q-mt-sm"
      >
        <template v-for="item in folderRootMedia">
          <div
            :class="[
              selectedIds.includes(item.id) && 'selected-item',
              'vabotu-tbl-row group-hover row items-center',
            ]"
            :key="item.id"
            :data-id="item.id"
            @click="fullViewHandler(item, 'noComment', folderRootMedia)"
          >
            <q-menu context-menu content-class="overflow-auto">
              <q-list>
                <q-item clickable>
                  <q-item-section> Share </q-item-section>
                  <q-item-section side>
                    <q-icon :name="$icons.matKeyboardArrowRight" />
                  </q-item-section>
                  <q-menu auto-close anchor="top end" self="top start">
                    <q-list style="min-width: 150px">
                      <q-item @click="attachToPost(item)" clickable>
                        Attach to message
                      </q-item>
                      <q-item
                        v-if="!item.is_document_section"
                        @click.stop="$copyTextToClipboard(item.file)"
                        clickable
                      >
                        <q-item-section> Copy Link </q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-item>
                <q-item
                  clickable
                  @click="
                    copyMoveFileFolderHandler([item], true, false, false, false)
                  "
                  v-close-popup
                >
                  <q-item-section> Move </q-item-section>
                </q-item>
                <q-item
                  clickable
                  class="q-mb-sm"
                  @click="
                    copyMoveFileFolderHandler(
                      [item],
                      false,
                      false,
                      false,
                      false
                    )
                  "
                  v-close-popup
                >
                  <q-item-section> Copy </q-item-section>
                </q-item>
                <q-item
                  clickable
                  class="q-mb-sm"
                  @click="openRenameFileDialog(item)"
                  v-close-popup
                >
                  <q-item-section> Rename </q-item-section>
                </q-item>
                <q-item
                  v-if="
                    item.is_document_section &&
                    !item.is_read_only &&
                    currentUser &&
                    currentUser.id === item.user_id
                  "
                  @click="updateReadOnlyHandler(item.id, true)"
                  clickable
                  class="q-mb-sm"
                  v-close-popup
                >
                  <q-item-section> Set to read only </q-item-section>
                </q-item>
                <q-item
                  v-else-if="
                    item.is_document_section &&
                    item.is_read_only &&
                    currentUser &&
                    currentUser.id === item.user_id
                  "
                  @click="updateReadOnlyHandler(item.id, false)"
                  clickable
                  class="q-mb-sm"
                  v-close-popup
                >
                  <q-item-section> Remove read only </q-item-section>
                </q-item>
                <q-separator />
                <q-item
                  clickable
                  @click="fromComputer(item.id)"
                  v-if="canRemove && item.type.match(/image.*/)"
                  v-close-popup
                >
                  <q-item-section> Replace image </q-item-section>
                </q-item>
                <q-item
                  clickable
                  @click="downloadFile(item.id)"
                  v-if="item.file_resource === 's3'"
                  v-close-popup
                >
                  <q-item-section> Download </q-item-section>
                </q-item>
                <q-item
                  clickable
                  @click="fullViewHandler(item, 'comment', folderRootMedia)"
                  v-if="item.type.match(/image.*/)"
                  v-close-popup
                >
                  <q-item-section> Add Comment </q-item-section>
                </q-item>
                <q-item
                  clickable
                  @click="openNextTabLink(item)"
                  v-if="item.file_resource === 'dropbox'"
                  v-close-popup
                >
                  <q-item-section> Open Dropbox Link </q-item-section>
                </q-item>
                <q-item
                  clickable
                  @click="openNextTabLink(item)"
                  v-if="item.file_resource === 'google'"
                  v-close-popup
                >
                  <q-item-section> Open GoogleDrive Link </q-item-section>
                </q-item>
                <q-item
                  clickable
                  @click="removeMedia(null, item.id)"
                  class="text-red"
                  v-if="
                    canRemove ||
                    (currentUser && currentUser.id === item.user_id)
                  "
                  v-close-popup
                >
                  <q-item-section> Delete File </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
            <div class="col-6">
              <q-list class="list list--two-line vb-tbody-list">
                <template>
                  <q-item>
                    <q-item-section class="items-center" avatar>
                      <span
                        @click.stop="toggleSelect(item)"
                        class="custom-selection-checkbox"
                      >
                        <div
                          class="selected-custom-selection-checkbox"
                          v-if="selectedIds.includes(item.id)"
                        ></div>
                      </span>
                    </q-item-section>
                    <q-item-section avatar>
                      <q-avatar rounded size="40px" style="border-radius: 6px">
                        <img
                          :src="
                            (item.file_resource === 'dropbox' ||
                              item.file_resource === 'google') &&
                            isImg(item)
                              ? item.thumb
                              : item.fileSrc
                          "
                          class="cursor-pointer"
                          style="border-radius: 7px"
                        />
                        <div
                          v-if="
                            item.type.match(/video.*/) &&
                            item.file_resource !== 'dropbox'
                          "
                          class="row justify-center items-center absolute video-icon"
                        >
                          <div class="text-center flex-1">
                            <q-btn
                              :icon="$icons.mdiPlay"
                              color="primary"
                              size="sm"
                              round
                              dense
                              padding="0"
                              flat
                            />
                          </div>
                        </div>
                        <div
                          class="comment-point"
                          v-if="item.commentPoint.length > 0"
                          :style="
                            item.commentPoint.length ==
                            completedMediaPoints(item).length
                              ? `background: #15d89a`
                              : ''
                          "
                        >
                          {{
                            completedMediaPoints(item).length +
                            "/" +
                            item.commentPoint.length
                          }}
                        </div>
                        <div
                          style="top: -8px; left: -9px"
                          v-if="item.file_resource === 'google'"
                          class="showing-file-type-table"
                        >
                          <q-btn dense round color="pink" size="md">
                            <q-icon
                              style="top: 3px; left: 3px"
                              class="absolute"
                              size="13px"
                              color="white"
                              :name="$icons.fabGoogleDrive"
                            />
                          </q-btn>
                        </div>
                        <div
                          style="top: -8px; left: -9px"
                          v-if="item.file_resource === 'dropbox'"
                          class="showing-file-type-table"
                        >
                          <q-btn dense round color="pink" size="md">
                            <q-icon
                              style="top: 4px; left: 4px"
                              class="absolute"
                              size="13px"
                              color="white"
                              :name="$icons.fabDropbox"
                            />
                          </q-btn>
                        </div>
                      </q-avatar>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label
                        class="username break-word pl-3 an-15 medium-text"
                      >
                        {{ item.filename }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-list>
            </div>
            <div class="col-2">
              <q-list>
                <q-item>
                  <q-item-section avatar>
                    <q-item-label>
                      <!-- <q-icon
                                class="comment-icon-media"
                                name="fas fa-comments"
                            /> -->
                      {{
                        item.commentPoint.length
                          ? completedMediaPoints(item).length +
                            "/" +
                            item.commentPoint.length
                          : 0
                      }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <div class="col-2">
              <q-list>
                <q-item>
                  <q-item-section avatar>
                    <q-item-label>
                      <!-- <q-icon
                                class="comment-icon-media"
                                name="fas fa-comments"
                            /> -->
                      <template v-if="item.is_document_section"> - </template>
                      <template v-else>
                        {{ item.size | filesizeFormat }} MB
                      </template>
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
            <div class="col-2">
              <q-list>
                <template>
                  <q-item>
                    <q-item-section>
                      <q-item-label>
                        {{ item.created_at | tableDateFormate }}
                      </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <div
                        class="task-menu-option thread-icons row items-center q-px-xs q-mt-sm q-mr-xs group-hover-item"
                        style="z-index: 100"
                      >
                        <div
                          v-if="item.isbookmarked"
                          @click.stop="removeMediaBookmark(item)"
                          class="cursor-pointer"
                        >
                          <q-icon
                            size="20px"
                            color="pink"
                            :name="$icons.matBookmark"
                          >
                            <q-tooltip> Remove from Saved items </q-tooltip>
                          </q-icon>
                        </div>
                        <div
                          v-if="!item.isbookmarked"
                          @click.stop="addMediaBookmark(item)"
                          class="cursor-pointer"
                        >
                          <q-icon
                            size="20px"
                            color="black"
                            :name="$icons.matBookmarkBorder"
                          >
                            <q-tooltip> Add to Saved items </q-tooltip>
                          </q-icon>
                        </div>
                      </div>
                      <div>
                        <q-icon
                          v-if="item.isbookmarked"
                          @click.stop="removeMediaBookmark(item)"
                          size="20px"
                          class="cursor-pointer"
                          color="pink"
                          :name="$icons.matBookmark"
                        >
                          <q-tooltip> Remove from Saved items </q-tooltip>
                        </q-icon>
                      </div>
                    </q-item-section>
                  </q-item>
                </template>
              </q-list>
            </div>
          </div>
        </template>
      </div>
    </template>
    <confirm-dialog
      v-if="deleteSection.flag"
      v-model="deleteSection.flag"
      :title="
        deleteSection.shouldDeleteMedia
          ? 'Delete section and media?'
          : 'Delete Section?'
      "
      :question="
        deleteSection.shouldDeleteMedia
          ? 'Deleting this section will also delete all the media in it.'
          : 'Are you sure you want to delete this section?'
      "
      cancleText="Cancel"
      successText="Yes"
      :cancleMethod="resetConfirmationDialog"
      :successMethod="deleteSectionHandler"
      :loading="deleteSection.loader"
    />
    <copy-move-file-folder-dialog
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isMoveAction="copyMoveDialog.isMoveAction"
      :fileFolderDetails="copyMoveDialog.data"
      :isRoot="copyMoveDialog.isRoot"
      :isMultiple="copyMoveDialog.isMultiple"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyFileFolderHandler="moveCopyFileFolderHandler"
      :multipleMoveCopyHandler="multipleMoveCopyHandler"
    />
    <move-file-section-folder-dialog
      v-if="moveSectionDialog.flag"
      v-model="moveSectionDialog.flag"
      :currentFolderSection="moveSectionDialog.currentFolderSection"
      :folderSection="folderSection"
      :cancelMethod="closeMoveFileSectionFolderDialog"
      :moveFolderSectionHandler="moveFolderSectionHandler"
      :fileFolderDetails="moveSectionDialog.data"
    />
    <vue-dropzone-file-upload
      hidden
      ref="folderSectionUploader"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="sectionFileUploadCloseHandler"
      @setProgress="(progress) => $emit('setProgress', progress)"
    ></vue-dropzone-file-upload>
    <shared-files-dialog
      v-if="sharedFileDialog"
      v-model="sharedFileDialog"
      @closeDialog="sharedFileDialog = false"
      :imageList="imageList"
      :attachMediaToPost="sectionFileUploadCloseHandler"
      :currentWorkspaceId="currentWorkspace"
      :fromFolder="true"
    />
    <div v-if="bulkDeleteFileConfirm.flag">
      <confirm-dialog
        v-model="bulkDeleteFileConfirm.flag"
        title="Delete Assets?"
        question="Are you sure you want to delete these assets?"
        cancleText="Cancel"
        successText="Yes"
        :cancleMethod="() => bulkDeleteAssetsConfirmHandler(false)"
        :successMethod="() => bulkDeleteAssetsConfirmHandler(true)"
        :loading="bulkDeleteFileConfirm.loader"
      />
    </div>
    <update-file-dialog
      v-if="renameFileDialog.flag"
      v-model="renameFileDialog.flag"
      :renameFile="renameFileDetails"
      :cancelMethod="cancelRenameFile"
    />
  </div>
</template>
<script>
import MediaViewMixin from "@/mixins/MediaViewMixin";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";
import groupBy from "lodash/groupBy";
import first from "lodash/first";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import CopyMoveFileFolderDialog from "@/components/Dialogs/CopyMoveFileFolderDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import UpdateFileDialog from "@/components/Dialogs/UpdateFileDialog";
import MoveFileSectionFolderDialog from "@/components/Dialogs/MoveFileSectionFolderDialog";

import { UpdateMediaMutation } from "@/gql";

export default {
  name: "FolderDetailListView",
  mixins: [MediaViewMixin, GoogleDriveMethodMixin, DropBoxMixin],
  props: [
    "isFolderOpenView",
    "folderSection",
    "items",
    "completedMediaPoints",
    "addMediaBookmark",
    "removeMediaBookmark",
    "attachToPost",
    "downloadFile",
    "openNextTabLink",
    "removeMedia",
    "canRemove",
    "currentUser",
    "currentWorkspace",
    "fromComputer",
    "openFolderId",
    "companyId",
    "activeCompany",
    "closeFolderHandler",
    "isWorkspaceOwner",
    "authToken",
    "fullViewHandler",
    "editDoc",
  ],
  components: {
    ConfirmDialog,
    CopyMoveFileFolderDialog,
    VueDropzoneFileUpload,
    SharedFilesDialog,
    UpdateFileDialog,
    MoveFileSectionFolderDialog,
  },
  api: {
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspace}`;
      },
    },
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.currentWorkspace}`;
      },
    },
  },
  data() {
    return {
      openSectionData: [],
      renameFileDialog: {
        flag: false,
      },
      renameFileDetails: null,
    };
  },
  created() {
    this.openSectionData = this.showSectionToggle;
  },
  mounted() {
    this.openSectionData = this.showSectionToggle;
  },
  methods: {
    async updateReadOnlyHandler(id, flag) {
      try {
        let is_read_only = flag;
        const variables = {
          id: id,
          is_read_only,
        };
        await this.$api.mutate({
          mutation: UpdateMediaMutation,
          skipUpdates: true,
          variables,
        });

        const media = this.$api.getEntity("media", id);
        media.is_read_only = is_read_only;
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: flag
            ? "Document added read only mode."
            : "Document remove read only mode.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    collapsibleChangeHandler(value, section) {
      this.$store.dispatch("allSectionList", {
        flag: value,
        id: section.id,
      });
      this.openSectionData = this.$store.getters.allSectionList;
    },
    openRenameFileDialog(media) {
      this.renameFileDetails = media;
      this.renameFileDialog.flag = true;
    },
    cancelRenameFile() {
      this.renameFileDetails = null;
      this.renameFileDialog.flag = false;
      this.renameFileDialog.loading = false;
    },
    isFirstSection(section) {
      return first(this.folderSection).id === section.id;
    },
    isLastSection(section) {
      return last(this.folderSection).id === section.id;
    },
    isImg(media) {
      return media.type.match(/image.*/);
    },
  },
  computed: {
    showSectionToggle() {
      return this.$store.getters.allSectionList;
    },
    allSectionMedia() {
      return this.items.filter((item) => item.media_card_id);
    },
    folderRootMedia() {
      return this.items.filter((item) => !item.media_card_id);
    },
    sectionMedia() {
      return groupBy(this.allSectionMedia, "media_card_id");
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      }
      return null;
    },
    folderList() {
      if (this.mediaFolders) {
        return uniqBy(orderBy(this.mediaFolders, ["title"], ["asc"]), "id");
      } else {
        return [];
      }
    },
  },
};
</script>
<style scoped>
.comment-point {
  position: absolute;
  right: -3px;
  top: -2px;
  width: 25px;
  height: 15px;
  border-radius: 9.5px;
  display: flex;
  color: white;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  z-index: 1;
  background: #ff3279;
}
.video-icon {
  width: 40px;
  height: 40px;
  top: 0;
  background: #FFEFF1;
  border-radius: 6px;
}
</style>
