<template>
  <div class="fill-height">
    <iframe
      :src="docUrl"
      width="100%"
      height="100%"
      style="border: none;"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "OtherDocViewer",
  props: ["docUrl"],
};
</script>
<style></style>
