<template>
  <div class="row items-center document-folder">
    <q-expansion-item class="flex-1" v-model="isFolderOpen" :key="folder.id">
      <template v-slot:header>
        <div class="row items-center flex-no-wrap full-width">
          <q-icon
            :style="{ color: '#006EE5' }"
            size="30px"
            :name="isFolderOpen ? $icons.mdiFolderOpen : $icons.matFolder"
          />
          <span :class="['folder-name q-ml-sm ellipses']" v-tooltip>
            {{ folder.title | capitalize }}
          </span>
          <q-tooltip :offset="[0, -50]" v-if="isTruncated">
            {{ folder.title }}
          </q-tooltip>
        </div>
      </template>
      <q-item v-for="document in documents" :key="document.id">
        <document-list-item
          :document="document"
          :setActiveDocumentHandler="setActiveDocumentHandler"
          :activeDocumentId="activeDocumentId"
          :deleteFile="deleteFile"
          class="flex-1 q-ml-sm"
          :canRemove="canRemove"
          @renameFile="(data) => $emit('renameFile', data)"
        />
      </q-item>
    </q-expansion-item>
  </div>
</template>

<script>
import DocumentListItem from "./DocumentListItem.vue";
export default {
  components: { DocumentListItem },
  props: [
    "folder",
    "documents",
    "setActiveDocumentHandler",
    "activeDocumentId",
    "deleteFile",
    "canRemove",
  ],
  data() {
    return {
      isFolderOpen: false,
      isTruncated: false,
    };
  },
  mounted() {
    this.isFolderOpen = this.hasActiveDocument;
  },
  computed: {
    hasActiveDocument() {
      if (this.documents) {
        return this.documents.map((d) => d.id).includes(this.activeDocumentId);
      }
      return false;
    },
  },
  watch: {
    hasActiveDocument(val) {
      this.isFolderOpen = val;
    },
  },
};
</script>
