<template>
  <vue-dropzone
    ref="myVueDropzone"
    id="dropzone"
    :options="dropzoneOptions"
    class="add-attachment-bar"
    @vdropzone-processing="fileProcessing"
    @vdropzone-sending="fileSending"
    @vdropzone-file-added="fileAdded"
    @vdropzone-total-upload-progress="totalUploadProgress"
    @vdropzone-queue-complete="queueComplete"
    @vdropzone-success="success"
    @vdropzone-error="errorHandler"
    @vdropzone-thumbnail="thumbnail"
  >
  </vue-dropzone>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import { apiUrl, fileValidations } from "@/config";
import has from "lodash/has";
import keys from "lodash/keys";
import { DeleteBulkMediaMutation } from "@/gql";
import filter from "lodash/filter";
import { pusher } from "@/pusher";

export default {
  name: "VueDropzoneFileUpload",
  props: [
    "workspace",
    "authToken",
    "media_id",
    "totalFile",
    "fromTaskAttachment",
    "fromDocEditor",
  ],
  data() {
    return {
      fileSuccessCounter: 0,
      fileAddCounter: 0,
      dropzoneOptions: {
        url: `${apiUrl}/api/media`,
        method: "post",
        maxFilesize: fileValidations.maxSize / 8000,
        ...(this.fromDocEditor && { acceptedFiles: "image/*" }),
        accept: (file, done) => {
          if (file.size / 1000 <= fileValidations.minSize) {
            done(this.$t("message.fileupload.dictFileTooSmall"));
            this.fileSuccessCounter += 1;
          } else {
            done();
          }
        },
        parallelUploads: 30,
        timeout: 300000,
        uploadMultiple: false,
        maxThumbnailFilesize: 25,
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        previewsContainer: false,
        headers: {
          Authorization: `Bearer ${this.authToken}`,
          Pusher: pusher.connection.socket_id,
        },
        paramName: "file",
        dictFileTooBig: this.$t("message.fileupload.dictFileTooBig"),
        dictCancelUploadConfirmation: this.$t(
          "message.fileupload.dictCancelUploadConfirmation"
        ),
      },
      requestSent: null,
      maxFiles: 10,
      uploaded: 0,
      requestFiles: {},
      isQueuecomplete: false,
      fileList: {},
      progress: 0,
    };
  },
  mixins: [FileExtensionMixin],
  components: {
    vueDropzone: vue2Dropzone,
  },
  methods: {
    fileProcessing(file) {
      this.$emit(
        "totalFileCount",
        Object.getOwnPropertyNames(this.requestFiles).length - 1
      );
      if (has(this.requestFiles, file.upload.uuid)) {
        this.requestFiles[file.upload.uuid].processing = true;
      }
    },
    fileSending(file, xhr, formdata) {
      if (has(this.requestFiles, file.upload.uuid)) {
        this.requestFiles[file.upload.uuid].sending = true;
      }
      formdata.append("workspace_id", this.currentWorkspace);
      if (this.media_id && typeof this.media_id === "number") {
        formdata.append("media_id", this.media_id);
      }
      this.requestSent = true;
    },
    fileAdded(file) {
      this.progress = 0;
      this.$emit("setProgress", this.progress);

      this.$set(this.requestFiles, file.upload.uuid, {
        name: file.name,
        id: null,
        thumb: null,
        progress: 0,
        uuid: file.upload.uuid,
        type: file.type,
        error: null,
        processing: false,
        sending: false,
        success: false,
        verifyKey: "uploading",
      });
      if (!file.type.match(/image.*/)) {
        let src = this.transformMedia(file, true).type;
        this.thumbnail(file, src);
      }
    },
    totalUploadProgress(progress) {
      if (this.progress < 1) {
        this.progress = Math.min(progress, 99) / 100;
        this.$emit("setProgress", this.progress);
      }
    },
    queueComplete() {
      this.progress = Object.keys(this.requestFiles).length === 0 ? 0 : 1;
      this.$emit("setProgress", this.progress);
      this.requestSent = null;
    },
    success(file, response) {
      const uploadedFileCount =
        this.totalFile - this.$refs.myVueDropzone.getUploadingFiles().length;
      this.$emit("updateUploadCount", uploadedFileCount);
      if (response && response.data) {
        if (has(this.requestFiles, file.upload.uuid)) {
          this.requestFiles[file.upload.uuid].id = response.data.id;
          this.requestFiles[file.upload.uuid].success = true;
        } else {
          this.removeMedia({ id: response.data.id });
        }
        file.postId = response.data.id;
        this.fileList[response.data.id] = {
          ...response.data,
        };
        // this.addToMediaCache(response.data);
        this.requestSent = false;
        this.fileSuccessCounter += 1;
        this.uploaded += 1;
        if (this.uploaded === this.maxFiles) {
          this.fileAddCounter = this.fileSuccessCounter;
        }
        if (response.data.thumb) {
          this.thumbnail(file, response.data.thumb);
        }
        this.addFileToUploader(this.fileList);
        if (this.fromTaskAttachment) {
          this.$emit("close", {
            fileList: this.fileList,
            fileSuccessCounter: this.fileSuccessCounter,
          });
        } else {
          this.$emit("close", this.fileList);
        }
        this.fileList = {};
      }
    },
    errorHandler(file, response) {
      if (has(this.requestFiles, file.upload.uuid)) {
        if (response.status_code === 403) {
          this.upgradeStorageDialog = true;
        }
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: response,
          icon: this.$icons.matReportProblem,
        });
        this.requestFiles[file.upload.uuid].error = response;
      }
    },
    thumbnail(file, dataUrl) {
      if (typeof dataUrl === "object") {
        let src = this.transformMedia(file, true).type;
        file.previewElement.querySelector("img").src = src;
      }
      if (has(this.requestFiles, file.upload.uuid)) {
        this.requestFiles[file.upload.uuid].thumb = dataUrl;
      }
    },
    openFileDialog() {
      this.fileList = {};
      this.fileSuccessCounter = 0;
      this.$refs.myVueDropzone.dropzone.element.click();
    },
    addFileToUploader(files) {
      let data;
      for (var media in files) {
        data = {};
        files[media]["verifyKey"] = "fromComputer";
        files[media]["name"] = files[media].filename;
        data[files[media].id] = files[media];
        const fileList = this.fileList;
        // const requestFiles = this.requestFiles;
        // this.requestFiles = { ...requestFiles, ...data };
        this.fileList = { ...fileList, ...data };
      }
      this.requestFiles = {};
    },
    removeMedia(media) {
      const fileList = this.fileList;
      delete this.fileList[media.id];
      this.fileList = { ...fileList };
    },
    destroyDropZone() {
      this.$refs.myVueDropzone.destroy();
    },
    async bulkRemove(fileList) {
      if (fileList) {
        const variables = {
          ids: fileList
            ? fileList.map((f) => f.id).filter((id) => id !== null)
            : [],
        };
        if (variables.ids.length > 0) {
          await this.$api.mutate({
            mutation: DeleteBulkMediaMutation,
            variables,
          });

          const query = this.$api.getQuery(
            `MediaQuery:${this.currentWorkspace}`
          );
          query.data.newMedia = query.data.newMedia.filter(
            (f) => !variables.ids.includes(f.id)
          );
        }
      }
    },
    cancelUpload() {
      let requestFiles = filter(
        this.requestFiles,
        (a) => a["verifyKey"] === "uploading"
      );
      this.$refs.myVueDropzone.removeAllFiles(true);
      this.bulkRemove(requestFiles);
      this.proxyModel = false;
      this.$emit("cancel");
    },
  },
  computed: {
    currentWorkspace() {
      return this.workspace;
    },
    uploadedFileIds() {
      if (this.fileList) {
        return keys(this.fileList);
      } else {
        return [];
      }
    },
  },
};
</script>
