import { MediaPreview } from "@/utils/Constants";
export default {
  methods: {
    transformMediaCollection(fileCollectionObj) {
      let fileSrc = null;
      return fileCollectionObj.map((element) => {
        fileSrc = this.transformMedia(element).type;

        return {
          ...element,
          selected: false,
          fileSrc,
        };
      });
    },
    transformFolderCollection(folderCollectionObj) {
      return folderCollectionObj.map((element) => {
        return {
          ...element,
          selected: false,
        };
      });
    },
    getExtensionColor() {},
    transformMedia(fileObj, isDropZone = false) {
      let type;
      let ext;
      let bgColor;
      let hoverClass;
      if (isDropZone) {
        ext = /[.]/.exec(fileObj.name)
          ? /[^.]+$/.exec(fileObj.name)[0].toLowerCase()
          : undefined;
      } else {
        ext = /[.]/.exec(fileObj.filename)
          ? /[^.]+$/.exec(fileObj.filename)[0].toLowerCase()
          : undefined;
      }
      if (fileObj.is_document_section) {
        if(fileObj.is_read_only) {
          type = MediaPreview["heycollabDocReadOnly"].icon;
          bgColor = MediaPreview["heycollabDocReadOnly"].bgColor;
          hoverClass = MediaPreview["heycollabDocReadOnly"].hoverClass;
          return { type, bgColor, hoverClass };
        }
        type = MediaPreview["heycollabDoc"].icon;
        bgColor = MediaPreview["heycollabDoc"].bgColor;
        hoverClass = MediaPreview["heycollabDoc"].hoverClass;
        return { type, bgColor, hoverClass };
      }
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "JPEG":
        case "JPG":
          type = fileObj.file
            ? fileObj.width > 200
              ? this.$resizeMedia(fileObj.file, 200, 200)
              : fileObj.file
            : MediaPreview["jpg"].icon;
          bgColor = fileObj.file
            ? MediaPreview["imagePreview"].bgColor
            : MediaPreview["jpg"].bgColor;
          hoverClass = fileObj.file
            ? MediaPreview["imagePreview"].hoverClass
            : MediaPreview["jpg"].hoverClass;
          break;
        case "webp":
        case "WEBP":
          type = fileObj.file
            ? fileObj.width > 200
              ? this.$resizeMedia(fileObj.file, 200, 200)
              : fileObj.file
            : MediaPreview["png"].icon;
          bgColor = fileObj.file
            ? MediaPreview["imagePreview"].bgColor
            : MediaPreview["png"].bgColor;
          hoverClass = fileObj.file
            ? MediaPreview["imagePreview"].hoverClass
            : MediaPreview["png"].hoverClass;
          break;
        case "HEIC":
        case "heic":
          type = fileObj.file
            ? this.$resizeMedia(fileObj.file, 200, 200)
            : MediaPreview["heic"].icon;
          bgColor = fileObj.file
            ? MediaPreview["imagePreview"].bgColor
            : MediaPreview["heic"].bgColor;
          hoverClass = fileObj.file
            ? MediaPreview["imagePreview"].hoverClass
            : MediaPreview["heic"].hoverClass;
          break;
        case "png":
        case "PNG":
          type = fileObj.file
            ? fileObj.width > 200
              ? this.$resizeMedia(fileObj.file, 200, 200)
              : fileObj.file
            : MediaPreview["png"].icon;
          bgColor = fileObj.file
            ? MediaPreview["imagePreview"].bgColor
            : MediaPreview["png"].bgColor;
          hoverClass = fileObj.file
            ? MediaPreview["imagePreview"].hoverClass
            : MediaPreview["png"].hoverClass;
          break;
        case "gif":
        case "GIF":
          type = fileObj.file
            ? fileObj.width > 200
              ? this.$resizeMedia(fileObj.file, 200, 200)
              : fileObj.file
            : MediaPreview["gif"].icon;
          bgColor = fileObj.file
            ? MediaPreview["imagePreview"].bgColor
            : MediaPreview["gif"].bgColor;
          hoverClass = fileObj.file
            ? MediaPreview["imagePreview"].hoverClass
            : MediaPreview["gif"].hoverClass;
          break;

        case "svg":
          type = fileObj.file
            ? this.$resizeMedia(fileObj.file, 200, 200)
            : MediaPreview["svg"].icon;
          bgColor = fileObj.file
            ? MediaPreview["imagePreview"].bgColor
            : MediaPreview["svg"].bgColor;
          hoverClass = fileObj.file
            ? MediaPreview["imagePreview"].hoverClass
            : MediaPreview["svg"].hoverClass;
          break;

        case "tif":
        case "TIF":
        case "tiff":
        case "TIFF":
          type = fileObj.file
            ? this.$resizeMedia(fileObj.file, 200, 200)
            : MediaPreview["tif"].icon;
          bgColor = fileObj.file
            ? MediaPreview["imagePreview"].bgColor
            : MediaPreview["tif"].bgColor;
          hoverClass = fileObj.file
            ? MediaPreview["imagePreview"].hoverClass
            : MediaPreview["tif"].hoverClass;
          break;

        case "mp4":
        case "mov":
        case "flv":
        case "mkv":
        case "avi":
        case "wmv":
        case "ogv":
        case "webm":
        case "m4v":
          type = "";
          break;

        case "doc":
        case "docx":
          type = MediaPreview["doc"].icon;
          bgColor = MediaPreview["doc"].bgColor;
          hoverClass = MediaPreview["doc"].hoverClass;
          break;

        case "xls":
        case "xlsx":
          type = MediaPreview["xls"].icon;
          bgColor = MediaPreview["xls"].bgColor;
          hoverClass = MediaPreview["xls"].hoverClass;
          break;

        case "ppt":
        case "pptx":
          type = MediaPreview["ppt"].icon;
          bgColor = MediaPreview["ppt"].bgColor;
          hoverClass = MediaPreview["ppt"].hoverClass;
          break;

        case "js":
        case "txt":
          type = MediaPreview["txt"].icon;
          bgColor = MediaPreview["txt"].bgColor;
          hoverClass = MediaPreview["txt"].hoverClass;
          break;

        case "raw":
          type = MediaPreview["raw"].icon;
          bgColor = MediaPreview["raw"].bgColor;
          hoverClass = MediaPreview["raw"].hoverClass;
          break;

        case "pdf":
          type = MediaPreview["pdf"].icon;
          bgColor = MediaPreview["pdf"].bgColor;
          hoverClass = MediaPreview["pdf"].hoverClass;
          break;

        case "iso":
          type = MediaPreview["iso"].icon;
          bgColor = MediaPreview["iso"].bgColor;
          hoverClass = MediaPreview["iso"].hoverClass;
          break;

        case "ps":
          type = MediaPreview["ps"].icon;
          bgColor = MediaPreview["ps"].bgColor;
          hoverClass = MediaPreview["ps"].hoverClass;
          break;

        case "zip":
          type = MediaPreview["zip"].icon;
          bgColor = MediaPreview["zip"].bgColor;
          hoverClass = MediaPreview["zip"].hoverClass;
          break;

        case "rar":
          type = MediaPreview["rar"].icon;
          bgColor = MediaPreview["rar"].bgColor;
          hoverClass = MediaPreview["rar"].hoverClass;
          break;

        case "ai":
          type = MediaPreview["ai"].icon;
          bgColor = MediaPreview["ai"].bgColor;
          hoverClass = MediaPreview["ai"].hoverClass;
          break;

        case "access":
          type = MediaPreview["access"].icon;
          bgColor = MediaPreview["access"].bgColor;
          hoverClass = MediaPreview["access"].hoverClass;
          break;
        case "cdr":
          type = MediaPreview["cdr"].icon;
          bgColor = MediaPreview["cdr"].bgColor;
          hoverClass = MediaPreview["cdr"].hoverClass;
          break;

        case "download":
          type = MediaPreview["download"].icon;
          bgColor = MediaPreview["download"].bgColor;
          hoverClass = MediaPreview["download"].hoverClass;
          break;

        case "dw":
          type = MediaPreview["dw"].icon;
          bgColor = MediaPreview["dw"].bgColor;
          hoverClass = MediaPreview["dw"].hoverClass;
          break;

        case "eps":
          type = MediaPreview["eps"].icon;
          bgColor = MediaPreview["eps"].bgColor;
          hoverClass = MediaPreview["eps"].hoverClass;
          break;

        case "fla":
          type = MediaPreview["fla"].icon;
          bgColor = MediaPreview["fla"].bgColor;
          hoverClass = MediaPreview["fla"].hoverClass;
          break;

        case "fw":
          type = MediaPreview["fw"].icon;
          bgColor = MediaPreview["fw"].bgColor;
          hoverClass = MediaPreview["fw"].hoverClass;
          break;

        case "hlp":
          type = MediaPreview["hlp"].icon;
          bgColor = MediaPreview["hlp"].bgColor;
          hoverClass = MediaPreview["hlp"].hoverClass;
          break;

        case "id":
          type = MediaPreview["id"].icon;
          bgColor = MediaPreview["id"].bgColor;
          hoverClass = MediaPreview["id"].hoverClass;
          break;

        case "wav":
          type = MediaPreview["wav"].icon;
          bgColor = MediaPreview["wav"].bgColor;
          hoverClass = MediaPreview["wav"].hoverClass;
          break;

        case "mp3":
          type = MediaPreview["mp3"].icon;
          bgColor = MediaPreview["mp3"].bgColor;
          hoverClass = MediaPreview["mp3"].hoverClass;
          break;

        case "aac":
        case "m4a":
        case "wma":
        case "aiff":
        case "ogg":
        case "alac":
          type = MediaPreview["otherAudiio"].icon;
          bgColor = MediaPreview["otherAudiio"].bgColor;
          hoverClass = MediaPreview["otherAudiio"].hoverClass;
          break;

        case "outlook":
          type = MediaPreview["outlook"].icon;
          bgColor = MediaPreview["outlook"].bgColor;
          hoverClass = MediaPreview["outlook"].hoverClass;
          break;

        case "settings":
          type = MediaPreview["settings"].icon;
          bgColor = MediaPreview["settings"].bgColor;
          hoverClass = MediaPreview["settings"].hoverClass;
          break;

        case "swf":
          type = MediaPreview["swf"].icon;
          bgColor = MediaPreview["swf"].bgColor;
          hoverClass = MediaPreview["swf"].hoverClass;
          break;

        case "rtf":
          type = MediaPreview["rtf"].icon;
          bgColor = MediaPreview["rtf"].bgColor;
          hoverClass = MediaPreview["rtf"].hoverClass;
          break;
          
        case "fig":
          type = MediaPreview["fig"].icon;
          bgColor = MediaPreview["fig"].bgColor;
          hoverClass = MediaPreview["fig"].hoverClass;
          break;

        case "pages":
          type = MediaPreview["pages"].icon;
          bgColor = MediaPreview["pages"].bgColor;
          hoverClass = MediaPreview["pages"].hoverClass;
          break;

        default:
          type = MediaPreview["upload"].icon;
          bgColor = MediaPreview["upload"].bgColor;
          hoverClass = MediaPreview["upload"].hoverClass;
          break;
      }
      return { type, bgColor, hoverClass };
    },
    transformMediaImage(fileObj, isDropZone = false) {
      let type;
      let ext;

      if (isDropZone) {
        ext = /[.]/.exec(fileObj.name)
          ? /[^.]+$/.exec(fileObj.name)[0].toLowerCase()
          : undefined;
      } else {
        ext = /[.]/.exec(fileObj.filename)
          ? /[^.]+$/.exec(fileObj.filename)[0].toLowerCase()
          : undefined;
      }
      
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "JPEG":
        case "JPG":
          type = fileObj.file ? fileObj.file : "/static/media-icons/Jpg.svg";
          break;
        case "HEIC":
        case "heic":
          type = fileObj.file ? fileObj.file : "/static/media-icons/upload.svg";
          break;
        case "png":
        case "PNG":
          type = fileObj.file ? fileObj.file : "/static/media-icons/Png.svg";
          break;

        case "gif":
        case "GIF":
          type = fileObj.file ? fileObj.file : "/static/media-icons/Gif.svg";
          break;

        case "svg":
          type = fileObj.file ? fileObj.file : "/static/media-icons/Upload.svg";
          break;

        case "tif":
        case "TIF":
        case "tiff":
        case "TIFF":
          type = fileObj.file ? fileObj.file : "/static/media-icons/Upload.svg";
          break;

        case "mp4":
        case "mov":
        case "flv":
        case "mkv":
        case "avi":
        case "wmv":
        case "ogv":
        case "webm":
        case "m4v":
          type = fileObj.thumb ? fileObj.thumb : "/static/media-icons/Mov.svg";
          break;

        case "doc":
        case "docx":
          type = "/static/media-icons/Word.svg";
          break;

        case "xls":
        case "xlsx":
          type = "/static/media-icons/Excel.svg";
          break;

        case "ppt":
        case "pptx":
          type = "/static/media-icons/PowerPoint.svg";
          break;

        case "js":
        case "txt":
          type = "/static/media-icons/TXT.svg";
          break;

        case "raw":
          type = "/static/media-icons/Raw.svg";
          break;

        case "pdf":
          type = "/static/media-icons/PDF.svg";
          break;

        case "iso":
          type = "/static/media-icons/Iso.svg";
          break;

        case "ps":
          type = "/static/media-icons/Ps.svg";
          break;

        case "zip":
          type = "/static/media-icons/ZIP.svg";
          break;

        case "rar":
          type = "/static/media-icons/Rar.svg";
          break;

        case "ai":
          type = "/static/media-icons/Ai.svg";
          break;

        case "access":
          type = "/static/media-icons/Access.svg";
          break;
        case "cdr":
          type = "/static/media-icons/Cdr.svg";
          break;

        case "download":
          type = "/static/media-icons/Download.svg";
          break;

        case "dw":
          type = "/static/media-icons/Dw.svg";
          break;

        case "eps":
          type = "/static/media-icons/Eps.svg";
          break;

        case "fla":
          type = "/static/media-icons/Fla.svg";
          break;

        case "fw":
          type = "/static/media-icons/Fw.svg";
          break;

        case "hlp":
          type = "/static/media-icons/Hlp.svg";
          break;

        case "id":
          type = "/static/media-icons/Id.svg";
          break;

        case "wav":
          type = "/static/media-icons/WAV.svg";
          break;

        case "mp3":
          type = "/static/media-icons/Mp3.svg";
          break;
        case "aac":
        case "m4a":
        case "wma":
        case "aiff":
        case "ogg":
        case "alac":
          type = "/static/media-icons/Audio_Files.svg";
          break;
        case "outlook":
          type = "/static/media-icons/Outlook.svg";
          break;

        case "settings":
          type = "/static/media-icons/Settings.svg";
          break;

        case "swf":
          type = "/static/media-icons/Swf.svg";
          break;

        case "rtf":
          type = "/static/media-icons/RTF.svg";
          break;
        
        case "fig":
            type = "/static/media-icons/Figma.svg";
            break;

        case "pages":
            type = "/static/media-icons/Pages.svg";
            break;

        default:
          type = "/static/media-icons/Upload.svg";
          break;
      }
      return type;
    },
  },
};
