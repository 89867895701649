<template>
  <div
    class="q-ma-md relative-position cursor-pointer media-card-hover border-radius10"
    @click="openFolder(folder)"
    v-if="view === 'GridView'"
  >
    <span
      :class="[
        'custom-selection-checkbox absolute shared-folder-grid cursor-pointer',
        isSelected && 'custom-selection-checkbox-visible',
      ]"
      @click.stop="selectionFolder(folder)"
    >
      <div
        class="selected-custom-selection-checkbox grid"
        v-if="isSelected"
      ></div>
    </span>
    <div
      class="media-grid-folder column justify-center items-center"
      style="height: 175px; width: 175px"
    >
      <div class="relative-position">
        <q-icon
          :name="$icons.matFolder"
          class="media-grid-folder-icon"
          style="font-size: 150px"
        />
        <div class="media-grid-folder-count" style="top: 40px">
          {{ folder.media_count > 9 ? "9+" : folder.media_count }}
        </div>
      </div>
      <div class="media-grid-folder-title ellipses">
        {{ folder.title }}
      </div>
    </div>
  </div>
  <div
    class="row items-center vabotu-tbl-row cursor-pointer media-list-folder"
    v-else
    @click="openFolder(folder)"
  >
    <div class="col-8 row items-center name-field">
      <q-item @click.stop="selectionFolder(folder)" class="items-center">
        <q-item-section class="items-center" avatar>
          <span class="custom-selection-checkbox">
            <div
              class="selected-custom-selection-checkbox"
              v-if="isSelected"
            ></div>
          </span>
        </q-item-section>
        <q-item-section class="q-ml-xs relative-position" avatar>
          <q-avatar rounded>
            <q-icon
              class="media-grid-folder-icon"
              size="40px"
              :name="$icons.matFolder"
            />
            <div class="media-grid-folder-count" style="top: 16px; right: 11px">
              {{ folder.media_count > 9 ? "9+" : folder.media_count }}
            </div>
          </q-avatar>
        </q-item-section>
        <q-item-section class="q-ml-sm">
          <div class="full-width">
            <div class="q-ml-xs ellipsis" v-tooltip>
              {{ folder.title }}
              <q-tooltip v-if="isTruncated">
                {{ media.filename }}
              </q-tooltip>
            </div>
          </div>
        </q-item-section>
      </q-item>
    </div>
    <div class="col-2">
      <q-item>
        <q-item-section>
          {{ folder.created_at | tableDateFormate }}
        </q-item-section>
      </q-item>
    </div>
    <div class="col-2"></div>
  </div>
</template>
<script>
export default {
  name: "SharedFolder",
  props: {
    folder: Object,
    selectionFolder: Function,
    selectedFiles: Array,
    view: String,
    openFolder: Function,
  },
  data() {
    return {
      isTruncated: false,
    };
  },
  computed: {
    isSelected() {
      if (this.selectedFiles.length) {
        return this.selectedFiles.map((a) => a.id).includes(this.folder.id);
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
.selction-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 35px;
  border: 2px solid white;
  background: white;
  border-radius: 50%;
  z-index: 1;
}
</style>
