<template>
  <q-dialog
    no-backdrop-dismiss
    transition-hide="none"
    transition-show="none"
    @hide="cancelMethod"
    no-esc-dismiss
    :value="value"
  >
    <q-card class="q-px-lg q-py-md bg-white copy-move-dialog">
      <div class="row title q-mb-md">
        <span class="q-mx-auto">{{
          isFolder
            ? isMoveAction
              ? "Move folders to:"
              : "Copy folders to:"
            : isMoveAction
            ? "Move files to:"
            : "Copy files to:"
        }}</span>
      </div>
      <q-btn
        outline
        size="md"
        class="dropdown-set full-width"
        no-caps
        style="font-weight:400"
        :icon-right="$icons.matKeyboardArrowDown"
        :label="
          selectedWorkspace && selectedWorkspace.id === currentWorkspaceId
            ? 'Current workspace'
            : selectedWorkspace && selectedWorkspace.title
        "
      >
        <q-menu>
          <div class="search-member q-pa-sm">
            <q-input
              autofocus
              ref="search"
              v-model="search"
              outlined
              placeholder="Filter workspaces"
              clearable
              dense
            >
              <template v-slot:prepend>
                <q-icon :name="$icons.matSearch" />
              </template>
            </q-input>
          </div>
          <q-list class="workspace-menu">
            <q-item
              @click="chooseWorkspace(workspace)"
              v-for="workspace in filteredWorkspaces"
              :key="workspace.id"
              clickable
              v-close-popup
            >
              <q-item-section>
                {{ workspace.title }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-btn
        v-if="
          (!isMultiple && !isFolder) ||
            (isMultiple && selectedFolders.length === 0)
        "
        outline
        size="md"
        class="dropdown-set full-width q-mt-md"
        no-caps
        style="font-weight:400"
        :icon-right="$icons.matKeyboardArrowDown"
        :label="`Folder: ${selectedFolder ? selectedFolder.title : 'Root'}`"
        :disable="isFetching"
      >
        <q-spinner-dots
          class="folders-loader"
          v-if="isFetching"
          color="primary"
          size="md"
        />
        <q-menu v-if="filteredFolders.length">
          <div class="search-member q-pa-sm">
            <q-input
              autofocus
              ref="search"
              v-model="searchFolder"
              outlined
              placeholder="Filter folders"
              clearable
              dense
            >
              <template v-slot:prepend>
                <q-icon :name="$icons.matSearch" />
              </template>
            </q-input>
          </div>
          <q-list class="workspace-menu">
            <q-item
              @click="chooseFolder(folder)"
              v-for="folder in filteredFolders"
              :key="folder.id"
              clickable
              v-close-popup
            >
              <q-item-section>
                {{ folder.title }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          @click="cancelMethod"
        />
        <!-- <q-btn
          color="primary"
          dense
          no-caps
          label="Rename folder"
          style="width:120px"
          v-if="renameFolder"
          @click="renameFolderHandler"
          :loading="loading"
        /> -->
        <q-btn
          color="primary"
          dense
          no-caps
          :disable="isDisabled"
          :label="
            isFolder
              ? isMoveAction
                ? 'Move folders'
                : 'Copy folders'
              : isMoveAction
              ? 'Move files'
              : 'Copy files'
          "
          class="create-folder-btn"
          :loading="loading"
          @click="moveCopyFileFolder"
        />
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import orderBy from "lodash/orderBy";
import Fuse from "fuse.js";
import { MediaFoldersQuery } from "@/gql";
export default {
  name: "CopyMoveFileFolderDialog",
  api: {
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
  },
  props: [
    "value",
    "loading",
    "isFolder",
    "isMoveAction",
    "fileFolderDetails",
    "cancelMethod",
    "folderList",
    "isRoot",
    "isMultiple",
    "moveCopyFileFolderHandler",
    "multipleMoveCopyHandler",
  ],
  data() {
    return {
      selectedWorkspace: null,
      selectedFolder: null,
      folderOptions: [],
      search: null,
      searchFolder: null,
      isFetching: false,
    };
  },
  mounted() {
    this.folderOptions = this.folderList;
    this.selectedWorkspace = this.currentWorkspace;
  },
  methods: {
    moveCopyFileFolder() {
      if (this.isMultiple) {
        this.multipleMoveCopyHandler(
          this.fileFolderDetails,
          this.isMoveAction,
          this.isRoot,
          this.selectedWorkspace,
          this.selectedFolder
        );
      } else {
        this.moveCopyFileFolderHandler(
          this.fileFolderDetails,
          this.isMoveAction,
          this.isFolder,
          this.isRoot,
          this.selectedWorkspace,
          this.selectedFolder
        );
      }
    },
    async chooseWorkspace(workspace) {
      this.selectedWorkspace = workspace;
      if (!this.isFolder) {
        const query = this.$api.getQuery(`MediaFoldersQuery:${workspace.id}`);
        try {
          //feth folder only if not available in cache
          if (!query.data) {
            this.isFetching = true;
            this.selectedFolder = null;
            const variables = {
              workspace_id: parseInt(workspace.id),
            };
            const response = await this.$api.query({
              query: MediaFoldersQuery,
              variables,
            });
            if (response) {
              this.isFetching = false;
              this.folderOptions = response.data.mediaFolders;
            }
          } else {
            this.folderOptions = query.data.mediaFolders;
          }
        } catch (error) {
          this.isFetching = false;
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
    },
    chooseFolder(folder) {
      this.selectedFolder = folder;
    },
  },
  computed: {
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else if (this.fileFolderDetails) {
        return this.fileFolderDetails[0].workspace_id;
      }
      {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    workspaceIds() {
      return this.workspaces.map((m) => m.id);
    },
    folderIds() {
      return this.folderOptions.map((f) => f.id);
    },
    filteredWorkspacesFuse() {
      if (this.workspaces) {
        const workspaces = this.workspaces.filter((workspace) =>
          this.workspaceIds.includes(workspace.id)
        );
        const orderedWorkspaces = orderBy(workspaces, ["title"], ["asc"]);
        return new Fuse(orderedWorkspaces, {
          keys: ["title"],
          shouldSort: true,
        });
      }
      return this.workspaces.filter(
        (workspace) => !this.workspaceIds.includes(workspace.id)
      );
    },
    filteredWorkspaces() {
      return this.search
        ? this.filteredWorkspacesFuse.search(this.search)
        : this.filteredWorkspacesFuse.hasOwnProperty("list")
        ? this.filteredWorkspacesFuse.list
        : this.filteredWorkspacesFuse;
    },
    filteredFoldersFuse() {
      if (this.folderOptions) {
        const folderOptions = this.folderOptions.filter((folder) =>
          this.folderIds.includes(folder.id)
        );
        const orderedWorkspaces = orderBy(folderOptions, ["title"], ["asc"]);
        return new Fuse(orderedWorkspaces, {
          keys: ["title"],
          shouldSort: true,
        });
      }
      return this.folderOptions.filter(
        (folder) => !this.folderIds.includes(folder.id)
      );
    },
    filteredFolders() {
      return this.searchFolder
        ? this.filteredFoldersFuse.search(this.searchFolder)
        : this.filteredFoldersFuse.hasOwnProperty("list")
        ? this.filteredFoldersFuse.list
        : this.filteredFoldersFuse;
    },
    //Check for
    isSelectedFolderIsSameAsParent() {
      return (
        !this.isFolder &&
        this.isMoveAction &&
        this.selectedFolder !== null &&
        this.fileFolderDetails[0].media_folder_id === this.selectedFolder.id
      );
    },
    isDisabled() {
      return (
        this.isFetching ||
        (this.selectedWorkspace &&
          this.selectedWorkspace.id === this.currentWorkspaceId &&
          this.isRoot &&
          !this.selectedFolder) ||
        (this.isFolder &&
          this.selectedWorkspace &&
          this.selectedWorkspace.id === this.currentWorkspaceId) ||
        this.isSelectedFolderIsSameAsParent
      );
    },
    selectedFolders() {
      return this.fileFolderDetails.filter((o) => {
        if (!o.hasOwnProperty("filename")) {
          return o;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: 600;
  font-size: 16px !important;
}
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
.workspace-menu {
  min-width: 140px;
  max-height: 190px;
  overflow: auto;
}
</style>
