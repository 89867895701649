import { VueNodeViewRenderer } from "@tiptap/vue-2";
import { Node } from "@tiptap/core";
import { dropImagePlugin } from "./Utilities";
import ImageResize from "./ImageResize.vue";
/**
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */
// const IMAGE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const ImageExtension = (uploadFn) => {
  return Node.create({
    name: "image",
    inline: true,
    group: "inline",
    draggable: true,
    addAttributes: () => ({
      src: {},
      alt: { default: null },
      title: { default: null },
      width: {
        default: "100%",
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          };
        },
      },
      height: {
        default: "auto",
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          };
        },
      },
      isDraggable: {
        default: true,
        renderHTML: () => {
          return {};
        },
      },
      isResizing: {
        default: false,
        renderHTML: (attributes) => {
          return {
            isResizing: attributes.isResizing,
          };
        },
      },
    }),
    parseHTML: () => [
      {
        tag: "img[src]",
        getAttrs: (dom) => {
          if (typeof dom === "string") return {};
          const element = dom;

          return {
            src: element.getAttribute("src"),
            title: element.getAttribute("title"),
            alt: element.getAttribute("alt"),
          };
        },
      },
    ],
    renderHTML: ({ HTMLAttributes }) => ["img", HTMLAttributes],

    // @ts-ignore
    addCommands() {
      return {
        insertImage: (attrs) => (state, dispatch) => {
          const { selection } = state;
          const position = selection.$cursor
            ? selection.$cursor.pos
            : selection.$to.pos;
          const node = this.type.create(attrs);
          const transaction = state.tr.insert(position, node);
          dispatch(transaction);
        },
        setImage:
          (options) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            });
          },
        toggleResizable:
          () =>
          ({ tr }) => {
            const { node } = tr?.selection;
            if (node?.type?.name === "image") {
              node.attrs.isDraggable = !node.attrs.isDraggable;
            }
          },
      };
    },
    // addInputRules() {
    //   return [
    //     nodeInputRule(IMAGE_INPUT_REGEX, this.type, (match) => {
    //       const [, alt, src, title] = match;
    //       return {
    //         src,
    //         alt,
    //         title,
    //       };
    //     }),
    //   ];
    // },
    addProseMirrorPlugins() {
      return [dropImagePlugin(uploadFn)];
    },
    addNodeView() {
      return VueNodeViewRenderer(ImageResize);
    },
  });
};
