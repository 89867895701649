<template>
  <q-dialog
    no-backdrop-dismiss
    transition-hide="none"
    transition-show="none"
    @hide="cancelMethod"
    :value="value"
  >
    <q-card style="min-width: 320px" class="create-folder">
      <div class="row title q-mb-md">
        <span class="q-mx-auto">Rename file</span>
      </div>
      <q-input
        placeholder="Type file name"
        dense
        outlined
        autofocus
        v-model="fileName"
        @keydown.enter.prevent="updateFileHandler"
      />
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width: 120px"
          class="q-mr-sm"
          @click="cancelMethod"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          label="Rename file"
          style="width: 120px"
          @click="updateFileHandler"
          :loading="loading"
          :disable="this.fileName ? false : true"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { UpdateMediaMutation } from "@/gql";

export default {
  name: "UpdateFileDialog",
  props: ["value", "renameFile", "cancelMethod"],
  data() {
    return {
      fileName: null,
      fileExt: null,
      loading: false,
    };
  },
  mounted() {
    if (this.renameFile && this.renameFile.filename) {
      if (this.renameFile.is_document_section) {
        this.fileName = this.renameFile.filename;
      } else {
        const filename = this.renameFile.filename.split("."); // Split the string using dot as separator
        this.fileExt = filename.pop(); // Get last element
        this.fileName = filename.join("."); // Re-join the remaining substrings, using dot as separator
      }
    }
  },
  methods: {
    async updateFileHandler() {
      let ext = this.renameFile.is_document_section ? "" : `.${this.fileExt}`;
      let title = this.fileName.trim() + ext;
      this.loading = true;
      if (this.renameFile) {
        try {
          const variables = {
            id: this.renameFile.id,
            title,
          };
          await this.$api.mutate({
            mutation: UpdateMediaMutation,
            skipUpdates: true,
            variables,
          });

          const media = this.$api.getEntity("media", this.renameFile.id);
          media.filename = title;
          this.$emit("success", title);
          this.cancelMethod();
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
          this.cancelMethod();
        }
      }
    },
  },
};
</script>
<style scoped>
.title {
  font-weight: 600;
  font-size: 16px !important;
}
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
</style>
