const PDF_BG_COLOR = "#FFF7F9";
const XLS_BG_COLOR = "#EAFFF8";
const DOC_BG_COLOR = "#EDF6FF";
const PNG_BG_COLOR = "#F5F2FF";
const GIF_BG_COLOR = "#FFFBEE";
const UPLOAD_BG_COLOR = "#E8FCFF";
const TXT_BG_COLOR = "#EDF6FF";
const MP3_BG_COLOR = "#F4F5FF";
const DOWNLOAD_BG_COLOR = "#FFF4F8";
const ZIP_BG_COLOR = "#EBFFEB";
const RTF_BG_COLOR = "#EDF6FF";
const PPT_BG_COLOR = "#FFF6F4";
const AI_BG_COLOR = "#FFFAED";
const PS_BG_COLOR = "#F4F7FF";
const EPS_BG_COLOR = "#FFFAED";
const JPG_BG_COLOR = "#FFF4F7";
const OTHER_BG_COLOR = "#f1f1f1";
const IMAGE_PREVIEW_BG_COLOR = "#F4F7FF";
const HEYCOLLAB_DOCUMENT_BG_COLOR = "#EDF6FF";
const PAGES_BG_COLOR = "#FFF9EE";

//Hover color
const PDF_HOVER_CLASS = "bg-hover-pdf";
const XLS_HOVER_CLASS = "bg-hover-xls";
const DOC_HOVER_CLASS = "bg-hover-doc";
const PNG_HOVER_CLASS = "bg-hover-png";
const GIF_HOVER_CLASS = "bg-hover-gif";
const UPLOAD_HOVER_CLASS = "bg-hover-upload";
const MP3_HOVER_CLASS = "bg-hover-mp3";
const DOWNLOAD_HOVER_CLASS = "bg-hover-download";
const ZIP_HOVER_CLASS = "bg-hover-zip";
const PPT_HOVER_CLASS = "bg-hover-ppt";
const AI_HOVER_CLASS = "bg-hover-ai";
const JPG_HOVER_CLASS = "bg-hover-jpg";
const OTHER_HOVER_CLASS = "bg-hover-other";
const IMAGE_PREVIEW_HOVER_CLASS = "bg-hover-blue";

export const MediaPreview = {
  jpg: {
    icon: "/static/media-icons/Jpg.svg",
    bgColor: JPG_BG_COLOR,
    hoverClass: JPG_HOVER_CLASS,
  },
  heic: {
    icon: "/static/media-icons/Upload.svg",
    bgColor: UPLOAD_BG_COLOR,
    hoverClass: UPLOAD_HOVER_CLASS,
  },
  png: {
    icon: "/static/media-icons/Png.svg",
    bgColor: PNG_BG_COLOR,
    hoverClass: PNG_HOVER_CLASS,
  },
  gif: {
    icon: "/static/media-icons/Gif.svg",
    bgColor: GIF_BG_COLOR,
    hoverClass: GIF_HOVER_CLASS,
  },
  svg: {
    icon: "/static/media-icons/Upload.svg",
    bgColor: UPLOAD_BG_COLOR,
    hoverClass: UPLOAD_HOVER_CLASS,
  },
  tif: {
    icon: "/static/media-icons/Upload.svg",
    bgColor: UPLOAD_BG_COLOR,
    hoverClass: UPLOAD_HOVER_CLASS,
  },
  mp4: {
    icon: "/static/media-icons/Mov.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  doc: {
    icon: "/static/media-icons/Word.svg",
    bgColor: DOC_BG_COLOR,
    hoverClass: DOC_HOVER_CLASS,
  },
  xls: {
    icon: "/static/media-icons/Excel.svg",
    bgColor: XLS_BG_COLOR,
    hoverClass: XLS_HOVER_CLASS,
  },
  ppt: {
    icon: "/static/media-icons/PowerPoint.svg",
    bgColor: PPT_BG_COLOR,
    hoverClass: PPT_HOVER_CLASS,
  },
  txt: {
    icon: "/static/media-icons/TXT.svg",
    bgColor: TXT_BG_COLOR,
    hoverClass: DOC_HOVER_CLASS,
  },
  raw: {
    icon: "/static/media-icons/Raw.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  pdf: {
    icon: "/static/media-icons/PDF.svg",
    bgColor: PDF_BG_COLOR,
    hoverClass: PDF_HOVER_CLASS,
  },
  iso: {
    icon: "/static/media-icons/Iso.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  ps: {
    icon: "/static/media-icons/Ps.svg",
    bgColor: PS_BG_COLOR,
    hoverClass: IMAGE_PREVIEW_HOVER_CLASS,
  },
  zip: {
    icon: "/static/media-icons/ZIP.svg",
    bgColor: ZIP_BG_COLOR,
    hoverClass: ZIP_HOVER_CLASS,
  },
  rar: {
    raricon: "/static/media-icons/Rar.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  ai: {
    icon: "/static/media-icons/Ai.svg",
    bgColor: AI_BG_COLOR,
    hoverClass: AI_HOVER_CLASS,
  },
  access: {
    icon: "/static/media-icons/Access.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  cdr: {
    icon: "/static/media-icons/Cdr.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  download: {
    icon: "/static/media-icons/Download.svg",
    bgColor: DOWNLOAD_BG_COLOR,
    hoverClass: DOWNLOAD_HOVER_CLASS,
  },
  dw: {
    icon: "/static/media-icons/Dw.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  eps: {
    icon: "/static/media-icons/Eps.svg",
    bgColor: EPS_BG_COLOR,
    hoverClass: AI_HOVER_CLASS,
  },
  fla: {
    icon: "/static/media-icons/Fla.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  fw: {
    icon: "/static/media-icons/Fw.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  hlp: {
    icon: "/static/media-icons/Hlp.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  id: {
    icon: "/static/media-icons/Id.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  wav: {
    icon: "/static/media-icons/WAV.svg",
    bgColor: MP3_BG_COLOR,
    hoverClass: MP3_HOVER_CLASS,
  },
  mp3: {
    icon: "/static/media-icons/Mp3.svg",
    bgColor: MP3_BG_COLOR,
    hoverClass: MP3_HOVER_CLASS,
  },
  otherAudiio: {
    icon: "/static/media-icons/Audio_Files.svg",
    bgColor: MP3_BG_COLOR,
    hoverClass: MP3_HOVER_CLASS,
  },
  outlook: {
    icon: "/static/media-icons/Outlook.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  settings: {
    icon: "/static/media-icons/Settings.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  swf: {
    icon: "/static/media-icons/Swf.svg",
    bgColor: OTHER_BG_COLOR,
    hoverClass: OTHER_HOVER_CLASS,
  },
  rtf: {
    icon: "/static/media-icons/RTF.svg",
    bgColor: RTF_BG_COLOR,
    hoverClass: DOC_HOVER_CLASS,
  },
  upload: {
    icon: "/static/media-icons/Upload.svg",
    bgColor: UPLOAD_BG_COLOR,
    hoverClass: UPLOAD_HOVER_CLASS,
  },
  fig: {
    icon: "/static/media-icons/Figma.svg",
    bgColor: HEYCOLLAB_DOCUMENT_BG_COLOR,
    hoverClass: IMAGE_PREVIEW_HOVER_CLASS,
  },
  pages: {
    icon: "/static/media-icons/Pages.svg",
    bgColor: PAGES_BG_COLOR,
    hoverClass: PAGES_BG_COLOR,
  },
  heycollabDoc: {
    icon: "/static/media-icons/heycollab_icon_2.svg",
    bgColor: HEYCOLLAB_DOCUMENT_BG_COLOR,
    hoverClass: IMAGE_PREVIEW_HOVER_CLASS,
  },
  heycollabDocReadOnly: {
    icon: "/static/media-icons/heycollab_icon_read_only.svg",
    bgColor: HEYCOLLAB_DOCUMENT_BG_COLOR,
    hoverClass: IMAGE_PREVIEW_HOVER_CLASS,
  },
  imagePreview: {
    bgColor: IMAGE_PREVIEW_BG_COLOR,
    hoverClass: IMAGE_PREVIEW_HOVER_CLASS,
  },
};
export const DOCUMENT_EDITOR_CHAR_LIMIT = 1200;
