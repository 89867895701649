<template>
  <q-dialog
    :value="isFolderOpen"
    transition-hide="none"
    transition-show="none"
    @hide="closeFolder"
  >
    <q-card
      id="dialogScroll"
      class="folder-details q-pt-none q-pb-md full-height overflow-hidden"
    >
      <q-card-section class="column full-height flex-no-wrap q-pa-none">
        <div
          v-if="progress > 0"
          style="display: flex; justify-content: center"
          class="q-mb-sm"
        >
          <q-linear-progress
            color="green"
            :indeterminate="query"
            size="20px"
            class="ma-0 progress"
            :value="progress"
            dark
            rounded
          >
            <div class="absolute-full flex label" style="margin-left: 10px">
              <div>
                <p>
                  {{progress &lt; 1 && uploadCount && totalFile? `${uploadCount} of ${totalFile - 1} files uploaded` : 'Uploaded'}}
                  <span>{{ Math.round(progress * 100) + "%" }}</span>
                </p>
              </div>
            </div>
          </q-linear-progress>
        </div>
        <div class="stick-at-top q-pt-md q-px-lg" style="z-index:111">
          <div class="row justify-between items-center q-mb-sm ">
            <div class="row items-center">
              <q-icon class="folder-icon" :name="$icons.matFolder" />
              <span class="folder-title q-ml-sm">{{ folder.title }}</span>
              <div class="folder-title q-ml-sm">
                {{ folderMedia.length && folderMedia.length }}
              </div>
              
            </div>
            <div class="row items-center">
              <div>
                <q-btn
                  v-if="mediaFolder.isgridview"
                  size="md"
                  flat
                  round
                  dense
                  :icon="$icons.matDashboard"
                  color="black"
                  @click="changeView"
                  class="toolbar-buttons"
                >
                  <q-tooltip>
                    Grid View
                  </q-tooltip>
                </q-btn>
                <q-btn
                  v-else
                  size="md"
                  flat
                  round
                  dense
                  color="black"
                  :icon="$icons.matViewList"
                  @click="changeView"
                  class="toolbar-buttons"
                >
                  <q-tooltip>
                    List View
                  </q-tooltip>
                </q-btn>
              </div>
              <div>
                <q-btn
                  dense
                  round
                  flat
                  :icon="$icons.matClose"
                  color="black"
                  @click.native="closeFolder"
                />
              </div>
            </div>
          </div>
          <div
            style="border-bottom: solid 1px #e3e8ed;margin:0px -32px 0"
          ></div>
        </div>
        <div
          v-if="mediaFolderLoading"
          class="row full-height items-center justify-center"
        >
          <q-circular-progress
            indeterminate
            :thickness="0.1"
            size="75px"
            color="primary"
            class="q-ma-md"
          />
        </div>
        <div class="overflow-auto q-pl-md" v-else>
          <template>
            <div
              v-if="
                !user.onboarding.is_folder_detail &&
                  folderMedia.length === 0 &&
                  folderSection.length === 0
              "
              class="row justify-center q-mt-xl"
            >
              <div class="card text-center">
                <div class="card__title card__title--primary q-mt-md q-pa-sm">
                  <div>
                    <q-avatar
                      size="60px"
                      :style="{ background: 'rgba(0, 196, 134, 0.2)' }"
                    >
                      <q-icon
                        color="teal-14"
                        size="30px"
                        :name="$icons.matDescription"
                      />
                    </q-avatar>
                    <div
                      class="title nolabel--text q-mt-sm q-mb-xs an-15 medium-text"
                    >
                      Files
                    </div>
                    <span class="nolabel--text an-15 regular-text">
                      Create a section or
                      <br /><span>add files to get started.</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <component
            :is="mediaView"
            :imageList="folderMedia"
            :isFolderOpenView="true"
            :openFolderId="folder.id"
            :workspace="currentWorkspaceId"
            :isWorkspaceOwner="isWorkspaceOwner"
            :canRemoveMedia="canRemove"
            :user="user"
            :currentCompanyId="companyId"
            :activeCompany="activeCompany"
            :addMediaBookmark="addMediaBookmark"
            :removeMediaBookmark="removeMediaBookmark"
            :folderSection="folderSection"
            :authToken="authToken"
            :editDoc="editDoc"
            @clickonmoreoption="clickonmoreoption"
            @deleteCommentPoint="deleteCommentPoint"
            @updatepositions="updatepositions"
            @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
            @updateComment="updateComment"
            @sendComment="sendComment"
            @deleteMediaComment="deleteMediaComment"
            @closeMediaModel="closeMediaModel"
            :labels="labels"
            :fromComputer="(data) => fromComputer(data)"
            :closeFolderHandler="closeFolder"
            @setProgress="setProgress"
          ></component>
        </div>
        <button :class="['fab-btn']" style="bottom: 0px;" v-if="isVisible([1, 2])">
        <q-icon :name="$icons.mdiPlusThick" size="20px" color="white" />
        <q-menu
          v-model="fabMenu"
          auto-close
          :offset="[-20, 0]"
          content-style="max-width:auto;max-height:200px;box-shadow:0px 2px 10px 0px rgb(0 0 0 / 19%)"
        >
          <q-list class="q-py-sm">
            <q-item
              @click="() => googleDrive('fromFolder', user.id)"
              clickable
              :class="['items-center q-px-md']"
              style="font-size: 15px"
            >
              <q-icon :name="$icons.fabGoogleDrive" size="18px"
              style="margin-right: 20px;color: #232C3B;" />
              Upload from Google Drive
            </q-item>
            <q-item
              @click="() => dropBox('fromFolder', user.id)"
              clickable
              :class="['items-center q-px-md']"
              style="font-size: 15px"
            >
                <q-icon :name="$icons.fabDropbox" size="18px"
                style="margin-right: 20px;color: #232C3B;" />
                Upload from Dropbox
            </q-item>
            <q-separator style="background:#ECEEF1;" />
            <q-item @click="sharedFileDialog = true" :class="['items-center q-px-md']"
              style="font-size: 15px" clickable>
                <q-icon :name="$icons.fasCopy" size="18px"
                style="margin-right: 20px;color: #232C3B;" />
                Upload from shared files
            </q-item>
            <q-item @click="uploadFileToFolder(folder.id)" :class="['items-center q-px-md']"
              style="font-size: 15px" clickable>
                <q-icon :name="$icons.matComputer" size="18px"
                style="margin-right: 20px;color: #232C3B;" />
                Upload from computer
            </q-item>
            <q-separator style="background:#ECEEF1;" />
            <q-item
              @click="openDocEditor(currentWorkspaceId, folder.id)"
              :class="['items-center q-px-md']"
              style="font-size: 15px"
              clickable
            >
              <q-icon
                :name="$icons.matDescription"
                size="18px"
                style="margin-right: 20px"
              />
              New doc
            </q-item>
            <q-item
              @click="checkAddSessionAccess"
              :class="['items-center q-px-md']"
              style="font-size: 15px"
              clickable
            >
              <q-icon
                :name="$icons.matAddBox"
                size="18px"
                style="margin-right: 20px;color: #232C3B;"
              />
              New Folder Section
            </q-item>
          </q-list>
        </q-menu>
      </button>
      </q-card-section>
    </q-card>
    <vue-dropzone-file-upload
      hidden
      ref="folderMediaUploader"
      :workspace="currentWorkspaceId"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="fileUploadCloseHandler"
      @setProgress="setProgress"
      :media_id="mediaId"
      :totalFile="totalFile"
      @totalFileCount="(total) => (totalFile = total)"
      @updateUploadCount="(uploaded) => (uploadCount = uploaded)"
    ></vue-dropzone-file-upload>
    <shared-files-dialog
      v-if="sharedFileDialog"
      v-model="sharedFileDialog"
      @closeDialog="sharedFileDialog = false"
      :imageList="imageList"
      :attachMediaToPost="(data) => addSharedFileToUploader(data, 'fromFolder')"
      :currentWorkspaceId="currentWorkspaceId"
      :fromFolder="true"
    />
  </q-dialog>
</template>

<script>
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";

import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";
import map from "lodash/map";
import UserScope from "@/mixins/UserScope";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";

import {
  MediaFolderQuery,
  AttachFolderMediaMutation,
  UpdateMediaFolderViewMutation,
} from "@/gql";

export default {
  name: "FolderDetailsDialog",
  mixins: [
    UserScope,
    MediaMethodMixin,
    CopiedImageOnEditor,
    GoogleDriveMethodMixin,
    DropBoxMixin,
  ],
  props: [
    "isFolderOpen",
    "folder",
    "currentWorkspaceId",
    "isWorkspaceOwner",
    "closeFolder",
    "companyId",
    "activeCompany",
    "addMediaBookmark",
    "removeMediaBookmark",
    "labels",
    "updateFolderCache",
    "authToken",
    "editDoc",
    "openDocEditor",
  ],
  api: {
    mediaFolder: {
      query: MediaFolderQuery,
      defaultValue: [],
      variables() {
        return {
          folder_id: parseInt(this.folder.id),
        };
      },
      cacheKey() {
        return `MediaFolderQuery:${parseInt(this.folder.id)}`;
      },
    },
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  components: {
    VueDropzoneFileUpload,
    VideoDialog,
    SharedFilesDialog,
  },
  data() {
    return {
      mediaSearch: "",
      fileUpload: {
        flag: false,
        dialog: false,
      },
      folderId: null,
      progress: 0,
      totalFile: 0,
      uploadCount: 0,
      mediaId: null,
      sharedFileDialog: false,
      fabMenu: false,
    };
  },
  beforeCreate() {
    this.$options.components.ListView = require("../../views/MediaView/ListView").default;
    this.$options.components.GridView = require("../../views/MediaView/GridView").default;
  },
  methods: {
    checkAddSessionAccess() {
      let eligible = this.$checkEligiblity("folder_sections");
      if (eligible) {
        this.$eventBus.$emit("addSectionFromHeader");
        this.$mixpanelEvent("add-section", {});
      }
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    async changeView() {
      const folder = this.$api.getQuery(
        `MediaFolderQuery:${parseInt(this.mediaFolder.id)}`
      );
      folder.data.mediaFolder.isgridview = !folder.data.mediaFolder.isgridview;
      await this.$api.mutate({
        mutation: UpdateMediaFolderViewMutation,
        variables: {
          folder_id: this.folder.id,
          is_grid_view: folder.data.mediaFolder.isgridview,
        },
      });
    },
    uploadFileToFolder(folderId) {
      this.folderId = folderId;
      this.$refs.folderMediaUploader.openFileDialog();
    },
    fromComputer(media_id) {
      this.mediaId = media_id;
      this.$refs.folderMediaUploader.openFileDialog();
    },
    async fileUploadCloseHandler(fileList) {
      if (this.mediaId) {
        const media = this.$api.getEntity("media", this.mediaId);
        media.file = fileList[this.mediaId].file;
        media.thumb = fileList[this.mediaId].thumb;
        this.mediaId = null;
      } else {
        const mediaIds = fileList ? map(fileList, (o) => o.id) : [];
        this.attachMediasToFolder({
          media: mediaIds,
          folder_id: this.folder.id,
        });
        this.folderId = null;
      }
    },
    async attachMediasToFolder(args) {
      try {
        const variables = {
          ...args,
          workspace_id: this.currentWorkspaceId,
        };
        const response = await this.$api.mutate({
          mutation: AttachFolderMediaMutation,
          variables,
        });
        //Add to multiple uploaded media to cache
        if (response.data.attachFolderMedia) {
          const { attachFolderMedia } = response.data;
          attachFolderMedia.forEach((media) => {
            const mediaFolder = this.$api.getEntity(
              "mediaFolder",
              args.folder_id
            );
            if (mediaFolder.media) {
              mediaFolder.media.push(media);
            } else {
              mediaFolder.media = [media];
            }
          });
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Files uploaded successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (erorr) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    async makeDriveMediaData(fileList) {
      // For Any drive Files
      const driveData = [];
      const media_urls = filter(
        fileList,
        (a) =>
          a.file_resource !== "s3" &&
          a.file_resource !== "outsourcce" &&
          !a.hasOwnProperty("verifyKey")
      );
      media_urls &&
        media_urls.forEach((element) => {
          const obj = {
            file: element.file,
            filename: element.filename,
            type: element.type,
            file_resource: element.file_resource,
            size: element.size,
            sequence: element.sequence,
          };
          driveData.push(obj);
        });
      this.attachMediasToFolder({
        media_urls: driveData,
        folder_id: this.folder.id,
      });
    },
  },
  computed: {
    canUploadMedia() {
      return this.canPostMessageFromWorkspace || this.isWorkspaceOwner;
    },
    canRemove() {
      return this.canRemoveMessageFromWorkspace || this.isWorkspaceOwner;
    },
    folderSection() {
      return orderBy(this.mediaFolder.mediaCard, ["sequence"]);
    },
    mediaView() {
      return this.mediaFolder.isgridview ? "GridView" : "ListView";
    },
    folderMedia() {
      if (this.mediaFolder.media) {
        return uniqBy(
          orderBy(
            this.mediaFolder.media.filter(
              (o) =>
                (o.filename &&
                  o.filename
                    .toLowerCase()
                    .includes(this.mediaSearch.toLowerCase())) ||
                o.file_resource
                  .toLowerCase()
                  .includes(this.mediaSearch.toLowerCase())
            ),
            ["folder_sequence"]
          ),
          "id"
        );
      } else {
        return [];
      }
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  position: absolute;
  font-size: 8px;
  right: 0;
  left: 0;
  top: 0;
  color: #15d89a !important;
}
</style>
