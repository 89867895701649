var render = function render(){var _vm=this,_c=_vm._self._c;return _c('textarea',{ref:"textarea",staticClass:"editor mark-down-editor",class:[_vm.isScroll ? 'is-scrolling' : ''],style:({
    overflow: _vm.overflowValue,
    'max-height': _vm.maxHeight + 'px',
    padding: _vm.isMessagingEditor && '8px 0 8px',
    'min-height': _vm.isMessagingEditor && '40px',
    marginLeft: _vm.setMargin && '15px',
  }),attrs:{"autofocus":_vm.autofocus},domProps:{"value":_vm.value},on:{"paste":_vm.onPaste,"input":_vm.handleInput,"keydown":[_vm.handleKeyDown,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.stopPropagation();return _vm.handleEscapeKey.apply(null, arguments)}],"blur":_vm.handleBlur,"focus":_vm.handleFocus}})
}
var staticRenderFns = []

export { render, staticRenderFns }