<template>
  <vue-plyr class="video-dialog-player">
    <video preload="metadata" :poster="videoMediaData.thumb">
      <source :src="videoMediaData.file" type="video/mp4" />
    </video>
  </vue-plyr>
  <!-- <video preload="metadata" width="100%" poster="https://vabotu.s3.us-west-1.amazonaws.com/Play.png" height="99%" controls>
    <source :src="videoMediaData.file" type="video/mp4" />
  </video> -->
</template>
<script>
export default {
  name: "VideoPlayerComponent",
  props: ["videoMediaData"],
};
</script>
