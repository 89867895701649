<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    :value="value"
    :content-class="modelClass"
    @before-hide="cancleMethod"
    persistent
    content-style="z-index:6000"
  >
    <q-card style="width:320px;" class="create-folder">
      <div class="row title q-mb-md">
        <span class="q-mx-auto text-center">{{ title }}</span>
      </div>
      <div v-if="question" class="text-dark text-center app-text-normal" v-html="question"></div>
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          :label="cancleText ? cancleText : 'Cancel'"
          style="width:120px"
          class="q-mr-sm app-text"
          v-if="cancleText"
          @click.stop="cancleMethod()"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          class="create-folder-btn app-text"
          :label="successText"
          @click.stop="successMethod()"
          :loading="loading"
          v-if="successText"
          :disable="loading"
        />
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
export default {
  name: "ConfirmDialog",
  props: [
    "value",
    "modelClass",
    "loading",
    "title",
    "question",
    "cancleText",
    "successText",
    "cancleMethod",
    "successMethod",
  ],
};
</script>
<style lang="scss" scoped>
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
</style>
