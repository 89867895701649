<template>
  <div class="column justify-between flex-no-wrap">
    <div class="flex-1 overflo-auto overflow-x-hidden q-pa-md">
      <template v-for="folder in foldersWithDocuments">
        <document-folder
          :key="folder.id"
          :folder="folder"
          :documents="documentsInsideFolder[folder.id]"
          :setActiveDocumentHandler="setActiveDocumentHandler"
          :activeDocumentId="activeDocumentId"
          :deleteFile="deleteFile"
          :canRemove="canRemove"
          @renameFile="(data) => $emit('renameFile', data)"
        />
      </template>
      <template v-for="document in documentsOutsideFolder">
        <document-list-item
          :document="document"
          :key="'doc' + document.id"
          class="q-py-xs"
          :setActiveDocumentHandler="setActiveDocumentHandler"
          :activeDocumentId="activeDocumentId"
          :deleteFile="deleteFile"
          :canRemove="canRemove"
          @renameFile="(data) => $emit('renameFile', data)"
        />
      </template>
    </div>
    <div class="fab-btn-container row items-center q-px-md q-pt-sm q-mb-lg">
      <button
        @click="openDocEditor(currentWorkspaceId)"
        :class="['doc-fab-btn']"
      >
        <q-icon :name="$icons.mdiPlusThick" size="20px" color="white" />
      </button>
      <span class="btn-title q-ml-sm">New doc</span>
    </div>
  </div>
</template>
<script>
import DocumentFolder from "@/components/DocumentEditor/DocumentEditorSidebar/DocumentFolder";
import DocumentListItem from "@/components/DocumentEditor/DocumentEditorSidebar//DocumentListItem";

export default {
  props: [
    "foldersWithDocuments",
    "heyCollabDocuments",
    "setActiveDocumentHandler",
    "activeDocumentId",
    "documentsInsideFolder",
    "documentsOutsideFolder",
    "isSidebarOpen",
    "openDocEditor",
    "currentWorkspaceId",
    "deleteFile",
    "canRemove",
  ],
  components: { DocumentFolder, DocumentListItem },
  data() {
    return {
      folders: [
        {
          id: 1,
          name: "folder 1",
        },
        {
          id: 2,
          name: "folder 2",
        },
        {
          id: 3,
          name: "folder 3",
        },
        {
          id: 4,
          name: "folder 4",
        },
        {
          id: 5,
          name: "folder 5",
        },
      ],
      documents: [
        {
          id: 1,
          title: "Document 1",
          folder_id: 1,
        },
        {
          id: 2,
          title: "Document 1",
        },
        {
          id: 3,
          title: "Document 1",
        },
        {
          id: 4,
          title: "Document 1",
          folder_id: 3,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.fab-btn-container {
  // position: absolute;
  bottom: 20px;
  right: 25px;
  .doc-fab-btn {
    width: 45px;
    height: 45px;
    background: #007aff;
    border-radius: 12px;
    // box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
    border: 3px solid #dbecff;
  }
  .btn-title {
    font-size: 13px;
    font-weight: 600;
  }
}
</style>
