<template>
  <div class="row items-center">
    <!-- Undo-Redo -->
    <div class="q-mr-xl q-sm-mt-sm" v-if="!isFromNote && !isFromTask">
      <button
        :disabled="!editor.can().undo() || isDisabled"
        @click="$emit('textformat', { type: 'undo' })"
        class="q-mr-sm btn-hover"
      >
        <q-icon :name="$icons.mdiUndo" size="20px" />
        <q-tooltip>Undo</q-tooltip>
      </button>
      <button
        :disabled="!editor.can().redo() || isDisabled"
        @click="$emit('textformat', { type: 'redo' })"
        class="btn-hover"
      >
        <q-icon :name="$icons.mdiRedo" size="20px" />
        <q-tooltip>Redo</q-tooltip>
      </button>
    </div>
    <!-- Heading -->
    <div
      class="text-format-block size-format q-mr-md btn-hover q-sm-mt-sm"
      v-if="!isFromNote"
    >
      <button
        :disabled="isDisabled"
        @click="showTooltip = false"
        class="row items-center justift-center"
      >
        <div>{{ getActiveTextFormat }}</div>
        <q-icon :name="$icons.matArrowDropDown" size="18px" />
        <q-tooltip v-if="showTooltip">Text styles</q-tooltip>
      </button>
      <q-menu
        auto-close
        @before-hide="showTooltip = true"
        content-class="size-format-menu text-format-menu"
      >
        <q-item
          :class="[editor.isActive('paragraph') && 'ediotor-format-is-active']"
          clickable
          @click="$emit('textformat', { type: 'paragraph' })"
          >Paragraph</q-item
        >
        <q-item
          :class="[
            editor.isActive('heading', { level: 1 }) &&
              'ediotor-format-is-active',
            'heading-tags h1',
          ]"
          @click="$emit('textformat', { type: 'heading', detail: 1 })"
          clickable
          >Heading 1</q-item
        >
        <q-item
          :class="[
            editor.isActive('heading', { level: 2 }) &&
              'ediotor-format-is-active',
            'heading-tags h2',
          ]"
          @click="$emit('textformat', { type: 'heading', detail: 2 })"
          clickable
          >Heading 2</q-item
        >
        <q-item
          :class="[
            editor.isActive('heading', { level: 3 }) &&
              'ediotor-format-is-active',
            'heading-tags h3',
          ]"
          @click="$emit('textformat', { type: 'heading', detail: 3 })"
          clickable
          >Heading 3</q-item
        >
        <q-item
          :class="[
            editor.isActive('heading', { level: 4 }) &&
              'ediotor-format-is-active',
            'heading-tags h4',
          ]"
          @click="$emit('textformat', { type: 'heading', detail: 4 })"
          clickable
          >Heading 4</q-item
        >
        <q-item
          :class="[
            editor.isActive('heading', { level: 5 }) &&
              'ediotor-format-is-active',
            'heading-tags h5',
          ]"
          @click="$emit('textformat', { type: 'heading', detail: 5 })"
          clickable
          >Heading 5</q-item
        >
        <q-item
          :class="[
            editor.isActive('heading', { level: 6 }) &&
              'ediotor-format-is-active',
            'heading-tags h6',
          ]"
          @click="$emit('textformat', { type: 'heading', detail: 6 })"
          clickable
          >Heading 6</q-item
        >
      </q-menu>
    </div>
    <!-- Text Decoration -->
    <div
      class="text-format-block style-format q-sm-mt-sm"
      style="padding: 0"
      :class="isFromNote ? 'q-mr-xs' : 'q-mr-md'"
    >
      <button
        @click="$emit('textformat', { type: 'bold' })"
        :disabled="isDisabled"
        :class="[
          editor.isActive('bold') && 'bg-primary text-white rounded-borders',
          'btn-hover',
          !isFromNote && 'q-mr-xs',
        ]"
        style="padding: 6px"
      >
        <q-icon :name="$icons.mdiFormatBold" size="18px" />
        <q-tooltip>Bold</q-tooltip>
      </button>
      <button
        @click="$emit('textformat', { type: 'italic' })"
        :disabled="isDisabled"
        :class="[
          editor.isActive('italic')
            ? 'bg-primary text-white rounded-borders'
            : 'btn-hover btn-hover',
          !isFromNote && 'q-mr-xs',
        ]"
        style="padding: 6px"
      >
        <q-icon :name="$icons.mdiFormatItalic" size="18px" />
        <q-tooltip>Italic</q-tooltip>
      </button>
      <button
        v-if="!isFromNote"
        @click="showTooltip = false"
        :disabled="isDisabled"
        class="btn-hover"
        style="padding: 6px"
      >
        <q-icon :name="$icons.mdiDotsHorizontal" size="18px" />
        <q-tooltip v-if="showTooltip">More formatting</q-tooltip>
        <q-menu
          content-class="text-format-menu "
          @before-hide="showTooltip = true"
        >
          <q-item
            @click="$emit('textformat', { type: 'underline' })"
            clickable
            :class="[
              editor.isActive('underline') && 'ediotor-format-is-active',
              'items-center',
            ]"
            ><q-icon :name="$icons.mdiFormatUnderline" class="q-mr-sm" />
            Underline</q-item
          >
          <q-item
            @click="$emit('textformat', { type: 'strike' })"
            :class="['items-center']"
            clickable
          >
            <q-icon :name="$icons.mdiFormatStrikethrough" class="q-mr-sm" />
            Strikethrough
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'subscript' })"
            :class="['items-center']"
            clickable
          >
            <q-icon :name="$icons.mdiFormatSubscript" class="q-mr-sm" />
            Subscript
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'superscript' })"
            :class="['items-center']"
            clickable
          >
            <q-icon :name="$icons.mdiFormatSuperscript" class="q-mr-sm" />
            Superscript
          </q-item>
        </q-menu>
      </button>
    </div>
    <!-- Text Alignment -->
    <div
      :class="isFromNote ? 'q-mr-xs' : 'q-mr-md'"
      class="text-format-block btn-hover q-sm-mt-sm"
    >
      <button
        :disabled="isDisabled"
        @click="showTooltip = false"
        class="row items-center justift-center"
      >
        <q-icon :name="$icons.mdiFormatAlignLeft" size="21px" />
        <q-icon :name="$icons.matArrowDropDown" size="18px" />
        <q-tooltip v-if="showTooltip">Alignment</q-tooltip>
        <q-menu
          @before-hide="showTooltip = true"
          content-class="text-format-menu"
        >
          <div class="row">
            <q-item
              @click="
                $emit('textformat', { type: 'textalign', detail: 'left' })
              "
              :class="[
                editor.isActive({ textAlign: 'left' }) &&
                  'ediotor-format-is-active',
                'q-px-sm',
              ]"
              clickable
            >
              <q-icon :name="$icons.mdiFormatAlignLeft" />
            </q-item>
            <q-item
              @click="
                $emit('textformat', { type: 'textalign', detail: 'center' })
              "
              :class="[
                editor.isActive({ textAlign: 'center' }) &&
                  'ediotor-format-is-active',
                'q-px-sm',
              ]"
              clickable
            >
              <q-icon :name="$icons.mdiFormatAlignCenter" />
            </q-item>
            <q-item
              @click="
                $emit('textformat', { type: 'textalign', detail: 'right' })
              "
              :class="[
                editor.isActive({ textAlign: 'right' }) &&
                  'ediotor-format-is-active',
                'q-px-sm',
              ]"
              clickable
            >
              <q-icon :name="$icons.mdiFormatAlignRight" />
            </q-item>
          </div>
        </q-menu>
      </button>
    </div>
    <!-- Text color formatting -->
    <div
      class="text-format-block color-format row items-center justify-center q-sm-mt-sm"
      :class="isFromNote ? 'q-mr-xs' : 'q-mr-sm'"
    >
      <button @click="showTooltip = false" :disabled="isDisabled">
        <q-tooltip v-if="showTooltip">Text color</q-tooltip>
        <div class="outer-circle row items-center justify-center">
          <div
            class="inner-circle row items-center justify-center"
            :style="{
              'background-color': editor.getAttributes('textStyle').color
                ? editor.getAttributes('textStyle').color
                : '#42526E',
            }"
          >
            <q-icon
              :name="$icons.matKeyboardArrowDown"
              color="white"
              size="18px"
            />
          </div>
        </div>
        <q-menu
          max-height="auto"
          content-class="document-editor-swatch-container row q-py-sm q-px-md"
          :offset="[0, 10]"
          @before-hide="showTooltip = true"
        >
          <button
            v-for="data in swatchColorOptions"
            :key="'swathc-' + data.id"
            class="'row flex-no-wrap relative-position"
            @click="
              $emit('textformat', { type: 'color', detail: data.colorcode })
            "
          >
            <div
              :class="[
                data.isWhite && 'white-box',
                'swatch-box',
                isSelectedColor(data.colorcode)
                  ? 'selected'
                  : isDefaultColorSelected(data)
                  ? 'selected'
                  : 'not-selected',
              ]"
              :style="{
                'background-color': data.colorcode,
              }"
            ></div>
            <q-icon
              v-if="
                isSelectedColor(data.colorcode) || isDefaultColorSelected(data)
              "
              :name="$icons.fasCheck"
              color="white"
              size="15px"
              class="swatch-selected-check"
            />
          </button>
        </q-menu>
      </button>
    </div>
    <!-- Bullet -->
    <div
      :class="isFromNote ? 'q-mr-xs' : 'q-mr-md'"
      class="text-format-block btn-hover q-sm-mt-sm"
    >
      <button
        @click="showTooltip = false"
        :disabled="isDisabled"
        class="row items-center justift-center"
      >
        <q-tooltip v-if="showTooltip">List</q-tooltip>
        <q-icon :name="$icons.mdiFormatListBulleted" size="21px" />
        <q-icon :name="$icons.matArrowDropDown" size="18px" />
        <q-menu
          @before-hide="showTooltip = true"
          content-class="text-format-menu"
        >
          <q-list>
            <q-item
              @click="
                $emit('textformat', { type: 'bullet', detail: 'inorder' })
              "
              clickable
              :class="[
                editor.isActive('bulletList') && 'ediotor-format-is-active',
                'items-center',
              ]"
              ><q-icon :name="$icons.mdiFormatListBulleted" class="q-mr-sm" />
              Bullet list</q-item
            >
            <q-item
              @click="
                $emit('textformat', { type: 'bullet', detail: 'ordered' })
              "
              clickable
              :class="[
                editor.isActive('orderedList') && 'ediotor-format-is-active',
                'items-center',
              ]"
              ><q-icon :name="$icons.mdiFormatListNumbered" class="q-mr-sm" />
              Numbered list</q-item
            >
          </q-list>
        </q-menu>
      </button>
    </div>
    <!-- tasklist and hyperlink -->
    <div
      v-if="!isFromAbout"
      :class="isFromNote ? 'q-mr-xs' : 'q-mr-md'"
      class="text-format-block q-sm-mt-sm"
      style="padding: 0"
    >
      <button
        @click="$emit('textformat', { type: 'task' })"
        :disabled="isDisabled"
        class="q-mr-xs btn-hover"
        style="padding: 6px"
      >
        <q-icon :name="$icons.mdiCheckboxMarkedOutline" size="18px" />
        <q-tooltip>Tasklist</q-tooltip>
      </button>
      <button
        @click="$emit('textformat', { type: 'hyperlink' })"
        :disabled="isDisabled"
        class="q-mr-xs btn-hover"
        style="padding: 6px"
      >
        <q-icon :name="$icons.mdiLinkVariant" size="18px" />
        <q-tooltip>Hyperlink</q-tooltip>
      </button>
      <button
        :disabled="isDisabled"
        class="q-mr-xs btn-hover"
        style="padding: 6px"
        v-if="!isFromNote"
      >
        <q-icon :name="$icons.mdiAttachment" size="18px" />
        <q-tooltip>Add image</q-tooltip>
        <q-menu content-class="overflow-auto" auto-close>
          <q-list>
            <q-item
              @click="$emit('textformat', { type: 'addimagefromcomputer' })"
              clickable
            >
              <q-item-section style="min-width: 35px" class="q-pr-none" avatar>
                <q-icon
                  :name="$icons.matComputer"
                  size="24px"
                  style="color: #000000"
                />
              </q-item-section>
              <q-item-section> Your Computer </q-item-section>
            </q-item>
            <q-item
              @click="$emit('textformat', { type: 'addimagefromsharedfiles' })"
              clickable
            >
              <q-item-section style="min-width: 35px" class="q-pr-none" avatar>
                <q-icon
                  :name="$icons.fasCopy"
                  size="24px"
                  style="color: #000000"
                />
              </q-item-section>
              <q-item-section> Shared Files </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </button>
    </div>
    <!-- Table  -->
    <div
      class="text-format-block q-sm-mt-sm q-mr-md"
      style="padding: 0"
      v-if="!isFromNote"
    >
      <button
        @click="$emit('textformat', { type: 'table' })"
        :disabled="isDisabled"
        class="btn-hover"
        style="padding: 6px"
      >
        <q-icon :name="$icons.mdiTable" size="18px" />
        <q-tooltip>Table</q-tooltip>
      </button>
      <button
        :disabled="isDisabled || !editor.isActive('table')"
        class="btn-hover"
        style="padding: 6px"
        @click="showTooltip = false"
      >
        <q-icon :name="$icons.mdiDotsHorizontal" size="18px" />
        <q-tooltip v-if="showTooltip">Table options</q-tooltip>
        <q-menu
          @before-hide="showTooltip = true"
          content-class="text-format-menu "
        >
          <q-item
            @click="$emit('textformat', { type: 'addcolbefore' })"
            clickable
            :class="['items-center']"
          >
            Add column before</q-item
          >
          <q-item
            @click="$emit('textformat', { type: 'addcolafter' })"
            :class="['items-center']"
            clickable
          >
            Add column after
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'addrowbefore' })"
            :class="['items-center']"
            clickable
          >
            Add row before
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'addrowafter' })"
            :class="['items-center']"
            clickable
          >
            Add row after
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'mergecell' })"
            :class="['items-center']"
            clickable
            :disabled="!editor.can().mergeCells()"
          >
            Merge cell
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'splitcell' })"
            :class="['items-center']"
            clickable
            :disabled="!editor.can().splitCell()"
          >
            Split cell
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'deleterow' })"
            :class="['items-center']"
            clickable
            class="text-pink"
          >
            Delete row
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'deletecol' })"
            :class="['items-center']"
            clickable
            class="text-pink"
          >
            Delete column
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'deletetable' })"
            :class="['items-center']"
            clickable
            class="text-pink"
          >
            Delete table
          </q-item>
        </q-menu>
      </button>
    </div>
    <!-- code,quote,codeblock,hr -->
    <div v-if="!isFromAbout" class="text-format-block btn-hover q-sm-mt-sm">
      <button @click="showTooltip = false" :disabled="isDisabled">
        <q-tooltip v-if="showTooltip">Insert</q-tooltip>
        <q-icon :name="$icons.mdiPlus" size="18px" />
        <q-menu
          @before-hide="showTooltip = true"
          content-class="text-format-menu"
        >
          <q-item
            @click="$emit('textformat', { type: 'togglelist' })"
            clickable
            :class="['items-center']"
          >
            <q-icon :name="$icons.matPlayArrow" class="q-mr-sm" />
            Toggle list
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'quote' })"
            clickable
            :class="[
              editor.isActive('blockquote') && 'ediotor-format-is-active',
              'items-center',
            ]"
            ><q-icon :name="$icons.mdiFormatQuoteClose" class="q-mr-sm" />
            Quote</q-item
          >
          <q-item
            @click="$emit('textformat', { type: 'code' })"
            :class="['items-center']"
            clickable
          >
            <q-icon :name="$icons.mdiCodeTags" class="q-mr-sm" />
            Code
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'codeblock' })"
            :class="['items-center']"
            clickable
          >
            <q-icon :name="$icons.mdiCodeNotEqualVariant" class="q-mr-sm" />
            Code block
          </q-item>
          <q-item
            @click="$emit('textformat', { type: 'hr' })"
            clickable
            :class="['items-center']"
          >
            <q-icon :name="$icons.mdiBorderHorizontal" class="q-mr-sm" />
            Horizontal rule
          </q-item>
        </q-menu>
      </button>
    </div>
    <div
      v-if="showButton"
      class="text-format-block btn-hover q-sm-mt-sm q-ml-md"
    >
      <button @click="toggleResize">
        <!-- {{ isDraggable ? "Dragging" : "Resizing" }} -->
        <q-icon v-if="isDraggable" :name="$icons.mdiArrowExpand" size="18px" />
        <q-icon v-else :name="$icons.matDragHandle" size="18px" />
        <q-tooltip>
          {{ isDraggable ? "Resize" : "Drag" }}
        </q-tooltip>
      </button>
    </div>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import { swatchColors } from "@/utils/Contents.js";

export default {
  props: {
    editor: {
      type: Object,
      default: () => null,
    },
    isDisabled: Boolean,
    isFromNote: Boolean,
    isFromAbout: Boolean,
    isFromTask: Boolean,
    showButton: Boolean,
    toggleResize: Function,
    isDraggable: Boolean,
  },
  data() {
    return {
      showTooltip: true,
    };
  },
  methods: {
    isSelectedColor(color) {
      return this.editor.isActive("textStyle", { color });
    },
    isDefaultColorSelected(data) {
      return !this.editor.getAttributes("textStyle").color && data.default;
    },
  },
  computed: {
    swatchColorOptions() {
      return swatchColors;
    },
    getActiveTextFormat() {
      let text = "";
      let heading = this.editor.getAttributes("heading");
      if (!isEmpty(heading)) {
        let { level } = heading;
        if (level === 1) {
          text = "H1";
        } else if (level === 2) {
          text = "H2";
        } else if (level === 3) {
          text = "H3";
        } else if (level === 4) {
          text = "H4";
        } else if (level === 5) {
          text = "H5";
        } else if (level === 6) {
          text = "H6";
        }
      } else {
        text = "Normal";
      }
      return text;
    },
  },
};
</script>
