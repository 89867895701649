<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    :value="value"
    full-width
    full-height
    persistent
    content-class="fullscreen-video-dialog"
  >
    <div class="shadow-0">
      <div class="text-right" style="z-index: 10000;position: relative;">
        <q-btn
          round
          flat
          color="primary"
          text-color="white"
          :icon="$icons.matClose"
          size="md"
          style="background: #3c3c3d;font-size: 10px;top: 10px;right: 5px;"
          class="absolute-top-right"
          @click="resetVideoDialog"
        >
          <q-tooltip>
            Close
          </q-tooltip>
        </q-btn>
      </div>
      <iframe
        v-if="videoMediaData.isVimeo"
        :src="videoMediaData.file"
        style="height:85vh;width:100%;background: #000;"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
      <video-player-component
        v-else-if="!videoMediaData.isVimeo && displayPlayer"
        :videoMediaData="videoMediaData"
      ></video-player-component>
    </div>
  </q-dialog>
</template>
<script>
import VideoPlayerComponent from "@/components/Miscellaneous/VideoPlayerComponent";
export default {
  name: "VideoDialog",
  props: ["value", "resetVideoDialog", "videoMediaData", "displayPlayer"],
  components: {
    VideoPlayerComponent,
  },
};
</script>
<style scoped>
/* .q-dialog__inner--fullwidth > div {
  display: flex;
  align-items: center;
} */

/* @media (min-width: 1120px) {
  .q-dialog__inner--fullwidth > div {
      max-width: 90% !important;
  }  
}
@media (min-width: 1220px) {
  .q-dialog__inner--fullwidth > div {
      max-width: 80% !important;
  }  
}
@media (min-width: 1350px) {
  .q-dialog__inner--fullwidth > div {
      max-width: 70% !important;
  }  
}
@media (min-width: 1950px) {
  .q-dialog__inner--fullwidth > div {
      max-width: 80% !important;
  }  
}
@media (min-width: 2150px) {
  .q-dialog__inner--fullwidth > div {
      max-width: 70% !important;
  }
} */
</style>