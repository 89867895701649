<template>
  <q-card class="document-editor-container q-mx-md q-pa-md">
    <TaskDescriptionEditor
      :doc="doc"
      :docDetails="documentProp && documentProp.details"
      :updateDocStateOnClose="updateDocStateOnClose"
      :isMobileView="isMobileView"
      class="document-editor overflow-hidden"
      @updateDescription="updateDescription"
      style="height: 100%;"
      :currentWorkspaceId="currentWorkspaceId"

    />
  </q-card>
</template>

<script>
import * as Y from "yjs";
import debounce from "lodash/debounce";

import TaskDescriptionEditor from "@/components/DocumentEditor/TaskDescriptionEditor";
// import { UpdateWorkspaceMutation } from "@/gql";

export default {
  components: { TaskDescriptionEditor },
  props: {
    documentProp: {
      type: Object,
      default: null,
    },
    workspace: {
      type: Object,
      default: null,
    },
    closeDocEditor: Function,
  },
  data() {
    return {
      version: null,
      isMobileView: false,
    };
  },
  created() {
    this.doc = new Y.Doc();
    // this.debouncedPush = debounce(this.push, 1000);
    // this.throttledPull = throttle(this.pull, 2000);

    // this.doc.on("update", this.push);
    window.addEventListener("resize", this.handleResize);

    // this.throttledPull();

    // this.interval = setInterval(() => {
    //   this.throttledPull();
    // }, 2000);
  },
  mounted() {
    // this.updateDocumentContent();
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    updateDescription(data) {
      const vm = this;
      let debounce_fun = debounce(function () {
        vm.push(data);
      }, 1000);
      debounce_fun();
    },
    handleResize() {
      if (window.innerWidth < 775) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    },
    async push(data) {
      try {
        // const state = Y.encodeStateAsUpdate(this.doc);
        this.updateDoc(data);
      } catch {
        await this.throttledPull();
        await this.debouncedPush();
      }
    },
    async pull() {
        let { details } = this.document;
        if (details) {
          Y.applyUpdate(this.doc, details);
        }
    },
    updateDocStateOnClose() {
      console.log("updateDocStateOnClose");
        this.debouncedPush.cancel();
    },
    async updateDoc(state) {
      try {
        this.$emit("addDescription", state);
        } catch (error) {
        console.error("er", error);
      }
    },
  },
  computed: {
    document() {
      return this.documentProp;
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
  },
};
</script>
<style scoped>
.document-editor-container {
  border: 1px solid #e3e8ed;
  border-radius: 5px !important;
  box-shadow: none;
}
</style>
