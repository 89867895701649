<template>
  <div class="q-mr-sm row flex-x justify-center">
    <q-btn
      flat
      dense
      :icon="$icons.fasPaperclip"
      round
      size="12px"
      :class="[isMessagingEditor ? 'q-ml-sm' : 'q-ml-xs']"
      :style="isMessagingEditor ? { 'margin-top': '2px' } : {}"
      class="add-media-icon media-attech-btn"
      @click="showTooltip = false;"
    >
      <q-tooltip v-if="showTooltip">Attach file</q-tooltip>
      <q-menu
        @before-hide="showTooltip = true;"
        content-class="overflow-auto"
        :offset="[0, isVideoButtonEnable ? -240 : -200]"
        auto-close
      >
        <q-list>
          <q-item @click="$emit('fromComputer')" clickable>
            <q-item-section style="min-width:35px" class="q-pr-none" avatar>
              <q-icon
                :name="$icons.matComputer"
                size="24px"
                style="color: #000000"
              />
            </q-item-section>
            <q-item-section>
              Your Computer
            </q-item-section>
          </q-item>
          <q-item @click="$emit('openSharedFile')" clickable>
            <q-item-section style="min-width:35px" class="q-pr-none" avatar>
              <q-icon
                :name="$icons.fasCopy"
                size="24px"
                style="color: #000000"
              />
            </q-item-section>
            <q-item-section>
              Shared Files
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item @click="$emit('dropBox')" clickable>
            <q-item-section style="min-width:35px" class="q-pr-none" avatar>
              <q-icon
                :name="$icons.fabDropbox"
                size="24px"
                style="color: #000000"
              />
            </q-item-section>
            <q-item-section>
              Dropbox
            </q-item-section>
          </q-item>
          <q-item @click="$emit('googleDrive')" clickable>
            <q-item-section style="min-width:35px" class="q-pr-none" avatar>
              <q-icon
                :name="$icons.fabGoogleDrive"
                size="24px"
                style="color: #000000"
              />
            </q-item-section>
            <q-item-section>
              Google Drive
            </q-item-section>
          </q-item>
          <q-item
            v-if="isVideoButtonEnable"
            @click="$emit('meetCall')"
            clickable
          >
            <q-item-section style="min-width:35px" class="q-pr-none" avatar>
              <q-icon
                :name="$icons.matVideoCall"
                size="24px"
                style="color: #000000"
              />
            </q-item-section>
            <q-item-section>
              Call link
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
    <input type="file" hidden id="fileInput" @change="onFileUpload" multiple />
  </div>
</template>
<script>
export default {
  name: "AddMediaPlus",
  props: ["isMessagingEditor", "isVideoButtonEnable"],
  data() {
    return {
      showTooltip:true,
    }
  },  
  methods: {
    openFileDialog() {
      const fileInput = document.getElementById("fileInput");
      fileInput.click();
    },
    onFileUpload(event) {
      this.$emit("filesFromComputer", event.target.files);
    },
  },
};
</script>
