<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
    @keydown.esc="updateDocStateOnClose()"
    maximized
  >
    <q-card
      class="document-editor-container overflow-hidden hide-expansion-hover hide-expansion-arrow"
    >
      <div class="document-editor-topbar row items-center q-pr-sm q-pl-md">
        <div v-if="!hideDocumentSidebar">
          <q-icon
            :name="$icons.mdiDockLeft"
            style="color: #cce4ff"
            size="20px"
            class="cursor-pointer"
            @click="toggleSidebar"
          >
            <q-tooltip>Toggle sidebar</q-tooltip>
          </q-icon>
        </div>
        <div
          class="row flex-no-wrap flex-1 items-center justify-end full-height"
        >
          <document-help-menu
            :viewDocShortcuts="viewDocShortcuts"
            :playVideo="playVideo"
          />
          <q-btn
            :icon="$icons.matOpenInNew"
            padding="0px"
            class="q-mr-sm"
            flat
            size="10px"
            style="color: #cce4ff"
          >
            <q-menu
              content-class="overflow-auto"
              :offset="[0, 5]"
              v-model="menu"
              auto-close
            >
              <q-list>
                <q-item
                  @click="$eventBus.$emit('exportDocumentHandler', 'doc')"
                  clickable
                >
                  <q-item-section> Doc </q-item-section>
                </q-item>
                <q-item
                  @click="$eventBus.$emit('exportDocumentHandler', 'pdf')"
                  clickable
                >
                  <q-item-section> PDF </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <q-icon
            @click="updateDocStateOnClose()"
            class="close-doc-editor cursor-pointer"
            :name="$icons.matClose"
          />
        </div>
      </div>
      <div class="row" style="max-height: 94vh; height: 100%; width: 100%">
        <document-editor-sidebar
          v-if="!hideDocumentSidebar"
          :class="[
            isSidebarOpen ? 'open-sidebar' : 'mini-sidebar',
            'document-editor-sidebar',
          ]"
          :isSidebarOpen="isSidebarOpen"
          :foldersWithDocuments="foldersWithDocuments"
          :setActiveDocumentHandler="setActiveDocumentHandler"
          :activeDocumentId="activeDocumentId"
          :documentsInsideFolder="documentsInsideFolder"
          :documentsOutsideFolder="documentsOutsideFolder"
          :openDocEditor="openDocEditor"
          :currentWorkspaceId="currentWorkspace"
          :deleteFile="deleteFile"
          :canRemove="canRemove"
          @renameFile="openRenameFileDialog"
        />
        <DocumentEditor
          :key="doc.guid"
          :doc="doc"
          :title="title"
          @updateTitle="updateTitle"
          @updateDocumentHtml="updateDocumentHtml"
          :isMobileView="isMobileView"
          :currentWorkspaceId="currentWorkspace"
          :isReadOnly="activeDocument.is_read_only"
          :currentUser="user"
          :docUserId="activeDocument.user_id"
          :editable="
            !activeDocument.is_read_only ||
            (activeDocument.is_read_only &&
              user &&
              user.id == activeDocument.user_id)
          "
          class="document-editor overflow-hidden flex-1"
        />
      </div>
    </q-card>
    <document-shortcuts-dialog
      v-model="isDocShortcutDialogOpen"
      @close="isDocShortcutDialogOpen = false"
    />
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <confirm-dialog
      v-if="confirm"
      v-model="confirm"
      title="Delete File?"
      question="Are you sure you want to delete this file?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => (confirm = false)"
      :successMethod="removeDocumentHandler"
      :loading="deletingDocument"
    />
    <update-file-dialog
      v-if="renameFileDialog.flag"
      v-model="renameFileDialog.flag"
      :renameFile="renameFileDetails"
      :cancelMethod="cancelRenameFile"
      @success="renameSuccessHandler"
    />
  </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import * as Y from "yjs";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import groupBy from "lodash/groupBy";
import omitBy from "lodash/omitBy";
import orderBy from "lodash/orderBy";
import UserScope from "@/mixins/UserScope";
import DocumentEditor from "@/components/DocumentEditor/DocumentEditor";
import DocumentEditorSidebar from "../DocumentEditor/DocumentEditorSidebar.vue";
import DocumentHelpMenu from "@/components/DocumentEditor/DocumentHelpMenu";
import DocumentShortcutsDialog from "@/components/DocumentEditor/DocumentShortcutsDialog";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import UpdateFileDialog from "@/components/Dialogs/UpdateFileDialog";

import {
  UpdateDocumentMutation,
  GetBulkMediaDetailMutation,
  DeleteMediaMutation,
  DocumentsQuery,
} from "@/gql";

export default {
  mixins: [UserScope],
  components: {
    DocumentEditor,
    DocumentEditorSidebar,
    DocumentHelpMenu,
    DocumentShortcutsDialog,
    VideoDialog,
    ConfirmDialog,
    UpdateFileDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    documentProp: {
      type: Object,
      default: null,
    },
    activeDocumentId: Number,
    closeDocEditor: Function,
    openDocEditor: Function,
    setActiveDocument: Function,
    hideDocumentSidebar: {
      type: Boolean,
      default: false,
    },
  },
  api: {
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.currentWorkspace}`;
      },
    },
    document: {
      query: DocumentsQuery,
      variables() {
        return {
          workspace_id: this.currentWorkspace,
        };
      },
      defaultValue: [],
      cacheKey() {
        return `DocumentsQuery:${this.currentWorkspace}`;
      },
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      deletingDocument: false,
      title: null,
      docHtml: null,
      version: null,
      isMobileView: false,
      isDocShortcutDialogOpen: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      menu: false,
      confirm: false,
      deleteFileId: null,
      renameFileDetails: null,
      renameFileDialog: {
        flag: false,
      },
      prevDocId: null,
    };
  },
  created() {
    this.doc = new Y.Doc();
    this.debouncedPush = debounce(this.push, 1000);
    this.throttledPull = throttle(this.pull, 2000);
    this.debounceUpdateTitle = debounce(this.updateTitleHandler, 1000);

    this.doc.on("update", this.debouncedPush);
    window.addEventListener("resize", this.handleResize);

    this.throttledPull();
    this.interval = setInterval(() => {
      this.throttledPull();
    }, 2000);
  },
  mounted() {
    this.title = this.documentProp.filename;
    this.prevDocId = this.activeDocumentId;
    const version = this.documentProp.version ? this.documentProp.version : 0;
    let { document_state } = this.documentProp;
    this.applyUpdateToDoc(document_state, version);
    this.handleResize();
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    renameSuccessHandler(updatedTitle) {
      if (this.renameFileDetails.id === this.activeDocument.id) {
        this.title = updatedTitle;
      }
    },
    openRenameFileDialog(media) {
      this.renameFileDetails = media;
      this.renameFileDialog.flag = true;
    },
    cancelRenameFile() {
      this.renameFileDetails = null;
      this.renameFileDialog.flag = false;
    },
    toggleSidebar() {
      this.$store.dispatch("toggleSidebarState", {
        documentId: this.activeDocument.id,
      });
    },
    handleResize() {
      if (window.innerWidth < 775) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    },
    //Update document content on 1sec pause of keystrokes when user is typing
    async push() {
      try {
        const state = Y.encodeStateAsUpdate(this.doc);
        this.updateDoc(this.title, state, false);
      } catch {
        await this.throttledPull();
        await this.debouncedPush();
      }
    },
    //Fetch document content every 2sec
    async pull() {
      const response = await this.$api.mutate({
        mutation: GetBulkMediaDetailMutation,
        variables: {
          ids: [this.activeDocument.id],
        },
      });
      if (response && response.data.getBulkMediaDetail[0]) {
        const version = response.data.getBulkMediaDetail[0].document_version;
        let { document_state } = response.data.getBulkMediaDetail[0];
        this.applyUpdateToDoc(document_state, version);
      }
    },
    applyUpdateToDoc(documentState, version) {
      this.version = version;
      const state = documentState
        ? Uint8Array.from(documentState.split(",").map((x) => parseInt(x, 10)))
        : "";
      if (state) {
        Y.applyUpdate(this.doc, state);
      }
    },
    updateTitle(title) {
      this.title = title;
      this.debounceUpdateTitle(title);
    },
    updateDocumentHtml(html) {
      this.docHtml = html;
    },
    async updateTitleHandler(title) {
      const state = this.activeDocument.document_state
        ? Uint8Array.from(
            this.activeDocument.document_state
              .split(",")
              .map((x) => parseInt(x, 10))
          )
        : "";
      if (this.title) {
        this.updateDoc(title, state);
      }
    },
    updateDocStateOnClose() {
      if (this.title || this.activeDocument.document_state) {
        const state = Y.encodeStateAsUpdate(this.doc);
        this.debouncedPush.cancel();
        this.updateDoc(this.title, state, true);
      } else {
        this.deleteDoc();
      }
    },
    async deleteDoc() {
      const variables = {
        id: this.activeDocument.id,
      };
      await this.$api.mutate({
        mutation: DeleteMediaMutation,
        variables,
      });
      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
      const mediaFolderQuery = this.$api.getQuery(
        `MediaFolderQuery:${this.activeDocument.media_folder_id}`
      );
      query.data.newMedia = query.data.newMedia.filter(
        (f) => f.id !== this.activeDocument.id
      );
      //remove from folder cache
      if (this.activeDocument.media_folder_id) {
        mediaFolderQuery.data.mediaFolder.media =
          mediaFolderQuery.data.mediaFolder.media.filter(
            (f) => f.id !== this.activeDocument.id
          );
      }
      this.closeDocEditor();
    },
    async updateDoc(title, state, fromClose = false) {
      try {
        await this.$api.mutate({
          mutation: UpdateDocumentMutation,
          variables: {
            id: this.activeDocument.id,
            title: title || "",
            document_state: state ? state.toString() : "",
            document_version: this.version,
            document_content: this.docHtml,
          },
        });
        if (fromClose) {
          this.closeDocEditor();
          this.$q.notify({
            classes: "success-notification",
            position: "top-right",
            message: "Document saved in files.",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      } catch (error) {
        console.error(
          "ERROR:updateDoc method in DocumentEditorDialog Component",
          error
        );
      }
    },
    viewDocShortcuts() {
      this.isDocShortcutDialogOpen = true;
    },
    playVideo() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/766700215",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },

    setActiveDocumentHandler(documentId) {
      this.throttledPull.cancel();
      this.setActiveDocument(documentId);
    },
    openDocEditorHandler(workspaceId) {
      this.openDocEditor(workspaceId);
    },
    deleteFile(mediaId) {
      this.confirm = true;
      this.deleteFileId = mediaId;
    },
    removeDocumentHandler() {
      this.deletingDocument = true;
      this.removeDocument(this.deleteFileId);
    },
    async removeDocument(id) {
      const variables = {
        id,
      };
      await this.$api.mutate({
        mutation: DeleteMediaMutation,
        variables,
      });
      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
      const documentQuery = this.$api.getQuery(
        `DocumentsQuery:${this.currentWorkspace}`
      );
      query.data.newMedia = query.data.newMedia.filter((f) => f.id !== id);
      documentQuery.data.document = documentQuery.data.document.filter(
        (f) => f.id !== id
      );
      if(documentQuery.data.document){
        this.setActiveDocument(documentQuery.data.document[0].id);
      }
      
      this.confirm = false;
      this.deletingDocument = false;
      // this.closeDocEditor();
    },
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: "isSidebarOpen",
    }),
    canRemove() {
      return this.canRemoveMessageFromWorkspace || this.isWorkspaceOwner;
    },
    isSidebarOpen() {
      return this.$store.getters.isSidebarOpen(this.activeDocument.id);
    },
    activeDocument() {
      if (this.activeDocumentId) {
        return this.$api.getEntity("media", this.activeDocumentId);
      }
      return this.$api.getEntity("media", this.documentProp.id);
    },
    userWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspace))
      );
    },
    isWorkspaceOwner() {
      if (this.userWorkspace && this.user) {
        return this.user.id === this.userWorkspace.owner.id;
      }
      return false;
    },
    currentWorkspace() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    foldersWithDocuments() {
      const folders = this.mediaFolders.filter(
        (f) => f.media && f.media.some((m) => m.is_document_section)
      );
      return folders;
    },
    heyCollabDocuments() {
      return this.document;
    },
    documentsInsideFolder() {
      return omitBy(
        groupBy(this.heyCollabDocuments, (document) => {
          if (document.media_folder_id) {
            return document.media_folder_id;
          }
        }),
        (val, key) => {
          if (key === "undefined") return true;
        }
      );
    },
    documentsOutsideFolder() {
      return orderBy(
        this.heyCollabDocuments.filter((document) => !document.media_folder_id),
        ["id"],
        ["asc"]
      );
    },
    folderList() {
      if (this.mediaFolders) {
        return uniqBy(orderBy(this.mediaFolders, ["title"], ["asc"]), "id");
      } else {
        return [];
      }
    },
  },
  watch: {
    activeDocument(document) {
      if (this.prevDocId != document.id) {
        this.prevDocId = document.id;
        this.doc = new Y.Doc();
        this.doc.on("update", this.debouncedPush);
        const version = document.document_version;
        let { document_state } = document;
        this.title = document.filename;
        this.applyUpdateToDoc(document_state, version);
      }
    },
  },
};
</script>
