<template>
  <div
    class="relative-position edtor-media-upload-attachment q-mb-sm row flex-no-wrap items-center group-hover-media cursor-pointer noselect"
    :class="[isDropbox || isGoogleDrive]"
    :style="maxWidth && { 'max-width': maxWidth + 'px' }"
    @click="selectViewMode(media)"
  >
    <q-tooltip v-if="isTruncated">
      {{ media.filename }}
    </q-tooltip>
    <div class="relative-position" style="height:40px">
      <img
        v-if="isVideo"
        :style="{ 'object-fit': 'contain' }"
        class="attachment-image"
        :height="height ? height : 133"
        :width="height ? height : 133"
      />
      <img
        v-else
        class="border-radius5"
        :src="imgSrc"
        :height="height ? height : 133"
        :width="height ? height : 133"
      />
      <div
        class="absolute media-comments-badge-task-attachment"
        v-if="media.type.match(/image.*/) && media.commentPoint.length > 0"
        :style="
          media.commentPoint.length == completedMediaPoints.length
            ? `background: #15d89a`
            : ''
        "
      >
        <span>
          {{ completedMediaPoints.length + "/" + media.commentPoint.length }}
        </span>
      </div>
    </div>
    <div class="file-name" v-tooltip>
      {{ media.filename }}
    </div>
    <div
      v-if="isVideo && !isDropbox"
      @click.stop="videoPlayer(media)"
      clickable
      class="row justify-center items-center absolute attachment-video"
      :style="
        height
          ? { height: height + 'px', width: height + 'px' }
          : { height: '133px', width: '133px' }
      "
    >
      <div class="text-center flex-1">
        <q-btn
          :icon="$icons.mdiFileVideoOutline"
          style="color: #FF001F;"                      
          size="16px"
          round
          dense
          flat
        />
      </div>
    </div>
    <div
      class="media-remove cursor-pointer group-hover-media-item"
      v-if="!isReplyCommentMedia"
      @click.stop="removeMedia(media)"
    >
      <q-icon :name="$icons.matClose" class="close-icon" />
    </div>
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <audio-dialog
      v-model="showAudioModel"
      v-if="showAudioModel"
      :resetAudioDialog="resetAudioDialog"
      :audioMediaData="audioMediaData"
      :displayAudioPlayer="displayAudioPlayer"
    />
    <pdf-view-dialog
      :fileExt="pdfFileExt"
      v-if="showpdfModel"
      v-model="showpdfModel"
      @resetPdfDialog="resetPdfDialog"
      :pdfMediaData="pdfMediaData"
    />
  </div>
</template>

<script>
import FileExtensionMixin from "@/mixins/FileExtensionMixin";

import VideoDialog from "@/components/VuePlayer/VideoDialog";
import AudioDialog from "@/components/Dialogs/AudioPlayerDialog";
import PdfViewDialog from "@/components/Dialogs/PdfViewDialog";
export default {
  name: "Media",
  mixins: [FileExtensionMixin],
  props: [
    "media",
    "removeMedia",
    "showProgress",
    "fullview",
    "height",
    "maxWidth",
    "isReplyCommentMedia",
    "editDoc",
  ],
  components: {
    VideoDialog,
    AudioDialog,
    PdfViewDialog,
  },
  data() {
    return {
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
      showAudioModel: false,
      displayAudioPlayer: false,
      audioMediaData: null,
      pdfMediaData: null,
      showpdfModel: false,
      isTruncated: false,
    };
  },
  methods: {
    selectViewMode(media) {
      if (this.isImg) {
        this.fullview({ media: media, newpoint: "no" });
      } else if (
        !this.isImg &&
        !this.isVideo &&
        !this.isAudio &&
        !this.isZip &&
        this.isS3
      ) {
        this.pdfView(media);
      } else if (this.isVideo && this.isS3) {
        this.videoPlayer(media);
      } else if (this.isAudio) {
        this.audioPlayer(media);
      } else if (this.media.is_document_section) {
        this.editDoc(this.media);
      } else {
        const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
        if (isElectron) window.electron.openExternal(media.file);
        else window.open(media.file, "_blank");
      }
    },
    videoPlayer(media) {
      if (!this.isReplyCommentMedia) {
        this.videoMediaData = media;
        this.displayPlayer = true;
        this.showVideoModel = true;
      }
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    audioPlayer(media) {
      this.audioMediaData = media;
      this.displayAudioPlayer = true;
      this.showAudioModel = true;
    },
    resetAudioDialog() {
      this.displayAudioPlayer = false;
      setTimeout(() => {
        this.showAudioModel = false;
      }, 10);
    },
    pdfView(media) {
      // this.pdfMediaData = media;
      // this.showpdfModel = true;
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) window.electron.openExternal(media.file);
      else window.open(media.file, "_blank");
    },
    resetPdfDialog() {
      this.pdfMediaData = null;
      this.showpdfModel = false;
    },
  },
  computed: {
    mediaPreview() {
      return this.transformMedia(this.media);
    },
    imgSrc() {
      if (this.isGoogleDrive) {
        return (
          "https://drive.google.com/thumbnail?id=" +
          this.media.id +
          "&sz=w1000-h1000"
        );
      }
      if (this.isDropbox && this.isImg) {
        return this.media.thumb;
      }
      if (this.isDropbox && this.isVideo) {
        return "/static/media-icons/Mov.svg";
      }
      // Used Mixin : FileExtensionMixin
      return this.mediaPreview.type;
    },
    isZip() {
      return this.media.type.match(/zip.*/);
    },
    isS3() {
      return this.media.file_resource === "s3";
    },
    isDropbox() {
      return this.media.file_resource === "dropbox";
    },
    isGoogleDrive() {
      return this.media.file_resource === "google";
    },
    isImg() {
      return this.media.type.match(/image.*/);
    },
    isVideo() {
      return this.media.type.match(/video.*/);
    },
    isAudio() {
      return this.media.type.match(/audio.*/);
    },
    pdfFileExt() {
      if (this.pdfMediaData) {
        return this.pdfMediaData.filename.split(".")[1];
      }
      return null;
    },
    getMedia() {
      if (this.media) {
        this.media.commentPoint.forEach((commentPoint) => {
          commentPoint["comments"] = [];
          commentPoint["menuFlag"] = false;
          if (commentPoint.last_label === "#15d89a") {
            commentPoint["completed"] = true;
          } else {
            commentPoint["completed"] = false;
          }
        });
      }
      return this.media;
    },
    completedMediaPoints() {
      return this.getMedia.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
  },
  directives: {
    tooltip: {
      bind(el, binding, vnode) {
        el.tooltipHandler = (evt) => {
          let targetEl = evt.target;
          if (targetEl.offsetWidth < targetEl.scrollWidth) {
            vnode.context.isTruncated = true;
          } else {
            vnode.context.isTruncated = false;
          }
        };
        el.addEventListener("mouseover", el.tooltipHandler);
      },
      unbind(el) {
        el.removeEventListener("mouseover", el.tooltipHandler);
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
.remove-media {
  right: -3px;
  top: 0px;
}
</style>
