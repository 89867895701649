<template>
  <div
    :id="'point' + commentIndex"
    class="row flex-no-wrap comment-list visual-comment-points relative-position q-py-xs"
    :class="
      commentIndex === selectedPointData.index
        ? 'point-selected'
        : 'point-not-selected'
    "
    :key="commentIndex"
    v-if="
      selectedTab === 'active'
        ? !commentPoint.completed
        : commentPoint.completed
    "
  >
    <div
      class="add-check-box"
      :class="[
        visualBubbleComments &&
          visualBubbleComments.length > 1 &&
          'comment-checkbox',
      ]"
      :key="commentIndex + 10"
    >
      <q-checkbox
        v-model="commentPoint.completed"
        value
        :ripple="false"
        size="sm"
        class="shrink-checkbox"
        style="color:#A6B1BD"
        @input="
          (e) => {
            checkInputChanged(commentPoint, e);
          }
        "
      />
    </div>
    <div
      class="comment-list-section  flex-1 q-pr-xs"
      :key="commentIndex + 20"
      style="margin-left: 8px"
    >
      <div class="group-hover" v-if="firstComment">
        <div class="row comment-title relative-position">
          <div class="row flex-1 flex-no-wrap justify-between">
            <div
              class="row flex-no-wrap items-center full-width"
              style="padding-right: 10px"
            >
              <div
                :class="[
                  commentIndex === selectedPointData.index
                    ? 'selected-point'
                    : '',
                  'q-mr-sm',
                ]"
              >
                <q-btn
                  @click="scrollToPoint(commentPoint, commentIndex)"
                  round
                  size="10px"
                  text-color="white"
                  :style="{
                    background:
                      commentPoint.last_label === 'nolabel'
                        ? '#007aff'
                        : commentPoint.last_label,
                  }"
                  :class="['q-ma-none comment-button']"
                  :label="commentIndex + 1"
                >
                </q-btn>
              </div>
              <div
                class="messaging-user-detail-chip flex full-width"
                style="justify-content: space-between"
              >
                <span class="user-name cursor-pointer" style="font-weight:bold"
                  >{{ firstComment.owner.first }}
                  {{ firstComment.owner.last }}
                </span>
                <span class="time">
                  <span
                    v-if="firstComment.updated_at > firstComment.created_at"
                  >
                    {{ getTimeDifference(firstComment.created_at) }}
                    <span class="capitalize-text an-12">(Edited)</span>
                  </span>
                  <span v-else>{{
                    getTimeDifference(firstComment.created_at)
                  }}</span>
                </span>
              </div>
            </div>
          </div>
          <div
            class="row group-hover-item flex-no-wrap thread-icons point-menu-options"
          >
            <q-btn
              dense
              flat
              rounded
              size="10px"
              :icon="$icons.matLocalOffer"
              class="thread-icon q-ml-xs"
            >
              <q-tooltip>
                Change Label
              </q-tooltip>
              <q-menu content-class="overflow-auto" auto-close>
                <q-list separator>
                  <template v-for="(label, index) in mediaCommentLabels">
                    <q-item
                      :key="`board-filter-${index}`"
                      @click="
                        clickonthemeoption(
                          label.name,
                          label.color,
                          commentPoint.id
                        )
                      "
                      clickable
                    >
                      <q-item-section avatar>
                        <q-avatar
                          size="30px"
                          :style="{ 'background-color': label.color }"
                          class="rounded-borders"
                        />
                      </q-item-section>
                      <q-item-section>{{ label.name }}</q-item-section>
                      <q-item-section avatar>
                        <q-icon
                          :name="$icons.matCheckCircle"
                          size="24px"
                          v-if="
                            selectedPointData &&
                              label.color === selectedPointData.last_label
                          "
                        />
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </q-menu>
            </q-btn>
            <q-btn
              v-if="user && user.id === firstComment.owner.id"
              dense
              flat
              rounded
              size="10px"
              :icon="$icons.matBorderColor"
              class="thread-icon q-ml-xs"
              @click="() => editComment(firstComment)"
            >
              <q-tooltip>
                Edit Comment
              </q-tooltip>
            </q-btn>
            <q-btn
              dense
              flat
              rounded
              size="10px"
              :icon="$icons.matDelete"
              class="thread-icon q-ml-xs"
              @click="deleteCommentPoint(commentIndex, commentPoint.id)"
            >
              <q-tooltip>
                Delete Point
              </q-tooltip>
            </q-btn>
          </div>
        </div>
        <div
          v-html="commentContent"
          class="post-content dont-break-out editor-prototype q-mt-xs q-mb-sm"
          style="white-space:normal"
        ></div>
      </div>
      <template v-for="(comment, index) in restComments">
        <comment-view-comments
          :key="index"
          :comment="comment"
          :labels="labels"
          :point="commentPoint"
          :pointIndex="commentIndex"
          :index="index"
          :selectedPoint="selectedPointData"
          @deleteMediaComment="deleteMediaComment"
          @editComment="editCommentHandler"
        >
        </comment-view-comments>
      </template>
      <div class="comment-editor" v-show="commentIndex === enabledTextbox">
        <comment-editor-nested
          ref="visibleComment"
          :showBorder="true"
          :editCommentContent="
            editCommentContent ? editCommentContent.comment : ''
          "
          :openTaskComment="true"
          :resetAddCommentEditor="resetTextArea"
          @submit="sendComment"
          @blur="(event) => handleEditorBlur(event, commentPoint, commentIndex)"
          @keyPressed="handleKeyPressed"
          :openTaskCommentFocus="true"
          :showAttatchmentIcon="false"
          :setMargin="true"
          @escapePressed="closeCommentEditor(commentPoint, commentIndex)"
        />
      </div>
      <div class="row" v-show="commentIndex !== enabledTextbox">
        <div
          class="add-comment-button group-hover-item q-mb-xs flex-1 text-center row justify-center items-center cursor-pointer "
          @click="() => addCommentToPoint(commentIndex)"
        >
          Add comment
        </div>
      </div>
      <!-- <div class="row" v-show="commentIndex !== enabledTextbox">
          <q-btn
            rounded
            class="add-comment-button"
            no-caps
            @click="() => addCommentToPoint(commentIndex)"
          >
            <q-icon
              :name="$icons.matTextsms"
              class="add-comment-icon"
              size="medium"
            />
            <label style="cursor: pointer">Add a new comment</label>
          </q-btn>
        </div> -->
    </div>
  </div>
</template>

<script>
import fromUnixTime from "date-fns/fromUnixTime";
import parseJSON from "date-fns/parseJSON";
import CommentViewComments from "@/views/MediaView/CommentViewComments";
import CommentEditorNested from "@/components/Editor/CommentEditorNested";
import { compile } from "@/components/Editor/MarkupUtils";

export default {
  name: "VisualCommentPoint",
  props: [
    "commentPoint",
    "commentIndex",
    "labels",
    "checkInputChanged",
    "clickonthemeoption",
    "selectedPointData",
    "deleteCommentPoint",
    "deleteMediaComment",
    "editCommentHandler",
    "enabledTextbox",
    "editCommentContent",
    "resetTextArea",
    "sendComment",
    "handleKeyPressed",
    "handleEditorBlur",
    "closeCommentEditor",
    "selectedTab",
    "visualBubbleComments",
    "user"
  ],
  components: {
    CommentEditorNested,
    CommentViewComments,
  },
  methods: {
    editComment(comment) {
      let data = {
        comment,
        selectedPoint: this.commentPoint,
        selectedPointIndex: this.commentIndex,
      };
      this.editCommentHandler(data);
    },
    scrollToPoint(point, index) {
      let data = {
        point: point,
        index: index,
      };
      this.$emit("selectPoint", data);
      this.$emit("scrollToPoint", point);
    },
    getTimeDifference(date) {
      return this.getTimeDifferenceForComments(fromUnixTime(date));
    },
    getTimeDifferenceForComments(date) {
      return this.timeDifference(new Date(), parseJSON(date));
    },
    timeDifference(current, previous) {
      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;
      if (elapsed < msPerMinute) {
        return "just now";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " m";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " h";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " d";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " m";
      } else {
        return Math.round(elapsed / msPerYear) + " y";
      }
    },
    addCommentToPoint(index) {
      this.resetTextArea();
      this.$emit("addCommentToPoint", index);
      this.$nextTick(() => {
        this.$refs.visibleComment.$refs.commentEditor.$refs.editor.fireFocus();
      });
    },
  },
  computed: {
    mediaCommentLabels() {
      this.labels.forEach((label) => {
        if (label.color === "nolabel") {
          label.color = "#677484";
        }
        if (label.color === "complete") {
          label.color = "#15d89a";
        }
        if (label.color === "inprogress") {
          label.color = "#FEBE36";
        }
        if (label.color === "pendingreview") {
          label.color = "#4DA1FF";
        }
        if (label.color === "notstarted") {
          label.color = "#8A75AE";
        }
        if (label.color === "overdue") {
          label.color = "#D75656";
        }
        if (label.color === "hightpriority") {
          label.color = "#FF3279";
        }
        if (label.color === "no") {
          label.color = "transparent";
        }
        label.color = label.colorcode;
      });
      return this.labels;
    },
    comments() {
      return this.visualBubbleComments;
    },
    firstComment() {
      return this.comments && this.comments[0];
    },
    restComments() {
      return this.comments ? this.comments.slice(1, this.comments.length) : [];
    },
    commentContent() {
      return compile(this.firstComment.comment);
    },
  },
  watch: {
    enabledTextbox(val) {
      if (val >= 0) {
        this.$nextTick(() => {
          this.$refs.visibleComment &&
            this.$refs.visibleComment.$refs.commentEditor.$refs.editor.fireFocus();
        });
      }
    },
  },
};
</script>
