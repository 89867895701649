<template>
  <div
    class="border-radius10 q-ma-xs relative-position media-card-hover cursor-pointer"
    style="width: 170px;"
    @click="$emit('openfolder', folder)"
  >
    <q-menu context-menu content-style="min-width:150px" auto-close>
      <q-list>
        <q-item
          class="q-mb-sm"
          @click="checkMediaFolderAccess(folder)"
          clickable
        >
          Attach to message
        </q-item>
        <q-separator />
        <q-item
          v-close-popup
          @click="uploadFileToFolder(folder.id)"
          clickable
        >
          <q-item-section>
            Upload files
          </q-item-section>
        </q-item>
        <q-item
          clickable
          class="q-mb-sm"
          @click="$emit('renameFolder', folder)"
          v-close-popup
        >
          <q-item-section>
            Rename
          </q-item-section>
        </q-item>
        <q-separator />
        <!-- <q-item clickable v-close-popup>
          <q-item-section>
            Download
          </q-item-section>
        </q-item> -->
        <q-item
          @click="copyMoveFileFolderHandler([folder], false, false, true)"
          avatar
          clickable
          v-close-popup
        >
          <q-item-section> Copy </q-item-section>
        </q-item>
        <q-item
          @click="copyMoveFileFolderHandler([folder], true, false, true)"
          clickable
          v-close-popup
        >
          <q-item-section>
            Move
          </q-item-section>
        </q-item>
        <q-item
          clickable
          class="text-red"
          @click="deleteFolder(folder.id)"
          v-close-popup
        >
          <q-item-section> Delete </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
    <span
      :class="[
        'custom-selection-checkbox absolute grid ',
        selectedIds.length && 'custom-selection-checkbox-visible',
      ]"
      @click.stop="toggleSelect(folder)"
    >
      <div
        class="selected-custom-selection-checkbox grid"
        v-if="selectedIds.includes(folder.id)"
      ></div>
    </span>
    <div
      class="absolute-full fill-height full-width folder-hover-item "
      style="height: 170px;border-radius:10px"
    >
      <div
        class="task-menu-option thread-icons q-px-xs q-mt-sm q-mr-xs group-hover-item row items-center"
        style="z-index: 100"
      >
        <div
          @click.stop="removeMediaFolderBookmark(folder)"
          v-if="folder.isbookmarked"
        >
          <q-icon
            size="20px"
            class="cursor-pointer"
            color="pink"
            :name="$icons.matBookmark"
          >
            <q-tooltip>
              Remove from Saved items
            </q-tooltip>
          </q-icon>
        </div>
        <div
          @click.stop="addMediaFolderBookmark(folder)"
          v-if="!folder.isbookmarked"
        >
          <q-icon
            size="20px"
            class=" cursor-pointer"
            color="black"
            :name="$icons.matBookmarkBorder"
          >
            <q-tooltip>
              Add to Saved items
            </q-tooltip>
          </q-icon>
        </div>
      </div>
    </div>
    <div class="media-grid-folder column justify-center items-center">
      <div class="relative-position">
        <q-icon :name="$icons.matFolder" class="media-grid-folder-icon" />
        <div class="media-grid-folder-count">
          {{ folder.media_count > 9 ? "9+" : folder.media_count }}
        </div>
      </div>
      <div class="media-grid-folder-title ellipses">
        {{ folder.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GridFolder",
  props: [
    "folder",
    "deleteFolder",
    "toggleSelect",
    "selectedIds",
    "copyMoveFileFolderHandler",
    "attachFolderToPost",
    "uploadFileToFolder",
    "addMediaFolderBookmark",
    "removeMediaFolderBookmark",
  ],
  methods: {
    checkMediaFolderAccess(folder) {
      let eligible = this.$checkEligiblity("file_folders");
      if (eligible) {
        this.attachFolderToPost([folder]);
      }
    },
  },
};
</script>
