<template>
  <div class="q-ml-sm row flex-x justify-center" style="align-items: center">
    <q-btn
      class="formatting-btn q-mr-xs"
      dense
      flat
      round
      size="sm"
      style="color: #6e788b"
      :icon="$icons.mdiFormatBold"
      @click="$emit('toggleBold')"
    />
    <q-btn
      class="formatting-btn q-mr-xs"
      dense
      flat
      round
      size="sm"
      style="color: #6e788b"
      :icon="$icons.mdiFormatItalic"
      @click="$emit('toggleItalic')"
    />
    <q-btn
      class="formatting-btn q-mr-xs"
      dense
      flat
      round
      size="sm"
      style="color: #6e788b"
      :icon="$icons.mdiLinkVariant"
      @click="$emit('openAddLink')"
    />
    <q-btn
      class="formatting-btn q-mr-xs"
      dense
      flat
      round
      size="sm"
      style="color: #6e788b"
      :icon="$icons.mdiAt"
      @click="$emit('insertText', '@')"
    />
    <q-btn
      class="formatting-btn q-mr-xs"
      dense
      flat
      round
      size="sm"
      style="color: #6e788b"
      :icon="$icons.matTagFaces"
      @click="$emit('insertText', ':')"
    />
    <q-btn
      dense
      flat
      round
      class="formatting-btn"
      :icon="$icons.mdiHelp"
      style="
        color: #007aff;
        background: #dbecff;
        font-size: 7px !important;
        width: 17px;
        height: 17px;
        margin-top: 3px;
      "
    >
      <q-menu auto-close>
        <q-item @click="viewDocShortcuts" clickable class="items-center"
          ><q-icon :name="$icons.mdiKeyboard" size="20px" class="q-mr-md" />
          Shortcuts</q-item
        >
        <q-item clickable class="items-center" @click="videoPlayer">
          <div class="help-icon row items-center justify-center q-mr-md">
            <q-icon :name="$icons.mdiHelpCircle" size="20px" />
          </div>
          Walkthrough</q-item
        >
      </q-menu>
    </q-btn>
    <document-shortcuts-dialog
      v-model="isDocShortcutDialogOpen"
      @close="isDocShortcutDialogOpen = false"
      isFrom="message"
    />
    <video-dialog
      v-if="showVideoModel"
      v-model="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <add-link-dialog
      v-if="showAddLink"
      v-model="showAddLink"
      :closeHyperlinkModal="closeHyperlinkModal"
      @setLinkHandler="setLinkHandler"
      :addLinkTitle="addLinkTitle"
    ></add-link-dialog>
  </div>
</template>
<script>
import DocumentShortcutsDialog from "@/components/DocumentEditor/DocumentShortcutsDialog";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import AddLinkDialog from "@/components/Dialogs/AddLinkDialog";

export default {
  name: "AddFormateOption",
  props: ["showAddLink", "addLinkTitle", "closeHyperlinkModal"],
  components: {
    DocumentShortcutsDialog,
    VideoDialog,
    AddLinkDialog,
  },
  data() {
    return {
      isDocShortcutDialogOpen: false,
      showVideoModel: false,
      displayPlayer: false,
      videoMediaData: null,
    };
  },
  methods: {
    videoPlayer() {
      let media = {
        isVimeo: true,
        file: "https://player.vimeo.com/video/874377151",
      };
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    viewDocShortcuts() {
      this.isDocShortcutDialogOpen = true;
    },
    setLinkHandler(title, url) {
      let linkTitle = title;
      this.closeHyperlinkModal();
      setTimeout(() => {
        if(url && linkTitle) {
          this.$emit("insertText", `${url}?h_link_name=${linkTitle.replace(/ /g,"_")}`);
        }
      }, 100);
    },
  },
  watch: {
    addLinkTitle: {
      immediate: true,
      handler (newVal) {
        this.title = newVal;
      }
    },
  },
};
</script>
