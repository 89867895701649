<template>
  <div>
    <!-- Multiple selection options -->
    <div
      v-if="selected.length"
      class="row items-center stick-at-top q-pt-sm q-pb-sm"
      style="z-index: 111"
    >
      <q-checkbox v-model="selectAll" size="sm" @input="toggleAll" />
      <q-btn
        flat
        dense
        no-caps
        :icon="$icons.fasShare"
        label="Share"
        class="multiple-select-options primary-btn"
        @click="attacheFileFolderToMessage(selected)"
      />
      <q-btn
        flat
        dense
        no-caps
        :icon="$icons.mdiContentCopy"
        @click="
          copyMoveFileFolderHandler(
            selected,
            false,
            true,
            false,
            isFolderOpenView ? false : true
          )
        "
        label="Copy"
        class="multiple-select-options primary-btn"
      />
      <q-btn
        flat
        dense
        no-caps
        :icon="$icons.mdiCursorMove"
        @click="
          copyMoveFileFolderHandler(
            selected,
            true,
            true,
            false,
            isFolderOpenView ? false : true
          )
        "
        label="Move"
        class="multiple-select-options primary-btn"
      />
      <q-btn
        flat
        dense
        no-caps
        :icon="$icons.fasTrash"
        label="Delete"
        class="multiple-select-options red-btn"
        @click="bulkDeleteFileConfirm.flag = true"
      />
    </div>
    <!-- Section  -->
    <template>
      <div
        v-sortable="{
          emptyInsertThreshold: 100,
          onEnd: onDragEnd,
        }"
        class="q-mt-sm"
      >
        <div
          :key="section.id"
          :data-id="section.id"
          v-for="section in folderSection"
          class="group-hover"
        >
          <q-expansion-item
            switch-toggle-side
            expand-icon-toggle
            :value="!openSectionData.includes(section.id)"
            @input="
              collapsibleChangeHandler(
                openSectionData.includes(section.id),
                section
              )
            "
            header-class="folder-section items-center cursor-pointer "
            :header-style="[
              openSectionData.includes(section.id) && {
                'background-color': '#eff2f6',
              },
              editSection.id === section.id && {
                'background-color': '#dbecff',
              },
            ]"
            class="q-mb-xs"
          >
            <template v-slot:header>
              <div class="row flex-1 items-center">
                <q-input
                  v-if="editSection.id === section.id"
                  dense
                  :value="section.title"
                  padding="0"
                  outlined
                  input-class="text-bold"
                  input-style="font-size:26px"
                  class="flex-1"
                  autofocus
                  @blur="editSection.id = null"
                  @change="updateSectionTitle"
                  @keydown.enter.exact="updateSectionTitle"
                />
                <div
                  v-else
                  @click="editSection.id = section.id"
                  class="row items-center text-bold flex-1"
                  style="font-size: 26px"
                >
                  <div>
                    {{ section.title }}
                  </div>
                  <div
                    class="row justify-center items-center q-ml-sm media-count"
                  >
                    {{
                      sectionMedia[section.id] ? sectionMedia[section.id].length : 0
                    }}
                  </div>
                </div>
                <q-btn
                  v-if="sectionMedia[section.id] && selected.length"
                  flat
                  dense
                  no-caps
                  :icon="$icons.mdiCursorMove"
                  label="Move to section"
                  class="section-btn primary-btn"
                  @click="openMoveFileSectionFolderDialog(section, selected)"
                />
                <div class="row section-header-item items-center">
                  <q-btn
                    size="md"
                    flat
                    round
                    dense
                    color="black"
                    class="q-mr-xs toolbar-buttons"
                    :icon="$icons.matAdd"
                  >
                    <q-tooltip> Add Files </q-tooltip>
                    <q-menu auto-close>
                      <q-list>
                        <q-item
                          @click="uploadFileToSection(section.id)"
                          clickable
                        >
                          <q-item-section
                            style="min-width: 35px"
                            class="q-pr-none"
                            avatar
                          >
                            <q-icon :name="$icons.matComputer" size="24px" />
                          </q-item-section>
                          <q-item-section> Your Computer </q-item-section>
                        </q-item>
                        <q-item
                          @click="openSharedFileDialog(section.id)"
                          clickable
                        >
                          <q-item-section
                            style="min-width: 35px"
                            class="q-pr-none"
                            avatar
                          >
                            <q-icon :name="$icons.fasCopy" size="24px" />
                          </q-item-section>
                          <q-item-section> Shared Files </q-item-section>
                        </q-item>
                        <q-separator />
                        <q-item
                          @click="
                            () => {
                              dropBox('fromFolder', currentUser.id);
                              sectionId = section.id;
                            }
                          "
                          clickable
                        >
                          <q-item-section
                            style="min-width: 35px"
                            class="q-pr-none"
                            avatar
                          >
                            <q-icon :name="$icons.fabDropbox" size="24px" />
                          </q-item-section>
                          <q-item-section> Dropbox </q-item-section>
                        </q-item>
                        <q-item
                          @click="
                            () => {
                              googleDrive('fromFolder', currentUser.id);
                              sectionId = section.id;
                            }
                          "
                          clickable
                        >
                          <q-item-section
                            style="min-width: 35px"
                            class="q-pr-none"
                            avatar
                          >
                            <q-icon :name="$icons.fabGoogleDrive" size="24px" />
                          </q-item-section>
                          <q-item-section> Google Drive </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                  <q-btn
                    size="16px"
                    flat
                    round
                    dense
                    :icon="$icons.matArrowDropDown"
                    color="black"
                    padding="0"
                    class="option-btn toolbar-buttons"
                    :disabled="isLastSection(section)"
                    @click="moveSectionDown(section)"
                  >
                    <q-tooltip> Move section down </q-tooltip>
                  </q-btn>
                  <q-btn
                    size="16px"
                    flat
                    round
                    dense
                    :icon="$icons.matArrowDropUp"
                    color="black"
                    padding="0"
                    class="option-btn toolbar-buttons"
                    :disabled="isFirstSection(section)"
                    @click="moveSectionUp(section)"
                    ><q-tooltip> Move section up </q-tooltip>
                  </q-btn>
                  <q-btn
                    size="12px"
                    flat
                    round
                    dense
                    :icon="$icons.matDelete"
                    color="black"
                    padding="0"
                    class="option-btn toolbar-buttons"
                  >
                    <q-menu auto-close>
                      <q-list>
                        <q-item
                          class="text-red"
                          @click="
                            openDeleteConfirmationDialog(section.id, true)
                          "
                          clickable
                        >
                          <q-item-section>
                            Delete section & media
                          </q-item-section>
                        </q-item>
                        <q-item
                          class="text-red"
                          @click="
                            openDeleteConfirmationDialog(section.id, false)
                          "
                          clickable
                        >
                          <q-item-section> Delete section </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </div>
            </template>
            <div
              v-sortable="{
                emptyInsertThreshold: 100,
                group: 'folderMedia',
                onAdd: (event) => onDragAddOrUpdateInSection(event, section),
                onUpdate: (event) => onDragAddOrUpdateInSection(event, section),
              }"
              class="q-mt-sm row"
            >
              <template v-for="media in sectionMedia[section.id]">
                <div
                  :key="media.id"
                  :data-id="media.id"
                  class="border-radius10 q-ma-xs relative-position"
                  style="width: 170px"
                >
                  <card-media
                    :media="media"
                    :height="170"
                    :fullview="fullviewMediaHandler"
                    :videoPlayer="videoPlayer"
                    :audioPlayer="audioPlayer"
                    :pdfView="pdfView"
                    :addMediaBookmark="addMediaBookmark"
                    :removeMediaBookmark="removeMediaBookmark"
                    :canRemoveMedia="
                      canRemove ||
                      (currentUser && currentUser.id === media.user_id)
                    "
                    :removeMediaFile="removeMediaFile"
                    @copyLink="clickonmoreoption"
                    :companyId="companyId"
                    :workspace="currentWorkspace"
                    :activeCompany="activeCompany"
                    :fromComputer="fromComputer"
                    :toggleSelect="toggleSelect"
                    :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                    :selectedIds="selectedIds"
                    :isFolderOpenView="isFolderOpenView"
                    :editDoc="editDoc"
                    @renameFile="openRenameFileDialog"
                    :currentUser="currentUser"
                  ></card-media>
                </div>
              </template>
            </div>
          </q-expansion-item>
          <div class="row">
            <div
              class="add-folder-section-area group-hover-item q-mb-xs flex-1 text-center row justify-center items-center cursor-pointer"
              @click="addSectionHandler(section)"
            >
              Add Section
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Root Media -->
    <div
      v-if="folderRootMedia.length"
      style="margin-left: -32px; margin-right: 0px"
      class="root-section-divider"
    ></div>
    <template>
      <div
        v-sortable="{
          emptyInsertThreshold: 100,
          group: 'folderMedia',
          onAdd: onDragAddOrUpdateInRoot,
          onUpdate: onDragAddOrUpdateInRoot,
        }"
        class="q-mt-sm row"
      >
        <template v-for="media in folderRootMedia">
          <div
            :key="media.id"
            :data-id="media.id"
            class="border-radius10 q-ma-xs relative-position"
            style="width: 170px"
          >
            <card-media
              :media="media"
              :height="170"
              :fullview="fullviewMediaHandler"
              :videoPlayer="videoPlayer"
              :audioPlayer="audioPlayer"
              :pdfView="pdfView"
              :addMediaBookmark="addMediaBookmark"
              :removeMediaBookmark="removeMediaBookmark"
              :canRemoveMedia="
                canRemove || (currentUser && currentUser.id === media.user_id)
              "
              :removeMediaFile="removeMediaFile"
              @copyLink="clickonmoreoption"
              :companyId="companyId"
              :workspace="currentWorkspace"
              :activeCompany="activeCompany"
              :fromComputer="fromComputer"
              :toggleSelect="toggleSelect"
              :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
              :selectedIds="selectedIds"
              :isFolderOpenView="isFolderOpenView"
              :editDoc="editDoc"
              @renameFile="openRenameFileDialog"
              :currentUser="currentUser"
            ></card-media>
          </div>
        </template>
      </div>
    </template>
    <confirm-dialog
      v-if="deleteSection.flag"
      v-model="deleteSection.flag"
      :title="
        deleteSection.shouldDeleteMedia
          ? 'Delete section and media?'
          : 'Delete Section?'
      "
      :question="
        deleteSection.shouldDeleteMedia
          ? 'Deleting this section will also delete all the media in it.'
          : 'Are you sure you want to delete this section?'
      "
      cancleText="Cancel"
      successText="Yes"
      :cancleMethod="resetConfirmationDialog"
      :successMethod="deleteSectionHandler"
      :loading="deleteSection.loader"
    />
    <copy-move-file-folder-dialog
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isMoveAction="copyMoveDialog.isMoveAction"
      :fileFolderDetails="copyMoveDialog.data"
      :isRoot="copyMoveDialog.isRoot"
      :isMultiple="copyMoveDialog.isMultiple"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyFileFolderHandler="moveCopyFileFolderHandler"
      :multipleMoveCopyHandler="multipleMoveCopyHandler"
    />
    <move-file-section-folder-dialog
      v-if="moveSectionDialog.flag"
      v-model="moveSectionDialog.flag"
      :currentFolderSection="moveSectionDialog.currentFolderSection"
      :folderSection="folderSection"
      :cancelMethod="closeMoveFileSectionFolderDialog"
      :moveFolderSectionHandler="moveFolderSectionHandler"
      :fileFolderDetails="moveSectionDialog.data"
    />
    <vue-dropzone-file-upload
      hidden
      ref="folderSectionUploader"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="sectionFileUploadCloseHandler"
      @setProgress="(progress) => $emit('setProgress', progress)"
    ></vue-dropzone-file-upload>
    <shared-files-dialog
      v-if="sharedFileDialog"
      v-model="sharedFileDialog"
      @closeDialog="sharedFileDialog = false"
      :imageList="imageList"
      :attachMediaToPost="sectionFileUploadCloseHandler"
      :currentWorkspaceId="currentWorkspace"
      :fromFolder="true"
    />
    <div v-if="bulkDeleteFileConfirm.flag">
      <confirm-dialog
        v-model="bulkDeleteFileConfirm.flag"
        title="Delete Assets?"
        question="Are you sure you want to delete these assets?"
        cancleText="Cancel"
        successText="Yes"
        :cancleMethod="() => bulkDeleteAssetsConfirmHandler(false)"
        :successMethod="() => bulkDeleteAssetsConfirmHandler(true)"
        :loading="bulkDeleteFileConfirm.loader"
      />
    </div>
    <update-file-dialog
      v-if="renameFileDialog.flag"
      v-model="renameFileDialog.flag"
      :renameFile="renameFileDetails"
      :cancelMethod="cancelRenameFile"
    />
  </div>
</template>
<script>
import MediaViewMixin from "@/mixins/MediaViewMixin";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";

import groupBy from "lodash/groupBy";
import first from "lodash/first";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import CardMedia from "@/components/Miscellaneous/CardMedia";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import CopyMoveFileFolderDialog from "@/components/Dialogs/CopyMoveFileFolderDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import UpdateFileDialog from "@/components/Dialogs/UpdateFileDialog";
import MoveFileSectionFolderDialog from "@/components/Dialogs/MoveFileSectionFolderDialog";

export default {
  name: "FolderDetailGridView",
  mixins: [MediaViewMixin, GoogleDriveMethodMixin, DropBoxMixin],
  props: [
    "isFolderOpenView",
    "folderSection",
    "items",
    "addMediaBookmark",
    "removeMediaBookmark",
    "fromComputer",
    "videoPlayer",
    "audioPlayer",
    "pdfView",
    "canRemove",
    "currentUser",
    "clickonmoreoption",
    "removeMediaFile",
    "companyId",
    "currentWorkspace",
    "activeCompany",
    "openFolderId",
    "closeFolderHandler",
    "isWorkspaceOwner",
    "authToken",
    "fullViewHandler",
    "editDoc",
  ],
  components: {
    CardMedia,
    ConfirmDialog,
    CopyMoveFileFolderDialog,
    VueDropzoneFileUpload,
    SharedFilesDialog,
    UpdateFileDialog,
    MoveFileSectionFolderDialog,
  },
  api: {
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspace}`;
      },
    },
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.currentWorkspace}`;
      },
    },
  },
  data() {
    return {
      openSection: true,
      openSectionData: [],
      renameFileDialog: {
        flag: false,
      },
      renameFileDetails: null,
    };
  },
  created() {
    this.openSectionData = this.showSectionToggle;
  },
  mounted() {
    this.openSectionData = this.showSectionToggle;
  },
  methods: {
    collapsibleChangeHandler(value, section) {
      this.$store.dispatch("allSectionList", {
        flag: value,
        id: section.id,
      });
      this.openSectionData = this.$store.getters.allSectionList;
    },
    openRenameFileDialog(media) {
      this.renameFileDetails = media;
      this.renameFileDialog.flag = true;
    },
    cancelRenameFile() {
      this.renameFileDetails = null;
      this.renameFileDialog.flag = false;
      this.renameFileDialog.loading = false;
    },
    fullviewMediaHandler(data) {
      this.fullViewHandler(
        data,
        data.media.media_card_id
          ? this.sectionMedia[data.media.media_card_id]
          : this.folderRootMedia
      );
    },
    isFirstSection(section) {
      return first(this.folderSection).id === section.id;
    },
    isLastSection(section) {
      return last(this.folderSection).id === section.id;
    },
  },
  computed: {
    showSectionToggle() {
      return this.$store.getters.allSectionList;
    },
    allSectionMedia() {
      return this.items.filter((item) => item.media_card_id);
    },
    folderRootMedia() {
      return this.items.filter((item) => !item.media_card_id);
    },
    sectionMedia() {
      return groupBy(this.allSectionMedia, "media_card_id");
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      }
      return null;
    },
    folderList() {
      if (this.mediaFolders) {
        return uniqBy(orderBy(this.mediaFolders, ["title"], ["asc"]), "id");
      } else {
        return [];
      }
    },
  },
};
</script>
