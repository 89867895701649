<template>
  <div class="column items-start">
    <div class="row full-width group-hover bg-white rounded-borders">
      <div class="flex-1 relative-position">
        <div class="row">
          <div
            class="flex-1"
            :class="
              pointIndex === selectedPoint.index ? 'selected-comment-point' : ''
            "
          >
            <div class="row flex-no-wrap justify-between">
              <div
                class="row flex-no-wrap items-center full-width"
                style="padding-right: 10px"
              >
                <!-- <div
                  :class="[
                    pointIndex === selectedPoint.index ? 'selected-point' : '',
                    'q-mr-sm',
                  ]"
                >
                  <q-btn
                    round
                    size="10px"
                    text-color="white"
                    :style="{
                      background:
                        point.last_label === 'nolabel'
                          ? '#007aff'
                          : point.last_label,
                    }"
                    :class="['q-ma-none comment-button']"
                    :label="pointIndex + 1"
                  >
                  </q-btn>
                </div> -->
                <div
                  class="messaging-user-detail-chip flex full-width"
                  style="justify-content: space-between"
                >
                  <span
                    class="user-name cursor-pointer"
                    style="font-weight:bold"
                    >{{ comment.owner.first }} {{ comment.owner.last }}
                  </span>
                  <span class="time">
                    <span v-if="comment.updated_at > comment.created_at">
                      {{ getTimeDifference(comment.created_at) }}
                      <span class="capitalize-text an-12">(Edited)</span>
                    </span>
                    <span v-else>{{
                      getTimeDifference(comment.created_at)
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="comment-content-and-options">
              <div
                v-html="commentContent"
                class="post-content dont-break-out editor-prototype q-mt-xs q-mb-sm"
                style="white-space:normal"
              ></div>
              <div
                class="row group-hover-item flex-no-wrap thread-icons point-menu-options"
              >
                <q-btn
                  dense
                  flat
                  rounded
                  size="10px"
                  :icon="$icons.matBorderColor"
                  class="thread-icon q-ml-xs"
                  @click="editComment"
                >
                  <q-tooltip>
                    Edit Comment
                  </q-tooltip>
                </q-btn>
                <q-btn
                  dense
                  flat
                  rounded
                  size="10px"
                  :icon="$icons.matDelete"
                  class="thread-icon q-ml-xs"
                  @click="deleteMediaComment"
                >
                  <q-tooltip>
                    Delete Comment
                  </q-tooltip>
                </q-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { compile } from "@/components/Editor/MarkupUtils";
import fromUnixTime from "date-fns/fromUnixTime";
import NotificationNavMixin from "@/mixins/NotificationNavMixin";

export default {
  name: "CommentViewComments",
  props: ["comment", "labels", "point", "index", "pointIndex", "selectedPoint"],
  mixins: [NotificationNavMixin],
  computed: {
    commentContent() {
      return compile(this.comment.comment);
    },
  },
  methods: {
    deleteMediaComment() {
      const data = {
        commentid: this.comment.id,
        index: this.index,
        commentpointid: this.comment.media_comments_point_id,
      };
      this.$emit("deleteMediaComment", data);
    },
    editComment() {
      let data = {
        comment: this.comment,
        selectedPoint: this.point,
        selectedPointIndex: this.pointIndex,
      };
      this.$emit("editComment", data);
    },
    getTimeDifference(date) {
      return this.getTimeDifferenceForComments(fromUnixTime(date));
    },
  },
};
</script>

<style scoped>
.time {
  font-size: 12px;
  color: #677484;
  font-family: "Avenir Next";
  line-height: 2;
}
.selected-comment-point {
  background-color: #e1ecf9;
}
.comment-content-and-options {
  display: flex;
  justify-content: space-between;
}
.comment-content-and-options .thread-icons {
  height: max-content;
}
.comment-button {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
}
.selected-point {
  border-radius: 50%;
  border: solid 2px #007aff;
  padding: 1px;
  display: flex;
}
</style>
