<template>
	<div>
		<q-btn
			v-if="isVisible"
			flat
			dense
			size="11px"
			style="display: flex;"
			round
			padding="0"
			:class="[
				'thread-icon',
				fromSubTask && 'task-control-button',
			]"
			@click="openAssignMember"
		>
			<div class="plus-icon" v-if="fromSubTask">
				<q-icon :name="$icons.fasPlus" size="12px" />
			</div>
			<div class="plus-icon" v-else>
				<q-icon :name="$icons.matAddCircleOutline" />
			</div>
			<q-tooltip>
				Assign subtask
			</q-tooltip>
			<q-menu
				v-if="menu"
				v-model="menu"
				content-class="overflow-auto assign-member-menu"
				id="assign-subtask-menu"
			>
				<div class="sticky-header">
					<div
						class="close-btn cursor-pointer"
						@click="
							menu = false;
							search = null;
						"
					>
						<q-icon :name="$icons.matClose" class="close-icon" />
					</div>
					<div class="assign-to q-py-sm">
						Assign to
					</div>
					<div class="search-member">
						<q-input
							autofocus
							ref="search"
							v-model="search"
							outlined
							placeholder="Search Member"
							clearable
							dense
						>
							<template v-slot:prepend>
								<q-icon :name="$icons.matSearch" />
							</template>
						</q-input>
					</div>
				</div>
				<q-list class="q-py-sm member-list">
					<template v-for="user in subTaskAvailableUsers">
						<q-item
							class="q-px-none q-py-xs member-item items-center"
							:key="user.id"
							@click.stop="addMember(user)"
							clickable
						>
							<q-item-section class="member-avatar" avatar>
								<q-avatar size="32px" v-if="user.pic">
									<img :src="user.pic" :alt="user.first" />
								</q-avatar>
								<!-- <q-avatar
									size="32px"
									color="grey-4"
									text-color="grey-10"
									v-else
								>
									{{ user.first | firstChar }}{{ user.last | firstChar }}
								</q-avatar> -->
								<avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>

							</q-item-section>
							<q-item-section class="member-name" v-tooltip>
								{{ user.first | capitalize }}
								{{ user.last | capitalize }}
								<q-tooltip v-if="isTruncated">
									{{ user.first | capitalize }}
									{{ user.last | capitalize }}
								</q-tooltip>
							</q-item-section>
						</q-item>
					</template>
				</q-list>
			</q-menu>
		</q-btn>
	</div>
</template>

<script>
import orderBy from "lodash/orderBy";

import { AddMemberToSubTaskMutation } from "@/gql";

import Avatar from 'vue-avatar'
export default {
	name: "AssignMemberToSubtask",
	props: [
		"subtask",
		"workspaceMembers",
		"currentWorkspaceId",
		"fromNewTask",
		"currentCompany",
		"fromSubTask",
	],
	api: {
		user: {
			cacheKey: "UserQuery",
			defaultValue: null,
		},
	},
	data() {
		return {
			menu: false,
			search: null,
			choosed: null,
			disabled: true,
			isTruncated: false,
			showAssignMember: false,
		};
	},
	components: {
		Avatar,
	},
	methods: {
		closePlanExpiredDialog() {
			this.showAssignMember = false;
		},
		openAssignMember() {
			if (
				this.currentCompany.status &&
				this.currentCompany.status.subscriptionOver
			) {
				this.showAssignMember = true;
				this.menu = false;
			} else {
				let eligible = this.$checkEligiblity("assign_member_to_subtask");
				if (eligible) {
					this.menu = true;
				}
			}
		},
		async addMember(member) {
			this.menu = false;
			if (this.$refs.search) this.$refs.search.focus();

			if (this.fromNewTask) {
				this.subtask.members.push(member);
				this.subtask.memberIds.push(member.id);
				return;
			}

			this.choosed = member;
			this.disabled = true;
			const variables = {
				workspace_id: this.currentWorkspaceId,
				subtask_id: this.subtask.id,
				user_id: member.id,
			};
			try {
				const subTaskEntity = this.$api.getEntity("subTask", this.subtask.id);
				const memberEntity = this.$api.getEntity("user", member.id);
				subTaskEntity.members.push(memberEntity);

				await this.$api.mutate({
					mutation: AddMemberToSubTaskMutation,
					variables,
				});
			} catch (error) {
				if (error) {
					// Start from this
					this.$q.notify({
						color: "negative",
						position: "top-right",
						message: this.$t("message.coudlNotAssignTaskToMember"),
						icon: this.$icons.matReportProblem,
					});
					this.choosed = null;
					this.disabled = false;
				}
			}
		},
	},
	computed: {
		isVisible() {
			if (this.subtask.members.length === 0) return true;
			return this.subTaskAvailableUsers && this.subTaskAvailableUsers.length;
		},
		subtTaskMemberIds() {
			return this.subtask.members.map((m) => m.id);
		},
		subTaskAvailableUsersFuse() {
			if (this.workspaceMembers) {
				const members = [this.user, ...this.workspaceMembers].filter(
					(member) => !this.subtTaskMemberIds.includes(member.id)
				);

				const orderedMembers = orderBy(
					members,
					["first", "last"],
					["asc", "asc"]
				);

				return orderedMembers;
			}
			return [this.user].filter(
				(member) => !this.subtTaskMemberIds.includes(member.id)
			);
		},
		subTaskAvailableUsers() {
			return this.search
				? this.subTaskAvailableUsersFuse.filter((u) =>
						u.fullname.toLowerCase().includes(this.search.toLowerCase())
					)
				: this.subTaskAvailableUsersFuse.hasOwnProperty("list")
				? this.subTaskAvailableUsersFuse.list
				: this.subTaskAvailableUsersFuse;
		},
	},
	watch: {
		menu(val) {
			if (!val) {
				this.$emit("menuClosed", true);
				this.search = null;
			}
		},
	},
};
</script>
