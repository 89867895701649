<template>
  <div
    class="attach-media-from-workspace q-ma-md relative-position cursor-pointer shared-media-card-hover border-radius10"
    @click="selectionMedia(media)"
    v-if="view === 'GridView'"
    :style="{
      'background-color': !isImg && !isGoogle && mediaPreview.bgColor,
    }"
  >
    <div>
      <span
        :class="[
          'custom-selection-checkbox absolute shared-folder-grid cursor-pointer ',
          isSelected && 'custom-selection-checkbox-visible',
        ]"
      >
        <div
          class="selected-custom-selection-checkbox grid"
          v-if="isSelected"
        ></div>
      </span>
      <div
        class="pos-absolute media-comments-badge"
        v-if="
          media.type.match(/image.*/) &&
          media.commentPoint.length > 0 &&
          !isSelected
        "
        :style="
          media.commentPoint.length == completedMediaPoints.length
            ? `background: #15d89a`
            : ''
        "
      >
        <span>{{
          completedMediaPoints.length + "/" + media.commentPoint.length
        }}</span>
      </div>
      <div
        v-if="isVideo && !isDropbox"
        class="row justify-center align-center pos-absolute grid-view-video-icon"
      >
        <div class="text-center flex-1">
          <q-btn
            :icon="$icons.mdiFileVideoOutline"
            style="color: #FF001F;"
            size="50px"
            round
            dense
            flat
          />
        </div>
      </div>
      <div v-if="isGoogle" class="showing-file-type">
        <q-btn
          :icon="$icons.fabGoogleDrive"
          dense
          color="pink"
          text-color="white"
          round
          size="sm"
        />
      </div>
      <div v-if="isDropbox" class="showing-file-type">
        <q-btn
          :icon="$icons.fabDropbox"
          dense
          color="pink"
          text-color="white"
          round
          size="sm"
        />
      </div>
      <img :src="imgSrc" class="border-radius10" />
      <div
        v-if="media.filename && media.filename.length <= 19"
        class="ellipses an-14 text-center"
      >
        {{ media.filename }}
      </div>
      <div v-else class="ellipses an-14 text-center">
        {{ media.filename && media.filename.substring(0, 16) + "..." }}
      </div>
    </div>
  </div>
  <div
    class="vabotu-tbl-row row items-center section-media-hover"
    v-else
    @click="selectionMedia(media)"
  >
    <div class="col-8">
      <q-list class="list list--two-line vb-tbody-list">
        <template>
          <q-item>
            <q-item-section class="items-center" avatar>
              <span class="custom-selection-checkbox">
                <div
                  class="selected-custom-selection-checkbox"
                  v-if="isSelected"
                ></div>
              </span>
            </q-item-section>
            <q-item-section avatar>
              <q-avatar rounded size="40px" style="border-radius: 6px">
                <div v-if="isGoogle" class="showing-file-type-table">
                  <q-btn
                    :icon="$icons.fabGoogleDrive"
                    dense
                    color="pink"
                    text-color="white"
                    round
                    size="sm"
                  />
                </div>
                <div v-if="isDropbox" class="showing-file-type-table">
                  <q-btn
                    :icon="$icons.fabDropbox"
                    dense
                    color="pink"
                    text-color="white"
                    round
                    size="sm"
                  />
                </div>
                <div
                  v-if="isVideo"
                  class="row justify-center items-center absolute video-icon"
                >
                  <div class="text-center flex-1">
                    <q-btn
                      :icon="$icons.mdiFileVideoOutline"
                      style="color: #FF001F;"
                      size="16px"
                      round
                      dense
                      padding="0"
                      flat
                    />
                  </div>
                </div>
                <img
                  :src="imgSrc"
                  class="cursor-pointer"
                  style="border-radius: 7px"
                />
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <div class="full-width">
                <div class="q-ml-xs ellipsis" v-tooltip>
                  {{ media.filename }}
                  <q-tooltip v-if="isTruncated">
                    {{ media.filename }}
                  </q-tooltip>
                </div>
              </div>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </div>
    <div class="col-2">
      <q-list>
        <q-item>
          <q-item-section>
            <q-item-label>
              {{ media.created_at | tableDateFormate }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="col-2">
      <q-list>
        <q-item>
          <q-item-section avatar>
            <q-item-label> {{ media.size | filesizeFormat }} MB </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>
<script>
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
export default {
  name: "SharedMedia",
  props: {
    media: Object,
    selectionMedia: Function,
    selectedFiles: Array,
    view: String,
  },
  data() {
    return {
      isTruncated: false,
    };
  },
  mixins: [FileExtensionMixin],
  computed: {
    isGoogle() {
      return this.media.file_resource === "google";
    },
    isDropbox() {
      return this.media.file_resource === "dropbox";
    },
    mediaPreview() {
      return this.transformMedia(this.media);
    },
    imgSrc() {
      if (this.isGoogle) {
        return this.media.thumb;
      }
      if (this.isDropbox && this.isImg) {
        return this.media.thumb;
      }
      if (this.isDropbox && this.isVideo) {
        return "/static/media-icons/Mov.svg";
      }
      // Used Mixin : FileExtensionMixin
      return this.mediaPreview.type;
    },
    isImg() {
      return this.media.type.match(/image.*/);
    },
    isVideo() {
      return this.media.type.match(/video.*/);
    },
    isSelected() {
      if (this.selectedFiles) {
        return this.selectedFiles.map((a) => a.id).includes(this.media.id);
      }
      return null;
    },
    getMedia() {
      if (this.media) {
        this.media.commentPoint.forEach((commentPoint) => {
          commentPoint["comments"] = [];
          commentPoint["menuFlag"] = false;
          if (commentPoint.last_label === "#15d89a") {
            commentPoint["completed"] = true;
          } else {
            commentPoint["completed"] = false;
          }
        });
      }
      return this.media;
    },
    completedMediaPoints() {
      return this.getMedia.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
  },
};
</script>
<style scoped>
.comment-point {
  position: absolute;
  right: -3px;
  top: -2px;
  width: 25px;
  height: 15px;
  border-radius: 9.5px;
  display: flex;
  color: white;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  z-index: 1;
  background: #ff3279;
}
.video-icon {
  width: 40px;
  height: 40px;
  top: 0;
  background: #FFEFF1;
  border-radius: 6px;
}
</style>
