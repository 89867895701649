<template>
  <div
    class="bg-white new-mention-menu"
    ref="mentionMenu"
    v-if="open && filteredUsers && filteredUsers.length"
    :style="
      isAbPosition && bottomPositions
        ? { top: topPositions + 'px', left: leftPositions + 'px', bottom: bottomPositions + 'px' }
        : isAbPosition ? { top: topPositions + 'px', left: leftPositions + 'px'} : {}
    "
  >
    <q-list>
      <q-item
        v-for="(user, i) in filteredUsers"
        :key="i"
        :ref="`mention${i}`"
        @click.native="() => $emit('insertMention', user.username)"
        :style="i === index && { background: '#007AFF', color: 'white' }"
        @mouseover.native="index = i"
        clickable
      >
        <template v-if="user.username === 'all'">
          <q-item-section
            style="min-width:45px"
            class="q-pr-none justify-center q-pl-sm"
            avatar
          >
            <q-icon :name="$icons.matPeople" size="20px" />
          </q-item-section>
          <q-item-section>
            all
          </q-item-section>
        </template>
        <template v-else>
          <q-item-section style="min-width:45px" class="q-pr-none" avatar>
            <q-avatar size="md" color="blue-grey-11">
              <img
                :src="user.pic"
                :alt="user.first"
                class="text-avatar-style"
                v-if="user.pic"
              />
              <!-- <span
                class="text-avatar-style row justify-center align-center"
                v-else
                >{{ user.first | firstChar }}{{ user.last | firstChar }}</span
              > -->
              <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="user.first+' '+user.last"></avatar>
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ user.fullname }}</q-item-label>
            <q-item-label class="text-body2">{{ user.username }}</q-item-label>
          </q-item-section>
        </template>
      </q-item>
    </q-list>
  </div>
</template>

<script>
import Avatar from 'vue-avatar';

export default {
  name: "MentionsPicker",
  props: ["query", "users", "topPositions", "leftPositions", "isAbPosition", "bottomPositions"],
  data() {
    return {
      open: false,
      index: 0,
    };
  },
  components:{
    Avatar,
  },
  computed: {
    filteredUsers() {
      if (this.menuOption && this.menuOption.length) {
        const query = this.query.slice(1);
        return query !== ""
          ? this.menuOption.filter((user) => {
              return (
                user.username.toLowerCase().includes(query.toLowerCase()) ||
                (user.first &&
                  user.first.toLowerCase().includes(query.toLowerCase())) ||
                (user.last &&
                  user.last.toLowerCase().includes(query.toLowerCase()))
              );
            })
          : this.menuOption;
      }
      return null;
    },
    menuOption() {
      if (this.users && this.users.length) {
        const users = [...this.users];
        if (!users[0].username.includes("all")) {
          users.unshift({
            username: "all",
          });
        }
        return users;
      }
      return null;
    },
  },
  methods: {
    handleScroll(val) {
      const ref = `mention${val}`;
      if (this.$refs[ref] && this.$refs[ref][0].$el) {
        const elmnt = this.$refs[ref][0].$el;
        const scroll = elmnt.offsetTop - 184;
        if (scroll > 0) {
          this.$refs.mentionMenu.scrollTop = scroll;
        } else {
          this.$refs.mentionMenu.scrollTop = 0;
        }
      }
    },
    handleKeyDown(event) {
      if (event.key === "Escape") {
        event.preventDefault();
        this.open = false;
      }
      if (event.key === "ArrowUp") {
        event.preventDefault();
        this.index = Math.max(this.index - 1, 0);
        this.handleScroll(this.index);
      }
      if (event.key === "ArrowDown") {
        event.preventDefault();
        this.index = Math.min(this.index + 1, this.filteredUsers.length - 1);
        this.handleScroll(this.index);
      }
      if (event.key === "Enter") {
        // Logic for Enter Click
        event.preventDefault();
        if (this.filteredUsers && this.filteredUsers.length) {
          // if (this.index === 0) {
          //   this.$emit("insertMention", "all");
          // } else {
          this.$emit("insertMention", this.filteredUsers[this.index].username);
          // }
        }
      }
    },
    handleClick() {
      if (this.filteredUsers && this.filteredUsers.length) {
        this.$emit("insertMention", this.filteredUsers[this.index].username);
      }
      this.open = false;
    },
  },
  watch: {
    query(query) {
      if (query !== null) {
        this.open = true;
      } else {
        this.open = false;
      }
    },
    open(open) {
      if (open) {
        // start listening for keys
        document.addEventListener("click", this.handleClick, true);
        document.addEventListener("keydown", this.handleKeyDown, true);
      } else {
        document.removeEventListener("click", this.handleClick, true);
        document.removeEventListener("keydown", this.handleKeyDown, true);
        this.index = 0;
      }
    },
  },
};
</script>
