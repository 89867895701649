import { GetSignedDownloadUrlMutation } from "@/gql";

export async function getDownloadUrl(id, api, type = "media") {
  if (id) {
    const response = await api.mutate({
      mutation: GetSignedDownloadUrlMutation,
      variables: {
        id,
        type
      }
    });
    if (response) {
      return response.data.getSignedDownloadUrl;
    }
  }
  return null;
}
