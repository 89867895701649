<template>
  <q-dialog
    :value="isFolderOpen"
    transition-hide="none"
    transition-show="none"
    @hide="closeFolder"
  >
    <q-card
      class="folder-details q-pt-none q-pb-md full-height overflow-hidden"
    >
      <q-card-section class="column full-height flex-no-wrap q-pa-none">
        <div class="stick-at-top q-pt-md q-px-lg" style="z-index: 111">
          <div class="row justify-between items-center q-mb-sm">
            <div class="row items-center">
              <q-icon class="folder-icon" :name="$icons.matFolder" />
              <span class="folder-title q-ml-sm">{{ folder.title }}</span>
              <div class="folder-title q-ml-sm">
                {{ folderMedia.length && folderMedia.length }}
              </div>
            </div>
            <div class="row items-center">
              <div>
                <q-btn
                  v-if="isGridView"
                  size="md"
                  flat
                  round
                  dense
                  :icon="$icons.matDashboard"
                  color="black"
                  @click="isGridView = !isGridView"
                  class="toolbar-buttons"
                >
                  <q-tooltip> Grid View </q-tooltip>
                </q-btn>
                <q-btn
                  v-else
                  size="md"
                  flat
                  round
                  dense
                  color="black"
                  :icon="$icons.matViewList"
                  @click="isGridView = !isGridView"
                  class="toolbar-buttons"
                >
                  <q-tooltip> List View </q-tooltip>
                </q-btn>
                <q-btn
                  flat
                  color="blue-grey-8"
                  size="md"
                  label="Cancel"
                  class="q-mx-sm"
                  @click="clearSelection(selectedFolderMedia)"
                />
                <q-btn
                  text-color="white"
                  size="md"
                  label="Attach"
                  :disabled="!selectedFolderMedia"
                  @click="attachMediaToPost(selectedFolderMedia)"
                  style="background: #007aff"
                />
              </div>
              <!-- <div>
                <q-btn
                  dense
                  round
                  flat
                  :icon="$icons.matClose"
                  color="black"
                  @click.native="closeFolder"
                />
              </div> -->
            </div>
          </div>
          <div
            style="border-bottom: solid 1px #e3e8ed; margin: 0px -32px 0"
          ></div>
        </div>
        <div
          v-if="mediaFolderLoading"
          class="row full-height items-center justify-center"
        >
          <q-circular-progress
            indeterminate
            :thickness="0.1"
            size="75px"
            color="primary"
            class="q-ma-md"
          />
        </div>
        <div class="overflow-auto q-pl-md" v-else>
          <template>
            <div
              v-if="folderMedia.length === 0"
              class="row justify-center q-mt-xl"
            >
              <div class="card text-center">
                <div class="card__title card__title--primary q-mt-md q-pa-sm">
                  <div>
                    <q-avatar
                      size="60px"
                      :style="{ background: 'rgba(0, 196, 134, 0.2)' }"
                    >
                      <q-icon
                        color="teal-14"
                        size="30px"
                        :name="$icons.matDescription"
                      />
                    </q-avatar>
                    <div
                      class="title nolabel--text q-mt-sm q-mb-xs an-15 medium-text"
                    >
                      Files
                    </div>
                    <span class="nolabel--text an-15 regular-text">
                      There are no files
                      <br /><span>in this folder.</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-if="folderMedia.length !== 0">
            <div v-if="isGridView">
              <div
                :key="section.id"
                :data-id="section.id"
                v-for="section in folderSection"
                class="group-hover"
              >
                <template
                  v-if="
                    sectionMedia[section.id] && sectionMedia[section.id].length
                  "
                >
                  <div
                    style="
                      font-size: 26px;
                      padding: 5px 20px 5px 20px;
                      font-weight: 600;
                    "
                  >
                    {{ section.title }}
                  </div>
                  <div class="row flex-wrap scroll-y q-pl-md media-scroll">
                    <template v-for="media in sectionMedia[section.id]">
                      <SharedMedia
                        :key="media.id"
                        :media="media"
                        :selectionMedia="selectionMedia"
                        :selectedFiles="selectedFiles"
                        view="GridView"
                      />
                    </template>
                  </div>
                </template>
              </div>
              <div
                v-if="folderRootMedia.length"
                style="margin-left: -32px; margin-right: 0px"
                class="root-section-divider"
              ></div>
              <div class="row flex-wrap scroll-y q-pl-md media-scroll">
                <template v-for="media in folderRootMedia">
                  <SharedMedia
                    :key="media.id"
                    :media="media"
                    :selectionMedia="selectionMedia"
                    :selectedFiles="selectedFiles"
                    view="GridView"
                  />
                </template>
              </div>
            </div>
            <div
              class="fill-height flex-x flex-wrap items-start q-px-md"
              v-else
            >
              <div class="flex-1 folder-open-view">
                <div class="row">
                  <div class="col-8">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <div class="greybtn--text text-midi">Name</div>
                      </q-item-section>
                    </q-item>
                  </div>
                  <div class="col-2">
                    <q-item>
                      <q-item-section avatar>
                        <q-item-label class="greybtn--text text-midi">
                          Uploaded
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                  <div class="col-2">
                    <q-item>
                      <q-item-section avatar>
                        <q-item-label class="greybtn--text text-midi">
                          Size
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <div
                  :key="section.id"
                  :data-id="section.id"
                  v-for="section in folderSection"
                  class="group-hover"
                >
                  <template
                    v-if="
                      sectionMedia[section.id] &&
                      sectionMedia[section.id].length
                    "
                  >
                    <div
                      style="
                        font-size: 26px;
                        padding: 5px 0px;
                        font-weight: 600;
                      "
                    >
                      {{ section.title }}
                    </div>
                    <template v-for="media in sectionMedia[section.id]">
                      <SharedMedia
                        :key="media.id"
                        :media="media"
                        :selectionMedia="selectionMedia"
                        :selectedFiles="selectedFiles"
                        view="ListView"
                      />
                    </template>
                  </template>
                </div>
                <div
                  v-if="folderRootMedia.length"
                  style="margin-left: -32px; margin-right: 0px"
                  class="root-section-divider"
                ></div>
                <!-- <div class="row flex-wrap scroll-y q-pl-md media-scroll"> -->
                <template v-for="media in folderRootMedia">
                  <SharedMedia
                    :key="media.id"
                    :media="media"
                    :selectionMedia="selectionMedia"
                    :selectedFiles="selectedFiles"
                    view="ListView"
                  />
                </template>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import SharedMedia from "@/components/SharedMedia/SharedMedia";

import { MediaFolderQuery } from "@/gql";
export default {
  props: [
    "isFolderOpen",
    "folder",
    "closeFolder",
    "selectionMedia",
    "selectedFiles",
    "clearSelection",
    "isFromDocEditor",
    "attachMediaToPost",
  ],
  api: {
    mediaFolder: {
      query: MediaFolderQuery,
      defaultValue: [],
      variables() {
        return {
          folder_id: parseInt(this.folder.id),
        };
      },
      cacheKey() {
        return `MediaFolderQuery:${parseInt(this.folder.id)}`;
      },
    },
  },
  components: { SharedMedia },
  data() {
    return {
      mediaSearch: "",
      isGridView: false,
    };
  },
  computed: {
    sectionMedia() {
      return groupBy(this.folderMedia, "media_card_id");
    },
    folderSection() {
      return orderBy(this.mediaFolder.mediaCard, ["sequence"]);
    },
    folderRootMedia() {
      return this.folderMedia.filter((item) => !item.media_card_id);
    },
    selectedFolderMedia() {
      const selectedMediaIds = this.selectedFiles.map((m) => m.id);
      return this.folderMedia.filter((m) => selectedMediaIds.includes(m.id));
    },
    folderMedia() {
      if (this.mediaFolder.media) {
        const folderMedia = this.mediaFolder.media;
        if (this.isFromDocEditor) {
          const filteredMedia = folderMedia.filter((m) => {
            if (m.type.match(/image.*/)) {
              return m;
            }
          });
          return uniqBy(
            orderBy(
              filteredMedia.filter(
                (o) =>
                  o.filename
                    .toLowerCase()
                    .includes(this.mediaSearch.toLowerCase()) ||
                  o.file_resource
                    .toLowerCase()
                    .includes(this.mediaSearch.toLowerCase())
              ),
              ["folder_sequence"]
            ),
            "id"
          );
        }
        return uniqBy(
          orderBy(
            this.mediaFolder.media.filter(
              (o) =>
                (o.filename &&
                  o.file_resource &&
                  o.filename
                    .toLowerCase()
                    .includes(this.mediaSearch.toLowerCase())) ||
                o.file_resource
                  .toLowerCase()
                  .includes(this.mediaSearch.toLowerCase())
            ),
            ["folder_sequence"]
          ),
          "id"
        );
      } else {
        return [];
      }
    },
  },
};
</script>
