<template>
  <div class="q-mx-md shared-file-list-view">
    <div
      class="q-mt-xs an-15 medium-text q-mx-lg q-mb-sm"
      :class="fileSelectInfoCss && ['q-mb-md']"
    >
      <strong>
        {{ selectedFiles.length }}
        <span v-if="selectedFiles.length > 1">files</span>
        <span v-else>file</span>
      </strong>
      selected
    </div>
    <div class="column">
      <div class="full-width">
        <div class="flex-1">
          <div class="row q-mx-lg">
            <div class="col-8">
              <q-item class="q-pa-none">
                <q-item-section avatar>
                  <div class="greybtn--text text-midi">Name</div>
                </q-item-section>
              </q-item>
            </div>
            <div class="col-2">
              <q-item>
                <q-item-section avatar>
                  <q-item-label class="greybtn--text text-midi">
                    Uploaded
                  </q-item-label>
                </q-item-section>
              </q-item>
            </div>
            <div class="col-2">
              <q-item>
                <q-item-section avatar>
                  <q-item-label class="greybtn--text text-midi">
                    Size
                  </q-item-label>
                </q-item-section>
              </q-item>
            </div>
          </div>
        </div>
        <template v-if="!fromFolder">
          <div class="group-hover">
            <template v-for="folder in folderList">
              <shared-folder
                :key="folder.id"
                :folder="folder"
                :selectionFolder="selectionFolder"
                :selectedFiles="selectedFiles"
                :openFolder="openFolder"
                view="ListView"
              />
            </template>
          </div>
        </template>
        <div class="group-hover">
          <template v-for="media in imageList">
            <SharedMedia
              :key="media.id"
              :media="media"
              :selectionMedia="selectionMedia"
              :selectedFiles="selectedFiles"
              view="ListView"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="full-width q-pr-sm text-center" v-if="isloadingMedia">
      <q-circular-progress
        indeterminate
        size="30px"
        :thickness="0.2"
        color="primary"
      />
    </div>
  </div>
</template>
<script>
import SharedMedia from "@/components/SharedMedia/SharedMedia";
import SharedFolder from "@/components/SharedMedia/SharedFolder";

export default {
  name: "ListView",
  props: {
    selectionMedia: Function,
    selectionFolder: Function,
    selectedFiles: Array,
    imageList: Array,
    isloadingMedia: Boolean,
    folderList: Array,
    fromFolder: Boolean,
    openFolder: Function,
  },
  data() {
    return {
      fileSelectInfoCss: false,
    };
  },
  mounted() {
    const scrollElement = document.getElementsByClassName("media-scroll")[0];
    if (scrollElement) {
      scrollElement.addEventListener("scroll", this.handlingScroll);
    }
  },
  beforeDestroy() {
    const scrollElement = document.getElementsByClassName("media-scroll")[0];
    if (scrollElement) {
      scrollElement.removeEventListener("scroll", this.handlingScroll);
    }
  },
  components: {
    SharedMedia,
    SharedFolder,
  },
  methods: {
    handlingScroll() {
      if (document.getElementsByClassName("media-scroll")[0].scrollTop > 0) {
        this.fileSelectInfoCss = true;
      } else {
        this.fileSelectInfoCss = false;
      }
    },
  },
};
</script>
<style lang="scss">
.shared-file-list-view {
  .vabotu-tbl-row {
    cursor: pointer;
    &:hover {
      background-color: #eff2f6;
    }
  }
}
</style>
