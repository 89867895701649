<template>
  <q-dialog
    no-backdrop-dismiss
    transition-hide="none"
    transition-show="none"
    @hide="cancelMethod"
    no-esc-dismiss
    :value="value"
  >
    <q-card class="q-px-lg q-py-md bg-white copy-move-dialog">
      <div class="row title q-mb-md">
        <span class="q-mx-auto">Move files to:</span>
      </div>
      <q-btn
        outline
        size="md"
        class="dropdown-set full-width q-mt-md"
        no-caps
        style="font-weight:400"
        :icon-right="$icons.matKeyboardArrowDown"
        :label="`Folder: ${selectedSection ? selectedSection.title : 'Root'}`"
      >
        <q-menu v-if="filteredSections">
          <div class="search-member q-pa-sm">
            <q-input
              autofocus
              ref="search"
              v-model="searchSection"
              outlined
              placeholder="Filter sections"
              clearable
              dense
            >
              <template v-slot:prepend>
                <q-icon :name="$icons.matSearch" />
              </template>
            </q-input>
          </div>
          <q-list class="workspace-menu">
            <q-item
              @click="chooseSection(section)"
              v-for="section in filteredSections"
              :key="section.id"
              clickable
              v-close-popup
            >
              <q-item-section>
                {{ section.title }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          @click="cancelMethod"
        />
        <!-- <q-btn
          color="primary"
          dense
          no-caps
          label="Rename folder"
          style="width:120px"
          v-if="renameFolder"
          @click="renameFolderHandler"
          :loading="loading"
        /> -->
        <q-btn
          color="primary"
          dense
          no-caps
          label="Move files"
          class="create-folder-btn"
          @click="moveFolderSection"
        />
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import orderBy from "lodash/orderBy";
import Fuse from "fuse.js";

export default {
  name: "MoveFileSectionFolderDialog",
  api: {},
  props: [
    "value",
    "currentFolderSection",
    "folderSection",
    "cancelMethod",
    "moveFolderSectionHandler",
  ],
  data() {
    return {
      searchSection: null,
      selectedSection: null,
    };
  },
  mounted() {
    //this.selectedSection = this.currentFolderSection;
  },
  methods: {
    chooseSection(section) {
      this.selectedSection = section;
    },
    moveFolderSection() {
      this.moveFolderSectionHandler(this.selectedSection);
    },
  },
  computed: {
    sectionIds() {
      return this.folderSection.map((m) => {
        if (this.currentFolderSection.id != m.id) {
          return m.id;
        }
      });
    },
    filteredSectionFuse() {
      if (this.folderSection) {
        const folderSection = this.folderSection.filter((section) =>
          this.sectionIds.includes(section.id)
        );
        const orderedWorkspaces = orderBy(folderSection, ["title"], ["asc"]);
        return new Fuse(orderedWorkspaces, {
          keys: ["title"],
          shouldSort: true,
        });
      }
      return this.folderSection.filter(
        (section) => !this.sectionIds.includes(section.id)
      );
    },
    filteredSections() {
      let data = this.searchSection
        ? this.filteredSectionFuse.search(this.searchSection)
        : this.filteredSectionFuse.hasOwnProperty("list")
        ? this.filteredSectionFuse.list
        : this.filteredSectionFuse;
      return data;
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: 600;
  font-size: 16px !important;
}
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
.workspace-menu {
  min-width: 140px;
  max-height: 190px;
  overflow: auto;
}
</style>
