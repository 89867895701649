import { CreateDocumentMutation } from "@/gql";
export default {
  data() {
    return {
      documentDialog: false,
      createdDoc: null,
      activeDocumentId: null,
    };
  },
  methods: {
    async openDocEditor(workspaceId, folderId = null) {
      try {
        const response = await this.$api.mutate({
          mutation: CreateDocumentMutation,
          variables: {
            title: "",
            workspace_id: workspaceId,
            ...(folderId && { folder_id: folderId }),
          },
        });
        this.createdDoc = response.data.createDocument;
        this.activeDocumentId = response.data.createDocument.id;
        if (folderId) {
          const mediaFolder = this.$api.getEntity("mediaFolder", folderId);
          
          const mediaFoldersQuery = this.$api.getQuery(
            `MediaFoldersQuery:${workspaceId}`
            );

          if (mediaFolder.media) {
            mediaFolder.media.push(response.data.createDocument);
          } else {
            mediaFolder.media = [response.data.createDocument];
          }
          if(mediaFoldersQuery.data) {
            mediaFoldersQuery.data.mediaFolders =
            mediaFoldersQuery.data.mediaFolders.map((f) => {
              if (f.id === folderId) {
                if (f.media) {
                  f.media.push(response.data.createDocument);
                } else {
                  f.media = [response.data.createDocument];
                }
              }
              return f;
            });
          }
        } else {
          const query = this.$api.getQuery(`MediaQuery:${workspaceId}`);
          const documentQuery = this.$api.getQuery(
            `DocumentsQuery:${workspaceId}`
          );
          query.data.newMedia.push(response.data.createDocument);
          documentQuery.data.document.push(response.data.createDocument);
        }
        if (!this.documentDialog) {
          this.documentDialog = true;
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    editDoc(doc) {
      this.createdDoc = doc;
      this.activeDocumentId = doc.id;
      this.documentDialog = true;
    },
    closeDocEditor() {
      this.createdDoc = null;
      this.documentDialog = false;
    },
    setActiveDocument(documentId) {
      this.activeDocumentId = documentId;
    },
  },
};
