import Vue from "vue";
import getUnixTime from "date-fns/getUnixTime";
import {
  GetNewMediaListMutation,
  GetMediaDetailMutation,
  CreateMediaCommentPointMutation,
  DeleteMediaCommentPointMutation,
  UpdateMediaCOmmentPointMutation,
  UpdateMediaCommentPointLabelMutation,
  DeleteMediaCommentMutation,
  UpdateMediaCommentMutation,
  CreateMediaCommentMutation,
  GetNewMediaPointMutation,
  GetMediaPointMutation,
  GetMediaCommentMutation,
  DeleteMediaMutation,
} from "@/gql";
import mixpanel from "@/mixpanel.js";

export default {
  beforeCreate() {
    this.$eventBus.$off("checkGlobalMediaAvaliable");
    this.$eventBus.$off("getDriveMedia");
  },
  created() {
    this.$eventBus.$on(
      "checkGlobalMediaAvaliable",
      this.checkGlobalMediaAvaliable
    );
    this.$eventBus.$on("getDriveMedia", this.getDriveMedia);
  },
  methods: {
    async getDriveMedia(mediaIds) {
      if (mediaIds && mediaIds.length) {
        const response = await this.$api.mutate({
          mutation: GetNewMediaListMutation,
          variables: {
            ids: mediaIds,
          },
        });

        const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
        query.data.newMedia.push(...response.data.getNewMediaList);
      }
    },
    async updateGloabalMediaCacheForPointing(id) {
      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: [id],
        },
      });
      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
      query.data.newMedia.push(...response.data.getNewMediaList);
    },
    async checkGlobalMediaAvaliable(id) {
      const { data } = await this.$api.getQuery(
        `MediaQuery:${this.currentWorkspace}`
      );
      if (data) {
        const findMedia = data.newMedia.find((item) => item.id === id);
        if (findMedia) {
          // console.log("")
        } else {
          this.updateGloabalMediaCacheForPointing(id);
        }
      }
    },
    async closeMediaModel(variable) {
      await this.$api.mutate({
        mutation: GetMediaDetailMutation,
        variables: variable,
      });
    },
    clickonmoreoption(item) {
      if (item.title === "Add Comment") {
        let elmnt;

        const point = {
          x: item.x,
          y: item.y,
          media_id: item.gridMediaId,
        };
        this.createMediaCommentPoint(point, item.fromMessagingEditor);

        if (item.forscroll === "yes") {
          elmnt = document.getElementsByClassName("modal-container")[0];
          elmnt.scrollLeft = 0;
          elmnt.scrollTop = 0;
        }

        if (item.forscroll === "yesforuploader") {
          elmnt = document.getElementsByClassName(
            "media-uploader-block-container"
          )[0];
          elmnt.scrollLeft = 0;
          elmnt.scrollTop = 0;
        }
      }
    },
    async createMediaCommentPoint(point, fromMessagingEditor) {
      const fakePoint = {
        id: Math.round(Math.random() * -1000000),
        last_label: "nolabel",
        x: point.x,
        y: point.y,
        user_id: this.user.id,
        media_id: point.media_id,
        __typename: "mediaCommentPoint",
      };
      const mediaEntity = this.$api.getEntity("media", point.media_id);
      mediaEntity.commentPoint.push(fakePoint);
      if (fromMessagingEditor) {
        let value = {};
        const files = this.$store.getters.workspaceEditorMedia(
          this.currentCompany.companyId,
          this.currentWorkspace.id
        );

        if (files) {
          value = files;
        }
        Vue.set(value, point.media_id, mediaEntity);
      }
      const res = await this.$api.mutate({
        mutation: CreateMediaCommentPointMutation,
        variables: point,
      });
      mixpanel.track("Media Point Create");
      mediaEntity.commentPoint = mediaEntity.commentPoint.map((p) => {
        return p === fakePoint ? res.data.createMediaCommentPoint : p;
      });
      if (res) {
        this.$eventBus.$emit(
          "updateFileList",
          res.data.createMediaCommentPoint
        );
        this.$nextTick(() => {
          this.$eventBus.$emit(
            "automationOfPoint",
            res.data.createMediaCommentPoint
          );
        });
      }
    },
    async deleteCommentPoint(deletedata) {
      const variables = {
        id: deletedata.id,
      };
      const mediaEntity = this.$api.getEntity("media", deletedata.media_id);
      mediaEntity.commentPoint = mediaEntity.commentPoint.filter(
        (p) => p.id !== deletedata.id
      );
      await this.$api.mutate({
        mutation: DeleteMediaCommentPointMutation,
        variables,
      });
      mixpanel.track("Media Point Delete");
    },
    updatepositions(updateposition) {
      const variable = {
        id: updateposition.id,
        x: updateposition.x,
        y: updateposition.y,
      };
      const commentPoint = this.$api.getEntity(
        "mediaCommentPoint",
        updateposition.id
      );
      commentPoint.x = updateposition.x;
      commentPoint.y = updateposition.y;
      this.updateposition(variable);
    },
    async updateposition(variable) {
      await this.$api.mutate({
        mutation: UpdateMediaCOmmentPointMutation,
        variables: variable,
      });
      mixpanel.track("Media Point Move");
    },
    async updateMediaCommentPointLabel(themedata) {
      const mediaCommentEntity = this.$api.getEntity(
        "mediaCommentPoint",
        themedata.id
      );
      mediaCommentEntity.last_label = themedata.last_label;
      await this.$api.mutate({
        mutation: UpdateMediaCommentPointLabelMutation,
        variables: themedata,
        skipUpdates: true,
      });
      mixpanel.track("Media Point Label Change");
    },
    async deleteMediaComment(commentData) {
      const variables = {
        id: commentData.commentid,
      };
      await this.$api.mutate({
        mutation: DeleteMediaCommentMutation,
        variables,
      });
      mixpanel.track("Media Comment Delete");
      const mediaEntity = this.$api.getEntity("media", commentData.media_id);
      mediaEntity.comments = mediaEntity.comments.filter(
        (c) => c.id !== commentData.commentid
      );
    },
    async updateComment(data) {
      const mediaCommentEntity = this.$api.getEntity("mediaComment", data.id);
      mediaCommentEntity.comment = data.comment;
      await this.$api.mutate({
        mutation: UpdateMediaCommentMutation,
        variables: data,
      });
      mixpanel.track("Media Comment Update");
    },
    sendComment(commentArg) {
      this.createmediaComment(commentArg);
    },
    async createmediaComment(commentArg) {
      const fakeComment = {
        __typename: "mediaComment",
        id: Math.round(Math.random() * -1000000),
        comment: commentArg.comment,
        user_id: this.user.id,
        media_id: commentArg.media_id,
        media_comments_point_id: commentArg.media_comments_point_id,
        owner: {
          __typename: "user",
          id: this.user.id,
          first: this.user.first,
          last: this.user.last,
          pic: this.user.pic,
          username: this.user.username,
        },
        created_at: getUnixTime(new Date()),
        updated_at: getUnixTime(new Date()),
      };

      const mediaEntity = this.$api.getEntity("media", commentArg.media_id);
      mediaEntity.comments.push(fakeComment);

      const response = await this.$api.mutate({
        mutation: CreateMediaCommentMutation,
        variables: commentArg,
      });
      mixpanel.track("Media Comment Create");
      mediaEntity.comments = mediaEntity.comments.map((c) =>
        c === fakeComment ? response.data.createMediaComment : c
      );
    },
    async reactivityFinalCreateMediaPoint(point) {
      const variable = {
        media_id: point.media_id,
        id: point.id,
      };

      const response = await this.$api.mutate({
        mutation: GetNewMediaPointMutation,
        variables: variable,
      });

      const mediaEntity = this.$api.getEntity("media", point.media_id);
      mediaEntity.commentPoint.push(response.data.getNewMediaPoint);

      this.$store.dispatch("addToMedia", {
        workspace: this.currentWorkspace,
        companyId: this.currentCompany.companyId,
        flag: false,
      });
    },
    async onCreateMediaPoint(point) {
      this.reactivityFinalCreateMediaPoint(point);
    },
    async onUpdatePointPosition(variable) {
      const updatedpointdata = {
        id: variable.id,
      };
      await this.$api.mutate({
        mutation: GetMediaPointMutation,
        variables: updatedpointdata,
      });
    },
    async onDeleteMediaPoint(detail) {
      var vm = this;
      const { data } = await this.$api.getQuery(
        `MediaQuery:${this.currentWorkspace}`
      );
      let filteredmedia = data.newMedia.filter((o) => {
        return o.id === detail.media_id;
      });
      var array = filteredmedia[0].commentPoint;
      let index = -1;
      array.find(function(item, i) {
        if (item.id === detail.id) {
          index = i;
          return i;
        }
      });
      array.splice(index, 1);

      vm.$store.dispatch("addToMedia", {
        workspace: this.currentWorkspace,
        companyId: this.currentCompany.companyId,
        flag: false,
      });
    },
    async onDeleteMediaComment(deletecomment) {
      const { data } = await this.$api.getQuery(
        `MediaQuery:${this.currentWorkspace}`
      );
      let filteredmedia = data.newMedia.filter((o) => {
        return o.id === deletecomment.media_id;
      });
      var array = filteredmedia[0].comments;
      let index = -1;
      array.find((item, i) => {
        if (item.id === deletecomment.commentid) {
          index = i;
          return i;
        }
      });
      array.splice(index, 1);
      this.$store.dispatch("addToMedia", {
        workspace: this.currentWorkspace,
        companyId: this.currentCompany.companyId,
        flag: false,
      });
    },
    async onEditMediaComment(data) {
      const variable = {
        id: data.id,
      };
      await this.$api.mutate({
        mutation: GetMediaCommentMutation,
        variables: variable,
      });
      this.$store.dispatch("addToMedia", {
        workspace: this.currentWorkspace,
        companyId: this.currentCompany.companyId,
        flag: false,
      });
    },
    async fetchNewMediaOnCreateReactivity(ids) {
      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids,
        },
      });

      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
      query.data.newMedia.unshift(...response.data.getNewMediaList);
    },
    async removeMedia(id) {
      const variables = {
        id,
      };
      await this.$api.mutate({
        mutation: DeleteMediaMutation,
        variables,
      });
      mixpanel.track("Media Delete");
      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
      query.data.newMedia = query.data.newMedia.filter((f) => f.id !== id);
    },
  },
};
