<template>
  <q-dialog
      transition-hide="none"
      transition-show="none"
      :value="value"
      @keydown.esc="closeHyperlinkModal"
      allow-focus-outside
    >
      <q-card style="min-width: 320px" class="create-folder">
        <div class="row title q-mb-md">
          <span class="q-mx-auto">Add link</span>
        </div>
        <q-input
          placeholder="Type name"
          dense
          outlined
          autofocus
          ref="focustitle"
          v-model="title"
          @blur="$v.title.$touch()"
          :error="fieldErrors('title').length > 0"
          bottom-slots
          no-error-icon
        >
          <template v-slot:error>
            <div class="text-negative">
              {{ fieldErrors("title").length > 0 ? fieldErrors("title")[0] : "" }}
            </div>
          </template>
        </q-input>
        <q-input
          placeholder="Type url"
          dense
          outlined
          autofocus
          ref="focusurl"
          v-model="url"
          @blur="$v.url.$touch()"
          :error="fieldErrors('url').length > 0"
          bottom-slots
          no-error-icon
          @keyup.enter="submitLinkData"
        >
          <template v-slot:error>
            <div class="text-negative">
              {{ fieldErrors("url").length > 0 ? fieldErrors("url")[0] : "" }}
            </div>
          </template>
        </q-input>
        <div class="text-center q-mt-md">
          <q-btn
            color="transperant"
            dense
            flat
            no-caps
            size="14px"
            label="Cancel"
            style="width: 120px"
            class="q-mr-sm"
            @click="closeHyperlinkModal"
          />
          <q-btn
            color="primary"
            dense
            no-caps
            size="14px"
            label="Add"
            class="set-link-btn"
            style="width: 120px"
            :disable="!title || !url"
            @click="submitLinkData"
          />
        </div>
      </q-card>
    </q-dialog>
</template>
<script>
import { url, required } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";

export default {
  name: "AddLinkDialog",
  props: ["value", "closeHyperlinkModal", "addLinkTitle"],
  data() {
    return {
      isDocShortcutDialogOpen: false,
      url: null,
      title: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    url: { url },
    title: { required },
  },
  validationMessages: {
    url: {
      url: "message.validation.url.url",
    },
    title: {
      required: "required",
    },
  },
  mounted() {
    this.$nextTick(() => {
      if(this.addLinkTitle) {
        this.$refs.focusurl.focus();
      } else {
        this.$refs.focustitle.focus();
      }
    });
  },
  methods: {
    submitLinkData(){
      this.$emit('setLinkHandler', this.title, this.url);
    }
  },
  computed: {
  },
  watch: {
    addLinkTitle: {
      immediate: true,
      handler (newVal) {
        this.title = newVal;
      }
    },
  },
};
</script>
