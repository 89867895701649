<template>
  <q-dialog
    no-backdrop-dismiss
    transition-hide="none"
    transition-show="none"
    @hide="cancelMethod"
    :value="value"
  >
    <q-card style="min-width:320px;" class="create-folder">
      <div class="row title q-mb-md">
        <span v-if="renameFolder" class="q-mx-auto">Rename folder</span>
        <span v-else class="q-mx-auto">Create new folder</span>
      </div>
      <q-input
        placeholder="Type folder name"
        dense
        outlined
        autofocus
        v-model="folderName"
        @keydown.enter.exact="
          createFolderHandler(folderName.trim());
          $event.preventDefault();
        "
      />
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="14px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          @click="cancelMethod"
        />
        <!-- <q-btn
          color="primary"
          dense
          no-caps
          label="Rename folder"
          style="width:120px"
          v-if="renameFolder"
          @click="renameFolderHandler"
          :loading="loading"
        /> -->
        <q-btn
          color="primary"
          dense
          no-caps
          :label="renameFolder ? 'Rename folder' : 'Create folder'"
          class="create-folder-btn"
          @click="createFolderHandler(folderName.trim())"
          :loading="loading"
          :disable="folderName ? false : true"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "CreateMediaFolderDialog",
  props: [
    "value",
    "renameFolder",
    "cancelMethod",
    "createFolderHandler",
    "loading",
  ],
  data() {
    return {
      folderName: null,
    };
  },
  mounted() {
    if (this.renameFolder) {
      this.folderName = this.renameFolder.title;
    }
  },
};
</script>
<style scoped>
.title {
  font-weight: 600;
  font-size: 16px !important;
}
.create-folder-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
</style>
