<template>
  <div v-if="editor">
    <document-editor-toolbar
      :editor="editor"
      @textformat="handleTextFormat"
      :isDisabled="isFocused"
      :isMobileView="isMobileView"
      :showButton="showButton"
      :toggleResize="toggleResize"
      :isDraggable="isDraggable"
      v-if="
        !isReadOnly ||
        (isReadOnly && currentUser && currentUser.id == docUserId)
      "
    />
    <div class="editor-scroll-wrapper">
      <div
        v-show="progress"
        class="full-height row image-upload-progress-overlay"
      >
        <div class="flex-1 items-center justify-around row">
          <q-circular-progress
            show-value
            font-size="22px"
            :value="Math.round(progress * 100)"
            size="200px"
            :thickness="0.15"
            color="green"
            track-color="grey-3"
            class="q-ma-md"
          >
            {{ Math.round(progress * 100) }}%
          </q-circular-progress>
        </div>
      </div>
      <div class="q-px-lg">
        <div class="editor-width">
          <div class="full-width" style="min-height: 42px">
            <textarea
              :disabled="
                !isReadOnly ||
                (isReadOnly && currentUser && currentUser.id == docUserId)
                  ? false
                  : true
              "
              data-gramm="false"
              data-gramm_editor="false"
              data-enable-grammarly="false"
              ref="docTitleTextarea"
              :value="title"
              @input="debounceTitleHandler"
              @keydown.enter.prevent="focusContentEdiotr"
              :id="'document-title' + doc.guid"
              :class="[isInvalid && 'error-indicator', 'doc-title full-width']"
              placeholder="Document title"
              @focus="isFocused = true"
              @blur="debounceTitleHandler"
              autofocus
            />
          </div>
          <EditorContent
            style="color: #42526e"
            class="q-mt-sm overflow-hidden"
            :editor="editor"
          >
          </EditorContent>
          <!-- <floating-menu
            :editor="editor"
            :tippy-options="{ duration: 100 }"
            v-show="showButton"
          >
            <button @click="toggleResize" :class="{ 'is-active': isDraggable }">
              Dragging
            </button>
            <button
              @click="toggleResize"
              :class="{ 'is-active': !isDraggable }"
            >
              Resizing
            </button>
          </floating-menu> -->
        </div>
      </div>
    </div>
    <div class="row justify-end q-my-sm q-mr-lg">
      <div class="character-count">
        {{ editor.storage.characterCount.words() }} words /
        {{ editor.storage.characterCount.characters() }}
        characters
      </div>
    </div>

    <q-dialog
      no-backdrop-dismiss
      transition-hide="none"
      transition-show="none"
      no-refocus
      @hide="clsoeHyperlinkModal"
      v-model="hyperlinkModal"
    >
      <q-card style="min-width: 320px" class="create-folder">
        <div class="row title q-mb-md">
          <span class="q-mx-auto">Add URL</span>
        </div>
        <q-input
          placeholder="Type url"
          dense
          outlined
          autofocus
          v-model="url"
          @blur="$v.url.$touch()"
          :error="fieldErrors('url').length > 0"
          bottom-slots
          no-error-icon
          @keydown.enter.prevent="setLinkHandler"
        >
          <template v-slot:error>
            <div class="text-negative">
              {{ fieldErrors("url").length > 0 ? fieldErrors("url")[0] : "" }}
            </div>
          </template>
        </q-input>
        <div class="text-center q-mt-md">
          <q-btn
            color="transperant"
            dense
            flat
            no-caps
            size="14px"
            label="Cancel"
            style="width: 120px"
            class="q-mr-sm"
            @click="clsoeHyperlinkModal"
          />
          <q-btn
            color="primary"
            dense
            no-caps
            label="OK"
            class="set-link-btn"
            @click="setLinkHandler"
          />
        </div>
      </q-card>
    </q-dialog>
    <shared-files-dialog
      v-if="sharedFileDialog"
      v-model="sharedFileDialog"
      @closeDialog="sharedFileDialog = false"
      :filterMedia="imageList"
      :attachMediaToPost="addImageToDocument"
      :currentWorkspaceId="currentWorkspaceId"
    />
    <vue-dropzone-file-upload
      hidden
      ref="vueFileUploader"
      :workspace="currentWorkspaceId"
      :authToken="authToken"
      :fromDocEditor="true"
      @close="fileUploadCloseHandler"
      @setProgress="setProgress"
    ></vue-dropzone-file-upload>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";

import { url } from "vuelidate/lib/validators";
import validationMixin from "@/mixins/validationMixin";

import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Typography from "@tiptap/extension-typography";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import CharacterCount from "@tiptap/extension-character-count";
// import HardBreak from "@tiptap/extension-hard-break";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Placeholder from "@tiptap/extension-placeholder";
import Collaboration from "@tiptap/extension-collaboration";
import FloatingMenu from "@tiptap/extension-floating-menu";
import Details from "@/lib/tiptap-details/tiptap-extension-details";
import DetailsContent from "@/lib/tiptap-content/tiptap-extension-details-content";
import DetailsSummary from "@/lib/tiptap-summary/tiptap-extension-details-summary";
import DocumentEditorToolbar from "@/components/DocumentEditor/DocumentEditorToolbar";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import { ImageExtension } from "@/lib/tiptap-image/tiptap-image";
import { upload } from "@/lib/tiptap-image/Utilities";
import { GetNewMediaListMutation } from "@/gql";
import {
  exportDocumentPDF,
  exportDocument,
  deleteDocumentPDF,
} from "@/services/auth";
export default {
  props: {
    doc: {
      type: Object,
      default: () => null,
    },
    currentUser: {
      type: Object,
      default: () => null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    title: String,
    isMobileView: {
      type: Boolean,
      default: false,
    },
    currentWorkspaceId: {
      type: Number,
      default: null,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    docUserId: Number,
  },
  components: {
    EditorContent,
    DocumentEditorToolbar,
    SharedFilesDialog,
    VueDropzoneFileUpload,
  },
  mixins: [validationMixin],
  validations: {
    url: { url },
  },
  validationMessages: {
    url: {
      url: "message.validation.url.url",
    },
  },
  api: {
    media: {
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  data() {
    return {
      editor: null,
      isFocused: false,
      isInvalid: false,
      hyperlinkModal: false,
      url: null,
      sharedFileDialog: false,
      progress: 0,
    };
  },
  beforeCreate() {
    this.$eventBus.$off("exportDocumentHandler");
  },
  updated() {
    if (this.$refs.docTitleTextarea) {
      if (this.$refs.docTitleTextarea.value) {
        const scrollHeight = this.$refs.docTitleTextarea.scrollHeight;
        this.$refs.docTitleTextarea.style.height = scrollHeight + "px";
      } else {
        this.$refs.docTitleTextarea.style.height = "42px";
      }
    }
  },
  created() {
    let vm = this;
    setTimeout(() => {
      this.editor = new Editor({
        // triggered on every change
        onUpdate: ({ editor }) => {
          this.updateHtml(editor.getHTML());
          // send the content to an API here
        },
        extensions: [
          StarterKit.configure({
            HardBreak: {
              addKeyboardShortcuts() {
                return {
                  Enter: () => this.editor.commands.setHardBreak(),
                  // "Shift-Enter": () => this.editor.commands.setHardBreak(),
                };
              },
            },
            history: false,
          }),
          Placeholder.configure({
            placeholder: "Type or paste your content here",
          }),
          Link.extend({
            addKeyboardShortcuts() {
              return {
                "Mod-k": () => {
                  const previousUrl = vm.editor.getAttributes("link").href;
                  vm.url = previousUrl;
                  vm.hyperlinkModal = true;
                  // return true;
                },
              };
            },
          }).configure({
            openOnClick: false,
          }),
          // Link.configure({
          //   openOnClick: false,
          // }),
          TextStyle,
          Color,
          TaskList,
          TaskItem.configure({
            nested: true,
          }),
          Typography,
          Underline,
          Superscript,
          Subscript,
          Table.extend({
            addKeyboardShortcuts() {
              return {
                "Mod-Enter": () => {
                  this.editor.chain().focus().addRowAfter().run();
                  return true;
                },
              };
            },
          }).configure({
            resizable: true,
          }),
          TableRow,
          TableHeader,
          TableCell,
          TextAlign.configure({
            types: ["heading", "paragraph"],
            alignments: ["left", "right", "center"],
          }),
          Collaboration.configure({
            document: this.doc,
          }),
          Details.configure({
            persist: true,
            HTMLAttributes: {
              class: "details",
            },
          }),
          DetailsContent,
          DetailsSummary,
          ImageExtension(upload),
          FloatingMenu.configure({
            pluginKey: "floatingMenuOne",
            element: document.querySelector(".floating-menu"),
          }),
          CharacterCount.configure({
            mode: "textSize",
          }),
        ],
        editorProps: {},
        editable: this.editable,
      });
    });

    document.addEventListener("click", this.docClickEventHandler, false);
    this.$eventBus.$on("exportDocumentHandler", this.exportDocumentHandler);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.docClickEventHandler, false);
    this.$eventBus.$off("exportDocumentHandler", this.exportDocumentHandler);
    if (!this.editor) return;
    this.editor.destroy();
  },
  methods: {
    updateHtml(html){
      this.$emit("updateDocumentHtml", html);
    },
    addImageToDocument(data) {
      data.forEach((m) => {
        this.editor.chain().focus().setImage({ src: m.file }).run();
      });
      this.sharedFileDialog = false;
    },
    toggleResize() {
      this.editor.chain().focus().toggleResizable().run();
    },
    async exportDocumentHandler(type) {
      let response = null;
      if (type == "pdf") {
        response = await exportDocumentPDF(
          {
            html: this.editor.getHTML(),
            title: this.title,
          },
          this.authToken
        );
      } else {
        response = await exportDocument(
          {
            html: this.editor.getHTML(),
            title: this.title,
          },
          this.authToken
        );
      }

      if (response && response.status_code === 200) {
        window.location = response.data;
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Document exported successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        setTimeout(() => {
          let fielName = this.getQueryParameterByName(
            "fileName",
            response.data
          );
          deleteDocumentPDF(
            {
              file: fielName,
            },
            this.authToken
          );
        }, 5000);
      }
    },
    getQueryParameterByName(name, url) {
      var match = RegExp("[?&]" + name + "=([^&]*)").exec(url);
      return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    },
    focusContentEdiotr() {
      this.editor.commands.focus();
    },
    async docClickEventHandler(event) {
      const element = event.target;
      if (element.tagName.toLowerCase() === "a") {
        const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
        if (isElectron) window.electron.openExternal(element.href);
        else window.open(element.href, "_blank");
      }
    },
    clsoeHyperlinkModal() {
      this.hyperlinkModal = false;
    },
    setLinkHandler() {
      // cancelled
      // this.editor.commands.scrollIntoView();
      if (this.url === null) {
        return;
      }
      if (this.url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        this.hyperlinkModal = false;
        return;
      }
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: this.url })
        .run();
      this.hyperlinkModal = false;
      // this.editor.commands.scrollIntoView();
    },

    inputHandler(event) {
      if (this.isValid(event.target.value)) {
        this.isInvalid = false;
      } else {
        this.isInvalid = true;
      }

      this.$emit("updateTitle", event.target.value);
    },
    isValid(title) {
      if (isEmpty(title) && this.$refs.docTitleTextarea) {
        this.isInvalid = true;
        this.$refs.docTitleTextarea.focus();
        return false;
      }
      this.isInvalid = false;
      return true;
    },
    handleTextFormat({ type, detail }) {
      switch (type) {
        case "undo":
          this.editor.chain().focus().undo().run();
          break;
        case "redo":
          this.editor.chain().focus().redo().run();
          break;
        case "paragraph":
          this.editor.chain().focus().setParagraph().run();
          break;
        case "heading":
          this.editor.chain().focus().toggleHeading({ level: detail }).run();
          break;
        case "bold":
          this.editor.chain().focus().toggleBold().run();
          break;
        case "italic":
          this.editor.chain().focus().toggleItalic().run();
          break;
        case "underline":
          this.editor.chain().focus().toggleUnderline().run();
          break;
        case "strike":
          this.editor.chain().focus().toggleStrike().run();
          break;
        case "subscript":
          this.editor.chain().focus().toggleSubscript().run();
          break;
        case "superscript":
          this.editor.chain().focus().toggleSuperscript().run();
          break;
        case "textalign":
          this.editor.chain().focus().setTextAlign(detail).run();
          break;
        case "color":
          this.editor.chain().focus().setColor(detail).run();
          break;
        case "bullet":
          if (detail === "ordered") {
            this.editor.chain().focus().toggleOrderedList().run();
          } else {
            this.editor.chain().focus().toggleBulletList().run();
          }
          break;
        case "task":
          this.editor.chain().focus().toggleTaskList().run();
          break;
        case "hyperlink": {
          const previousUrl = this.editor.getAttributes("link").href;
          this.url = previousUrl;
          this.hyperlinkModal = true;
          break;
        }
        case "quote":
          this.editor.chain().focus().toggleBlockquote().run();
          break;
        case "code":
          this.editor.chain().focus().toggleCode().run();
          break;
        case "codeblock":
          this.editor.chain().focus().toggleCodeBlock().run();
          break;
        case "hr":
          this.editor.chain().focus().setHorizontalRule().run();
          break;
        case "togglelist":
          if (this.editor.can().setDetails()) {
            this.editor.chain().focus().setDetails().run();
          } else {
            this.editor.chain().focus().unsetDetails().run();
          }
          break;
        case "table":
          /*
              Add empty node to have space above table if table is inserted at
              start of document editor.It is not correct way it is just work around 
              solution for time being.
            */
          this.editor.commands.insertContent("<p><div></div></p>");

          this.editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run();
          break;
        case "addcolbefore":
          this.editor.chain().focus().addColumnBefore().run();
          break;
        case "addcolafter":
          this.editor.chain().focus().addColumnAfter().run();
          break;
        case "addrowbefore":
          this.editor.chain().focus().addRowBefore().run();
          break;
        case "addrowafter":
          this.editor.chain().focus().addRowAfter().run();
          break;
        case "deletetable":
          this.editor.chain().focus().deleteTable().run();
          break;
        case "deleterow":
          this.editor.chain().focus().deleteRow().run();
          break;
        case "deletecol":
          this.editor.chain().focus().deleteColumn().run();
          break;
        case "mergecell":
          this.editor.chain().focus().mergeCells().run();
          break;
        case "splitcell":
          this.editor.chain().focus().splitCell().run();
          break;
        case "addimagefromcomputer":
          this.attachImageToDocEditor();
          break;
        case "addimagefromsharedfiles":
          this.sharedFileDialog = true;
          break;
        default:
          console.warn("no option");
      }
    },
    debounceTitleHandler: debounce(function (event) {
      this.inputHandler(event);
      this.isFocused = false;
    }),
    attachImageToDocEditor() {
      this.$refs.vueFileUploader.openFileDialog();
    },
    setProgress(progress) {
      this.progress = progress;
    },
    async fileUploadCloseHandler(data) {
      /* 
        - data is from dropzone with key value format,
          E.g 8831:{
            file: url from s3
          }
        - adding each image to editor using command
      */
      for (let key in data) {
        const value = data[key];
        this.editor.chain().focus().setImage({ src: value.file }).run();
      }
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
      const mediaIds = data ? map(data, (o) => o.id) : [];
      //Add images to app cache
      if (isEmpty(data)) return;
      if (mediaIds && mediaIds.length) {
        this.updateMediaCache(mediaIds);
      }
    },
    async updateMediaCache(mediaIds) {
      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: mediaIds,
        },
      });
      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspaceId}`);
      query.data.newMedia.unshift(...response.data.getNewMediaList);
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    showButton() {
      return this.editor?.state?.selection?.node?.type?.name === "image";
    },
    isDraggable() {
      return this.editor?.state?.selection?.node?.attrs?.isDraggable;
    },
    isToolbarDisabled() {
      return (
        document.getElementById("document-title") === document.activeElement
      );
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
    imageList() {
      if (this.media) {
        const filterMedia = this.media.filter((m) => {
          if (m.type.match(/image.*/)) {
            return m;
          }
        });
        return uniqBy(orderBy(filterMedia, ["id"], ["desc"]), "id");
      }
      return null;
    },
  },
  watch: {
    doc() {},
    editable(value) {
      this.editor.setEditable(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.error-indicator {
  border: 1px solid #fc173d;
  background-color: #ffe5eb;
  border-radius: 6px;
}
.set-link-btn {
  width: 120px;
  height: 36px;
  font-size: 14px;
}
.floating-menu {
  background: #fc173d;
}
.image-upload-progress-overlay {
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: white;
  opacity: 0.9;
}
</style>
