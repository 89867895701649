import getUnixTime from "date-fns/getUnixTime";

export default {
  data() {
    return {
      gConfig: {
        developerKey: "AIzaSyDNfQHXWFxyOog6kqi-qSLuQmGpImLoK28",
        clientId:
          "16981721378-g5e2oumfn798fafdn2g261365r17hugk.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/drive.file",
      },
    };
  },
  mounted() {
    window.gapi.load("auth2");
    window.gapi.load("picker", () => {
      this.pickerApiLoaded = true;
    });
  },
  methods: {
    handleAuthResult(authResult, type = null, userId) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker(type, userId);
      } else {
        return null;
      }
    },
    createPicker(type = null, userId) {
      if (this.pickerApiLoaded && this.oauthToken) {
        this.picker = new window.google.picker.PickerBuilder()
          .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
          .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
          .addView(
            new window.google.picker.View(window.google.picker.ViewId.DOCS)
          )
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey(this.gConfig.developerKey)
          .setCallback((data) => this.pickerCallback(data, type, userId))
          .build();
        this.picker.setVisible(true);
      }
    },
    pickerCallback(data, type = null, userId) {
      if (
        data[window.google.picker.Response.ACTION] ==
        window.google.picker.Action.PICKED
      ) {
        const driveData = {};
        data.docs.forEach((element) => {
          const obj = {
            id: element.id,
            file: element.url,
            filename: element.name,
            type: element.mimeType.substring(0, 30),
            file_resource: "google",
            size: element.sizeBytes,
            commentPoint: [],
            comments: [],
            thumb: `https://drive.google.com/uc?export="view"&id=${element.id}&sz=w700-h700`,
            user_id: userId,
            workspace_id: null,
            created_at: getUnixTime(new Date()),
            __typename: "media",
          };
          driveData[element.id] = obj;
        });
        this.attachMediaToPost(driveData, "google", type);
      }
    },
    googleDrive(type = null, userId) {
      window.gapi.auth2.authorize(
        {
          client_id: this.gConfig.clientId,
          scope: this.gConfig.scope,
        },
        (authResult) => this.handleAuthResult(authResult, type, userId)
      );
    },
  },
};
