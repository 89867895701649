import format from "date-fns/format";
import subDays from "date-fns/subDays";
import parseJSON from "date-fns/parseJSON";

export default {
  methods: {
    routeTo(name, workspaceId) {
      this.$router.push({
        name,
        params: {
          company: this.$route.params.company,
          workspace: workspaceId,
        },
      });
    },
    timeDifference(current, previous) {
      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;
      if (elapsed < msPerMinute) {
        return "just now";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " m";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " h";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " d";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " m";
      } else {
        return Math.round(elapsed / msPerYear) + " y";
      }
    },
    dateTimeFormat() {
      return this.timeDifference(
        new Date(),
        parseJSON(this.notification.action.created_at)
      );
    },
    trucateContet(content) {
      if (!content) return "";
      return content.substring(0, 165) + "...";
    },
    getTimeDifferenceForComments(date) {
      return this.timeDifference(new Date(), parseJSON(date));
    },
  },
  computed: {
    todaytask() {
      return format(new Date(), "MMM. do, yyyy");
    },
    yesterdayTask() {
      return format(subDays(new Date(), 1), "MMM. do, yyyy");
    },
    postDate() {
      const datetime = format(
        parseJSON(this.notification.action.created_at),
        "MMM. do, yyyy"
      );
      const time = format(
        parseJSON(this.notification.action.created_at),
        "h:mm a"
      );
      if (this.todaytask === datetime) {
        return `Today @${time}`;
      } else if (this.yesterdayTask === datetime) {
        return `Yesterday @${time}`;
      } else {
        return format(
          parseJSON(this.notification.action.created_at),
          "MMM. do @h:mm a"
        );
      }
    },
    actionTakenByFullName() {
      if (this.actionSubject) {
        return this.user && this.user.id === this.actionSubject.id
          ? "You"
          : this.actionSubject.first + " " + this.actionSubject.last;
      }
      return "";
    },
    actionTakenOn() {
      return this.notification.actionTakenOn;
    },
  },
};
