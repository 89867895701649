<template>
  <div
    style=""
    class="group-background-hover group-background-hover-item subtask-main-child position-relative"
    @dblclick="$emit('openSubtaskDialogFromTask', subtask)"
    @mouseover="isHover = true"
    @mouseleave="mouseLeaveHandler"
    :id="'subtask-' + subtask.id"
    :class="[
      (subtask.id == this.$route.query.sub_task ||
        subtask.id == this.subTaskId ||
        subtask.id == this.$route.query.child_sub_task) &&
      !fromNewTask
        ? 'subtask-border'
        : '',
      (datePickermenu || menu || isHover || reactionMenu) && 'bg-active',
    ]"
  >
    <div
      class="row flex-no-wrap q-pt-xs q-pb-xs flex-1 q-px-sm"
      :ref="`subtask-${subtask.id}`"
    >
      <div
        v-if="!fromNewTask && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
        :style="[isHover ? { opacity: 1 } : { opacity: 0 }]"
        class="handle"
        style="cursor: all-scroll; margin-right: -10px; margin-left: -10px"
      >
        <q-icon
          size="sm"
          class="inborader--text"
          :name="$icons.mdiDragVertical"
        />
      </div>
      <div class="subtask-checkbox" v-if="!fromNewTask">
        <q-checkbox
          :disable="subtask.owner.id === user.id || ((task.owner.id === user.id && task.is_lock) || !task.is_lock) ? false : true"
          v-model="subtask.completed"
          value
          :ripple="false"
          size="sm"
          class="shrink-checkbox"
          style="color: #a6b1bd"
          @input="$emit('statusUpdate', subtask)"
        />
      </div>
      <div class="flex-1 q-ml-xs">
        <div class="row flex-no-wrap items-start no-mrpd border-radius6">
          <q-menu
          auto-close
          context-menu
          content-class="cursor-pointer overflow-auto"
          v-model="rightmenu"
        >
          <q-list>
            <q-item
              clickable
              @click="handleEditorFocus"
              v-if="!fromNewTask && (isVisible([1, 2]) && ((task.owner.id === user.id && task.is_lock) || !task.is_lock))"
            >
              <!-- <q-item-section side top>
                    <q-icon :name="$icons.mdiSquareEditOutline" style="color:#000" />
                  </q-item-section> -->
              <q-item-section> Edit </q-item-section>
            </q-item>
            <q-item
              clickable
              @click="handleEditorFocus"
              v-else-if="fromNewTask"
            >
              <!-- <q-item-section side top>
                    <q-icon :name="$icons.mdiSquareEditOutline" style="color:#000" />
                  </q-item-section> -->
              <q-item-section> Edit </q-item-section>
            </q-item>
            <q-item
              @click="
                $copyTextToClipboard(copySubTaskLink);
                isSubTaskCopy = true;
              "
              clickable
              v-if="!fromNewTask"
            >
              <!-- <q-item-section side top>
                    <q-icon :name="$icons.matLink" style="color:#000" />
                  </q-item-section> -->
              <q-item-section> Copy link </q-item-section>
            </q-item>
            <!-- <q-item
                         @click="
                            $copyTextToClipboard(`S${currentWorkspace.title.substring(0, 2).toUpperCase()}-${subtask.id}`);
                            isSubTaskCopy = true;
                          "
                        clickable
                      >
                        <q-item-section>Copy Subtask Link</q-item-section>
                      </q-item> -->
            <q-item @click.stop="$copyContentToClip(subtask.title)" clickable>
              <!-- <q-item-section side top>
                    <q-icon style="color:#000" :name="$icons.mdiContentCopy" />
                  </q-item-section> -->
              <q-item-section> Copy text </q-item-section>
            </q-item>
            <q-separator style="background: #e3e8ed" />
            <q-item v-if="!fromNewTask && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)" @click.stop="openMoveSubtask('move', 'Move Subtask')" clickable>
              <q-item-section> Move subtask </q-item-section>
            </q-item>
            <q-item v-if="!fromNewTask" @click.stop="openMoveSubtask('copy', 'Copy Subtask')" clickable>
              <q-item-section> Copy subtask </q-item-section>
            </q-item>
            <q-separator v-if="!fromNewTask" style="background: #e3e8ed" />
            <q-item
              @click="addSubtaskBelow(subtask)"
              clickable
              v-if="!fromNewTask && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
            >
              <!-- <q-item-section side top>
                      <q-icon :name="$icons.mdiPlusBox" style="color:#000" />
                    </q-item-section> -->
              <q-item-section> Add sub-task below </q-item-section>
            </q-item>
            <q-item
              @click.stop="subtaskConfirmHandler(true)"
              clickable
              class="text-red"
              v-if="!fromNewTask && (isVisible([1, 2])  && ((task.owner.id === user.id && task.is_lock) || !task.is_lock))"
            >
              <!-- <q-item-section side top>
                      <q-icon class="text-red" :name="$icons.matDelete" />
                    </q-item-section> -->
              <q-item-section> Delete </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
          <div
            class="flex-1 row flex-no-wrap justify-between relative-position"
          >
            <!--  Note: JRAC: We need to call with blur event -->
            <div class="flex-no-wrap flex-1 single-subtask">
              <!-- <span class="subtask-ticket-number" style="display: inline-flex;">
                S{{
                  currentWorkspace.title.substring(0, 2).toUpperCase() +
                    "-" +
                    subtask.id
                }}
                <q-tooltip>
                  Created by {{ subtask.owner.first }} {{ subtask.owner.last }}
                </q-tooltip>
              </span> -->
              <!-- <q-tooltip anchor="center left" self="center right">
                Created by {{ subtask.owner.first }} {{ subtask.owner.last }}
              </q-tooltip> -->
              <div
                class="editor-prototype sub-task-special-prototype break-word subtaskEditable dont-break-out flex-1"
                style="padding: 1px 1px 1px 0px !important"
                v-html="subtaskContent"
                :style="!subtask.childSubtasks.length && { width: '85%' }"
              ></div>
            </div>
            <div
              v-if="subtask.childSubtasks.length > 0"
              class="row items-center q-px-xs"
              :style="
                subtask.end_date &&
                first2SubtaskMembers &&
                first2SubtaskMembers.length
                  ? { 'margin-right': '90px' }
                  : first2SubtaskMembers && first2SubtaskMembers.length
                  ? { 'margin-right': '115px' }
                  : subtask.end_date
                  ? { 'margin-right': '90px' }
                  : { 'margin-right': '140px' }
              "
              style="
                display: block;
                color: #8e97a8;
                font-weight: 600;
                font-size: 12px;
                margin-top: 8px;
                cursor: pointer;
              "
              @click="$emit('openSubtaskDialogFromTask', subtask)"
            >
              {{ completedCount }}/{{ subtask.childSubtasks.length }}
              <img class="q-ml-xs" src="/static/media-icons/Vector.svg" />
            </div>
            <div
              class="row items-center q-px-xs"
              style="height: 30px"
              v-show="
                subtask.end_date ||
                (first2SubtaskMembers && first2SubtaskMembers.length)
              "
            >
              <div
                v-if="subtask.end_date"
                @click="datePickermenu = !datePickermenu"
                :class="[checkDateDue(subtask.end_date)]"
                style="padding-top: 1.5px; height: 20px"
              >
                {{subtask.end_date | dateMonthDateDefault }}
              </div>
              <div
                class="row q-ml-xs q-mr-xs"
                v-if="first2SubtaskMembers && first2SubtaskMembers.length"
              >
                <q-avatar
                  size="20px"
                  color="grey-4"
                  text-color="grey-10"
                  v-for="(member, index) in first2SubtaskMembers"
                  :class="[
                    index < first2SubtaskMembers.length - 1 ||
                    subTaskMembers.length > 2
                      ? 'subtask-member-position'
                      : '',
                    'cursor-pointer',
                  ]"
                  :key="member.id"
                  @mouseenter="rsbMemberHover = member.id"
                  @mouseleave="rsbMemberHover = null"
                >
                  <img
                    :src="member.pic"
                    :alt="member.first"
                    v-if="member.pic"
                  />
                  <!-- <span class="text-grey-9 text-weight-bold" @click.stop v-else>
                    {{ member.first | firstChar }}{{ member.last | firstChar }}
                  </span> -->
                  <avatar v-else :size="20" :customStyle="{'font-size':'9px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
                  <q-badge
                    color="pink"
                    class="q-pa-xs absolute subtask-member-remove row items-center justify-center"
                    v-if="rsbMemberHover === member.id"
                  >
                    <span @click.stop="confirmBoxHandler(true, member.id)">
                      <q-icon
                        size="10px"
                        :name="$icons.matClose"
                        color="white"
                        class="cursor-pointer"
                        style="margin-top: -1px"
                      />
                    </span>
                  </q-badge>
                  <q-tooltip>
                    {{ member.first | capitalize }}
                    {{ member.last | capitalize }}
                  </q-tooltip>
                </q-avatar>
                <q-avatar
                  style="background-color: #bfcfe0"
                  size="20px"
                  v-if="subTaskMembers.length > 2"
                >
                  <span class="text-bold text-black"
                    >+{{
                      subTaskMembers.length - first2SubtaskMembers.length
                    }}</span
                  >
                </q-avatar>
              </div>
            </div>
            <div
              class="row items-center thread-icons q-px-xs absolute-top-right"
              style="height: 30px"
              v-show="isHover || showMenu || reactionMenu"
            >
              <q-btn
                v-if="!isFromSubtask"
                flat
                :icon="$icons.mdiChevronRightCircleOutline"
                size="11px"
                padding="0px"
                rounded
                dense
                @click="$emit('openSubtaskDialogFromTask', subtask)"
                class="thread-icon q-mr-xs"
              >
                <q-tooltip> Open subtask </q-tooltip>
              </q-btn>
              <q-btn
                flat
                dense
                size="11px"
                round
                padding="0"
                :icon="$icons.matSentimentSatisfied"
                class="q-mr-xs thread-icon"
                @click="showTooltip = false"
              >
                <q-tooltip v-if="showTooltip" > React to Subtask </q-tooltip>
                <q-menu
                  content-class="overflow-auto"
                  auto-close
                  v-model="reactionMenu"
                  @before-hide="showTooltip = true"
                >
                  <div style="max-width: 300px; padding: 6px 2px 0px 10px">
                    <img
                      v-for="(emoji, index) in reactions"
                      :key="index"
                      :src="'/static/react-icons/' + emoji.icon"
                      alt="ANGRY"
                      @click="
                        () => {
                          reactOnSubtask({ reaction: emoji }),
                            $mixpanelEvent('subtask-reaction', {});
                        }
                      "
                      class="react-emoji-icon q-mr-sm cursor-pointer"
                    />
                  </div>
                </q-menu>
              </q-btn>
              <div
                class="row q-mr-xs"
                v-if="first2SubtaskMembers && first2SubtaskMembers.length"
              >
                <q-avatar
                  size="20px"
                  color="grey-4"
                  text-color="grey-10"
                  v-for="(member, index) in first2SubtaskMembers"
                  :class="[
                    index < first2SubtaskMembers.length - 1 ||
                    subTaskMembers.length > 2
                      ? 'subtask-member-position'
                      : '',
                    'cursor-pointer',
                  ]"
                  :key="member.id"
                  @mouseenter="rsbMemberHover = member.id"
                  @mouseleave="rsbMemberHover = null"
                  @click.stop="showMenu = true"
                >
                  <img
                    :src="member.pic"
                    :alt="member.first"
                    v-if="member.pic"
                  />
                  <!-- <span
                    class="text-grey-9 text-weight-bold"
                    v-else
                    @click.stop="showMenu = true"
                  >
                    {{ member.first | firstChar }}{{ member.last | firstChar }}
                  </span> -->
                  <avatar v-else :size="20" :customStyle="{'font-size':'9px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
                  <!-- <q-badge
                    color="pink"
                    class="q-pa-xs absolute subtask-member-remove row items-center justify-center"
                    v-if="rsbMemberHover === member.id"
                  >
                    <span @click.stop="confirmBoxHandler(true, member.id)">
                      <q-icon
                        size="10px"
                        :name="$icons.matClose"
                        color="white"
                        class="cursor-pointer"
                        style="margin-top:-1px"
                      />
                    </span>
                  </q-badge> -->
                  <q-tooltip>
                    {{ member.first | capitalize }}
                    {{ member.last | capitalize }}
                  </q-tooltip>
                </q-avatar>
                <q-avatar
                  style="background-color: #bfcfe0"
                  size="20px"
                  v-if="subTaskMembers.length > 2"
                >
                  <span
                    class="text-bold text-black"
                    @click.stop="showMenu = !showMenu"
                    >+{{
                      subTaskMembers.length - first2SubtaskMembers.length
                    }}</span
                  >
                </q-avatar>
                <q-menu
                  content-class="overflow-auto assign-member-menu"
                  auto-close
                  v-model="showMenu"
                >
                  <div class="sticky-header">
                    <div
                      class="close-btn cursor-pointer"
                      style="right: 10px"
                      @click="showMenu = false"
                    >
                      <q-icon :name="$icons.matClose" class="close-icon" />
                    </div>
                    <div class="assign-to q-pt-sm q-px-sm">Assigned to</div>
                  </div>
                  <q-list class="q-py-sm q-px-sm member-list">
                    <q-item
                      class="q-px-none q-py-xs member-item items-center cursor-pointer"
                      v-for="member in subTaskMembers"
                      v-bind:key="`member-list-${member.id}`"
                    >
                      <q-item-section
                        @click.stop="
                          showUserInfo(member);
                          showMenu = false;
                        "
                        class="member-avatar"
                        avatar
                      >
                        <q-avatar v-if="member.pic" size="32px">
                          <img :src="member.pic" :alt="member.first" />
                        </q-avatar>
                        <!-- <q-avatar
                          size="32px"
                          color="grey-4"
                          text-color="grey-10"
                          v-else
                        >
                          {{ member.first | firstChar
                          }}{{ member.last | firstChar }}
                        </q-avatar> -->
                        <avatar v-else :size="32" :customStyle="{'font-size':'14px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
                      </q-item-section>
                      <q-item-section
                        class="member-name"
                        @click="showUserInfo(member)"
                      >
                        {{ member.first | capitalize }}
                      </q-item-section>
                      <q-item-section avatar>
                        <q-btn
                          flat
                          round
                          dense
                          color="grey-7"
                          :icon="$icons.matClose"
                          size="sm"
                          @click="confirmBoxHandler(true, member.id)"
                          v-if="
                            isVisible([1, 2]) || subtask.owner.id === user.id
                          "
                        />
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </div>
                <assign-member-to-subtask
                  v-if="isVisible([1, 2]) || subtask.owner.id === user.id"
                  :workspaceMembers="workspaceMembers"
                  :currentWorkspaceId="currentWorkspaceId"
                  :subtask="subtask"
                  class="q-mr-xs"
                  @menuClosed="
                    () => {
                      isHover = false;
                    }
                  "
                  :fromNewTask="fromNewTask"
                  :currentCompany="currentCompany"
                  :fromSubTask="false"
                />
              <div
                v-if="subtask.end_date"
                @click="datePickermenu = !datePickermenu"
                :class="[checkDateDue(subtask.end_date)]"
                style="padding-top: 1.5px; height: 20px"
              >
                {{ subtask.end_date | dateMonthDateDefault }}
              </div>
              <div class="row">
                <q-btn
                  v-if="!subtask.end_date"
                  flat
                  dense
                  size="10px"
                  round
                  padding="0"
                  class="thread-icon q-mr-xs"
                  :icon="$icons.matDateRange"
                  @click="datePickermenu = !datePickermenu"
                >
                  <q-tooltip> Due Date </q-tooltip>
                </q-btn>
                <div>
                  <q-menu
                    v-model="datePickermenu"
                    max-height="auto"
                    anchor="top right"
                    self="top left"
                    content-class="overflow-auto"
                  >
                    <q-date
                      mask="YYYY-MM-DD"
                      v-model="date"
                      today-btn
                      class="date-picker-calendar"
                      @input="updatedDate"
                    >
                      <div
                        class="date-time-block text-center row items-center justify-center"
                        style="height: 35px"
                      >
                        <div class="date-label">
                          <span class="demi-bold"> Ends: </span>
                          <span class="an-14 demi-bold-text">
                            {{
                              subtask.end_date
                                ? subtask.end_date.substring(0, 10)
                                : date
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="row items-center justify-between btn-section">
                        <q-btn
                          flat
                          class="btn-style clear"
                          @click="dateUpdateHandler(null)"
                          label="Clear"
                          no-caps
                        />
                        <q-btn
                          flat
                          color="black"
                          class="btn-style"
                          @click="datePickermenu = false"
                          label="Cancel"
                          no-caps
                        />
                        <q-btn
                          flat
                          padding="xs md"
                          class="bg-primary text-white btn-style"
                          style="width: 70px"
                          @click="dateUpdateHandler(date)"
                          label="OK"
                        />
                      </div>
                    </q-date>
                  </q-menu>
                </div>
              </div>
              <q-btn
                flat
                :icon="$icons.mdiInformationOutline"
                size="11px"
                padding="0px"
                rounded
                dense
                class="thread-icon"
              >
                <q-tooltip>
                  S{{ currentWorkspace.title.substring(0, 2).toUpperCase() }}-{{
                    subtask.id
                  }}
                  created by {{ subtask.owner.first }} {{ subtask.owner.last }}
                </q-tooltip>
              </q-btn>
              <q-btn
                flat
                dense
                size="11px"
                round
                padding="0"
                :icon="$icons.matMoreVert"
                class=" thread-icon"
              >
              <q-menu auto-close content-class="overflow-auto" v-model="menu">
                <q-list>
                  <q-item
                    clickable
                    @click="handleEditorFocus"
                    v-if="!fromNewTask && (isVisible([1, 2])  && ((task.owner.id === user.id && task.is_lock) || !task.is_lock))"
                  >
                    <!-- <q-item-section side top>
                          <q-icon :name="$icons.mdiSquareEditOutline" style="color:#000" />
                        </q-item-section> -->
                    <q-item-section> Edit </q-item-section>
                  </q-item>
                  <q-item
                    @click="
                      $copyTextToClipboard(copySubTaskLink);
                      isSubTaskCopy = true;
                    "
                    clickable
                    v-if="!fromNewTask"
                  >
                    <!-- <q-item-section side top>
                          <q-icon :name="$icons.matLink" style="color:#000" />
                        </q-item-section> -->
                    <q-item-section> Copy link </q-item-section>
                  </q-item>
                  <!-- <q-item
                              @click="
                                  $copyTextToClipboard(`S${currentWorkspace.title.substring(0, 2).toUpperCase()}-${subtask.id}`);
                                  isSubTaskCopy = true;
                                "
                              clickable
                            >
                              <q-item-section>Copy Subtask Link</q-item-section>
                            </q-item> -->
                  <q-item @click.stop="$copyContentToClip(subtask.title)" clickable>
                    <!-- <q-item-section side top>
                          <q-icon style="color:#000" :name="$icons.mdiContentCopy" />
                        </q-item-section> -->
                    <q-item-section> Copy text </q-item-section>
                  </q-item>
                  <q-separator style="background: #e3e8ed" />
                  <q-item v-if="!fromNewTask && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)" @click.stop="openMoveSubtask('move', 'Move Subtask')" clickable>
                    <q-item-section> Move subtask </q-item-section>
                  </q-item>
                  <q-item v-if="!fromNewTask" @click.stop="openMoveSubtask('copy', 'Copy Subtask')" clickable>
                    <q-item-section> Copy subtask </q-item-section>
                  </q-item>
                  <q-separator v-if="!fromNewTask" style="background: #e3e8ed" />
                  <q-item
                    @click="addSubtaskBelow(subtask)"
                    clickable
                    v-if="!fromNewTask  && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
                  >
                    <!-- <q-item-section side top>
                            <q-icon :name="$icons.mdiPlusBox" style="color:#000" />
                          </q-item-section> -->
                    <q-item-section> Add sub-task below </q-item-section>
                  </q-item>
                  <q-item
                    @click.stop="subtaskConfirmHandler(true)"
                    clickable
                    class="text-red"
                    v-if="!fromNewTask && ((task.owner.id === user.id && task.is_lock) || !task.is_lock)"
                  >
                    <!-- <q-item-section side top>
                            <q-icon class="text-red" :name="$icons.matDelete" />
                          </q-item-section> -->
                    <q-item-section> Delete </q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
              </q-btn>
            </div>
          </div>
        </div>
        <div
          v-if="
            subtaskMedia.length > 0 ||
            (subtask.mediafolder && subtask.mediafolder.length)
          "
          class="q-mb-xs"
          style="margin-top: -6px"
        >
          <sub-task-media
            v-if="subtaskMedia"
            type="subtask"
            :attachments="subtaskMedia"
            :canRemoveMedia="
              canRemoveAttachment ||
              (currentUser && currentUser.id === subtask.owner.id)
            "
            :forceDisableRemove="
              subtask.title === '' && subtaskMedia.length <= 1
            "
            :comment="subtask"
            :openFolder="openFolder"
            :deleteFolderHandler="deleteFolderHandler"
            :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
            :postId="subtask.id"
            :labels="labels"
            :user="currentUser"
            :removeMedia="removeMedia"
            :workspace="currentWorkspaceId"
            :height="105"
            :isWorkspaceOwner="isWorkspaceOwner"
            :editDoc="editDoc"
            @closeMediaModel="(data) => $emit('closeMediaModel', data)"
            @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
            @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
            @sendComment="(data) => $emit('sendComment', data)"
            @updatepositions="(data) => $emit('updatepositions', data)"
            @updateMediaCommentPointLabel="
              (data) => $emit('updateMediaCommentPointLabel', data)
            "
            @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
            @updateComment="(data) => $emit('updateComment', data)"
          />
        </div>
      </div>
    </div>
    <div class="row flex-no-wrap justify-between items-center full-width">
      <!-- Reactions List badges -->
      <div
        style="margin-left: 15px"
        class="q-mt-xs"
        v-if="
          subtask.subtaskreactiondata &&
          !Array.isArray(subtask.subtaskreactiondata) &&
          Object.keys(subtask.subtaskreactiondata).length !== 0 &&
          subtask.subtaskreactiondata.constructor === Object
        "
      >
        <div class="row inline">
          <template
            v-for="(reactiondata, index) in subtask.subtaskreactiondata"
          >
            <div class="q-mr-sm" :key="index">
              <div class="relative-position reaction-icon row items-center">
                <img
                  :src="`/static/react-icons/${index}.svg`"
                  :alt="index"
                  class="react-icon-size q-mr-xs cursor-pointer"
                  @click="reactOnSubtask(index)"
                />
                <div class="text-black" style="font-size: 12px">
                  {{ reactiondata.length }}
                </div>
              </div>
              <q-tooltip
                :offset="[-50, 0]"
                content-class="bg-white elevation-2 q-pa-md"
              >
                <div class="row reaction-menu">
                  <img
                    :src="`/static/react-icons/${index}.svg`"
                    :alt="index"
                    class="q-mr-md react-menu-icon"
                  />
                  <q-list class="column justify-center" v-if="reactiondata">
                    <q-item
                      :style="
                        reactiondata.length === 1 && {
                          'margin-bottom': '0px',
                        }
                      "
                      class="q-pa-none q-mb-sm"
                      style="min-height: 30px"
                      v-for="item in reactiondata"
                      :key="item.id"
                    >
                      <div class="row items-center">
                        <div class="q-mr-sm">
                          <q-avatar
                            color="blue-grey-11"
                            text-color="grey-10"
                            size="26px"
                          >
                            <img
                              :src="item.pic"
                              :alt="item.first"
                              v-if="item.pic"
                            />
                            <!-- <span
                              class="text-avatar-style"
                              style="font-size: 9px"
                              v-else
                            >
                              {{ item.first | firstChar
                              }}{{ item.last | firstChar }}
                            </span> -->
                            <avatar v-else :size="26" :customStyle="{'font-size':'12px', 'font-weight':'600'}" :username="item.first+' '+item.last"></avatar>
                          </q-avatar>
                        </div>
                        <div class="reacted-user">
                          {{ item.first }} {{ item.last }}
                        </div>
                        <div v-if="item.time" class="reaction-time q-ml-xs">
                          {{ item.time | formatDateTime }}
                        </div>
                      </div>
                    </q-item>
                  </q-list>
                </div>
              </q-tooltip>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- <q-separator class="q-mt-sm" style="background:#E3E8ED;" /> -->
    <confirm-dialog
      v-if="confirmRemoveMember.flag"
      v-model="confirmRemoveMember.flag"
      title="Remove Member?"
      question="Are you sure you want to remove this member?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => confirmBoxHandler(false)"
      :successMethod="deleteSubtaskMember"
      :loading="false"
    />
    <confirm-dialog
      v-if="confirmRemoveSubtask.flag"
      v-model="confirmRemoveSubtask.flag"
      title="Remove subtask?"
      question="Are you sure you want to remove this subtask?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => subtaskConfirmHandler(false)"
      :successMethod="deleteSubTask"
      :loading="false"
    />
    <post-copy-move-file-folder
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isRoot="copyMoveDialog.isRoot"
      :fileFolderDetails="copyMoveDialog.data"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyHandler="copyFileFolderHandler"
      :currentWorkspaceId="currentWorkspaceId"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Delete Folder?"
      question="Are you sure you want to detach the folder from the subtask?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false)"
      :successMethod="() => deleteFolderDialogHandler(true)"
      :loading="deleteFolder.loader"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="currentWorkspaceId"
      :user="currentUser"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="labels"
      :folderList="subtask.mediafolder"
      :authToken="currentCompany.accessToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
    <move-subtask-dialog
      v-if="moveSubtask.flag"
      v-model="moveSubtask.flag"
      :subtask="subtask"
      :task="task"
      :title="moveSubtask.title"
      :type="moveSubtask.type"
      :closeDialog="closeMoveSubtaskDialog"
      :isFromSubtask="isFromSubtask"
    />
  </div>
</template>
<script>
import { format } from "date-fns";
import { isPast } from "date-fns";
import { parseISO } from "date-fns";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
// import Editor from "@/components/Editor/Editor";
import SubTaskMedia from "@/components/Miscellaneous/SubTaskMedia";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import PostCopyMoveFileFolder from "@/components/Dialogs/PostCopyMoveFileFolder";

import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import AssignMemberToSubtask from "@/views/BoardView/AssignMemberToSubtask";
import { compile } from "@/components/Editor/MarkupUtils";
import MoveSubtaskDialog from "@/components/Dialogs/MoveSubtaskDialog.vue";

import Avatar from 'vue-avatar';

import mixpanel from "@/mixpanel";
import {
  DeleteSubTaskMemberMutation,
  AddSubtaskReactionMutation,
  RemoveSubtaskReactionMutation,
  DetachSubtaskFolderMutation,
  CopyMediaFolderMutation,
  CopyMediaMutation,
  AddMediaBookmarkMutation,
  RemoveMediaBookmarkMutation,
} from "@/gql";

import { mapGetters } from "vuex";
import get from "lodash/get";
import getUnixTime from "date-fns/getUnixTime";
import UserScope from "@/mixins/UserScope";

export default {
  name: "Subtask",
  mixins: [UserScope],
  props: [
    "subtask",
    "task",
    "canRemoveAttachment",
    "isWorkspaceOwner",
    "currentUser",
    "labels",
    "currentWorkspace",
    "currentWorkspaceId",
    "removeMedia",
    "deleteSubTaskHandler",
    "addSubtaskBelow",
    "workspaceMembers",
    "showUserInfo",
    "subTaskId",
    "fromNewTask",
    "folderList",
    "editDoc",
    "openDocEditor",
    "isFromSubtask",
    "currentSubtask",
  ],
  api: {
    reactions: {
      defaultValue: null,
      cacheKey: "ReactionsQuery",
    },
  },
  data() {
    return {
      showTooltip:true,
      moveSubtask: {
        flag: false,
        title:null,
        type:null
      },
      menu: false,
      rightmenu: false,
      date: this.subtask.end_date
        ? this.subtask.end_date
        : new Date().toISOString().split("T")[0],
      datePickermenu: false,
      showMenu: false,
      rsbMemberHover: null,
      isHover: false,
      confirmRemoveMember: {
        flag: false,
        id: null,
      },
      confirmRemoveSubtask: {
        flag: false,
        id: null,
      },
      isSubTaskCopy: false,
      isSubtaskActive: false,
      reactionMenu: false,
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
      deleteFolder: {
        id: null,
        flag: false,
        loader: false,
      },
      copyMoveDialog: {
        data: [],
        comment: null,
        isFolder: false,
        isRoot: true,
        flag: false,
        loading: false,
      },
      showAssignMember: false,
    };
  },
  components: {
    // Editor,
    SubTaskMedia,
    AssignMemberToSubtask,
    ConfirmDialog,
    PostCopyMoveFileFolder,
    FolderDetailsDialog,
    MoveSubtaskDialog,
    Avatar,
  },
  methods: {
    checkDateDue(date) {
      if (
        format(parseISO(date), "yyyy-MM-dd") ===
        format(new Date(), "yyyy-MM-dd")
      ) {
        return "due-date-today task-due-date cursor-pointer q-mr-xs";
      } else if (isPast(parseISO(date))) {
        return "overdue-date task-due-date cursor-pointer q-mr-xs";
      }
      return "due-date task-due-date cursor-pointer q-mr-xs";
    },
    openMoveSubtask(type, title){
      this.moveSubtask.type = type;
      this.moveSubtask.title = title;
      this.moveSubtask.flag = true;
      this.rightmenu=false;
      this.menu=false;
    },
    closeMoveSubtaskDialog() {
      this.moveSubtask.flag = false;
      this.moveSubtask.type = null;
      this.moveSubtask.title = null;
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    openFolder(folder) {
      this.folderDetailsDialog.flag = true;
      this.folderDetailsDialog.data = folder;
    },
    closeFolder() {
      this.folderDetailsDialog.flag = false;
      this.folderDetailsDialog.data = null;
    },
    copyMoveFileFolderHandler(
      items,
      isFolder = false,
      isRoot = true,
      comment = null
    ) {
      this.copyMoveDialog.flag = true;
      this.copyMoveDialog.data = items; // Array of folder or file items
      this.copyMoveDialog.isFolder = isFolder;
      this.copyMoveDialog.isRoot = isRoot;
      this.copyMoveDialog.comment = comment;
    },
    copyFileFolderHandler(workspace, folder) {
      if (this.copyMoveDialog.isFolder) {
        this.copyFolders(workspace);
      } else {
        this.copyFiles(workspace, folder);
      }
    },
    async copyFolders(workspace) {
      try {
        this.copyMoveDialog.loading = true;
        // Array of folders
        const folderIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          folder_id: folderIds,
          workspace_id: workspace.id,
        };
        await this.$api.mutate({
          mutation: CopyMediaFolderMutation,
          variables,
        });
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Folder(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    async copyFiles(workspace, folder) {
      try {
        this.copyMoveDialog.loading = true;
        const fileIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          media_id: fileIds,
          workspace_id: workspace.id,
          target_folder_id: folder ? folder.id : null,
        };
        const response = await this.$api.mutate({
          mutation: CopyMediaMutation,
          variables,
        });
        if (response.data) {
          //increase folder count when copy to folder
          if (workspace.id === this.currentWorkspaceId) {
            //cache update
            const query = this.$api.getQuery(
              `MediaFoldersQuery:${this.currentWorkspaceId}`
            );
            query.data.mediaFolders = query.data.mediaFolders.map((f) => {
              if (f.id === folder.id) {
                f.media_count += fileIds.length;
              }
              return f;
            });
          }
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "File(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    closeCopyMoveFileFolderDialog() {
      this.copyMoveDialog.data = [];
      this.copyMoveDialog.flag = false;
      this.copyMoveDialog.loading = false;
      this.copyMoveDialog.isFolder = false;
      this.copyMoveDialog.post = null;
    },
    async deleteFolderHandler(folderId) {
      this.deleteFolder.id = folderId;
      this.deleteFolder.flag = true;
    },
    async deleteFolderDialogHandler(flag = false) {
      if (flag) {
        this.deleteFolder.loader = true;
        try {
          const variables = {
            subtask_id: this.subtask.id,
            folder: [this.deleteFolder.id],
          };
          await this.$api.mutate({
            mutation: DetachSubtaskFolderMutation,
            variables,
          });
          //Remove from props
          this.subtask.mediafolder = this.subtask.mediafolder.filter(
            (f) => f.id !== this.deleteFolder.id
          );
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteFolder.id = null;
      this.deleteFolder.flag = false;
      this.deleteFolder.loader = null;
    },

    updatedDate(date) {
      if (date && date.type && date.type === "input") {
        date.preventDefault();
        return false;
      }
      this.subtask.end_date = date;
    },
    mouseLeaveHandler() {
      if (
        !this.menu &&
        !this.datePickermenu &&
        !this.isAssignSubtaskMenuOpen()
      ) {
        this.isHover = false;
      }
    },
    editSubtask(subtask) {
      this.$emit("update", {
        id: subtask.id,
        title: subtask.title,
        sequence: subtask.sequence,
        completed: subtask.completed,
        end_date: subtask.end_date,
      });
    },
    handleEditorFocus() {
      this.$emit("updateSubtaskMod", {
        ...this.subtask,
        task_id: this.task.id,
      });
    },
    deleteSubTask() {
      if (this.fromNewTask) {
        this.$emit("deleteSubtask");
      }
      const variables = {
        id: this.subtask.id,
        task_id: this.task.id,
      };
      this.deleteSubTaskHandler(variables);
      this.menu = false;
    },
    dateUpdateHandler(date) {
      this.date = date;
      this.datePickermenu = false;
      const subtask = {
        ...this.subtask,
        end_date: date,
      };
      this.editSubtask(subtask);
    },
    confirmBoxHandler(flag = true, id = null) {
      this.confirmRemoveMember = {
        flag,
        id,
      };
    },
    subtaskConfirmHandler(flag = true, id = null) {
      this.confirmRemoveSubtask = {
        flag,
        id,
      };
    },
    async deleteSubtaskMember() {
      const variables = {
        subtask_id: this.subtask.id,
        user_id: this.confirmRemoveMember.id,
      };

      const subTaskEntity = this.$api.getEntity("subTask", this.subtask.id);
      subTaskEntity.members = subTaskEntity.members.filter(
        (m) => m.id !== this.confirmRemoveMember.id
      );

      this.confirmRemoveMember.flag = false;

      await this.$api.mutate({
        mutation: DeleteSubTaskMemberMutation,
        variables,
      });
      mixpanel.track("Subtask Member Remove");
    },

    isAssignSubtaskMenuOpen() {
      let menu_id = document.getElementById("assign-subtask-menu");
      if (menu_id) {
        return true;
      } else {
        return false;
      }
    },
    async reactOnSubtask(data) {
      let { reaction } = data;
      if (typeof data !== "object") {
        reaction = this.reactions.find((o) => o.name === data);
      }
      const variables = {
        id: this.subtask.id,
        reaction_id: reaction.id,
      };
      const newReactiondata =
        !this.subtask.subtaskreactiondata ||
        this.subtask.subtaskreactiondata.length != undefined
          ? {}
          : this.subtask.subtaskreactiondata;
      if (
        newReactiondata &&
        newReactiondata.hasOwnProperty(reaction.name) &&
        newReactiondata[reaction.name].map((a) => a.id).includes(this.user.id)
      ) {
        if (newReactiondata[reaction.name].length > 1) {
          let index = newReactiondata[reaction.name].findIndex(
            (a) => a.id === this.user.id
          );
          newReactiondata[reaction.name].splice(index, 1);
          this.subtask.subtaskreactiondata = newReactiondata;
        } else {
          delete newReactiondata[reaction.name];
          // this.subtask.subtaskreactiondata = newReactiondata;
        }
        this.removeReactionFromSubtask(reaction);
        return;
      } else {
        let fakeReactionData = {
          first: this.user.first,
          fullname: this.user.first + " " + this.user.last,
          id: this.user.id,
          is_deleted: null,
          last: this.user.last,
          time: getUnixTime(new Date()),
          pic: this.user.pic,
        };
        if (newReactiondata[reaction.name]) {
          newReactiondata[reaction.name].push(fakeReactionData);
        } else {
          newReactiondata[reaction.name] = [fakeReactionData];
        }
        this.subtask.subtaskreactiondata = newReactiondata;
      }
      await this.$api.mutate({
        mutation: AddSubtaskReactionMutation,
        variables,
        skipUpdates: true,
      });
    },
    async removeReactionFromSubtask(reaction) {
      const variables = {
        id: this.subtask.id,
        reaction_id: reaction.id,
      };
      await this.$api.mutate({
        mutation: RemoveSubtaskReactionMutation,
        variables,
        skipUpdates: true,
      });
    },
  },
  computed: {
    subtaskContent() {
      // const ticket = `<div class="subtask-ticket-number float-left q-mr-xs">S${this.currentWorkspace.title
      //   .substring(0, 2)
      //   .toUpperCase()}-${this.subtask.id}</div>`;
      return compile(this.subtask.title);
    },
    isDateDue() {
      if (
        format(parseISO(this.subtask.end_date), "yyyy-MM-dd") ===
        format(new Date(), "yyyy-MM-dd")
      ) {
        return "due-date-today";
      } else if (isPast(parseISO(this.subtask.end_date))) {
        return "overdue-date";
      }
      return "due-date";
    },
    subTaskMembers() {
      return this.subtask.members;
    },
    first2SubtaskMembers() {
      return this.subTaskMembers !== undefined && this.subTaskMembers.length > 2
        ? this.subTaskMembers.slice(0, 2)
        : this.subTaskMembers;
    },
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    copySubTaskLink() {
      if (this.isFromSubtask) {
        const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.activeCompany}/workspaces/${this.currentWorkspaceId}/board?task=${this.task.id}&workspace=${this.currentWorkspaceId}&sub_task=${this.currentSubtask.id}&child_sub_task=${this.subtask.id}&workspaceName=${this.currentWorkspace.title}`;
        return taskLink;
      }
      const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.activeCompany}/workspaces/${this.currentWorkspaceId}/board?task=${this.task.id}&workspace=${this.currentWorkspaceId}&sub_task=${this.subtask.id}&workspaceName=${this.currentWorkspace.title}`;
      return taskLink;
    },
    subtaskMedia() {
      return orderBy(uniqBy(this.subtask && this.subtask.media, "id"), [
        "sequence",
      ]);
    },
    completedCount() {
      if (this.subtask && this.subtask.childSubtasks) {
        const data = this.subtask.childSubtasks.filter(
          (child) => child.completed
        );
        return data.length;
      }
      return 0;
    },
  },
  watch: {
    menu(val) {
      if (!val) {
        this.isHover = true;
      }
    },
    datePickermenu(val) {
      if (!val) {
        this.isHover = false;
      }
    },
  },
  updated() {
    if (
      (this.$route.query.sub_task == this.subtask.id ||
        parseInt(this.subTaskId) == this.subtask.id ||
        this.$route.query.child_sub_task == this.subtask.id) &&
      !this.isSubtaskActive
    ) {
      let selectedSubtasks = document.querySelectorAll(
        '[data-id="' + this.subtask.id + '"]'
      );
      let selectedSubtask = selectedSubtasks[selectedSubtasks.length - 1];
      if (selectedSubtask) {
        selectedSubtask.scrollIntoView();
      }
      this.isSubtaskActive = true;
    }
  },
};
</script>
<style scoped></style>
