<template>
  <div>
    <div
      class="q-mt-md an-15 medium-text q-px-ms q-mx-md"
      :class="fileSelectInfoCss && ['q-mb-md']"
    >
      <strong>
        {{ selectedFiles.length }}
        <span v-if="selectedFiles.length > 1">files</span>
        <span v-else>file</span>
      </strong>
      selected
    </div>
    <div class="row flex-wrap scroll-y q-pl-md media-scroll">
      <template v-if="!fromFolder">
        <template v-for="folder in folderList">
          <shared-folder
            :key="folder.id"
            :folder="folder"
            :selectionFolder="selectionFolder"
            :selectedFiles="selectedFiles"
            :openFolder="openFolder"
            view="GridView"
          />
        </template>
      </template>
      <template v-for="media in imageList">
        <SharedMedia
          :key="media.id"
          :media="media"
          :selectionMedia="selectionMedia"
          :selectedFiles="selectedFiles"
          view="GridView"
        />
      </template>
      <div
        class="load-more-media cursor-pointer border-radius10 q-ma-xs"
        style="width: 175px; height: 175px"
        @click="$emit('paginate')"
        v-if="!removeloadButton && imageList && imageList.length > 49"
      >
        <q-icon
          :name="$icons.mdiDotsHorizontal"
          size="45px"
          color="blue-grey-5"
          style="margin:auto"
        >
          <q-tooltip>
            Load More
          </q-tooltip>
        </q-icon>
      </div>
    </div>
    <div class="full-width q-pr-sm text-center" v-if="isloadingMedia">
      <q-circular-progress
        indeterminate
        size="30px"
        :thickness="0.2"
        color="primary"
      />
    </div>
  </div>
</template>
<script>
import SharedMedia from "@/components/SharedMedia/SharedMedia";
import SharedFolder from "@/components/SharedMedia/SharedFolder";
export default {
  name: "GridView",
  props: {
    selectionMedia: Function,
    selectionFolder: Function,
    selectedFiles: Array,
    imageList: Array,
    isloadingMedia: Boolean,
    removeloadButton: Boolean,
    folderList: Array,
    fromFolder: Boolean,
    openFolder: Function,
  },
  data() {
    return {
      fileSelectInfoCss: false,
    };
  },
  mounted() {
    const scrollElement = document.getElementsByClassName("media-scroll")[0];
    if (scrollElement) {
      scrollElement.addEventListener("scroll", this.handlingScroll);
    }
  },
  beforeDestroy() {
    const scrollElement = document.getElementsByClassName("media-scroll")[0];
    if (scrollElement) {
      scrollElement.removeEventListener("scroll", this.handlingScroll);
    }
  },
  components: {
    SharedMedia,
    SharedFolder,
  },
  methods: {
    handlingScroll() {
      if (document.getElementsByClassName("media-scroll")[0].scrollTop > 0) {
        this.fileSelectInfoCss = true;
      } else {
        this.fileSelectInfoCss = false;
      }
    },
  },
};
</script>
