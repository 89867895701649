"use strict";
var __assign =
  (this && this.__assign) ||
  Object.assign ||
  function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) {
          t[p] = s[p];
        }
      }
    }
    return t;
  };
function extractHandle(handle) {
  return (handle && handle.$el) || handle;
}
function isInBoundries(elementRect, boundingRect, dx, dy) {
  if (dx === void 0) {
    dx = 0;
  }
  if (dy === void 0) {
    dy = 0;
  }
  if (boundingRect && elementRect) {
    var actualMaxTop = elementRect.top + elementRect.height + dy;
    var maxTop = boundingRect.bottom;
    var actualMinTop = elementRect.top + dy;
    var minTop = boundingRect.top;
    var actualMaxLeft = elementRect.left + dx;
    var maxLeft = boundingRect.right - elementRect.width;
    var actualMinLeft = elementRect.left + dx;
    var minLeft = boundingRect.left;
    if (
      (actualMaxTop > maxTop && actualMaxTop - dy > maxTop) ||
      (actualMinTop < minTop && actualMinTop - dy < minTop) ||
      (actualMaxLeft > maxLeft && actualMaxLeft - dx > maxLeft) ||
      (actualMinLeft < minLeft && actualMinLeft - dx < minLeft)
    ) {
      return false;
    }
  }
  return true;
}
const Draggable = {
  bind: function(el, binding) {
    Draggable.update(el, binding);
  },
  update: function(el, binding) {
    if (binding.value && binding.value.stopDragging) {
      return;
    }
    var handler =
      (binding.value &&
        binding.value.handle &&
        extractHandle(binding.value.handle)) ||
      el;
    init(binding);
    function mouseMove(event) {
      event.preventDefault();
      var state = getState();
      var dx = event.clientX - state.initialMousePos.x;
      var dy = event.clientY - state.initialMousePos.y;
      var boundingRect = binding.value && binding.value.boundingRect;
      var stopDragging = binding.value && binding.value.stopDragging;
      var elementRect = el.getBoundingClientRect();
      if (!isInBoundries(elementRect, boundingRect, dx, dy) || stopDragging) {
        return;
      }
      state.lastPos = {
        x: state.startPosition.x + dx,
        y: state.startPosition.y + dy,
      };
      setState(state);
      el.style.transform =
        "translate(" + state.lastPos.x + "px, " + state.lastPos.y + "px)";
      onPositionChanged();
    }
    function mouseUp(event) {
      var state = getState();
      event.stopPropagation();
      document.removeEventListener("mousemove", mouseMove);
      document.removeEventListener("mouseup", mouseUp);
      onPositionChangedmouseup();
      state.lastPos = {
        x: 0,
        y: 0,
      };
      setState(state);
      el.style.transform = "translate(0px, 0px)";
      // console.log(state)
    }
    function mouseDown(event) {
      var state = getState();
      event.stopPropagation();

      state.startPosition = state.lastPos;
      state.initialMousePos = getInitialMousePosition(event);
      setState(state);
      onPositionChanged();
      document.addEventListener("mousemove", mouseMove);
      document.addEventListener("mouseup", mouseUp);
      onPositionChangedmousedown();
      el.style.transform =
        "translate(" + state.lastPos.x + "px, " + state.lastPos.y + "px)";
      // console.log(state)
    }
    function getInitialMousePosition(event) {
      return {
        x: event.clientX,
        y: event.clientY,
      };
    }
    function getInitState() {
      var startPosition =
        binding && binding.value && binding.value.initialPosition
          ? binding.value.initialPosition
          : { x: 0, y: 0 };
      return {
        startPosition: startPosition,
        initialMousePos: { x: 0, y: 0 },
        lastPos: startPosition,
      };
    }
    function init(binding) {
      if (binding && binding.value && binding.value.resetInitialPos) {
        el.style.transform = "translate(0px, 0px)";
        setState(getInitState());
        onPositionChanged();
      }
      // el.style.position = 'absolute'
    }
    function setState(state) {
      handler.setAttribute("draggable-state", JSON.stringify(state));
    }
    function onPositionChanged() {
      var state = getState();
      binding.value &&
        binding.value.onPositionChange &&
        state &&
        binding.value.onPositionChange(__assign({}, state.lastPos));
    }
    function onPositionChangedmouseup() {
      var state = getState();
      binding.value &&
        binding.value.onPositionChangedmouseup &&
        state &&
        binding.value.onPositionChangedmouseup(__assign({}, state.lastPos));
    }
    function onPositionChangedmousedown() {
      var state = getState();
      binding.value &&
        binding.value.onPositionChangedmousedown &&
        state &&
        binding.value.onPositionChangedmousedown(__assign({}, state.lastPos));
    }
    function getState() {
      return JSON.parse(handler.getAttribute("draggable-state"));
    }
    if (!handler.getAttribute("draggable")) {
      el.removeEventListener("mousedown", el["listener"]);
      handler.addEventListener("mousedown", mouseDown);
      handler.setAttribute("draggable", "true");
      el["listener"] = mouseDown;
      setState(getInitState());
      onPositionChanged();
    }
  },
};
export default Draggable;
