<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    v-model="value"
    persistent
    content-class="revamp-commenting-dialog q-ma-none"
    @keydown.esc="resetDialog"
    @show="handleEventListner"
  >
    <q-card class="file-viewer relative-position">
      <q-bar v-if="view" class="file-viewer-header">
        <div class="file-viewer-overlay top"></div>
        <div class="row justify-between items-center flex-1 q-mt-md">
          <div class="row items-center q-pt-md">
            <div>
              <q-avatar v-if="userDetail.pic" rounded size="38px">
                <img :src="userDetail.pic" :alt="userDetail.first" />
              </q-avatar>
              <!-- <q-avatar size="32px" color="grey-4" text-color="grey-10" v-else>
                {{ userDetail.first | firstChar
                }}{{ userDetail.last | firstChar }}
              </q-avatar> -->
              <avatar
                v-else
                :size="32"
                :customStyle="{ 'font-size': '14px', 'font-weight': '600' }"
                :username="userDetail.first + ' ' + userDetail.last"
              ></avatar>
            </div>
            <div class="text-white q-ml-md">
              <div class="row items-center q-mb-xs">
                <div class="username">
                  {{ userDetail.fullname }}
                </div>
                <div
                  class="comment-point q-ml-sm"
                  v-if="
                    dialogMedia.commentPoint &&
                    dialogMedia.commentPoint.length > 0
                  "
                  :style="
                    dialogMedia.commentPoint.length ==
                    completedMediaPoints(dialogMedia).length
                      ? `background: #15d89a`
                      : ''
                  "
                >
                  {{
                    completedMediaPoints(dialogMedia).length +
                    "/" +
                    dialogMedia.commentPoint.length
                  }}
                </div>
              </div>
              <div class="title">
                <span> {{ calculatedTime }} - </span>
                <span>
                  {{ dialogMedia.filename }}
                  <q-tooltip style="z-index: 1">{{
                    dialogMedia.filename
                  }}</q-tooltip>
                </span>
              </div>
            </div>
          </div>
          <q-btn
            class="close-icon row justify-center items-center cursor-pointer"
            :icon="$icons.mdiCloseThick"
            size="12px"
            dense
            flat
            text-color="black"
            @click="resetDialog"
          />
        </div>
      </q-bar>
      <!-- DIALOG CONTENT -->
      <div
        style="padding: 0"
        :class="[
          view ? 'file-viewer-content' : 'comment-viewer-content',
          'overflow-auto',
        ]"
        ref="reVampScroll"
        v-dragscroll
        id="imageScrollContainer"
      >
        <div v-if="!view">
          <q-page-container style="padding: 0">
            <div style="position: relative">
              <template v-for="(point, index) in returncommentpoints">
                <comment-box
                  v-if="
                    selectedTab === 'active'
                      ? point.last_label !== '#15d89a'
                      : point.last_label === '#15d89a'
                  "
                  ref="commentBox"
                  :class="{ ishide: ishide }"
                  :index="index"
                  :key="point.id"
                  :currentUser="currentUser"
                  :isWorkspaceOwner="isWorkspaceOwner"
                  :commentListing="commentListing"
                  :point="point"
                  :selectedPointData="selectedPoint"
                  @deleteCommentPoint="deleteCommentPoint"
                  @sendComment="sendComment"
                  @updatepositions="updatepositions"
                  @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
                  @deleteMediaComment="deleteMediaComment"
                  @updateComment="updateComment"
                  :labels="labels"
                  @selectPoint="selectPoint"
                  :initialiseComment="initialiseComment"
                ></comment-box>
              </template>
              <vue-load-image>
                <img
                  @mouseleave="mouseCursorLeave"
                  @mousemove="mouseCursor"
                  v-if="mediaLink"
                  ref="myImgId"
                  :style="{ cursor: returncursorpoint }"
                  @dblclick="point_it"
                  :src="mediaLink"
                  slot="image"
                />
                <q-spinner color="primary" size="5em" slot="preloader" />
                <!-- <img slot="preloader" src="/static/images/loading.gif" /> -->
              </vue-load-image>
              <span
                class="comment-custom-tooltip tooltipcolor text-white an-14 medium-text"
                style="position: absolute; display: none"
                ref="myCustomCursor"
              >
                Double Click to leave a comment
              </span>
            </div>
          </q-page-container>
        </div>
        <template v-else>
          <template>
            <q-btn
              class="carousal-arrows carousal-viewer-arrow left row justify-center items-center"
              :icon="$icons.fasAngleLeft"
              size="14px"
              dense
              @click="previousImage"
              :disabled="!hasPreviousImage"
            />
            <q-btn
              class="carousal-arrows carousal-viewer-arrow right row justify-center items-center"
              :icon="$icons.fasAngleRight"
              size="14px"
              dense
              @click="nextImage"
              :disabled="!hasNextImage"
            />
          </template>
          <vue-load-image
            tabindex="0"
            id="image-wrapper"
            :class="[
              isZoomedIn !== 'none'
                ? 'zoomed-in-wrapper'
                : 'zoomed-out-wrapper',
              'image-viewer',
            ]"
          >
            <img
              id="image-viewer"
              :src="imgSrc"
              slot="image"
              style="max-height: 100%"
              :class="getZoomClass()"
              @mouseover="handleZoom"
              @mouseout="removeZoom"
              @click="customeZoomIn"
              @mousedown="imageMouseDownHandler"
              @mouseup="imageMouseUpHandler"
              @mouseleave="imageMouseLeaveHandler"
            />
            <q-spinner color="primary" size="5em" slot="preloader" />
          </vue-load-image>
          <!-- <span
            class="comment-custom-tooltip tooltipcolor text-white an-14 medium-text"
            style="position: absolute; display: none"
            ref="zoomInTooltipCursor"
          >
            {{ isZoomedIn === "mid" ? "click to zoom in more" : "zoom out" }}
          </span> -->
        </template>
      </div>
      <div v-if="view" class="file-viewer-footer">
        <div class="file-viewer-overlay bottom"></div>
        <div class="row items-center justify-center">
          <div class="carousel">
            <carousel
              :paginationEnabled="false"
              :mouseDrag="true"
              :navigationEnabled="false"
              :navigateTo="activeCarouselPage"
              :perPageCustom="[[768, 10]]"
            >
              <slide v-for="(media, index) in getImageList" :key="index">
                <div
                  :class="[
                    getDialogMedia && getDialogMedia.id === media.id
                      ? 'carasoule-media-selected'
                      : 'carasoule-media-not-selected',
                    'carasoule-media cursor-pointer row justify-center',
                  ]"
                  @click="() => setActiveMedia(media, index)"
                >
                  <div
                    class="comment-point"
                    v-if="media.commentPoint.length > 0"
                    :style="
                      media.commentPoint.length ==
                      completedMediaPoints(media).length
                        ? `background: #15d89a`
                        : ''
                    "
                  >
                    <span v-if="media.commentPoint.length <= 9">
                      {{
                        completedMediaPoints(media).length +
                        "/" +
                        media.commentPoint.length
                      }}
                    </span>
                    <span v-else>9+</span>
                  </div>
                  <img
                    :src="
                      media.file_resource === 's3'
                        ? $resizeMedia(media.file, 200, 200)
                        : media.thumb
                    "
                    alt="#"
                  />
                  <div
                    v-if="getDialogMedia && getDialogMedia.id === media.id"
                    class="overlay"
                  ></div>
                  <div
                    v-if="!(getDialogMedia && getDialogMedia.id === media.id)"
                    class="overlay-on-hover"
                  ></div>
                  <q-tooltip style="z-index: 1">{{ media.filename }}</q-tooltip>
                </div>
              </slide>
            </carousel>
          </div>
          <div class="header-buttons">
            <q-btn
              flat
              size="12px"
              round
              class="zoom-btn"
              @click="zoomOutFn"
              :icon="$icons.mdiMagnifyMinusOutline"
            >
              <q-tooltip> Zoom out </q-tooltip>
            </q-btn>
            <q-btn
              flat
              size="12px"
              round
              :class="[isZoomedIn !== 'none' ? 'zoom' : 'zoom-btn']"
              @click="zoomInFn"
              :icon="$icons.mdiMagnifyPlusOutline"
            >
              <q-tooltip> Zoom in </q-tooltip>
            </q-btn>
            <q-btn
              flat
              size="12px"
              round
              class="zoom-btn"
              @click="openInNewWindow"
              :icon="$icons.matOpenInNew"
            >
              <q-tooltip>
                Open in a new {{ isElectron ? "window" : "tab" }}
              </q-tooltip>
            </q-btn>
            <q-btn
              flat
              size="12px"
              round
              @click="downloadFile(dialogMedia.id)"
              class="zoom-btn"
              :icon="$icons.mdiDownload"
            >
              <q-tooltip> Download file </q-tooltip>
            </q-btn>
            <q-btn
              flat
              size="12px"
              round
              style="color: #727e8b"
              :icon="$icons.fabGoogleDrive"
              @click="openNextTabLink"
              v-if="isGoogle"
            >
              <q-tooltip> Google Drive </q-tooltip>
            </q-btn>
            <q-btn
              flat
              size="12px"
              round
              style="color: #727e8b"
              :icon="$icons.fabDropbox"
              @click="openNextTabLink"
              v-if="isDropbox"
            >
              <q-tooltip> Drop Box </q-tooltip>
            </q-btn>
            <!-- <q-btn
              flat
              size="10px"
              :class="
                commentMode ? 'comment-view-btn' : 'comment-view-btn selected'
              "
              :style="{ color: commentMode ? '#d9dde2' : '#ffffff' }"
              :icon="$icons.matRemoveRedEye"
              @click="toggleCommentMode(false)"
              label="View"
            >
            </q-btn> -->
            <q-btn
              flat
              size="10px"
              :class="[
                'comment-view-btn',
                commentMode ? 'comment-mode-on' : 'comment-mode-off',
              ]"
              :icon="commentMode ? $icons.matDone : $icons.matQuestionAnswer"
              @click="checkVisualCommentAccess"
              :label="commentMode ? 'Done' : 'Comment'"
              v-if="isVisible([1, 2, 3])"
            >
              <q-tooltip> Create and Read Comments </q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
      <div v-if="!view" class="comment-drawer">
        <comment-view-drawer
          :commentPoints="returncommentpoints"
          :commentListing="commentListing"
          :labels="labels"
          :selectedPointData="selectedPoint"
          :enabledTextbox="enabledTextbox"
          :newCommentPoint="returnNewCommentPoint"
          :currentWorkspaceId="currentWorkspace"
          ref="commentViewDrawer"
          @sendComment="sendComment"
          @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
          @deleteCommentPoint="deleteCommentPoint"
          @addCommentToPoint="addCommentToPoint"
          @deleteMediaComment="deleteMediaComment"
          @scrollToPoint="scrollToPoint"
          @updateComment="updateComment"
          @selectPoint="selectPoint"
          @tabChanged="changeTab"
          :changeView="toggleCommentMode"
          :hasPreviousImage="hasPreviousImage"
          :hasNextImage="hasNextImage"
          :previousImage="previousImage"
          :nextImage="nextImage"
        />
      </div>
    </q-card>
    <image-viewer-walkthrough-dialog
      v-if="user.onboarding.is_image_viewer"
      @closeBanner="closeImageViewerWalkthrough"
    />
  </q-dialog>
</template>
<script>
import formatDistance from "date-fns/formatDistance";
import fromUnixTime from "date-fns/fromUnixTime";

import { getDownloadUrl } from "@/services/downloadS3File";
import CommentBox from "@/views/MediaView/CommentBox";
import { Carousel, Slide } from "vue-carousel";
import filter from "lodash/filter";
import { SET_WORKSPACE_EDITOR_MEDIA } from "@/store/mutation-types";
import { mapGetters } from "vuex";
import get from "lodash/get";
import forEach from "lodash/forEach";
import CommentViewDrawer from "./CommentViewDrawer.vue";
import ImageViewerWalkthroughDialog from "./ImageViewerWalkthroughDialog";
import { UpdateUserOnboardingMutation } from "@/gql";
import VueLoadImage from "vue-load-image";
import UserScope from "@/mixins/UserScope";
import Avatar from "vue-avatar";

const ZOOM_LVL = {
  0: "none",
  1: "mid",
  2: "full",
};
export default {
  name: "ImageViewerDialog",
  mixins: [UserScope],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  props: [
    "fromUploader",
    "moreOption",
    "isWorkspaceOwner",
    "currentUser",
    "generatedPointPixels",
    "dialogMedia",
    "ishide",
    "commentListing",
    "labels",
    "resetDialog",
    "imageList",
    "value",
  ],
  components: {
    CommentBox,
    Carousel,
    Slide,
    CommentViewDrawer,
    ImageViewerWalkthroughDialog,
    "vue-load-image": VueLoadImage,
    Avatar,
  },
  data() {
    return {
      view: true,
      commentMode: false,
      cursorpoint: "context-menu",
      commentBack: "#d9dde2",
      filterTask: {
        label: false,
      },
      scrollTop: 0,
      scrollLeft: 0,
      currentIndex: 0,
      commentDrawer: true,
      selectedPoint: 0,
      enabledTextbox: -1,
      newCommentPoint: null,
      initialiseComment: false,
      selectedTab: "active",
      isZoomedIn: ZOOM_LVL[0],
      formatDistance,
      isDown: false,
      isEventDragging: false,
      startX: 0,
      startY: 0,
    };
  },
  beforeDestroy() {
    document.body.classList.add("vabotu-body");
    this.$eventBus.$off("automationOfPoint");
    this.$refs.reVampScroll.removeEventListener("scroll", this.handlingScroll);
  },
  beforeCreate() {
    this.$eventBus.$off("automationOfPoint");
  },
  mounted() {
    document.body.classList.remove("vabotu-body");
    this.currentIndex = this.getImageList
      .map((a) => a.id)
      .indexOf(this.dialogMedia.id);
    this.$eventBus.$on("automationOfPoint", this.automationOfPoint);
    if (this.fromUploader) {
      this.commentMode = true;
    }

    document.addEventListener("keyup", this.keyUpHandler);
  },
  destroyed() {
    document.removeEventListener("keyup", this.keyUpHandler);
  },
  methods: {
    checkVisualCommentAccess() {
      let eligible = this.$checkEligiblity("visual_comments");
      if (eligible) {
        this.toggleCommentMode(!this.commentMode);
      }
    },
    imageMouseDownHandler(evt) {
      if (this.isZoomedIn === "full") {
        this.isDown = true;
        //Set
        this.startX = evt.pageX;
        this.startY = evt.pageY;
        const imageDragContainer = document.getElementById("image-viewer");
        imageDragContainer.classList.add("imageDragActive");
      }
    },
    imageMouseUpHandler(evt) {
      if (this.isZoomedIn !== "none") {
        const imageDragContainer = document.getElementById("image-viewer");
        if (evt.pageX === this.startX && evt.pageY === this.startY) {
          this.isZoomedIn = "none";
          this.isEventDragging = true;
        }
        imageDragContainer.classList.remove("imageDragActive");
      }
    },
    imageMouseLeaveHandler() {
      if (this.isZoomedIn !== "none") {
        this.isDown = false;
        const imageDragContainer = document.getElementById("image-viewer");
        imageDragContainer.classList.remove("imageDragActive");
      }
    },
    zoomInFn() {
      if (this.isZoomedIn === "mid") {
        this.isZoomedIn = "full";
      }
      if (this.isZoomedIn === "none") this.isZoomedIn = "mid";
    },
    zoomOutFn() {
      this.isZoomedIn = "none";
    },
    handleZoom() {
      let ele = document.getElementById("image-viewer");
      ele.classList.add("zoom-in");
    },
    removeZoom() {
      if (!this.isDown) {
        let ele = document.getElementById("image-viewer");
        ele.classList.remove("zoom-in");
      }
    },
    customeZoomIn() {
      // let customCursor = this.$refs.zoomInTooltipCursor;
      // customCursor.style.display = "none";
      if (
        this.isZoomedIn === "none" &&
        this.startX &&
        this.startY &&
        this.isEventDragging
      ) {
        this.isEventDragging = false;
        this.startX = 0;
        this.startY = 0;
      } else if (this.isZoomedIn === "none") {
        this.isZoomedIn = "mid";
        // customCursor.style.display = "block";
      } else if (this.isZoomedIn === "mid") {
        // customCursor.style.display = "block";
        this.isZoomedIn = "full";
      }
    },
    getZoomClass() {
      if (this.isZoomedIn) {
        return this.isZoomedIn === "none"
          ? `zoomed-out-image zoom-in`
          : `${this.isZoomedIn}-zoomed-in ${
              this.isZoomedIn === "full" ? "zoom-out" : "zoom-in"
            }`;
      }
    },
    mouseZoomCursor(e) {
      if (this.isZoomedIn !== "none") {
        let customCursor = this.$refs.zoomInTooltipCursor;
        let x = e.clientX,
          y = e.clientY;
        customCursor.style.top = y - 20 + "px";
        customCursor.style.left = x + 5 + "px";
      }
    },
    selectPoint(data) {
      this.selectedPoint = data;
      this.$refs.commentViewDrawer.commentEditorFlag = true;
    },
    handleEventListner() {
      this.$refs.reVampScroll.addEventListener("scroll", this.handlingScroll);
    },
    openNextTabLink() {
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) window.electron.openExternal(this.dialogMedia.file);
      else window.open(this.dialogMedia.file, "_blank");
    },
    openInNewWindow() {
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron)
        window.open(this.dialogMedia.file, this.dialogMedia.filename, "popup");
      else window.open(this.dialogMedia.file);
    },
    mouseCursorLeave() {
      let customCursor = this.$refs.myCustomCursor;
      customCursor.style.display = "none";
    },
    mouseCursor(e) {
      let customCursor = this.$refs.myCustomCursor;
      const findContext =
        this.$refs.commentBox &&
        this.$refs.commentBox.find((context) => context.menu);
      if (findContext) {
        this.cursorpoint = "context-menu";
        customCursor.style.display = "hide";
      } else {
        this.cursorpoint = "crosshair";
        customCursor.style.display = "block";
      }
      let x = e.clientX + this.scrollLeft,
        y = e.clientY + this.scrollTop;

      customCursor.style.top = y - 55 + "px";
      customCursor.style.left = x + 20 + "px";
    },
    handlingScroll(e) {
      this.scrollTop = Math.round(e.target.scrollTop);
      this.scrollLeft = Math.round(e.target.scrollLeft);
    },
    automationOfPoint(data) {
      const findContext =
        this.$refs.commentBox &&
        this.$refs.commentBox.find((context) => context.point.id === data.id);
      if (findContext) {
        findContext.menu = true;
      }
    },
    FindPosition(oElement) {
      if (typeof oElement.offsetParent !== "undefined") {
        for (
          var posX = 0, posY = 0;
          oElement;
          oElement = oElement.offsetParent
        ) {
          posX += oElement.offsetLeft;
          posY += oElement.offsetTop;
        }
        return [posX, posY];
      } else {
        return [oElement.x, oElement.y];
      }
    },
    point_it(e) {
      const findContext =
        this.$refs.commentBox &&
        this.$refs.commentBox.find((context) => context.menu);
      if (findContext) {
        findContext.menu = false;
      } else {
        this.initialiseComment = true;
        let myImg = this.$refs.myImgId;
        let PosX = 0;
        let PosY = 0;
        let ImgPos;
        ImgPos = this.FindPosition(myImg);
        if (!e) e = window.event;
        if (e.pageX || e.pageY) {
          PosX = e.clientX + this.scrollLeft - 15;
          PosY = e.clientY + this.scrollTop - 15;
          PosX = PosX - ImgPos[0];
          PosY = PosY - ImgPos[1];
          const data = {
            title: "Add Comment",
            x: PosX,
            y: PosY,
          };
          if (this.returncommentMode) {
            this.clickonmoreoption(data);
          }
        }
        let point =
          this.returncommentpoints[this.returncommentpoints.length - 1];
        let index = this.returncommentpoints.length
          ? this.returncommentpoints.length - 1
          : 0;
        this.selectedPoint = 0;
        this.newCommentPoint = {
          point: point,
          index: index,
        };

        setTimeout(() => {
          this.enabledTextbox = index;
        }, 200);
      }
    },
    async downloadFile(id) {
      const response = await getDownloadUrl(id, this.$api);
      if (response !== null) {
        window.location = response;
      }
    },
    sendComment(data) {
      const commentArg = {
        ...data,
        media_comments_point_id: data.id,
        media_id: this.dialogMedia.id,
      };
      this.$emit("sendComment", commentArg);
      var evt = new Event("dblclick");
      this.$refs.myImgId.dispatchEvent(evt);
    },
    clickonmoreoption(label) {
      if (label.title === "Copy Link") {
        this.$copyTextToClipboard(this.dialogMedia.file);
      }
      if (label.title === "Hide Comments") {
        this.clickonhidebutton();
      }
      if (label.x) {
        const data = {
          title: label.title,
          media: this.dialogMedia,
          forscroll: "no",
          x: label.x,
          y: label.y,
          gridMediaId: this.dialogMedia.id,
        };
        this.$emit("clickonmoreoption", data);
      } else {
        if (label.title === "Add Comment") {
          this.commentMode = true;
        }
        const data = {
          title: label.title,
          media: this.dialogMedia,
          forscroll: "yes",
          x: 0,
          y: 42,
          gridMediaId: this.dialogMedia.id,
        };
        this.$emit("clickonmoreoption", data);
      }
    },
    createNewCommentPoint() {
      if (this.returncommentMode) {
        this.commentMode = false;
        this.commentBack = "#bdbdbd";
        this.cursorpoint = "context-menu";
      } else {
        this.commentMode = true;
        this.cursorpoint = "crosshair";
        this.commentBack = "#007aff";
      }
    },
    updatepositions(updateposition) {
      const myImage = this.$refs.myImgId;
      if (
        myImage &&
        updateposition.x <= myImage.clientWidth &&
        updateposition.y <= myImage.clientHeight
      ) {
        const positions = {
          ...updateposition,
          media_id: this.dialogMedia.id,
        };
        this.$emit("updatepositions", positions);
      }
    },
    clickonhidebutton() {
      this.$emit("clickonhidebutton");
    },
    deleteCommentPoint(data) {
      const makedata = {
        ...data,
        media_id: this.dialogMedia.id,
      };
      this.initialiseComment = false;
      this.$emit("deleteCommentPoint", makedata);
    },
    updateMediaCommentPointLabel(themedata) {
      this.$emit("updateMediaCommentPointLabel", themedata);
    },
    deleteMediaComment(commentData) {
      const obj = {
        ...commentData,
        media_id: this.dialogMedia.id,
      };
      this.$emit("deleteMediaComment", obj);
    },
    updateComment(changedata) {
      this.$emit("updateComment", changedata);
    },
    nextImage() {
      if (this.currentIndex < this.getImageList.length - 1) {
        this.currentIndex++;
        let media = this.getImageList[this.currentIndex];
        this.setActiveMedia(media, this.currentIndex);
      }
    },
    previousImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        let media = this.getImageList[this.currentIndex];
        this.setActiveMedia(media, this.currentIndex);
      }
    },
    setActiveMedia(media, index) {
      this.currentIndex = index;
      this.$emit("setActiveMedia", media);
    },
    attachToPost(media) {
      let value = {};
      const files = this.$store.getters.workspaceEditorMedia(
        this.currentCompany.companyId,
        this.currentWorkspace
      );

      if (files) {
        value = files;
      }

      value[media.id] = media;
      this.$store.commit(SET_WORKSPACE_EDITOR_MEDIA, {
        value,
        companyId: this.currentCompany.companyId,
        workspaceId: this.currentWorkspace,
      });
      if (this.$route.name !== "MessagingView") {
        this.$router.push(
          `/companies/${this.activeCompany}/workspaces/${this.currentWorkspace}/messaging`
        );
      }
    },
    keyUpHandler(event) {
      switch (event.key) {
        case "ArrowRight":
          this.nextImage();
          break;
        case "ArrowLeft":
          this.previousImage();
          break;
      }
    },
    addCommentToPoint(data) {
      this.enabledTextbox = data;
    },
    scrollToPoint(data) {
      this.$refs.reVampScroll.scrollTop =
        data.y - this.$refs.reVampScroll.offsetTop;
      if (data.x > this.$refs.reVampScroll.clientWidth) {
        this.$refs.reVampScroll.scrollLeft = data.x;
      } else {
        this.$refs.reVampScroll.scrollLeft = 0;
      }
    },
    toggleCommentMode(flag) {
      this.commentMode = flag;
      if (flag) {
        document.removeEventListener("keyup", this.keyUpHandler);
      } else {
        document.addEventListener("keyup", this.keyUpHandler);
      }
    },
    changeTab(tab) {
      this.selectedTab = tab;
    },
    completedMediaPoints(media) {
      return media.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
    async closeImageViewerWalkthrough() {
      this.user.onboarding.is_image_viewer = false;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_image_viewer",
          flag_value: false,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
      workspaceEditorMedia: "workspaceEditorMedia",
    }),
    activeCarouselPage() {
      const activeIndex = this.getImageList.findIndex(
        (m) => m.id === this.mediaId
      );
      return activeIndex % 10 > 7
        ? Math.ceil(activeIndex / 10)
        : Math.floor(activeIndex / 10);
    },
    isS3() {
      return this.dialogMedia.file_resource === "s3";
    },
    isGoogle() {
      return this.dialogMedia.file_resource === "google";
    },
    isDropbox() {
      return this.dialogMedia.file_resource === "dropbox";
    },
    imgSrc() {
      if (this.isGoogle || this.isDropbox) {
        return this.dialogMedia.thumb;
      }
      return this.dialogMedia.type.toLowerCase() === "image/gif"
        ? this.dialogMedia.file
        : this.$resizeMedia(this.dialogMedia.file);
    },
    returnFromUploder() {
      return this.fromUploader;
    },
    returncommentMode() {
      return this.commentMode;
    },
    returncommentBack() {
      return this.commentBack;
    },
    returncursorpoint() {
      return this.cursorpoint;
    },
    returncommentpoints() {
      return this.generatedPointPixels;
    },
    mediaLink() {
      if (this.dialogMedia) {
        if (this.isGoogle || this.isDropbox) {
          return this.dialogMedia.thumb;
        } else {
          return this.dialogMedia.type.toLowerCase() === "image/gif"
            ? this.dialogMedia.file
            : this.$resizeMedia(this.dialogMedia.file);
        }
      }
      return null;
    },
    mediaId() {
      if (this.dialogMedia) {
        return this.dialogMedia.id;
      }
      return null;
    },
    getImageList() {
      if (this.imageList) {
        forEach(this.imageList, (media) => {
          media.commentPoint &&
            media.commentPoint.forEach((commentPoint) => {
              commentPoint["comments"] = [];
              commentPoint["menuFlag"] = false;
              if (commentPoint.last_label === "#15d89a") {
                commentPoint["completed"] = true;
              } else {
                commentPoint["completed"] = false;
              }
            });
        });
        return filter(this.imageList, (o) => o.type.match(/image.*/));
      }
      return null;
    },
    getDialogMedia() {
      if (this.dialogMedia) {
        return this.dialogMedia;
      } else {
        return null;
      }
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    currentWorkspace() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else if (this.generalWorkspace) {
        return this.generalWorkspace.id;
      } else {
        return 1;
      }
    },
    returnNewCommentPoint() {
      return this.newCommentPoint;
    },
    userDetail() {
      return this.$api.getEntity("user", this.dialogMedia.user_id);
    },
    calculatedTime() {
      return formatDistance(
        fromUnixTime(this.dialogMedia.created_at),
        new Date(),
        {
          addSuffix: true,
          includeSeconds: true,
        }
      );
    },
    hasPreviousImage() {
      if (this.currentIndex - 1 < 0) {
        return false;
      }
      return true;
    },
    hasNextImage() {
      if (this.currentIndex + 1 > this.getImageList.length - 1) {
        return false;
      }
      return true;
    },
    isElectron() {
      return navigator.userAgent.indexOf("Electron") !== -1;
    },
  },
  watch: {
    commentMode(next) {
      if (next) {
        this.view = false;
        this.cursorpoint = "crosshair";
        this.commentBack = "#ffffff";
      } else {
        this.$emit("closeMediaModel", {
          id: this.dialogMedia.id,
        });
        this.view = true;
        this.commentBack = "#d9dde2";
        this.cursorpoint = "context-menu";
      }
    },
    dialogMedia(to, from) {
      if (to && from && to.id !== from.id && this.commentMode) {
        this.$emit("closeMediaModel", {
          id: this.dialogMedia.id,
        });
      }
    },
  },
};
</script>

<style scoped>
.comment-drawer {
  position: absolute;
  right: 0;
  top: 0;
  width: 320px;
  height: 100%;
  border: solid 3px #d9dde2;
}
.q-dialog__inner--maximized > div {
  overflow: hidden;
}
.zoom-in {
  cursor: zoom-in;
}
.mid-zoomed-in {
  width: 120%;
}
.full-zoomed-in {
  width: 200%;
}
.zoomed-out-image {
  max-width: calc(100% - 128px);
}
.zoomed-out-wrapper {
  display: flex !important;
}
.zoomed-in-wrapper {
  display: block !important;
  height: auto !important;
}
.zoom-out {
  cursor: zoom-out;
}
.imageDragActive {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
</style>
