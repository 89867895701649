<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    maximized
    :value="value"
  >
    <q-card>
      <!-- Toolbar -->
      <div class="q-px-md q-pt-md q-mx-md">
        <q-toolbar class="rounded-borders q-py-sm bg-white">
          <div class="an-16 medium-text">Select up to 10 files</div>
          <q-space />
          <div>
            <q-input
              dense
              autogrow
              v-model="searchText"
              outlined
              placeholder="Search"
              class="q-mr-xs"
            >
              <template v-slot:prepend>
                <q-icon :name="$icons.matSearch" />
              </template>
              <template v-slot:append>
                <div
                  @click="() => clearBoardSearchHandler()"
                  class="cursor-pointer"
                  style="margin-top: -4px"
                >
                  <q-icon :name="$icons.matClose" />
                </div>
              </template>
            </q-input>
          </div>
          <div class="q-ml-sm">
            <q-btn
              flat
              dense
              round
              size="md"
              :icon="$icons.matDashboard"
              color="blue-grey-7"
              v-if="currentView === 'GridView'"
              @click="changeView('ListView')"
            >
              <q-tooltip> Grid View </q-tooltip>
            </q-btn>
            <q-btn
              flat
              dense
              round
              size="md"
              :icon="$icons.matViewList"
              color="blue-grey-8"
              v-else
              @click="changeView('GridView')"
            >
              <q-tooltip> List View </q-tooltip>
            </q-btn>
          </div>
          <div>
            <q-btn
              flat
              color="blue-grey-8"
              size="md"
              label="Cancel"
              class="q-mx-md"
              @click="closeDialog"
            />
            <q-btn
              text-color="white"
              size="md"
              label="Attach"
              :disabled="selectedItems.length === 0"
              @click="attachMediaToPost(selectedItems)"
              style="background: #007aff"
            />
          </div>
        </q-toolbar>
      </div>
      <div
        v-if="
          filterMediaList &&
          filterMediaList.length === 0 &&
          mediaFolders.length === 0
        "
        class="row justify-center q-mt-xl"
      >
        <div class="card text-center q-pa-sm">
          <div class="card__title card__title--primary q-pt-md">
            <div>
              <q-avatar :style="{ background: 'rgba(0, 196, 134, 0.2)' }">
                <q-icon color="teal-5" :name="$icons.fasFile" />
              </q-avatar>
              <div
                class="title nolabel--text q-mt-sm q-mb-xs an-15 medium-text"
              >
                Media Hub
              </div>
              <span class="nolabel--text an-15 regular-text">
                There are no files shared
                <br />in this workspace.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex-1 overflow-auto"
        v-infinite-scroll="fetchScrollHandler"
      >
        <div style="max-height: calc(100vh - 88px)">
          <component
            :is="currentView"
            :currentView="currentView"
            :imageList="imageList"
            :selectedFiles="selectedItems"
            :selectionMedia="toggleFileSelect"
            :selectionFolder="toggleFolderSelect"
            :isloadingMedia="isloadingMedia"
            :fetchMoreMedia="fetchMoreMedia"
            :folderList="folderList"
            :openFolder="openFolder"
            @paginate="fetchMoreMedia"
            :fromFolder="fromFolder"
          ></component>
        </div>
      </div>
    </q-card>
    <share-folder-detail-dialog
      v-if="openedFolder.flag"
      :isFolderOpen="openedFolder.flag"
      :folder="openedFolder.data"
      :closeFolder="closeFolder"
      :clearSelection="clearSelection"
      :selectionMedia="toggleFileSelect"
      :selectedFiles="selectedItems"
      :isFromDocEditor="filterMedia ? true : false"
      :attachMediaToPost="attachMediaToPost"
    />
  </q-dialog>
</template>
<script>
import last from "lodash/last";
import isEmpty from "lodash/isEmpty";
// import omitBy from "lodash/omitBy";
// import isNil from "lodash/isNil";

import GridView from "@/components/SharedMedia/GridView";
import ListView from "@/components/SharedMedia/ListView";
import ShareFolderDetailDialog from "@/components/Dialogs/ShareFolderDetailDialog";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";

import { NewMediaQuery } from "@/gql";
export default {
  name: "SharedFilesDialog",
  mixins: [FileExtensionMixin],
  props: [
    "value",
    "attachMediaToPost",
    "currentWorkspaceId",
    "fromFolder",
    "filterMedia",
  ],
  components: {
    GridView,
    ListView,
    ShareFolderDetailDialog,
  },
  api: {
    media: {
      query: NewMediaQuery,
      variables() {
        return {
          workspace_id: this.currentWorkspaceId,
        };
      },
      defaultValue: [],
      cacheKey() {
        return `MediaQuery:${this.currentWorkspaceId}`;
      },
    },
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.currentWorkspaceId}`;
      },
    },
  },
  data() {
    return {
      currentView: "GridView",
      selectedItems: [],
      searchText: "",
      isloadingMedia: false,
      openedFolder: {
        flag: false,
        data: null,
      },
    };
  },
  computed: {
    medias() {
      /*
        If this component is reneder in other components except DocumentEditor then will
        not pass media from component.We pass media from DocumentEditor because we only need to
        add image in document.
      */
      if (this.filterMedia) {
        return this.filterMedia;
      }
      return this.media;
    },
    filterMediaList() {
      if (this.medias) {
        return this.medias.filter(
          (o) =>
            (o.filename &&
              o.filename
                .toLowerCase()
                .includes(this.searchText.toLowerCase())) ||
            o.file_resource
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
        );
      }
      return null;
    },
    imageList() {
      if (this.filterMediaList) {
        // Used Mixin : FileExtensionMixin
        return this.transformMediaCollection(this.filterMediaList);
      } else {
        return [];
      }
    },
    folderList() {
      // const mediaFolders = [...this.mediaFolders];
      if (this.mediaFolders) {
        // if (this.filterMedia) {
        //   const tempMediafolders = mediaFolders.map((f) => {
        //     if (f.media && f.media.length) {
        //       const imageList = f.media.map((m) => {
        //         if (m.type.match(/image.*/)) {
        //           return m;
        //         }
        //       });
        //       f.media = [...imageList];
        //     }
        //     return f;
        //   });
        //   return tempMediafolders;
        // }
        return this.mediaFolders;
      } else {
        return [];
      }
    },
  },
  methods: {
    openFolder(folder) {
      this.openedFolder.flag = true;
      this.openedFolder.data = folder;
    },
    clearSelection(selectedFolderMedia) {
      this.selectedItems = this.selectedItems.filter(
        (selected) =>
          !selectedFolderMedia.map((m) => m.id).includes(selected.id)
      );
      this.closeFolder();
    },
    closeFolder() {
      this.openedFolder.flag = false;
      this.openedFolder.data = null;
    },
    closeDialog() {
      this.clearBoardSearchHandler();
      this.$emit("closeDialog");
    },
    changeView(viewType) {
      this.currentView = viewType;
    },
    clearBoardSearchHandler() {
      this.searchText = "";
    },
    toggleFileSelect(item) {
      const files = this.selectedItems;
      if (
        files.map((a) => a.id).includes(item.id) &&
        item.hasOwnProperty("filename")
      ) {
        files.splice(
          files.findIndex((o) => o.id === item.id),
          1
        );
      } else {
        if (this.selectedItems.length < 10) {
          files.push(item);
        }
      }
      this.selectedFiles = files;
    },
    toggleFolderSelect(item) {
      const files = this.selectedItems;
      if (
        files.map((a) => a.id).includes(item.id) &&
        item.hasOwnProperty("title")
      ) {
        files.splice(
          files.findIndex((o) => o.id === item.id),
          1
        );
      } else {
        if (this.selectedItems.length < 10) {
          files.push(item);
        }
      }
      this.selectedFiles = files;
    },
    fetchScrollHandler() {
      if (this.isloadingMedia === false) {
        this.fetchMoreMedia();
      }
    },
    async fetchMoreMedia() {
      this.isloadingMedia = true;
      const lastMedia = last(this.medias);
      if (lastMedia && lastMedia.id) {
        const variables = {
          workspace_id: this.currentWorkspaceId,
          cursor: lastMedia.id,
          cursor_type: "prev",
          limit: 10,
        };
        const currentMedia = this.$api.getQueryByName("media");
        const newMedia = await this.$api.query({
          query: NewMediaQuery,
          variables,
        });
        if (!isEmpty(newMedia.data.newMedia)) {
          currentMedia.data.newMedia = [
            ...currentMedia.data.newMedia,
            ...newMedia.data.newMedia,
          ];
        }
      }
      this.isloadingMedia = false;
    },
  },
  watch: {
    filterMediaList(val) {
      if (val && val.length < 50) {
        this.fetchMoreMedia();
      }
    },
    value(val) {
      if (val) {
        this.selectedItems = [];
      }
    },
  },
};
</script>
