var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-dialog',{attrs:{"no-backdrop-dismiss":"","transition-hide":"none","transition-show":"none","no-esc-dismiss":"","value":_vm.value},on:{"hide":_vm.cancelMethod}},[_c('q-card',{staticClass:"q-px-lg q-py-md bg-white copy-move-dialog"},[_c('div',{staticClass:"row title q-mb-md"},[_c('span',{staticClass:"q-mx-auto"},[_vm._v(_vm._s(_vm.isFolder ? _vm.isMoveAction ? "Move folders to:" : "Copy folders to:" : _vm.isMoveAction ? "Move files to:" : "Copy files to:"))])]),_c('q-btn',{staticClass:"dropdown-set full-width",staticStyle:{"font-weight":"400"},attrs:{"outline":"","size":"md","no-caps":"","icon-right":_vm.$icons.matKeyboardArrowDown,"label":_vm.selectedWorkspace && _vm.selectedWorkspace.id === _vm.currentWorkspaceId
          ? 'Current workspace'
          : _vm.selectedWorkspace && _vm.selectedWorkspace.title}},[_c('q-menu',[_c('div',{staticClass:"search-member q-pa-sm"},[_c('q-input',{ref:"search",attrs:{"autofocus":"","outlined":"","placeholder":"Filter workspaces","clearable":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":_vm.$icons.matSearch}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('q-list',{staticClass:"workspace-menu"},_vm._l((_vm.filteredWorkspaces),function(workspace){return _c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],key:workspace.id,attrs:{"clickable":""},on:{"click":function($event){return _vm.chooseWorkspace(workspace)}}},[_c('q-item-section',[_vm._v(" "+_vm._s(workspace.title)+" ")])],1)}),1)],1)],1),(
        (!_vm.isMultiple && !_vm.isFolder) ||
          (_vm.isMultiple && _vm.selectedFolders.length === 0)
      )?_c('q-btn',{staticClass:"dropdown-set full-width q-mt-md",staticStyle:{"font-weight":"400"},attrs:{"outline":"","size":"md","no-caps":"","icon-right":_vm.$icons.matKeyboardArrowDown,"label":`Folder: ${_vm.selectedFolder ? _vm.selectedFolder.title : 'Root'}`,"disable":_vm.isFetching}},[(_vm.isFetching)?_c('q-spinner-dots',{staticClass:"folders-loader",attrs:{"color":"primary","size":"md"}}):_vm._e(),(_vm.filteredFolders.length)?_c('q-menu',[_c('div',{staticClass:"search-member q-pa-sm"},[_c('q-input',{ref:"search",attrs:{"autofocus":"","outlined":"","placeholder":"Filter folders","clearable":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":_vm.$icons.matSearch}})]},proxy:true}],null,false,654650568),model:{value:(_vm.searchFolder),callback:function ($$v) {_vm.searchFolder=$$v},expression:"searchFolder"}})],1),_c('q-list',{staticClass:"workspace-menu"},_vm._l((_vm.filteredFolders),function(folder){return _c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],key:folder.id,attrs:{"clickable":""},on:{"click":function($event){return _vm.chooseFolder(folder)}}},[_c('q-item-section',[_vm._v(" "+_vm._s(folder.title)+" ")])],1)}),1)],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"text-center q-mt-md"},[_c('q-btn',{staticClass:"q-mr-sm",staticStyle:{"width":"120px"},attrs:{"color":"transperant","dense":"","flat":"","no-caps":"","size":"14px","label":"Cancel"},on:{"click":_vm.cancelMethod}}),_c('q-btn',{staticClass:"create-folder-btn",attrs:{"color":"primary","dense":"","no-caps":"","disable":_vm.isDisabled,"label":_vm.isFolder
            ? _vm.isMoveAction
              ? 'Move folders'
              : 'Copy folders'
            : _vm.isMoveAction
            ? 'Move files'
            : 'Copy files',"loading":_vm.loading},on:{"click":_vm.moveCopyFileFolder}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }