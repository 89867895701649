<template>
  <div
    class="card-media-card-hover border-radius10"
    :class="mediaPreview.hoverClass"
    :style="{ 'background-color': mediaPreview.bgColor }"
  >
  <q-menu context-menu content-style="min-width:150px">
    <q-list>
      <q-item clickable>
        <q-item-section>
          Share
        </q-item-section>
        <q-item-section side>
          <q-icon :name="$icons.matKeyboardArrowRight" />
        </q-item-section>
        <q-menu auto-close anchor="top end" self="top start">
          <q-list style="min-width:150px">
            <q-item @click="attachToPost()" clickable>
              Attach to message
            </q-item>
            <q-item
              v-if="!media.is_document_section"
              @click.stop="$copyTextToClipboard(media.file)"
              clickable
            >
              <q-item-section> Copy Link </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
      <q-item
        @click="
          copyMoveFileFolderHandler(
            [media],
            true,
            false,
            false,
            isFolderOpenView ? false : true
          )
        "
        clickable
        v-close-popup
      >
        <q-item-section>
          Move
        </q-item-section>
      </q-item>
      <q-item
        @click="
          copyMoveFileFolderHandler(
            [media],
            false,
            false,
            false,
            isFolderOpenView ? false : true
          )
        "
        clickable
        class="q-mb-sm"
        v-close-popup
      >
        <q-item-section>
          Copy
        </q-item-section>
      </q-item>
      <q-item
        @click="$emit('renameFile', media)"
        clickable
        class="q-mb-sm"
        v-close-popup
      >
        <q-item-section>
          Rename
        </q-item-section>
      </q-item>
      <q-item
        v-if="media.is_document_section && !media.is_read_only && (currentUser && currentUser.id === media.user_id)"
        @click="updateReadOnlyHandler(true)"
        clickable
        class="q-mb-sm"
        v-close-popup
      >
        <q-item-section>
          Set to read only
        </q-item-section>
      </q-item>
      <q-item
        v-else-if="media.is_document_section && media.is_read_only  && (currentUser && currentUser.id === media.user_id)"
        @click="updateReadOnlyHandler(false)"
        clickable
        class="q-mb-sm"
        v-close-popup
      >
        <q-item-section>
          Remove read only
        </q-item-section>
      </q-item>
      <q-separator />
      <q-item
        clickable
        @click="fromComputer(media.id)"
        v-if="canRemove && media.type.match(/image.*/)"
        v-close-popup
      >
        <q-item-section>
          Replace image
        </q-item-section>
      </q-item>
      <q-item
        avatar
        @click.stop="downloadFile(mediaId)"
        v-if="isS3"
        clickable
        v-close-popup
      >
        <q-item-section> Download </q-item-section>
      </q-item>
      <q-item
        clickable
        @click="clickonmoreoption({ title: 'Add Comment' })"
        v-if="media.type.match(/image.*/)"
        v-close-popup
      >
        <q-item-section>
          Add comment
        </q-item-section>
      </q-item>
      <q-item
        @click="clickonmoreoption({ title: 'Delete File' })"
        v-if="canRemove"
        clickable
        class="text-red"
        v-close-popup
      >
        <q-item-section> Delete </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
    <span
      :class="[
        'custom-selection-checkbox absolute grid cursor-pointer',
        selectedIds.length && 'custom-selection-checkbox-visible',
      ]"
      @click="toggleSelect(media)"
    >
      <div
        class="selected-custom-selection-checkbox grid"
        v-if="selectedIds.includes(media.id)"
      ></div>
    </span>
    <div
      class="task-menu-option thread-icons q-px-xs q-mt-sm q-mr-xs row"
      style="z-index: 100"
    >
      <q-btn
        v-if="media.isbookmarked"
        text-color="pink"
        size="13px"
        round
        dense
        padding="0"
        :icon="$icons.matBookmark"
        @click.stop="removeMediaBookmark(media)"
      >
        <q-tooltip>
          Remove from Saved items
        </q-tooltip>
      </q-btn>
      <q-btn
        v-if="!media.isbookmarked"
        text-color="black"
        size="13px"
        round
        dense
        padding="0"
        class="q-button-hover"
        :icon="$icons.matBookmarkBorder"
        @click.stop="addMediaBookmark(media)"
      >
        <q-tooltip>
          Add to Saved items
        </q-tooltip>
      </q-btn>
    </div>
    <q-img :height="mediaDiamension" class="border-radius10" :src="imgSrc">
      <q-tooltip>
        {{ media.filename }}
      </q-tooltip>
      <div
        v-if="isVideo && !isDropbox"
        @click="videoPlayer(media)"
        class="row justify-center items-center fit"
      >
        <div class="text-center flex-1" style="z-index: 50">
          <q-btn
            :icon="$icons.mdiFileVideoOutline"
            style="color: #FF001F;"
            size="50px"
            round
            dense
            flat
          />
        </div>
      </div>
      <div
        @click="openViewMode"
        class="fill-height full-width media-card-hover-item cursor-pointer"
      ></div>
    </q-img>
    <span
      class="ellipses an-12 filename q-mt-sm block absolute-bottom text-center q-mx-sm"
      style="margin-bottom:7px"
      :style="[{ 'z-index': '30' }]"
    >
      {{ media.filename }}
    </span>
    <div
      class="pos-absolute media-comments-badge"
      :style="
        media.commentPoint.length == completedMediaPoints.length
          ? `background: #15d89a`
          : ''
      "
      v-if="media.type.match(/image.*/) && media.commentPoint.length > 0"
    >
      <span
        v-if="media.commentPoint.length <= 9 || completedMediaPoints.length"
      >
        {{ completedMediaPoints.length + "/" + media.commentPoint.length }}
      </span>
      <span v-else>9+</span>
    </div>
  </div>
</template>
<script>
import { UpdateMediaMutation } from "@/gql";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import { getDownloadUrl } from "@/services/downloadS3File";
import { SET_WORKSPACE_EDITOR_MEDIA } from "@/store/mutation-types";

export default {
  name: "CardMedia",
  mixins: [FileExtensionMixin],
  props: [
    "media",
    "height",
    "removeMediaFile",
    "fullview",
    "canRemoveMedia",
    "postId",
    "forceDisableRemove",
    "videoPlayer",
    "audioPlayer",
    "pdfView",
    "companyId",
    "workspace",
    "activeCompany",
    "addMediaBookmark",
    "removeMediaBookmark",
    "fromComputer",
    "copyMoveFileFolderHandler",
    "selectedIds",
    "toggleSelect",
    "isFolderOpenView",
    "editDoc",
    "currentUser"
  ],
  data() {
    return {
      filterTask: {
        label: false,
      },
    };
  },
  methods: {
    async updateReadOnlyHandler(flag) {
      try {
        let is_read_only = flag;
        const variables = {
          id: this.media.id,
          is_read_only,
        };
        await this.$api.mutate({
          mutation: UpdateMediaMutation,
          skipUpdates: true,
          variables,
        });

        const media = this.$api.getEntity("media", this.media.id);
        media.is_read_only = is_read_only;
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: flag ? "Document added read only mode." : "Document remove read only mode.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    openViewMode() {
      if (this.isImg) {
        this.fullview({ media: this.media, newpoint: "no" });
      } else if (this.isDoc && this.isS3) {
        this.openNextTabLink();
      } else if (this.isAudio) {
        this.audioPlayer(this.media);
      } else if (this.isGoogle || this.isDropbox) {
        this.openNextTabLink();
      } else if (this.isVideo && this.isS3) {
        this.videoPlayer(this.media);
      } else if (this.isZip) {
        this.downloadFile(this.media.id);
      } else if (this.media.is_document_section) {
        this.editDoc(this.media);
      }
    },
    openNextTabLink() {
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) window.electron.openExternal(this.media.file);
      else window.open(this.media.file, "_blank");
    },
    attachToPost() {
      let value = {};
      const files = this.$store.getters.workspaceEditorMedia(
        this.companyId,
        this.workspace
      );

      if (files) {
        value = files;
      }

      value[this.media.id] = this.media;
      this.$store.commit(SET_WORKSPACE_EDITOR_MEDIA, {
        value,
        companyId: this.companyId,
        workspaceId: this.workspace,
      });
      this.$router.push(
        `/companies/${this.activeCompany}/workspaces/${this.workspace}/messaging`
      );
    },
    pdfViewMethod(media) {
      this.pdfView(media);
    },
    async clickonmoreoption(name) {
      if (name.title === "Add Comment") {
        const data = {
          media: this.media,
          newpoint: "yes",
        };
        this.fullview(data);
      } else if (name.title === "Copy Link") {
        const data = {
          title: "Copy Link",
          media: this.media,
          forscroll: "no",
        };
        this.$emit("copyLink", data);
      } else if (name.title === "Delete File") {
        this.removeMediaFile(this.postId, this.mediaId);
      }
    },
    async downloadFile(id) {
      const response = await getDownloadUrl(id, this.$api);
      if (response !== null) {
        window.location = response;
      }
    },
  },
  computed: {
    completedMediaPoints() {
      return this.media.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
    currentWorkspace() {
      return this.workspace ? this.workspace : -1;
    },
    fileExt() {
      if (this.media) {
        return this.media.filename && this.media.filename.split(".")[1];
      }
      return null;
    },
    isDoc() {
      if (
        this.fileExt &&
        (this.fileExt.match(/pdf.*/) ||
          this.fileExt.match(/txt.*/) ||
          this.fileExt.match(/html.*/) ||
          this.fileExt.match(/doc.*/) ||
          this.fileExt.match(/pptx.*/) ||
          this.fileExt.match(/xls.*/) ||
          this.fileExt.match(/rtf.*/))
      ) {
        return true;
      }
      return false;
    },
    mediaId() {
      return this.media.id;
    },
    isS3() {
      return this.media.file_resource === "s3";
    },
    isGoogle() {
      return this.media.file_resource === "google";
    },
    isDropbox() {
      return this.media.file_resource === "dropbox";
    },
    isImg() {
      return this.media.type.match(/image.*/);
    },
    isVideo() {
      return this.media.type.match(/video.*/);
    },
    isAudio() {
      return this.media.type.match(/audio.*/);
    },
    isZip() {
      return this.media.type.match(/zip.*/);
    },
    filename() {
      return this.media.filename;
    },
    mediaPreview() {
      return this.transformMedia(this.media);
    },
    imgSrc() {
      if (this.isDropbox && this.isVideo) {
        return "/static/media-icons/Mov.svg";
      }
      if (this.isDropbox && this.isImg) {
        return this.media.thumb;
      }
      if (this.isGoogle) {
        return this.media.thumb;
      }
      // Used Mixin : FileExtensionMixin
      return this.mediaPreview.type;
    },
    canRemove() {
      return this.canRemoveMedia;
    },
    forceDisableDelete() {
      return this.forceDisableRemove ? this.forceDisableRemove : false;
    },
    mediaDiamension() {
      if (this.height) return `${this.height}px`;
      return "133px";
    },
  },
};
</script>
