<template>
  <div
    @click="setActiveDocumentHandler(document.id)"
    :class="[
      activeDocumentId === document.id && 'active-item',
      'row flex-no-wrap items-center document-list-items cursor-pointer',
    ]"
  >
    <q-menu context-menu content-class="overflow-auto">
      <q-item
        clickable
        class="q-mb-sm"
        @click="$emit('renameFile', document)"
        v-close-popup
      >
        <q-item-section> Rename </q-item-section>
      </q-item>
      <q-separator />
      <q-item
        clickable
        @click="deleteFile(document.id)"
        class="text-red"
        v-if="canRemove || (currentUser && currentUser.id === document.user_id)"
        v-close-popup
      >
        <q-item-section> Delete File </q-item-section>
      </q-item>
    </q-menu>
    <img
      src="/static/media-icons/heycollab_icon_2.svg"
      alt="avatar"
      style="border-radius: 6px"
      class="document-icon"
    />
    <div class="document-title q-ml-sm ellipses" v-tooltip>
      {{ document.filename }}
      <q-tooltip v-if="isTruncated">
        {{ document.filename }}
      </q-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "document",
    "setActiveDocumentHandler",
    "activeDocumentId",
    "deleteFile",
    "canRemove",
  ],
  data() {
    return {
      isTruncated: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.document-list-items {
  height: 40px;
  &:hover,
  &.active-item {
    border-radius: 5px;
    background: #dcecfe;
  }
  .document-icon {
    height: 30px;
    width: 30px;
  }
  .document-title {
    font-size: 13px;
  }
}
</style>
