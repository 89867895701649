import { uploadOutSourceFile } from "@/services/auth";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import map from "lodash/map";

import { DeleteMediaMutation, GetNewMediaListMutation } from "@/gql";
import mixpanel from "@/mixpanel.js";

export default {
  data() {
    return {
      copiedFiles: {},
      valueCount: 0,
      query: false,
      showProgress: false,
    };
  },
  methods: {
    pastedContent({ file, preview }, type = null) {
      const files = {};
      const obj = {
        __typename: "media",
        commentPoint: [],
        comments: [],
        fileContent: file,
        file: "",
        file_resource: "outsource",
        filename: file.name,
        id: file.lastModified,
        name: file.name,
        size: file.size,
        sequence: file.sequence,
        thumb: preview,
        type: file.type,
        user_id: this.user.id,
        verifyKey: "",
        workspace_id: this.currentWorkspace,
      };
      files[obj.id] = obj;
      if (type) {
        this.copiedFiles = { ...this.copiedFiles, ...files };
      } else {
        this.fileList = { ...this.fileList, ...files };
      }
    },
    deleteFolderHandler(folder) {
      const folderList = this.folderList;
      if (folderList) {
        delete folderList[folder.id];
        this.folderList = { ...folderList };
      }
    },
    deleteCommonMediaHandler(media, type = null) {
      if (type) {
        const copiedFiles = this.copiedFiles;
        delete copiedFiles[media.id];
        this.copiedFiles = { ...copiedFiles };
      } else {
        const fileList = this.fileList;
        if (fileList) {
          delete fileList[media.id];
          this.fileList = { ...fileList };
        }
      }
    },
    commonOutSourceFileHandler(callback) {
      const outSourceFiles = filter(
        this.fileList,
        (a) => a.file_resource === "outsource"
      );
      let storageFull;
      if (outSourceFiles && outSourceFiles.length) {
        this.showProgress = true;
        this.$q.notify({
          classes: "success-notification",
          timeout: 3000,
          position: "top",
          message: "Uploading files...",
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.query = true;
        const fileObj = {
          fileData: map(outSourceFiles, (a) => a.fileContent),
          workspace_id: this.currentWorkspace,
        };
        uploadOutSourceFile(
          fileObj,
          this.currentCompany.accessToken,
          (res) => {
            storageFull = res.status_code;
            this.query = false;
            const count = Math.floor(100 / outSourceFiles.length);
            const newValue = this.valueCount + count;
            if (newValue > 100) {
              this.valueCount = 100 / 100;
            } else {
              this.valueCount = newValue / 100;
            }
          },
          (res) => {
            this.showProgress = false;
            this.valueCount = 0;
            let outsourceMediaIds = res.map((a) => a.id);
            callback(outsourceMediaIds, storageFull);
            mixpanel.track("Media Create");
            this.updateMediaCacheFromEditor(outsourceMediaIds);
          }
        );
      } else {
        callback();
      }
    },
    async deleteMediaMutation(media) {
      const variables = {
        id: media.id,
      };

      await this.$api.mutate({
        mutation: DeleteMediaMutation,
        variables,
      });
      mixpanel.track("Media Delete");
      if (this.notificationWorksape) {
        const query = this.$api.getQuery(
          `MediaQuery:${this.notificationWorksape}`
        );
        query.data.newMedia = query.data.newMedia.filter(
          (m) => m.id !== media.id
        );
      } else {
        const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
        query.data.newMedia = query.data.newMedia.filter(
          (m) => m.id !== media.id
        );
      }
    },
    async updateMediaCacheFromEditor(mediaIds) {
      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: mediaIds,
        },
      });
      if (this.notificationWorksape) {
        const query = this.$api.getQuery(
          `MediaQuery:${this.notificationWorksape}`
        );
        query.data.newMedia.unshift(...response.data.getNewMediaList);
      } else {
        const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
        query.data.newMedia.unshift(...response.data.getNewMediaList);
      }
    },
    attachMediaToPost(files, action = "s3", type = null) {
      if (action === "s3") {
        let data;
        for (var media in files) {
          data = {};
          data[files[media].id] = files[media];
          const fileList = this.fileList;
          this.fileList = { ...fileList, ...data };
        }
        if (this.sharedFileDialog) {
          this.sharedFileDialog = false;
        }
      } else if (action === "google") {
        const fileList = this.fileList;
        this.fileList = { ...fileList, ...files };
        //Attach media to folder from shared file
        if (type === "fromFolder") {
          const fileList = { ...fileList, ...files };
          this.makeDriveMediaData(fileList);
        }
      }
    },
    addSharedFileToUploader(files, type = null) {
      const sharedFiles = files.filter((f) => {
        if (f.hasOwnProperty("filename")) {
          return f;
        }
      });
      const sharedFolders = files.filter((f) => {
        if (f.hasOwnProperty("title")) {
          return f;
        }
      });
      if (sharedFiles.length) {
        let data;
        for (let media in sharedFiles) {
          const mediaEntity = this.$api.getEntity(
            "media",
            sharedFiles[media].id
          );
          mediaEntity.verifyKey = "fromShared";
          data = {};
          sharedFiles[media]["verifyKey"] = "fromShared";
          data[sharedFiles[media].id] = sharedFiles[media];
          const fileList = this.fileList;
          this.fileList = { ...fileList, ...data };
        }
        if (type === "fromTaskAttachment") {
          const mediaIds = sharedFiles ? map(sharedFiles, (o) => o.id) : [];
          this.attachTaskMediaHandler({
            mediaIds,
            medias: Object.values(this.fileList),
            task_id: this.task.id,
          });
          const coverMedia = sharedFiles[Object.keys(sharedFiles)[0]];
          if (coverMedia.type.match(/image.*/)) {
            this.setCoverImage(sharedFiles[Object.keys(sharedFiles)[0]]);
          }
          this.expandAttachmentHandler();
        }
        //Attach media to folder from shared file
        if (type === "fromFolder") {
          const mediaIds = sharedFiles ? map(sharedFiles, (o) => o.id) : [];
          this.attachMediasToFolder({
            media: mediaIds,
            folder_id: this.folder.id,
          });
        }
      }
      if (sharedFolders.length) {
        let data;

        for (let folder in sharedFolders) {
          data = {};
          data[sharedFolders[folder].id] = sharedFolders[folder];
          const folderList = this.folderList;
          this.folderList = { ...folderList, ...data };
        }
        if (type === "fromTaskAttachment") {
          const folderIds = sharedFolders
            ? map(sharedFolders, (f) => f.id)
            : [];
          this.attachTaskMediaFolder(folderIds, this.task.id);
        }
      }
      this.sharedFileDialog = false;
    },
    takeFilefromComputer() {
      this.$refs.myFileuploader.openFileDialog();
    },
    makeDriveData() {
      // For Any drive Files
      const driveData = [];
      const media_urls = filter(
        this.fileList,
        (a) =>
          a.file_resource !== "s3" &&
          a.file_resource !== "outsourcce" &&
          !a.hasOwnProperty("verifyKey")
      );
      media_urls &&
        media_urls.forEach((element) => {
          const obj = {
            file: element.file,
            filename: element.filename,
            type: element.type,
            file_resource: element.file_resource,
            size: element.size,
            sequence: element.sequence,
          };
          driveData.push(obj);
        });
      return driveData;
      // For Any drive Files
    },
    async fileUploadCloseHandler(data, type = null) {
      const fileList = this.fileList;
      this.fileList = { ...fileList, ...data };
      const mediaIds = data ? map(data, (o) => o.id) : [];
      if (mediaIds && mediaIds.length) {
        if (type === "fromTaskAttachment") {
          this.attachTaskMediaHandler({
            mediaIds,
            medias: Object.values(this.fileList),
            task_id: this.task.id,
          });
          this.expandAttachmentHandler();
        }
      }

      this.fileUpload = {
        flag: false,
        dialog: false,
      };

      if (isEmpty(data)) return;
      if (mediaIds && mediaIds.length) {
        this.updateMediaCacheFromEditor(mediaIds);
      }
    },
    uploadFromYourComputer(files) {
      if (files && files.length) {
        this.showProgress = true;
        this.$q.notify({
          classes: "success-notification",
          timeout: 3000,
          position: "top",
          message: "Uploading files...",
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        let fileObj = {
          fileData: files,
          workspace_id: this.currentWorkspace,
        };
        uploadOutSourceFile(
          fileObj,
          this.currentCompany.accessToken,
          () => {
            this.query = false;
            const count = Math.floor(100 / files.length);
            const newValue = this.valueCount + count;
            if (newValue > 100) {
              this.valueCount = 100 / 100;
            } else {
              this.valueCount = newValue / 100;
            }
          },
          (res) => {
            this.showProgress = false;
            this.valueCount = 0;
            let outsourceMediaIds = res.map((a) => a.id);
            mixpanel.track("Media Create");
            let data;
            let files = {};
            res.forEach((file) => {
              data = {};
              data[file.id] = file;
              files = { ...files, ...data };
            });
            this.fileList = { ...this.fileList, ...files };
            this.updateMediaCacheFromEditor(outsourceMediaIds);
          }
        );
      }
    },
  },
};
