import emojiRegexText from "emoji-regex";
import emojis from "@/components/Editor/emoji.json";
const XRegExp = require("xregexp");
const emojiRegex = emojiRegexText();

function parse(input) {
  const lines = input.trim().split("\n");

  return lines.map((line) => {
    const match = line.trim().match(/^-\s*(.*)/);

    if (match !== null) {
      return {
        type: "bullet",
        children: parseInline(match[1]),
      };
    }

    return {
      type: "paragraph",
      children: parseInline(line),
    };
  });
}

function parseInline(input) {
  // empty string
  if (input === "") return [];
  const regex = XRegExp(
    `
      ^
      (?<prefix>.*?\\s)??
      (?:
        (?<bold>\\*.*?\\*)            |
        (?<italic>_.*?_)              |
        (?<mention>@[a-zA-Z0-9-._,]+)   |
        (?<mentionss>task=([0-9])+)   |
        (?<link>https?:\\/\\/[^\\s]*)
      )
      (?<suffix>\\s.*)?
      $
    `,
    "x"
  );
  const match = XRegExp.exec(input, regex);
  // simple text
  if (match === null) return [input];

  // const prefix = input.slice(0, match.index);
  // const suffix = input.slice(match.index + match[0].length);

  let node;

  // bold
  if (match.bold) {
    node = {
      type: "bold",
      children: parseInline(match.bold.slice(1, -1)),
    };
  }
  // italic
  else if (match.italic) {
    node = {
      type: "italic",
      children: parseInline(match.italic.slice(1, -1)),
    };
  }
  // mention
  else if (match.mention) {
    node = {
      type: "mention",
      username: match.mention.slice(1),
    };
  }
  // mention
  // else if (match.taskmention) {
  //   console.log("match.taskmention", match.taskmention)
  //   node = {
  //     type: "taskmention",
  //     task: match.taskmention.slice(1),
  //   };
  // }
  // link
  else if (match.link) {
    node = {
      type: "link",
      href: match.link,
    };
  }

  const nodes = [node];

  if (match.prefix !== "") nodes.unshift(match.prefix);
  if (match.suffix !== "") nodes.push(...parseInline(match.suffix));

  return nodes;
}

function render(nodes) {
  return nodes.map(renderNode).join("");
}

function renderNode(node) {
  if (typeof node === "string") {
    // NSAttributedString(string: node)
    return node;
  }

  // block

  if (node && node.type === "paragraph") {
    return `<p>${render(node.children)}</p>`;
  }

  if (node && node.type === "bullet") {
    return `<p class="bullet"><span>${render(node.children)}</span></p>`;
  }

  // inline

  if (node && node.type === "bold") {
    // bold
    return `<strong>${render(node.children)}</strong>`;
  }

  if (node && node.type === "italic") {
    return `<em>${render(node.children)}</em>`;
  }

  if (node && node.type === "mention") {
    // return `<a href="mention://${node.username}">@${node.username}</a>`;
    return `<span data-id=${node.username.replace(/[^A-Za-z0-9-]$/, '')} class="mention">${node.username}</span>`;
  }
  
  if (node && node.type === "link") {
    // return `${node.href}`;
    if (node.href.includes(process.env.VUE_APP_APPBASE_URL) && getQueryParameterByName("task", node.href)) {
      let workspaceId = getQueryParameterByName("workspace", node.href);
      let workspaceName = getQueryParameterByName("workspaceName", node.href);
      let subtaskId = getQueryParameterByName("sub_task", node.href);
      let childSubtaskId = getQueryParameterByName("child_sub_task", node.href);
      let taskId = getQueryParameterByName("task", node.href);
      let taskStr;
      if(workspaceName) {
        taskStr = subtaskId ? 'S'+workspaceName.substring(0, 2).toUpperCase()+'-'+subtaskId : 'T'+workspaceName.substring(0, 2).toUpperCase()+'-'+taskId;
      }else {
        taskStr = subtaskId ? 'S'+subtaskId : 'T'+taskId;
      }
      let dataId = childSubtaskId ? taskId+'-'+workspaceId+'-'+subtaskId+'-'+ childSubtaskId : subtaskId ? taskId+'-'+workspaceId+'-'+subtaskId :  taskId+'-'+workspaceId;
      return `<span data-id=${dataId} class="taskmention">${taskStr}</span>`;
    } else if (node.href.includes(process.env.VUE_APP_MEET_URL)) { 
      let segments = new URL(node.href).pathname.split('/');
      let roomName = segments[1];
      let userId = segments[2];
      let copyUrl = segments.length > 2 ? node.href.slice(0, node.href.lastIndexOf('/')) : node.href;
      return `<div style="position: relative;max-width: 220px;width: 100%;"><img data-url=${copyUrl}  data-user=${userId}  data-room=${roomName}  class="open-video-call" src="/static/images/join-call.svg" /><img title="Copy call link" data-url=${copyUrl} class="copy-video-call" src="/static/images/link.png" /></div>`;
    } else if (getQueryParameterByName("h_link_name", node.href)) {
      let linkName = getQueryParameterByName("h_link_name", node.href);
      let url = node.href.split(/[?#]/)[0];
      return `<a target="_blank" href="${url}">${linkName.replace(/_/g, ' ')}</a>`;
    } else {
      return `<a target="_blank" href="${node.href}">${node.href}</a>`;
    }
  }
}

export function emojiShortCodeParsing(input) {
  return input.replace(/:.*?:/g, (match) => {
    const shortcode = match.slice(1, -1);
    return emojis[shortcode]
      ? `<span class="editor-emoji">${emojis[shortcode]}</span>`
      : match;
  });
}

export function emojiParsing(input) {
  return input.replace(emojiRegex, `<span class="editor-emoji">$&</span>`);
}

export function compile(input) {
  if (input) {
    return render(
      parse(
        emojiShortCodeParsing(
          emojiParsing(input.replace(/</g, "&lt;").replace("</", "&lt;/"))
        )
      )
    );
  }
  return `<span></span>`;
}

export function checkImageUrl(input) {
  const match = input
    .trim()
    .match(
      /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|JPEG|JPG|GIF|PNG|gif|png)/
    );
  if (match && match.length) {
    return true;
  }
  return false;
}

export function getQueryParameterByName(name, url) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(url);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}