<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
    @keydown.esc="closeDialog"
  >
    <q-card
      class="doc-shortcut-list-wrapper q-pa-lg full-width overflow-hidden"
      >
      <div class="text-bold text-center">Shortcut list</div>
      <div @click="closeDialog" class="cursor-pointer absolute close-icon">
        <q-icon :name="$icons.matClose" size="20px" />
      </div>
      <div>
        <table :style="isFrom === 'message' && { height: 'auto'}">
          <thead class="stick-at-top">
            <tr>
              <th>Action</th>
              <th>Shortcut</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="shortcut.action" v-for="shortcut in shortcutList">
              <td>{{ shortcut.action }}</td>
              <td>{{ shortcut.shorcut }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import { shortcuts } from "../../utils/Contents";
import { messageShortcuts } from "../../utils/Contents";
export default {
  name: "DocumentShortcutsDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isFrom: {
      type: String,
      default: null,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
  },
  computed: {
    shortcutList() {
      if(this.isFrom === "message") {
        return messageShortcuts;
      } else {
        let sortedList = shortcuts.sort(function(a, b) {
          var textA = a.action.toUpperCase();
          var textB = b.action.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        return sortedList;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.doc-shortcut-list-wrapper {
  border-radius: 20px;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    height: 500px;
    overflow-y: scroll;
  }
  .close-icon {
    top: 10px;
    right: 10px;
    color: #535d67;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    color: #535d67;
  }
  th {
    font-weight: 600;
    background-color: #ecf1f5;
  }
  td:last-child,
  th:last-child {
    width: 60%;
  }
  tr {
    width: 100%;
    display: table;
  }
}
</style>
