import uniq from "lodash/uniq";
import difference from "lodash/difference";
export const extractMentionsFromContent = (content, mentionList = []) => {
  const regex = /@[0-9a-zA-Z-.]+/g;
  if (content) {
    const match = content.match(regex);
    const newmentions = [];
    if (match) {
      match.forEach(element => {
        const filtered = mentionList.filter(
          a => a.username === element.slice(1)
        );
        if (filtered && filtered.length) {
          newmentions.push(filtered[0].id);
        }
      });
      return uniq(newmentions);
    }
    return [];
  }
  return [];
};

export const extractDiffMentionsFromContent = (
  mentioned,
  previouslyMentioned
) => {
  return mentioned.length
    ? uniq(difference(mentioned, previouslyMentioned))
    : [];
};
