<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card class="q-px-lg q-py-md bg-white task-duplication">
      <div class="close-btn-bg">
        <q-btn
          :icon="$icons.matClose"
          text-color="blue-grey-8"
          round
          flat
          padding="0"
          dense
          @click="closeDialog"
          v-close-popup
        />
      </div>
      <div class="row title">
        <span class="q-mx-auto">{{title}}</span>
      </div>
      <div class="text-bold q-mt-sm q-mb-sm ">
        Workspace
      </div>
      <div class="q-mt-sm">
        <q-btn
          outline
          size="md"
          class="dropdown-set full-width"
          no-caps
          style="font-weight:400"
          :icon-right="$icons.matKeyboardArrowDown"
          :label="selectedWorkspace && selectedWorkspace.title"
        >
          <q-menu content-class="workspace-menu" auto-close>
            <div class="search-member">
              <q-input
                autofocus
                ref="search"
                v-model="search"
                outlined
                placeholder="Filter workspaces"
                clearable
                dense
              >
                <template v-slot:prepend>
                  <q-icon :name="$icons.matSearch" />
                </template>
              </q-input>
            </div>
            <q-list style="min-width:140px; max-height: 250px; overflow: auto">
              <q-item
                @click="chooseWorkspace(workspace)"
                v-for="workspace in filteredWorkspaces"
                :key="workspace.id"
                clickable
              >
                <q-item-section>
                  {{ workspace.title }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
      <div class="text-bold q-mt-sm q-mb-sm ">
        Task
      </div>
      <div v-if="taskList" class="q-mt-sm">
        <q-btn
          outline
          size="md"
          class="dropdown-set full-width"
          no-caps
          style="font-weight:400"
          :icon-right="$icons.matKeyboardArrowDown"
          :label="selectedTask && selectedTask.title"
        >
          <q-menu content-class="overflow-auto" auto-close>
            <div class="search-member">
              <q-input
                autofocus
                ref="searchTask"
                v-model="searchTask"
                outlined
                placeholder="Filter workspaces"
                clearable
                dense
              >
                <template v-slot:prepend>
                  <q-icon :name="$icons.matSearch" />
                </template>
              </q-input>
            </div>
            <q-list style="min-width:140px; max-height: 250px; overflow: auto">
              <q-item
                @click="chooseTask(task)"
                v-for="task in filteredTasks"
                :key="task.id"
                clickable
              >
                <q-item-section>
                  {{ task.title }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="15px"
          label="Cancel"
          style="width:120px"
          class="q-mr-sm"
          padding="5px 5px"
          @click="closeDialog"
        />
        <q-btn
          dense
          no-caps
          padding="5px 5px"
          class="create-task-btn"
          color="primary"
          size="md"
          :label="title"
          :disable= "!taskList.length"
          @click="createTaskHandler"
          :loading="isLoading"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";

import {
  MoveSubtaskMutation,
  DeleteSubTaskMutation,
  TasksQuery,
} from "@/gql";
import Fuse from "fuse.js";

export default {
  name: "MoveSubtaskDialog",
  props: ["value", "subtask", "closeDialog", "task", "title", "type", "isFromSubtask"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    workspaces: {
      cacheKey: "Workspaces2Query",
      defaultValue: null,
    },
  },
  data() {
    return {
      selectedWorkspace: null,
      selectedTask: null,
      taskList: null,
      isLoading: false,
      subtaskData: {
        title: null,
        start_date: null,
        end_date: null,
        includeDescription: true,
        includeAttachments: true,
      },
      search: null,
      searchTask: null,
    };
  },
  mounted() {
    this.selectedWorkspace = this.currentWorkspace;
    this.taskList = this.currentWorkspace.boards[0].tasks;
    this.selectedTask = this.taskList[0];
  },
  methods: {
    chooseWorkspace(workspace) {
      this.selectedWorkspace = workspace;
      this.getTasks(workspace.id);
    },
    async getTasks(workspaceId) {
      this.taskList = [];
      if (!this.selectedWorkspace.boards[0].tasks) {
        const query = this.$api.getQuery(
          `TasksQuery:${this.selectedWorkspace.id}`
        );
        if (query.data && query.data.tasks) {
          this.taskList = query.data.tasks;
          this.selectedTask = this.taskList[0];
        } else {
          this.isLoading = true;
        }
        const response = await this.$api.query({
          query: TasksQuery,
          variables: {
            workspace_id: workspaceId,
          },
          cacheKey() {
            return `TasksQuery:${this.selectedWorkspace.id}`;
          },
        });
        this.isLoading = false;
        if (response.data) {
          this.taskList = response.data.tasks;
          this.selectedTask = this.taskList[0];
        }
      } else {
        this.taskList = this.selectedWorkspace.boards[0].tasks;
        this.selectedTask = this.taskList[0];
      }
    },
    chooseTask(task) {
      this.selectedTask = task;
    },
    createTaskHandler() {
      this.isLoading = true;
      const subtask = {
        workspace_id: this.currentWorkspaceId,
        title: this.subtask.title,
        task_id: this.selectedTask.id,
        end_date: this.subtask.end_date,
        details: this.subtask.details,
        media: [],
        childSubtasks: [],
      };

      if (this.subtask && this.subtask.media) {
        const mediaIds = map(this.subtask.media, (o) => o.id);
        subtask.media = mediaIds;
      }

      if (this.subtask && this.subtask.mediafolder) {
        const mediaFolderIds = map(this.subtask.mediafolder, (o) => o.id);
        subtask.folder = mediaFolderIds;
      }

      if(this.subtask.childSubtasks.length > 0) {
        const childSubtasks = map(this.subtask.childSubtasks, (childSub) => {
          const childMedia = childSub.media.map((s) => s.id);
          const childMediaFolderIds = map(childSub.mediafolder, (o) => o.id);
          const child = {
            title: childSub.title,
            user_id: this.user.id,
            sequence: childSub.sequence,
            media: childMedia,
            folder: childMediaFolderIds,
          }
          return child;
        });
        
        subtask.childSubtasks = childSubtasks;
      }

      this.addTask(subtask);
    },
    async addTask(subtask) {
      
      const variables = {
        ...subtask,
      };

      await this.$api
        .mutate({
          mutation: MoveSubtaskMutation,
          variables,
        })
        .then(async (response) => {
          const boardQuery = this.$api.getQuery(
            `BoardQuery:${this.selectedWorkspace.boards[0].id}`
          );
          if (boardQuery.data) {
            const taskEntity = this.$api.getEntity("task", this.selectedTask.id);
            taskEntity.subtasks.push(response.data.moveSubtask);
          }

          if(this.type == "move") {
            const variables = {
              id: this.subtask.id,
            };

            if(this.isFromSubtask) {
              const subtaskEntity = this.$api.getEntity("subTask", this.subtask.parent_id);
              subtaskEntity.childSubtasks = subtaskEntity.childSubtasks.filter(
                (s) => s.id !== this.subtask.id
              );
            } else {
              const taskEntity = this.$api.getEntity("task", this.task.id);
              taskEntity.subtasks = taskEntity.subtasks.filter((s) => s.id !== this.subtask.id);
            }
            
            await this.$api.mutate({
              mutation: DeleteSubTaskMutation,
              variables,
            });
          }

          this.isLoading = false;
          this.$q.notify({
            classes: "success-notification",
            position: "top-right",
            message: this.type == "move" ? "Subtask moved successfully." : "Subtask copied successfully.",
            icon: this.$icons.matAnnouncement,
          });
          this.closeDialog();
        });
    },
  },
  computed: {
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
    currentWorkspace() {
      return (
        this.workspaces &&
        this.workspaces.find((w) => w.id === Number(this.currentWorkspaceId))
      );
    },
    cardTasks: {
      get() {
        return groupBy(
          orderBy(this.selectedWorkspace.boards[0].tasks, ["sequence"]),
          "card_id"
        );
      },
    },
    workspaceIds() {
      return this.workspaces.map((m) => m.id);
    },
    filteredWorkspacesFuse() {
      if (this.workspaces) {
        const workspaces = this.workspaces.filter((workspace) =>
          this.workspaceIds.includes(workspace.id)
        );
        const orderedWorkspaces = orderBy(workspaces, ["title"], ["asc"]);
        return new Fuse(orderedWorkspaces, {
          keys: ["title"],
          shouldSort: true,
        });
      }
      return this.workspaces.filter(
        (workspace) => !this.workspaceIds.includes(workspace.id)
      );
    },
    filteredWorkspaces() {
      return this.search
        ? this.filteredWorkspacesFuse.search(this.search)
        : this.filteredWorkspacesFuse.hasOwnProperty("list")
        ? this.filteredWorkspacesFuse.list
        : this.filteredWorkspacesFuse;
    },
    filterTaskFuse() {
      if (this.taskList.length) {
        return new Fuse(this.taskList, {
          keys: ["title", "id"],
          shouldSort: true,
          minMatchCharLength: 3,
          threshold: 0.0,
        });
      }
      return [];
    },
    filteredTasks() {
      return uniqBy(this.searchTask
        ? this.filterTaskFuse.search(this.searchTask)
        : this.filterTaskFuse.hasOwnProperty("list")
        ? this.filterTaskFuse.list
        : this.filterTaskFuse, 'id');
    },
    // tasks() {
    //   if (this.selectedWorkspace.boards[0].tasks) {
    //     return this.selectedWorkspace.boards[0].tasks;
    //   } else {
    //     return this.taskList;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.search-member {
  padding: 15px 25px 0px 25px;
}
</style>
