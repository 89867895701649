<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    maximized
    :value="value"
  >
    <q-card>
      <div class="pdf-close-dialog ">
        <q-btn
          text-color="blue-grey-8"
          :icon="$icons.matClose"
          size="md"
          round
          flat
          dense
          padding="0"
          class="q-mr-md"
          :style="
            fileExt.match(/rtf.*/) && isEle
              ? { left: '0', top: '35px' }
              : fileExt.match(/rtf.*/)
              ? { left: '0' }
              : {}
          "
          @click="$emit('resetPdfDialog')"
        >
          <q-tooltip>
            Close
          </q-tooltip>
        </q-btn>
      </div>
      <other-doc-viewer :docUrl="docUrl"></other-doc-viewer>
    </q-card>
  </q-dialog>
</template>
<script>
import OtherDocViewer from "@/components/Miscellaneous/OtherDocViewer";
export default {
  name: "PdfViewDialog",
  props: ["value", "pdfMediaData", "fileExt"],
  components: {
    OtherDocViewer,
  },
  computed: {
    isEle() {
      return navigator.userAgent.indexOf("Electron") !== -1;
    },
    docUrl() {
      if (this.fileExt) {
        if (this.fileExt.match(/txt.*/)) {
          return this.pdfMediaData.file;
        } else if (this.fileExt.match(/pdf.*/) || this.fileExt.match(/key.*/)) {
          return this.pdfMediaData.file + "#toolbar=0";
        } else if (
          this.fileExt.match(/html.*/) ||
          this.fileExt.match(/rtf.*/)
        ) {
          return `https://docs.google.com/gview?url=${encodeURI(
            this.pdfMediaData.file
          )}&embedded=true`;
        } else {
          return `https://view.officeapps.live.com/op/embed.aspx?src=${this.pdfMediaData.file}`;
        }
      }
      return null;
    },
  },
};
</script>
